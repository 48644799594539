import { rgba, darken } from 'polished';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import AtheleteCardBackground from '../../assets/athelete-card-background.jpg';

interface IChampionshipCard {
  $bg?: string;
}

interface IAthletesOfTheWeekBanner {
  $bg?: string;
}

interface IRankingSelectorProps {
  $selected: boolean;
}

interface IRankingBanner {
  $bg: string;
}

export const Container = styled.div`
  /* padding: 64px 56px; */
  padding: 64px 0;

  @media only screen and (max-width: 1199px) {
    /* padding: 40px 32px; */
    padding: 40px 0;
  }

  @media only screen and (max-width: 600px) {
    /* padding: 32px 16px; */
    padding: 32px 0;
  }
`;

export const Session = styled.div`
  position: relative;
  margin-bottom: 32px;

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: column;

    /* align-items: center; */
    /* justify-content: space-between; */

    /* > div {
      display: flex;
      align-items: center;
      margin-top: 4px;

      a {
        & + a {
          margin-left: 8px;
        }
      }
    } */

    padding: 0 56px;
    @media only screen and (max-width: 1199px) {
      padding: 0 40px;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }

  /* > button {
    position: absolute;
    z-index: 10;
    top: 60px;
    height: 288px;
    width: 56px;
    background: ${props => rgba(props.theme.colors.dark.dark0, 0.6)};
    display: flex;
    align-items: center;
    border: 0;
    transition: background 0.2s;

    &:first-child {
      left: 0;

      svg {
        margin-left: 4px;
      }
    }

    &:last-child {
      right: 0;
      justify-content: flex-end;

      svg {
        margin-right: 4px;
      }
    }

    &:hover {
      background: ${props => rgba(props.theme.colors.dark.dark0, 0.8)};
    }

    svg {
      color: ${props => props.theme.colors.light['light-2']};
    }
  } */

  /* > ul {
    display: flex;
    margin-top: 16px;
    margin-bottom: 0;
    overflow-x: auto;
    position: relative;

    &::after,
    &::before {
      content: '';

      min-width: 56px;
      @media only screen and (max-width: 1199px) {
        min-width: 40px;
      }

      @media only screen and (max-width: 600px) {
        min-width: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  } */

  > p {
    padding: 0 56px;
    margin-top: 24px;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }

    padding: 0 56px;
    @media only screen and (max-width: 1199px) {
      padding: 0 32px;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }
`;

export const SessionActionButtonsContainer = styled.div`
  ul {
    display: flex;
    margin-top: 4px;
    margin-bottom: 0;
    overflow-x: auto;
    position: relative;

    &::after,
    &::before {
      content: '';

      min-width: 56px;
      @media only screen and (max-width: 1199px) {
        min-width: 40px;
      }

      @media only screen and (max-width: 600px) {
        min-width: 16px;
      }
    }

    a {
      & + a {
        margin-left: 8px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ChampionshipCard = styled(Link)<IChampionshipCard>`
  width: 272px;
  height: 288px;
  border-radius: 8px;
  background: url(${props =>
      props.$bg ||
      'https://i0.statig.com.br/bancodeimagens/16/6u/li/166uli5jnowompk9gicgwodq8.jpg'})
    no-repeat center;
  background-size: cover;
  position: relative;
  /* flex: 10 0 auto; */
  flex-shrink: 0;

  & + a {
    margin-left: 8px;
  }

  > div {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px;
    position: absolute;
    background: ${props => rgba(props.theme.colors.dark['dark+1'], 0.9)};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    > img {
      width: 50px;
    }

    > div {
      p {
        color: ${props => props.theme.colors.light['light-2']};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const AthletesOfTheWeekSession = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0 56px;

  @media only screen and (max-width: 1199px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        & + a {
          margin-left: 8px;
        }
      }
    }
  }
`;

export const AthletesOfTheWeekBanner = styled(Link)<IAthletesOfTheWeekBanner>`
  width: 100%;
  height: 176px;
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(69, 69, 69, 0.9),
      rgba(69, 69, 69, 0.9)
    ),
    url(${props => props.$bg || AtheleteCardBackground}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;

  > div {
    p {
      color: ${props => props.theme.colors.light['light-2']};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
`;

export const CoachFriendRoomCard = styled(Link)`
  width: 272px;
  /* height: 288px; */
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;

  & + a {
    margin-left: 8px;
  }

  > div:nth-child(1) {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div {
      display: flex;
      align-items: center;
      /* Private Room Indicator */
      svg {
        color: ${props => props.theme.colors.primary.primary0};
        margin-left: 8px;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 16px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary['primary+1']};
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }

  > div:last-child {
    width: 100%;
    padding-top: 8px;
    border-top: 1px solid ${props => props.theme.colors.dark['dark+4']};
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        font-weight: ${props => props.theme.typograph.weights.medium};
        color: ${props => props.theme.colors.light.light0};
        margin: 0;

        &:nth-child(1) {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          margin-left: 16px;
          flex-shrink: 0;
        }
      }
    }
  }

  /* > div {
    > img {
      width: 50px;
    }

    > div {
      p {
        color: ${props => props.theme.colors.light['light-2']};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  } */
`;

export const CoachFriendRoomTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }
`;
export const CoachFriendRoomStatusIndicator = styled.small`
  padding: 2px 8px;
  border-radius: 4px;

  background: #e4ce00;
  color: ${props => props.theme.colors.dark.dark0} !important;
`;

export const RankingsSession = styled.div`
  position: relative;
  margin-bottom: 32px;

  > div:nth-child(1),
  > div:nth-child(3) {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0 56px;
    @media only screen and (max-width: 1199px) {
      padding: 0 40px;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 16px;
    }

    &:nth-child(3) {
      margin-top: 24px;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    /* max-width: 688px; */
    display: flex;
    /* margin-top: 16px; */
    margin-top: 4px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::after,
    &::before {
      content: '';
      min-width: 56px;
      @media only screen and (max-width: 1199px) {
        min-width: 40px;
      }

      @media only screen and (max-width: 600px) {
        min-width: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  /* > button {
    position: absolute;
    z-index: 10;
    top: 60px;
    height: 288px;
    width: 56px;
    background: ${props => rgba(props.theme.colors.dark.dark0, 0.6)};
    display: flex;
    align-items: center;
    border: 0;
    transition: background 0.2s;

    &:first-child {
      left: 0;

      svg {
        margin-left: 4px;
      }
    }

    &:last-child {
      right: 0;
      justify-content: flex-end;

      svg {
        margin-right: 4px;
      }
    }

    &:hover {
      background: ${props => rgba(props.theme.colors.dark.dark0, 0.8)};
    }

    svg {
      color: ${props => props.theme.colors.light['light-2']};
    }
  } */

  /* > ul {
    display: flex;
    margin-top: 16px;
    margin-bottom: 0;
    overflow-x: auto;
    position: relative;

    &::after,
    &::before {
      content: '';

      min-width: 56px;
      @media only screen and (max-width: 1199px) {
        min-width: 40px;
      }

      @media only screen and (max-width: 600px) {
        min-width: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  } */

  > p {
    padding: 0 56px;
    margin-top: 24px;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }

    padding: 0 56px;
    @media only screen and (max-width: 1199px) {
      padding: 0 32px;
    }

    @media only screen and (max-width: 600px) {
      padding: 0 16px;
    }
  }
`;

export const RankingBanner = styled(Link)<IRankingBanner>`
  width: 100%;
  padding: 16px;
  background: linear-gradient(
      0deg,
      rgba(69, 69, 69, 0.92),
      rgba(69, 69, 69, 0.92)
    ),
    url(${props => props.$bg}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  > div {
    align-self: center;
    display: flex;
    margin-top: 64px;
    z-index: 5;
  }

  > small {
    text-align: end;
    /* margin-top: -24px; */
    color: ${props => props.theme.colors.light['light-2']};
  }
`;

export const RankingTop3Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 104px;

  &:nth-child(2) {
    margin: 0 16px;
  }

  p:first-child {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  svg {
    color: #f8c700;
    margin-bottom: 8px;
  }

  span {
    /* width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%; */
    border: 3px solid ${props => props.theme.colors.primary.primary0};
    box-sizing: content-box;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
  }

  small {
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  small:last-child {
    color: ${props => props.theme.colors.primary.primary0};
    margin-top: 8px;
  }

  &:nth-child(2) {
    span {
      /* width: 100px;
      height: 100px; */
      box-shadow: 0px 0px 20px
        ${props => rgba(props.theme.colors.primary.primary0, 0.5)};
    }

    transform: translateY(-60px);
  }
`;

export const RankingSelector = styled.button<IRankingSelectorProps>`
  border: 0;
  padding: 4px 8px;
  background: ${props => props.theme.colors.dark['dark+2']};
  color: ${props => props.theme.colors.light['light-2']};
  border-radius: 4px;
  transition: 0.2s box-shadow;
  flex-shrink: 0;

  & + button {
    margin-left: 8px;
  }

  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
      color: ${props.theme.colors.dark.dark0};
    `}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  &:hover {
    box-shadow: 1px 1px 3px
      ${props => darken(0.1, props.theme.colors.dark.dark0)};
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
  /* padding: 0 56px;
  @media only screen and (max-width: 1199px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  } */
  margin-bottom: 32px;
`;

export const AdvertisementItemContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 42.85%;
  margin-bottom: 8px;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    img {
      z-index: 5;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    div.shimmer {
      z-index: 5 !important;
      width: 100% !important;
      height: 100% !important;
      border: 1px solid ${props => props.theme.colors.light['light-2']} !important;
    }
  }
`;
