import { createGlobalStyle } from 'styled-components';

import theme from './theme';

import './App.less';
// import './antd.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-crop/dist/ReactCrop.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    background: ${theme.colors.dark.dark0};
    color: #fff;
    -webkit-font-smoothing: antialiased;
    font-size: ${theme.typograph.sizes.p}px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  body, input, button {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: ${theme.typograph.weights.bold};
    color: ${theme.colors.light['light-2']};
    margin-bottom: 0px;
  }

  h4 {
    font-size: ${theme.typograph.sizes.h4}px;
    color: ${theme.colors.light['light-2']};
  }

  h5 {
    font-size: ${theme.typograph.sizes.h5}px;
    color: ${theme.colors.light['light-2']};
    display: inline-block;
  }

  h6 {
    font-size: ${theme.typograph.sizes.h6}px;
    color: ${theme.colors.light['light-2']};
  }

  p {
    font-size: ${theme.typograph.sizes.p}px;
    font-weight: ${theme.typograph.weights.medium};
    color: ${theme.colors.light.light0};
    margin-bottom: 0px;
    line-height: 120%;
  }

  strong {
    font-size: ${theme.typograph.sizes.p}px;
    line-height: 120%;
  }

  small {
    font-size: ${theme.typograph.sizes.small}px;
    font-weight: ${theme.typograph.weights.medium};
    color: ${theme.colors.light.light0};
    display: block;
    line-height: 120%;
  }

  button {
    cursor: pointer;
  }

  .ant-tabs-tab:nth-last-child(2) {
    margin-right: 0;
  }

  /* Styling for select-language */
  .ant-select-selection-item {
    white-space: pre !important;
  }
  .ant-select-item-option-content {
    white-space: pre !important;
  }

  /* Styling for carroussel */
  .ant-carousel .slick-dots li button, .ant-carousel .slick-dots li.slick-active button {
    background: ${theme.colors.light.light0};
  }

  .noscroll {
    overflow: hidden;
  }
`;
