import styled, { css } from 'styled-components';

interface IChallengerWinnerStatusDescription {
  $status: 'win' | 'lose' | 'wait';
}

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+3']};
  padding: 1.25rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UsersAvatarsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const NoOpponentAvatar = styled.p`
  cursor: default;
  font-size: ${props => props.theme.typograph.sizes.h6}px;
  font-weight: ${props => props.theme.typograph.weights.bold};
  color: ${props => props.theme.colors.dark.dark0};
  background-color: ${props => props.theme.colors.light['light-2']};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100%;
`;

export const ChallengersDescription = styled.p`
  font-size: ${props => props.theme.typograph.sizes.small}px;
  font-weight: ${props => props.theme.typograph.weights.medium};
  text-align: center;
  color: ${props => props.theme.colors.light.light0};
  line-height: 1.2;
  margin-bottom: 1rem;

  span {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.bold};
  }
`;

export const ChallengerWinnerStatusDescription = styled.p<IChallengerWinnerStatusDescription>`
  margin: 1rem 0 0;
  font-weight: ${props => props.theme.typograph.weights.medium};

  strong {
    font-weight: ${props => props.theme.typograph.weights.bold};
    color: inherit;
  }

  ${props =>
    props.$status === 'win' &&
    css`
      font-size: ${props.theme.typograph.sizes.p}px;
      line-height: 1.6;
      color: ${props.theme.colors.primary['primary+1']};
    `}
  ${props =>
    props.$status === 'lose' &&
    css`
      font-size: ${props.theme.typograph.sizes.p}px;
      line-height: 1.6;
      color: ${props.theme.colors.danger};
    `}
  ${props =>
    props.$status === 'wait' &&
    css`
      font-size: ${props.theme.typograph.sizes.small}px;
      line-height: 1.2;
      color: ${props.theme.colors.light['light-2']};
    `}
`;
