import styled from 'styled-components';

export const Container = styled.li`
  list-style: none;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  cursor: default;

  @media only screen and (min-width: 1450px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h6 {
      flex: 1;
      margin-bottom: 16px;
      cursor: default;
      text-transform: uppercase;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: 110%;
    }
  }
`;

export const MatchCardScoreboardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;

  > h6 {
    color: ${props => props.theme.colors.primary.primary0};
    margin: 0 32px;
    width: auto;

    span {
      margin: 8px;
      text-transform: lowercase;
    }
  }
`;
export const MatchCardScoreboardTeam = styled.div`
  background: transparent;
  display: flex;
  align-items: center;
  transition: 0.2s opacity;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;

    img {
      height: 26px;
    }
  }

  strong {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    text-transform: uppercase;
  }
`;
export const MatchCardPlayersContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};
  min-height: 64px;

  > p {
    margin: 0 auto;
  }

  > div {
    flex: 1;
    padding: 16px;
    display: flex;
    justify-content: center;

    small {
      color: ${props => props.theme.colors.light['light-2']};
      text-align: center;
    }

    &:first-child {
      border-right: 1px solid ${props => props.theme.colors.dark['dark+4']};
    }
  }
`;

export const MatchCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;

  div {
    width: 100%;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const MatchIsInLive = styled.small`
  padding: 2px 8px;
  border-radius: 4px;
  flex-shrink: 0;

  background: ${props => props.theme.colors.danger};
  color: ${props => props.theme.colors.light.light0} !important;
`;
