import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import CustomAntButton from '@/components/CustomAntButton';
import UserAvatar from '@/components/UserAvatar';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import { IListWithPagination } from '@/types/application';
import { getSanitizedText } from '@/utils/sanitize';
import socket from '@/services/socket';
import { useCoachFriendRoom } from '../../CoachFriendRoomContext';
import {
  Container,
  ChallengeCard,
  OwnerNameAndUsername,
  ChallengeDataContainer,
  ChallengePriceContainer,
  ChallengePrice,
} from './styles';
import { IChallenge } from '../types';
import { convertTextWithMarkersToHTML } from '../utils';
import { SubHeaderContainer } from '../styles';
import ChallengeStatusContainer from './ChallengeStatusContainer';

const ChallengeList: React.FC = () => {
  const isFirstRender = useRef(true);

  const intlContext = useIntl();
  const coachFriendRoomContext = useCoachFriendRoom();

  const [loadingMyChallenges, setLoadingMyChallenges] = useState(true);
  const [myChallenges, setMyChallenges] = useState<IChallenge[]>([]);
  const [myChallengesPagination, setMyChallengesPagination] = useState<
    Omit<IListWithPagination, 'docs'>
  >({
    limit: 50,
    page: 0,
    pages: 0,
    total: 0,
  });

  const getMyChallenges = useCallback(
    async (page = 1) => {
      setLoadingMyChallenges(true);
      try {
        const params = {
          limit: myChallengesPagination.limit,
          page,
        };

        const { data } = await api.get<IListWithPagination<IChallenge>>(
          `/api/coach-friend/${coachFriendRoomContext.room?._id}/join/challenger`,
          {
            params,
          },
        );

        setMyChallenges(oldState => {
          const oldChallenges = page > 1 ? oldState : [];
          return [...oldChallenges, ...data.docs];
        });
        setMyChallengesPagination(oldState => ({
          ...oldState,
          ...data,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intlContext.getTranslatedText(
            'common.errors.unexpectedError.title',
          ),
          description: intlContext.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMyChallenges(false);
    },
    [myChallengesPagination.limit, coachFriendRoomContext.room, intlContext],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      getMyChallenges();
    }
    isFirstRender.current = false;

    const joinToChallengeEvent = `challenger-join`;
    socket.on(joinToChallengeEvent, () => getMyChallenges());
    return () => {
      socket.off(joinToChallengeEvent);
    };
  }, [coachFriendRoomContext.room?._id, getMyChallenges]);

  return (
    <Container>
      <SubHeaderContainer>
        <CustomAntButton
          icon={<FiArrowLeft size={20} />}
          useCustomIcon
          type="text"
          htmlType="button"
          onClick={() =>
            coachFriendRoomContext.setCurrentChallengeRoute('list')
          }
        />
        <h6>Meus desafios</h6>
      </SubHeaderContainer>
      <ul>
        {myChallenges.map(challenge => (
          <ChallengeCard key={challenge._id}>
            <ChallengeDataContainer>
              <div>
                <UserAvatar size={32} photoId={challenge._user.photo} />
                <OwnerNameAndUsername>
                  <strong>{challenge._user.name}</strong>
                  <p>{challenge._user.username}</p>
                </OwnerNameAndUsername>
                <ChallengePriceContainer>
                  <div>
                    <small>Desafiante</small>
                    <ChallengePrice $type="challenger">
                      <span>T¢</span>
                      {challenge.valueUser}
                    </ChallengePrice>
                  </div>
                  <div>
                    <small>Oponente</small>
                    <ChallengePrice $type="opponent">
                      <span>T¢</span>
                      {challenge.valueChallenger}
                    </ChallengePrice>
                  </div>
                </ChallengePriceContainer>
              </div>
              <h6
                dangerouslySetInnerHTML={{
                  __html: getSanitizedText(
                    convertTextWithMarkersToHTML(challenge.question),
                  ),
                }}
              />
            </ChallengeDataContainer>
            <ChallengeStatusContainer
              challenge={challenge}
              getMyChallenges={getMyChallenges}
            />
          </ChallengeCard>
        ))}
      </ul>
    </Container>
  );
};

export default ChallengeList;
