import React, { useEffect, useMemo, useState } from 'react';

import CustomAntButton from '@/components/CustomAntButton';
import ConfirmAction from '@/components/ConfirmAction';
import { useNewChallenge } from '../NewChallengeContext';
import { Container } from './styles';
import SelectedChallengeCard from './SelectedChallengeCard';
import ChallengePaymentContainer from './ChallengePaymentContainer';

const FillChallengeOptionsContainer: React.FC = () => {
  const newChallengeContext = useNewChallenge();

  const [isConfirmSubmitVisible, setIsConfirmSubmitVisible] = useState(false);

  const isCreateChallengeButtonEnabled = useMemo(() => {
    const { priceValidationMessage, opponentPriceValidationMessage } =
      newChallengeContext;

    const isPriceValid =
      !priceValidationMessage && !opponentPriceValidationMessage;
    const areAllChallengeFieldsFilled = newChallengeContext.fields.every(
      field => !!field.value,
    );

    const isButtonEnabled = isPriceValid && areAllChallengeFieldsFilled;

    return isButtonEnabled;
  }, [newChallengeContext]);

  useEffect(() => {
    if (!isCreateChallengeButtonEnabled) {
      setIsConfirmSubmitVisible(false);
    }
  }, [isCreateChallengeButtonEnabled]);

  return (
    <Container>
      <SelectedChallengeCard />
      <ChallengePaymentContainer />
      {!isConfirmSubmitVisible || !isCreateChallengeButtonEnabled ? (
        <CustomAntButton
          type="primary"
          disabled={!isCreateChallengeButtonEnabled}
          onClick={() => {
            if (
              isCreateChallengeButtonEnabled &&
              !newChallengeContext.isSubmitting
            ) {
              setIsConfirmSubmitVisible(true);
            }
          }}
          loading={newChallengeContext.isSubmitting}
        >
          Criar desafio
        </CustomAntButton>
      ) : (
        <ConfirmAction
          description="Deseja realmente criar esse desafio?"
          onConfirm={() => {
            setIsConfirmSubmitVisible(false);
            newChallengeContext.handleSubmit();
          }}
          onCancel={() => setIsConfirmSubmitVisible(false)}
        />
      )}
    </Container>
  );
};

export default FillChallengeOptionsContainer;
