import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom';

import { formatParam } from '../../routes/RoutesList';

import { useChatP2P } from '../../context/ChatP2PContext';
import { useIntl } from '../../context/IntlContext';

import ChatsList from './ChatsList';
import Chat from './Chat';

import { Container, Header } from './styles';

const Chats: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const intl = useIntl();
  const { unreadChatsCount } = useChatP2P();

  return (
    <Container $isOnChatPage={location.pathname !== '/chats'}>
      <Switch>
        <Route exact path={path}>
          <Header>
            <h4>{intl.getTranslatedText('pages.chats.title')}</h4>
            {!!unreadChatsCount && (
              <small>
                {intl.getTranslatedText('pages.chats.unreadChats', {
                  unreadChatsCount,
                })}
              </small>
            )}
          </Header>
          <ChatsList />
        </Route>
        <Route path={`${path}/${formatParam('user_id')}`}>
          <Chat />
        </Route>
        <Route path={`${path}/*`}>
          <Redirect
            to={{
              pathname: '/*',
              state: {
                from: location,
              },
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};

export default Chats;
