import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import { ICoachFriendRoom, IMyDataInTheRoom, IMe, IMeGame } from '..';
import PlayCoachGoalMode from './PlayCoachGoalMode';
import PayGoalMode from './PayGoalMode';
import CoachMode from './CoachMode';
import MinuteOfFirstGoalMode from './MinuteOfFirstGoalMode';

import { Container, Header, RoomInfoContainer } from './styles';
import api from '../../../../services/api';
import NotFound from '../../../../components/NotFound';
import Loading from '../../../../components/Loading';
import { useIntl } from '../../../../context/IntlContext';

interface IRouteParams {
  id: string;
}

const MyGameDetails: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();

  const intl = useIntl();

  const [coachFriendRoom, setCoachFriendRoom] =
    useState<ICoachFriendRoom | null>(null);
  const [myDataInTheRoom, setMyDataInTheRoom] =
    useState<IMyDataInTheRoom | null>(null);
  const [loadingCoachFriendRoom, setLoadingCoachFriendRoom] = useState(true);

  useEffect(() => {
    async function getCoachFriendRoom(): Promise<void> {
      try {
        const { data } = await api.get<{
          doc: ICoachFriendRoom;
          _me: IMe | null;
          _meGame: IMeGame | null;
        }>(`/api/coach-friend/${params.id}`);

        const room: ICoachFriendRoom = { ...data.doc };
        room._game.local.abbrev = room._game.local.name
          .substring(0, 3)
          .toUpperCase();
        room._game.away.abbrev = room._game.away.name
          .substring(0, 3)
          .toUpperCase();

        setCoachFriendRoom(room);
        setMyDataInTheRoom({
          _me: data._me,
          _meGame: data._meGame,
        });
      } catch (error) {
        /* */
      }
      setLoadingCoachFriendRoom(false);
    }

    getCoachFriendRoom();
  }, [params]);

  if (loadingCoachFriendRoom) {
    return (
      <Container>
        <Breadcrumbs />
        <Loading />
      </Container>
    );
  }

  if (!myDataInTheRoom?._meGame || !coachFriendRoom) {
    return (
      <NotFound showBreadcrumbs>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.coachFriend.room.myGameDetails.messages.gameNotFount',
          )}
        </h4>
        <Link to="/coach-friend">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.coachFriend.room.myGameDetails.goToRoomsListLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText('pages.coachFriend.room.myGameDetails.title')}
        </h5>
      </Header>
      <RoomInfoContainer>
        <div>
          <small>
            <b>
              {intl.getTranslatedText(
                'pages.coachFriend.room.myGameDetails.roomIdentifier.label',
              )}
            </b>
            {coachFriendRoom.name}
          </small>
        </div>
        <div>
          <p>
            {intl.getTranslatedText(
              'pages.coachFriend.room.myGameDetails.totalScore',
              {
                totalScore: myDataInTheRoom._meGame.totalScore,
              },
            )}
          </p>
        </div>
      </RoomInfoContainer>
      <PlayCoachGoalMode
        coachFriendRoom={coachFriendRoom}
        myDataInTheRoom={myDataInTheRoom}
      />
      <MinuteOfFirstGoalMode
        coachFriendRoom={coachFriendRoom}
        myDataInTheRoom={myDataInTheRoom}
      />
      <PayGoalMode
        coachFriendRoom={coachFriendRoom}
        myDataInTheRoom={myDataInTheRoom}
      />
      <CoachMode myDataInTheRoom={myDataInTheRoom} />
    </Container>
  );
};

export default MyGameDetails;
