import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronUp,
  FiLock,
  FiLogOut,
  FiTrash2,
} from 'react-icons/fi';
import { HiCurrencyDollar } from 'react-icons/hi';
import { Modal, Spin } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import lottie from 'lottie-web';
import Countdown from 'react-countdown';

import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import * as dateFns from 'date-fns';

import { IoTrophy } from 'react-icons/io5';
import { useBullet } from '@/context/BulletContext';
import Breadcrumbs from '../../../components/Breadcrumbs';
import CustomAntButton from '../../../components/CustomAntButton';

import { useLoadingMasked } from '../../../context/LoadingMaskedContext';
import { useAuth } from '../../../context/AuthContext';
import { useChat } from '../../../context/ChatContext';
import { useIntl } from '../../../context/IntlContext';

import api from '../../../services/api';
import socket from '../../../services/socket';
import { formatMoney } from '../../../utils/formatters';

import ChooseOpponent from './ChooseOpponent';
import Lobby from './Lobby';
import Play from './Play';
import GameDetails from './GameDetails';

import Env from '../../../config/Environment';
import defaultAvatar from '../../../assets/DefaultAvatar.svg';
import flipCoinAnimation from '../../../assets/flip-coin-animation.json';

import {
  Container,
  Header,
  MatchInfoContainer,
  TeamsContainer,
  MatchInfoDetailsContainer,
  CoachFriendRoomTeam,
  AccumulatedValueContainer,
  RestTimerContainer,
  TopContent,
  BottomContainer,
  ContentBottom,
  EnterOnRoomContainer,
  LoadingAndNotFoundContainer,
  FlipCoinContainer,
  HeadsTailsContainer,
} from './styles';
import Loading from '../../../components/Loading';
import NotFound from '../../../components/NotFound';
import { showToast } from '../../../hooks/showToast';

interface IRouteParams {
  id: string;
}

interface IPlayerOfTeam {
  _id: string;
  name: string;
  number: string;
  position: string;
}

export interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
  players: IPlayerOfTeam[];
}

export interface IMe {
  _id: string;
  roles?: 'owner'[];
  status: boolean;
}

export interface IMeGame {
  _id: string;
  totalScore: number;
  localScore: number;
  awayScore: number;
  redCards: number;
  yellowCards: number;
  localPlayers: IThrowOfPayGoalMode[];
  awayPlayers: IThrowOfPayGoalMode[];
  collapsedOnList?: boolean;
  _user: string;
}

export interface IPlayer {
  players: {
    _id: string;
    _user: string;
    roles?: 'owner'[];
    status: boolean;
  };
  _player: {
    username: string;
    name: string;
    email: string;
    photo?: string;
  };
  games: boolean;
}

export interface IPlayerGame {
  games: {
    _id: string;
    isWinner: boolean;
    totalScore: number;
    localScore: number;
    awayScore: number;
    _user: string;
  };
  _player: {
    username: string;
    name: string;
    email: string;
    photo?: string;
  };
}

export interface IGameWinner {
  _id: string;
  totalScore: number;
  localScore: number;
  awayScore: number;
  _user: {
    _id: string;
    username: string;
    name: string;
    email: string;
    photo?: {
      _id: string;
      filename: string;
    };
  };
}

interface IThrowOfPayGoalMode {
  _id: string;
  player: string;
  playerName: string;
}

export interface IVersusRoom {
  _id: string;
  price: number;
  amount: number;
  isPrivate: boolean;
  isFinished: boolean;
  name: string;
  started: boolean;
  blocked: boolean;
  gameCount: number;
  info: 'wait' | 'ready' | 'running' | 'finished';
  headsTails?: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    _id: string;
    info: {
      place: string;
      date: string;
    };
    local: ITeam;
    away: ITeam;
    localScore: number;
    awayScore: number;
  };
  adversary: IPlayer | null;
  gameWinner: IGameWinner | null;
}

export interface IMyDataInTheRoom {
  _me: IMe | null;
  _meGame: IMeGame | null;
}

const { confirm } = Modal;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const { parseISO, isAfter, sub, format } = dateFns;

const Room: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();
  const bullet = useBullet();
  const { setChat } = useChat();
  const { showLoading, hideLoading } = useLoadingMasked();

  const [showMoreDetailsOfMatch, setShowMoreDetailsOfMatch] = useState(false);

  const [versusRoom, setVersusRoom] = useState<IVersusRoom | null>(null);
  const [myDataInTheRoom, setMyDataInTheRoom] =
    useState<IMyDataInTheRoom | null>(null);
  const [loadingVersusRoom, setLoadingVersusRoom] = useState(true);
  const [loadingPlayersOfRoom, setLoadingPlayersOfRoom] = useState(true);

  const [gamesOfRoom, setGamesOfRoom] = useState<IPlayerGame[]>([]);
  const [loadingGamesOfRoom, setLoadingGamesOfRoom] = useState(false);
  const [gamesOfRoomIsLoaded, setGamesOfRoomIsLoaded] = useState(false);

  const [flippingCoin, setFlippingCoin] = useState(false);
  const [flipCoinAnimationHasLoaded, setFlipCoinAnimationHasLoaded] =
    useState(false);

  const handleLoadFlipCoinAnimation = useCallback(
    (instance: HTMLDivElement | null) => {
      if (instance && !flipCoinAnimationHasLoaded) {
        lottie.loadAnimation({
          container: instance,
          autoplay: true,
          loop: true,
          animationData: flipCoinAnimation,
        });
        setFlipCoinAnimationHasLoaded(true);
      }
    },
    [flipCoinAnimationHasLoaded],
  );

  const getPlayersOfRoom = useCallback(async (): Promise<void> => {
    setLoadingPlayersOfRoom(true);
    try {
      const { data } = await api.get<{
        doc: IPlayer[];
      }>(`/api/game-vs/${params.id}/players`);

      const adversary =
        data.doc.find(player => player.players._user !== me?._id) || null;

      setVersusRoom(oldCoachFriendRoom => {
        if (oldCoachFriendRoom) {
          return {
            ...oldCoachFriendRoom,
            adversary,
          };
        }
        return null;
      });
    } catch (error) {
      /* */
    }
    setLoadingPlayersOfRoom(false);
  }, [me?._id, params.id]);

  const getGamesOfRoom = useCallback(async (): Promise<void> => {
    setLoadingGamesOfRoom(true);
    try {
      const { data } = await api.get<{
        doc: IPlayerGame[];
      }>(`/api/game-vs/${params.id}/games`, {
        params: {
          limit: 24,
        },
      });

      setGamesOfRoom(data.doc.map(game => game));
    } catch (error) {
      /* */
    }
    setLoadingGamesOfRoom(false);
    setGamesOfRoomIsLoaded(true);
  }, [params.id]);

  const getVersusRoom = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        doc: IVersusRoom;
        _me: IMe | null;
        _meGame: IMeGame | null;
      }>(`/api/game-vs/${params.id}`);

      setChat(null);

      const room: IVersusRoom = { ...data.doc };
      room._game.local.abbrev = room._game.local.name
        .substring(0, 3)
        .toUpperCase();
      room._game.away.abbrev = room._game.away.name
        .substring(0, 3)
        .toUpperCase();

      room.started = isAfter(new Date(), parseISO(room._game.info.date));

      const limitForActionsOnRoomData = sub(parseISO(room._game.info.date), {
        minutes: 15,
      });
      room.blocked = isAfter(new Date(), limitForActionsOnRoomData);

      setVersusRoom(oldRoom => ({ ...oldRoom, ...room }));
      setMyDataInTheRoom({
        _me: data._me,
        _meGame: data._meGame,
      });

      await getPlayersOfRoom();

      if (data._me?.status) {
        setChat({
          _resourceId: data.doc._id,
          name: data.doc.name,
          from: intl.getTranslatedText('common.gamesTitles.versus'),
          fromCode: 'versus',
        });
      }

      if (room.info !== 'wait') {
        getGamesOfRoom();
      }
    } catch (error) {
      /* */
      setVersusRoom(null);
      setMyDataInTheRoom(null);
    }
    setLoadingVersusRoom(false);
  }, [getGamesOfRoom, getPlayersOfRoom, intl, params.id, setChat]);

  useEffect(() => {
    getVersusRoom();

    return () => {
      setChat(null);
    };
  }, [getVersusRoom, setChat]);

  useEffect(() => {
    const eventName = `vs:${params.id}`;
    socket.on(
      eventName,
      async (
        data:
          | 'start-animation'
          | 'end-animation'
          | {
              stmt: {
                [key: string]: string;
              };
              status:
                | 'games'
                | 'remove_player'
                | 'reject_player'
                | 'accept_player'
                | 'delete_room';
            },
      ) => {
        if (data === 'start-animation') {
          setFlippingCoin(true);
          return;
        }
        if (data === 'end-animation') {
          await getVersusRoom();
          setTimeout(() => {
            setFlippingCoin(false);
          }, 4000);
          return;
        }
        if (
          data.status === 'remove_player' ||
          data.status === 'reject_player' ||
          data.status === 'accept_player' ||
          data.status === 'delete_room'
        ) {
          getVersusRoom();
          return;
        }
        getGamesOfRoom();
      },
    );
    if (versusRoom?._game._id) {
      socket.on(`vs-end:${versusRoom?._game._id}`, () => {
        getVersusRoom();
      });
    }
    return () => {
      socket.off(eventName);
      if (versusRoom?._game._id) {
        socket.off(`vs-end:${versusRoom?._game._id}`);
      }
    };
  }, [getGamesOfRoom, getVersusRoom, params.id, versusRoom]);

  const handleAcceptOrRejectInviteForThisRoom = useCallback(
    async (action: 'reject' | 'accept'): Promise<void> => {
      if (action === 'reject') {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.versus.room.messages.submitRejectInviteForThisRoomConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.versus.room.submitRejectInviteForThisRoomConfirmButton',
            ),
            onOk() {
              resolve(true);
            },
            okButtonProps: {
              danger: true,
            },
          });
        });
      } else {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.versus.room.messages.submitAcceptInviteForThisRoomConfirm.title',
            ),
            icon: <CheckCircleOutlined />,
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.versus.room.submitAcceptInviteForThisRoomConfirmButton',
            ),
            onOk() {
              resolve(true);
            },
          });
        });
      }

      if (versusRoom?.price) {
        const hasBalance = bullet.checkIfBalanceIsAvailableToAction(
          versusRoom?.price,
        );
        if (!hasBalance) return;
      }

      try {
        const body: { status?: boolean; action?: 'reject' } = {};
        if (action === 'accept') {
          body.status = true;
        } else {
          body.action = 'reject';
        }

        await api.put(`/api/game-vs/${params.id}/invite`, body);

        if (action === 'reject') {
          history.push('/versus');
        } else {
          getVersusRoom();
        }
      } catch (error) {
        if (action === 'reject') {
          showToast({
            type: 'error',
            title: intl.getTranslatedText(
              'common.errors.unexpectedError.title',
            ),
            description: error.message,
          });
        } else {
          showToast({
            type: 'error',
            title: intl.getTranslatedText(
              'common.errors.unexpectedError.title',
            ),
            description:
              error.response?.data?.message ||
              intl.getTranslatedText(
                'common.errors.unexpectedError.description',
              ),
          });
        }
      }
    },
    [bullet, getVersusRoom, history, intl, params.id, versusRoom?.price],
  );

  const handleGetInOnPublicRoom = useCallback(async () => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.versus.room.messages.submitGetInOnPublicRoomConfirm.title',
          ),
          icon: <CheckCircleOutlined />,
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.versus.room.submitGetInOnPublicRoomConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      // setLoadingAddUsers(true);

      const body = {
        users: [
          {
            _user: me?._id,
          },
        ],
      };

      await api.put(`/api/game-vs/${params.id}/players`, body);

      // setLoadingAddUsers(false);
      // handleCloseAddFriendsModal();
      getVersusRoom();
    } catch (error) {
      // setLoadingAddUsers(false);
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [getVersusRoom, intl, me?._id, params.id]);

  const handleDeleteRoom = useCallback(async (): Promise<void> => {
    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'pages.versus.room.messages.submitDeleteRoomConfirm.title',
        ),
        icon: <ExclamationCircleOutlined />,
        content: intl.getTranslatedText('common.messages.actionCannotBeUndone'),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.versus.room.submitDeleteRoomConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
        okButtonProps: {
          danger: true,
        },
      });
    });

    try {
      showLoading(
        intl.getTranslatedText(
          'pages.versus.room.messages.submitDeleteRoomLoading',
        ),
      );
      await api.delete(`/api/game-vs/${params.id}/room`);
      hideLoading();
      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.versus.room.messages.submitDeleteRoomSuccess.title',
        ),
      });
      history.push('/versus');
    } catch (error) {
      hideLoading();
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [hideLoading, history, intl, params.id, showLoading]);

  const handleLogOutForThisRoom = useCallback(async (): Promise<void> => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.versus.room.messages.submitLogOutForThisRoomConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.versus.room.submitLogOutForThisRoomConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          okButtonProps: {
            danger: true,
          },
        });
      });

      showLoading(
        intl.getTranslatedText(
          'pages.versus.room.messages.submitLogOutForThisRoomLoading',
        ),
      );

      const body = {
        action: 'reject',
      };

      await api.put(`/api/game-vs/${params.id}/invite`, body);
      hideLoading();
      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'pages.versus.room.messages.submitLogOutForThisRoomSuccess.title',
        ),
        description: intl.getTranslatedText(
          'pages.versus.room.messages.submitLogOutForThisRoomSuccess.description',
        ),
      });
      history.push('/versus');
    } catch (error) {
      hideLoading();
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [hideLoading, history, intl, params.id, showLoading]);

  const handleRemoveOpponent = useCallback(
    async (userId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.versus.room.messages.submitRemoveOpponentConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.versus.room.submitRemoveOpponentConfirmButton',
            ),
            onOk() {
              resolve(true);
            },
            okButtonProps: {
              danger: true,
            },
          });
        });

        showLoading(
          intl.getTranslatedText(
            'pages.versus.room.messages.submitRemoveOpponentLoading',
          ),
        );

        const body = {
          _user: userId,
          action: 'remove',
        };

        await api.put(`/api/game-vs/${params.id}/invite`, body);
        hideLoading();
        getPlayersOfRoom();
        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'pages.versus.room.messages.submitRemoveOpponentSuccess.title',
          ),
          description: intl.getTranslatedText(
            'pages.versus.room.messages.submitRemoveOpponentSuccess.description',
          ),
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description:
            error.response?.data?.message ||
            intl.getTranslatedText('common.errors.unexpectedError.description'),
        });
        hideLoading();
      }
    },
    [getPlayersOfRoom, hideLoading, intl, params.id, showLoading],
  );

  const topContentViewer = useMemo(() => {
    if (!versusRoom) return <></>;

    return (
      <TopContent>
        <Breadcrumbs />
        <Header>
          <h5>{versusRoom.name}</h5>
          <div>
            {versusRoom.isPrivate && <FiLock size={19} />}
            {versusRoom.gameCount === 0 &&
              myDataInTheRoom?._me?.roles?.includes('owner') && (
                <CustomAntButton
                  type="text"
                  danger
                  useCustomIcon
                  icon={<FiTrash2 size={21} />}
                  onClick={() => handleDeleteRoom()}
                />
              )}
            {versusRoom.gameCount === 0 &&
              !myDataInTheRoom?._me?.roles?.includes('owner') &&
              myDataInTheRoom?._me?.status && (
                <CustomAntButton
                  type="text"
                  danger
                  useCustomIcon
                  icon={<FiLogOut size={21} />}
                  onClick={() => handleLogOutForThisRoom()}
                />
              )}
          </div>
        </Header>
        <MatchInfoContainer
          onClick={() => setShowMoreDetailsOfMatch(oldState => !oldState)}
        >
          <TeamsContainer>
            <div>
              <CoachFriendRoomTeam>
                <div>
                  <img
                    src={versusRoom._game.local.image}
                    alt={versusRoom._game.local.name}
                  />
                </div>
                <strong>{versusRoom._game.local.abbrev}</strong>
              </CoachFriendRoomTeam>
              <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
              <CoachFriendRoomTeam>
                <div>
                  <img
                    src={versusRoom._game.away.image}
                    alt={versusRoom._game.away.name}
                  />
                </div>
                <strong>{versusRoom._game.away.abbrev}</strong>
              </CoachFriendRoomTeam>
            </div>
            <small>{versusRoom._champ.name}</small>
          </TeamsContainer>
          <AccumulatedValueContainer>
            <IoTrophy size={18} />
            <small>T¢{intl.getFormattedDecimalNumber(versusRoom.amount)}</small>
          </AccumulatedValueContainer>
          <Countdown
            date={parseISO(versusRoom._game.info.date)}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <></>;
              }

              return (
                <RestTimerContainer>
                  <div>
                    <h4>
                      {days.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h4>
                    <small>
                      {intl.getTranslatedText('common.countDown.days')}
                    </small>
                  </div>
                  <div>
                    <h4>
                      {hours.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h4>
                    <small>
                      {intl.getTranslatedText('common.countDown.hours')}
                    </small>
                  </div>
                  <div>
                    <h4>
                      {minutes.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h4>
                    <small>
                      {intl.getTranslatedText('common.countDown.minutes')}
                    </small>
                  </div>
                  <div>
                    <h4>
                      {seconds.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h4>
                    <small>
                      {intl.getTranslatedText('common.countDown.seconds')}
                    </small>
                  </div>
                </RestTimerContainer>
              );
            }}
            onComplete={() => {
              getVersusRoom();
            }}
          />
          {showMoreDetailsOfMatch && (
            <MatchInfoDetailsContainer>
              <div>
                <div>
                  <small>
                    {intl.getTranslatedText('common.matchInfo.date')}
                  </small>
                  <small>
                    {format(
                      parseISO(versusRoom._game.info.date),
                      intl.getTranslatedText('common.formatDates.fullDate'),
                      {
                        locale: intl.getDatefnsLocale(),
                      },
                    )}
                  </small>
                </div>
                <div>
                  <small>
                    {intl.getTranslatedText('common.matchInfo.place')}
                  </small>
                  <small>{versusRoom._game.info.place}</small>
                </div>
              </div>
            </MatchInfoDetailsContainer>
          )}
          <div>
            {!showMoreDetailsOfMatch ? (
              <>
                <FiChevronDown size={16} />
                <small>
                  {intl.getTranslatedText('common.buttons.moreDetails')}
                </small>
              </>
            ) : (
              <>
                <FiChevronUp size={16} />
                <small>
                  {intl.getTranslatedText('common.buttons.lessDetails')}
                </small>
              </>
            )}
          </div>
        </MatchInfoContainer>
      </TopContent>
    );
  }, [
    versusRoom,
    myDataInTheRoom?._me?.roles,
    myDataInTheRoom?._me?.status,
    intl,
    showMoreDetailsOfMatch,
    handleDeleteRoom,
    handleLogOutForThisRoom,
    getVersusRoom,
  ]);

  const bottomContentViewer = useMemo(() => {
    if (!versusRoom) return <></>;

    if (loadingPlayersOfRoom || loadingGamesOfRoom) {
      return (
        <BottomContainer>
          <ContentBottom>
            <LoadingAndNotFoundContainer>
              <div>
                <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
                <p>
                  {intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              </div>
            </LoadingAndNotFoundContainer>
          </ContentBottom>
        </BottomContainer>
      );
    }

    if (!myDataInTheRoom?._me?.status) {
      if (versusRoom.isPrivate) {
        return (
          <BottomContainer>
            <EnterOnRoomContainer>
              <img
                src={
                  versusRoom.adversary?._player.photo
                    ? Env.IMAGE_SERVER_URL + versusRoom.adversary?._player.photo
                    : defaultAvatar
                }
                alt={versusRoom.adversary?._player.name}
              />
              <p>
                {/* {versusRoom.adversary?._player.name}(
                {versusRoom.adversary?._player.username}) lhe convidou para
                jogar com ele por T¢{formatMoney(versusRoom.price)} */}
                {intl.getTranslatedText(
                  'pages.versus.room.messages.inviteDescription',
                  {
                    playerName: versusRoom.adversary?._player.name,
                    playerUsername: versusRoom.adversary?._player.username,
                    price: formatMoney(versusRoom.price),
                  },
                )}
              </p>
              {!versusRoom.blocked ? (
                <div>
                  <CustomAntButton
                    onClick={() =>
                      handleAcceptOrRejectInviteForThisRoom('reject')
                    }
                    danger
                  >
                    {intl.getTranslatedText(
                      'pages.versus.room.rejectInviteButton',
                    )}
                  </CustomAntButton>
                  <CustomAntButton
                    onClick={() =>
                      handleAcceptOrRejectInviteForThisRoom('accept')
                    }
                    type="primary"
                  >
                    {intl.getTranslatedText(
                      'pages.versus.room.acceptInviteButton',
                    )}
                  </CustomAntButton>
                </div>
              ) : (
                <h6>
                  {intl.getTranslatedText(
                    'pages.versus.room.messages.itsNotPossibleToEnterTheRoom',
                  )}
                </h6>
              )}
            </EnterOnRoomContainer>
          </BottomContainer>
        );
      }
      return (
        <BottomContainer>
          <EnterOnRoomContainer>
            <p>
              {intl.getTranslatedText(
                'pages.versus.room.messages.youIsNotOnRoom',
              )}
            </p>
            {!versusRoom.blocked ? (
              <div>
                <CustomAntButton
                  onClick={() => handleGetInOnPublicRoom()}
                  type="primary"
                >
                  {intl.getTranslatedText(
                    'pages.versus.room.submitGetInOnPublicRoomConfirmButton',
                  )}
                </CustomAntButton>
              </div>
            ) : (
              <h6>
                {intl.getTranslatedText(
                  'pages.versus.room.messages.itsNotPossibleToEnterTheRoom',
                )}
              </h6>
            )}
          </EnterOnRoomContainer>
        </BottomContainer>
      );
    }

    if (!versusRoom.adversary && myDataInTheRoom._me.roles?.includes('owner')) {
      return (
        <BottomContainer>
          <ContentBottom>
            <ChooseOpponent
              room={versusRoom}
              getPlayersOfRoom={() => {
                getPlayersOfRoom();
              }}
            />
          </ContentBottom>
        </BottomContainer>
      );
    }

    if (gamesOfRoom.length === 24) {
      return (
        <BottomContainer>
          <GameDetails
            versusRoom={versusRoom}
            gamesOfRoom={gamesOfRoom}
            loading={loadingPlayersOfRoom || loadingGamesOfRoom}
          />
        </BottomContainer>
      );
    }

    return (
      <BottomContainer>
        <ContentBottom>
          <Lobby
            room={versusRoom}
            // getPlayersOfRoom={() => {
            //   getPlayersOfRoom();
            // }}
            myDataInTheRoom={myDataInTheRoom}
            handleRemoveOpponent={handleRemoveOpponent}
          />
        </ContentBottom>
      </BottomContainer>
    );
    // if(!versusRoom.adversary && myDataInTheRoom._me.roles?.includes('owner')) {
    // }
  }, [
    versusRoom,
    loadingPlayersOfRoom,
    loadingGamesOfRoom,
    myDataInTheRoom,
    gamesOfRoom,
    handleRemoveOpponent,
    intl,
    handleAcceptOrRejectInviteForThisRoom,
    handleGetInOnPublicRoom,
    getPlayersOfRoom,
  ]);

  if (loadingVersusRoom) {
    return (
      <Container>
        <TopContent>
          <Breadcrumbs />
          <Loading />
        </TopContent>
      </Container>
    );
  }

  if (!versusRoom) {
    return (
      <NotFound showBreadcrumbs>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.versus.room.messages.roomNotFound',
          )}
        </h4>
        <Link to="/versus">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText('pages.versus.room.goToListRoomsLink')}
        </Link>
      </NotFound>
    );
  }

  if (flippingCoin) {
    if (!versusRoom.headsTails) {
      return (
        <Container>
          <Breadcrumbs />
          <FlipCoinContainer>
            <div ref={handleLoadFlipCoinAnimation} />
            <div>
              <div>
                <img
                  src={
                    me?.photo ? Env.IMAGE_SERVER_URL + me?.photo : defaultAvatar
                  }
                  alt={me?.name}
                />
                <p>{me?.name}</p>
              </div>
              <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
              <div>
                <img
                  src={
                    versusRoom.adversary?._player.photo
                      ? Env.IMAGE_SERVER_URL +
                        versusRoom.adversary?._player.photo
                      : defaultAvatar
                  }
                  alt={versusRoom.adversary?._player.name}
                />
                <p>{versusRoom.adversary?._player.name}</p>
              </div>
            </div>
          </FlipCoinContainer>
        </Container>
      );
    }

    const headsTailsUserPhoto =
      versusRoom.headsTails && versusRoom.headsTails === me?._id
        ? me.photo
        : versusRoom.adversary?._player.photo;
    const headsTailsUserName =
      versusRoom.headsTails && versusRoom.headsTails === me?._id
        ? me.name
        : versusRoom.adversary?._player.name;

    return (
      <Container>
        <HeadsTailsContainer>
          <div>
            <img
              src={
                headsTailsUserPhoto
                  ? Env.IMAGE_SERVER_URL + headsTailsUserPhoto
                  : defaultAvatar
              }
              alt={headsTailsUserName}
            />
            <HiCurrencyDollar size={48} />
          </div>
          <p>
            {intl.getTranslatedTextWithHTML(
              'pages.versus.room.messages.playerIsTheFirst',
              {
                playerUsername: headsTailsUserName,
              },
            )}
          </p>
        </HeadsTailsContainer>
      </Container>
    );
  }

  if (
    (versusRoom.info === 'ready' || versusRoom.info === 'running') &&
    gamesOfRoom.length < 24 &&
    gamesOfRoomIsLoaded
  ) {
    return (
      <Play
        versusRoom={versusRoom}
        gamesOfRoom={gamesOfRoom}
        loading={loadingPlayersOfRoom || loadingGamesOfRoom}
      />
    );
  }

  return (
    <Container>
      {topContentViewer}
      {bottomContentViewer}
    </Container>
  );
};

export default Room;
