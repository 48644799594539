import React from 'react';
import * as dateFns from 'date-fns';

import { useIntl } from '../../../../../../context/IntlContext';

import { IGame } from '../index';

import {
  Container,
  Header,
  MatchCardSelectedMatchChampionContainer,
  MatchCardSelectedMatchChampion,
  MatchCardSelectPlayersContainer,
  MatchCardInfoContainer,
} from './styles';

interface IPlayedCardGameMatch extends IGame {
  // eslint-disable-next-line react/no-unused-prop-types
  test?: string;
}

const { format, parseISO } = dateFns;

const PlayedCardGameMatch: React.FC<IPlayedCardGameMatch> = ({
  choise,
  game,
  localPlayerName,
  awayPlayerName,
  choiceScore,
  playerScore,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <Header>
        <h6>
          {game.local.name.toUpperCase()}{' '}
          {intl.getTranslatedText('common.vsDescription')}{' '}
          {game.away.name.toUpperCase()}
        </h6>
        <small>
          {intl.getTranslatedText(
            'pages.championship.myCardGameDetails.playedCardGameMatch.totalScore',
            {
              score: choiceScore + playerScore,
            },
          )}
        </small>
      </Header>
      <MatchCardSelectedMatchChampionContainer>
        <strong>
          {intl.getTranslatedText(
            'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.label',
          )}
        </strong>
        {choise === 'local' || choise === 'away' ? (
          <MatchCardSelectedMatchChampion>
            <div>
              <img src={game[choise].image} alt={game[choise].name} />
            </div>
            <strong>{game[choise].abbrev}</strong>
          </MatchCardSelectedMatchChampion>
        ) : (
          <p>
            {intl.getTranslatedText(
              'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.draw',
            )}
          </p>
        )}
      </MatchCardSelectedMatchChampionContainer>
      <MatchCardSelectPlayersContainer>
        <strong>
          {intl.getTranslatedText(
            'pages.championship.myCardGameDetails.playedCardGameMatch.selectedPlayers.label',
          )}
        </strong>
        <div>
          <div>
            <small>{game.local.name}</small>
            <p>{localPlayerName}</p>
          </div>
          <div>
            <small>{game.away.name}</small>
            <p>{awayPlayerName}</p>
          </div>
        </div>
      </MatchCardSelectPlayersContainer>
      <MatchCardInfoContainer>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.place')}</small>
          <small>{game.info.place}</small>
        </div>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.date')}</small>
          <small>
            {format(
              parseISO(game.info.date),
              intl.getTranslatedText('common.formatDates.fullDate'),
              {
                locale: intl.getDatefnsLocale(),
              },
            )}
          </small>
        </div>
      </MatchCardInfoContainer>
    </Container>
  );
};

export default PlayedCardGameMatch;
