import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  ul {
    margin: 16px 0 32px;
    display: flex;
    flex-wrap: wrap;
  }
`;
