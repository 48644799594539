import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';
import { FiCheck } from 'react-icons/fi';

import { useIntl } from '@/context/IntlContext';

import { useCoachFriendRoom } from '@/pages/CoachFriend/Room/CoachFriendRoomContext';
import { ITeam } from '@/pages/CoachFriend/Room';
import { Container, SelectPlayerButton, TeamPlayerSelector } from './styles';
import { useNewChallenge } from '../../../NewChallengeContext';
import { FormFieldIdentifier } from '../../../types';
import { IAthleteSelectorProps } from './types';

const AthleteSelector: React.FC<IAthleteSelectorProps> = ({
  onSelect,
  selectedAthleteId,
}) => {
  const intl = useIntl();
  const coachFriendRoomContext = useCoachFriendRoom();
  const newChallengeContext = useNewChallenge();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const localTeam = coachFriendRoomContext.room?._game.local;
  const awayTeam = coachFriendRoomContext.room?._game.away;

  const selectedTeam = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.TEAM,
    )?.value as ITeam;
  }, [newChallengeContext.fields]);

  const playersOfSelectedTeam = useMemo(() => {
    if (selectedTeam?._id === localTeam?._id) {
      return localTeam.players;
    }
    if (selectedTeam?._id === awayTeam?._id) {
      return awayTeam.players;
    }

    return [];
  }, [awayTeam, localTeam, selectedTeam]);

  const selectedAthlete = useMemo(() => {
    return playersOfSelectedTeam.find(
      athelete => athelete._id === selectedAthleteId,
    );
  }, [playersOfSelectedTeam, selectedAthleteId]);

  return (
    <>
      <Modal
        title="Selecione o jogador"
        open={isModalOpened}
        onCancel={() => setIsModalOpened(false)}
        footer={null}
      >
        {playersOfSelectedTeam.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              onSelect(player);
              setIsModalOpened(false);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
              </p>
              <small>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.newGame.athletes.playerNumber',
                  {
                    playerNumber: player.number,
                  },
                )}
              </small>
            </div>
            {player._id === selectedAthleteId && <FiCheck size={20} />}
          </TeamPlayerSelector>
        ))}
      </Modal>
      <Container>
        <small>Jogador</small>
        <SelectPlayerButton
          selected={!!selectedAthleteId}
          onClick={() => setIsModalOpened(true)}
        >
          <p>{selectedAthlete?.name || 'Selecionar jogador'}</p>
        </SelectPlayerButton>
      </Container>
    </>
  );
};

export default AthleteSelector;
