import React, { useCallback, useState } from 'react';

import UserAvatar from '@/components/UserAvatar';
import { useAuth } from '@/context/AuthContext';
import CustomAntButton from '@/components/CustomAntButton';
import ConfirmAction from '@/components/ConfirmAction';
import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import { IChallengeStatusContainerProps } from './types';
import {
  ChallengerWinnerStatusDescription,
  ChallengersDescription,
  Container,
  NoOpponentAvatar,
  UsersAvatarsContainer,
} from './styles';

const ChallengeStatusContainer: React.FC<IChallengeStatusContainerProps> = ({
  challenge,
  getMyChallenges,
}) => {
  const { user: me } = useAuth();
  const intlContext = useIntl();

  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isOwnerMe = challenge._user._id === me?._id;
  const isChallengerMe = challenge._challenger?._id === me?._id;

  const ownerDescription = isOwnerMe ? 'Você' : challenge._user.name;
  const challengerDescription = isChallengerMe
    ? 'Você'
    : challenge._challenger?.name;

  const handleDeleteChallenge = useCallback(async () => {
    const roomId = challenge._coach._id;
    const challengerId = challenge._id;

    try {
      setIsDeleting(true);
      await api.delete(
        `/api/coach-friend/${roomId}/challenger/${challengerId}`,
      );
      getMyChallenges();
      showToast({
        type: 'success',
        title: 'Desafio excluído!',
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: intlContext.getTranslatedText(
          'common.errors.unexpectedError.title',
        ),
        description: intlContext.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
    setIsDeleting(false);
  }, [challenge, getMyChallenges, intlContext]);

  return (
    <Container>
      <UsersAvatarsContainer>
        <UserAvatar photoId={challenge._user.photo} size={32} />
        <small>x</small>
        <NoOpponentAvatar>?</NoOpponentAvatar>
      </UsersAvatarsContainer>
      <ChallengersDescription>
        {challenge._challenger ? (
          <>
            <span>{challengerDescription}</span> confrontou{' '}
            <span>{ownerDescription}</span>
          </>
        ) : (
          <span>Aguardando oponente</span>
        )}
      </ChallengersDescription>
      {challenge._challenger && !challenge.isFinished && (
        <ChallengerWinnerStatusDescription $status="wait">
          Aguarde o fim da partida para saber o resultado
        </ChallengerWinnerStatusDescription>
      )}
      {challenge._challenger && challenge.isFinished && (
        <>
          {challenge.winner === me?._id ? (
            <ChallengerWinnerStatusDescription $status="win">
              Você <strong>venceu</strong>!
            </ChallengerWinnerStatusDescription>
          ) : (
            <ChallengerWinnerStatusDescription $status="lose">
              Você <strong>perdeu</strong>...
            </ChallengerWinnerStatusDescription>
          )}
        </>
      )}
      {!challenge._challenger && !isDeleteConfirmationVisible && (
        <CustomAntButton
          type="default"
          danger
          onClick={() => setIsDeleteConfirmationVisible(true)}
          loading={isDeleting}
        >
          Excluir desafio
        </CustomAntButton>
      )}
      {isDeleteConfirmationVisible && (
        <ConfirmAction
          description="Deseja realmente excluir esse desafio?"
          confirmButtonText="Excluir"
          danger
          onConfirm={() => {
            setIsDeleteConfirmationVisible(false);
            handleDeleteChallenge();
          }}
          onCancel={() => setIsDeleteConfirmationVisible(false)}
        />
      )}
    </Container>
  );
};

export default ChallengeStatusContainer;
