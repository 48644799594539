import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';

import { FaCoins } from 'react-icons/fa';
import { RiInboxArchiveFill } from 'react-icons/ri';

import { EBulletPagePath, useBullet } from '@/context/BulletContext';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import { BiTransferAlt } from 'react-icons/bi';
import {
  Container,
  BalanceContainer,
  TransactionsContainer,
  ActionButton,
} from './styles';
import {
  ETransactionModule,
  ETransactionStatus,
  ITransaction,
} from '../bullet.types';
import Transaction from './Transaction';

const Main: React.FC = () => {
  const intl = useIntl();
  const bullet = useBullet();

  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [transactionsPagination, setTransactionsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });
  const [loadingTransactions, setLoadingTransactions] = useState(true);

  useEffect(() => {
    async function getTransaction(page = 1): Promise<void> {
      try {
        const { data } = await api.get<{
          docs: ITransaction[];
          page: number;
          pages: number;
        }>(`/api/transactions`);

        setTransactions(oldState => {
          let transactionsWithWaitingFilter =
            page === 1 ? data.docs : [...oldState, ...data.docs];

          transactionsWithWaitingFilter = transactionsWithWaitingFilter.filter(
            transaction => {
              const paymentTerm = dateFns.add(
                dateFns.parseISO(transaction.createdAt),
                {
                  days: 1,
                },
              );

              const isWaiting =
                transaction.status === ETransactionStatus.WAITING;
              const isDeposit =
                transaction.details.module === ETransactionModule.DEPOSIT;
              const canBePaid = dateFns.isBefore(new Date(), paymentTerm);

              if (!isDeposit) return true;
              if (isDeposit && !isWaiting) return true;
              if (isDeposit && isWaiting && canBePaid) return true;

              return false;
            },
          );

          return transactionsWithWaitingFilter;
        });

        setTransactionsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
        setLoadingTransactions(false);
      } catch (error) {
        setLoadingTransactions(false);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
      }
    }

    getTransaction();
  }, [intl]);

  return (
    <Container>
      <BalanceContainer>
        <p>Saldo atual</p>
        <h5>
          <span>T¢</span>
          {bullet.balance.amount.toFixed(2)}
        </h5>
        <div>
          <ActionButton
            $type="getCoins"
            onClick={() => bullet.navigateTo(EBulletPagePath.DEPOSIT)}
          >
            <FaCoins size={16} />
            <small>Comprar moedas</small>
          </ActionButton>
          <ActionButton
            $type="withdraw"
            onClick={() => bullet.navigateTo(EBulletPagePath.WITHDRAW)}
          >
            <RiInboxArchiveFill size={16} />
            <small>Sacar dinheiro</small>
          </ActionButton>
          <ActionButton
            $type="transfer"
            onClick={() => bullet.navigateTo(EBulletPagePath.TRANSFER)}
          >
            <BiTransferAlt size={16} />
            <small>Transferir moedas</small>
          </ActionButton>
        </div>
      </BalanceContainer>
      <TransactionsContainer>
        {/* <div>
          <h5>Compras pendentes</h5>
          <ul>
            <PendingPurchaseContainer>
              <img src="https://via.placeholder.com/150" alt="package" />
              <div>
                <strong>Pacote 1 - T¢12</strong>
                <small>R$12</small>
              </div>
            </PendingPurchaseContainer>
          </ul>
        </div> */}
        <div>
          <h5>Últimas transações</h5>
          <ul>
            {transactions.map(transaction => (
              <Transaction key={transaction._id} transaction={transaction} />
            ))}
          </ul>
        </div>
      </TransactionsContainer>
    </Container>
  );
};

export default Main;
