import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { Menu, Dropdown, Modal } from 'antd';

import { FiChevronLeft, FiTrash2, FiCornerUpLeft } from 'react-icons/fi';
import { FaCrown } from 'react-icons/fa';
import { IoEllipsisVertical, IoTrophy } from 'react-icons/io5';

import Breadcrumbs from '../../components/Breadcrumbs';

import { useIntl } from '../../context/IntlContext';

import { showToast } from '../../hooks/showToast';
import api from '../../services/api';
import { formatMoney } from '../../utils/formatters';

import {
  Container,
  Header,
  CardGameList,
  CardGameItem,
  CardGameHeader,
  MatchInfoContainer,
  TeamsContainer,
  Team,
  AccumulatedValueContainer,
  PlayedPushGameInfoContainer,
  LoadMoreCardsButton,
} from './styles';
import CustomAntButton from '../../components/CustomAntButton';
import Loading from '../../components/Loading';

const { format, parseISO } = dateFns;

export interface ITeam {
  _id: string;
  name: string;
  abbrev?: string;
  image: string;
}

interface IPlayedPushGameState {
  _id: string;
  amountReceived: number;
  answer: boolean | null;
  friendlyId: string;
  from: string;
  isWinner: boolean;
  status: boolean;
  createdAt: string;
  _push: {
    _id: string;
    question: string;
    price: number;
    amount: number;
    answer: boolean | null;
    createdAt: string;
    friendlyId: string;
    from: string;
    isFinished: boolean;
    status: boolean;
    winnerCount: boolean;
    _createdBy: string;
    _game: {
      _id: string;
      info: {
        place: string;
        date: string;
      };
      _champ: {
        _id: string;
        name: string;
      };
      local: ITeam;
      away: ITeam;
    };
  };
}

const { confirm } = Modal;

const CardGamesHistory: React.FC = () => {
  const intl = useIntl();

  const [playedPushGames, setPlayedPushGames] = useState<
    IPlayedPushGameState[]
  >([]);
  const [playedPushGamesPagination, setPlayedPushGamesPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [archivedPlayedPushGames, setArchivedPlayedPushGames] = useState<
    IPlayedPushGameState[]
  >([]);
  const [
    archivedPlayedPushGamesPagination,
    setArchivedPlayedPushGamesPagination,
  ] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [showArchivedGames, setShowArchivedGames] = useState(false);
  const [loadingPlayedPushGames, setLoadingPlayedPushGames] = useState(true);

  const getPlayedPushGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingPlayedPushGames(true);
      try {
        const { data } = await api.get<{
          docs: IPlayedPushGameState[];
          page: number;
          pages: number;
          total: number;
        }>('/api/push-played', {
          params: {
            limit: playedPushGamesPagination.limit,
            page,
          },
        });

        if (page === 1) {
          setPlayedPushGames(
            data.docs.map(playedPushGame => {
              const playedPushGameWithAbbrevOnTeams = { ...playedPushGame };

              playedPushGameWithAbbrevOnTeams._push._game.local.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.local.name
                  .substring(0, 3)
                  .toUpperCase();
              playedPushGameWithAbbrevOnTeams._push._game.away.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.away.name
                  .substring(0, 3)
                  .toUpperCase();

              return playedPushGameWithAbbrevOnTeams;
            }),
          );
        } else {
          setPlayedPushGames(oldState => [
            ...oldState,
            ...data.docs.map(playedPushGame => {
              const playedPushGameWithAbbrevOnTeams = { ...playedPushGame };

              playedPushGameWithAbbrevOnTeams._push._game.local.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.local.name
                  .substring(0, 3)
                  .toUpperCase();
              playedPushGameWithAbbrevOnTeams._push._game.away.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.away.name
                  .substring(0, 3)
                  .toUpperCase();

              return playedPushGameWithAbbrevOnTeams;
            }),
          ]);
        }
        if (data.total > 0) {
          setPlayedPushGamesPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.pushGamesHistory.messages.getPlayedPushGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingPlayedPushGames(false);
    },
    [intl, playedPushGamesPagination.limit],
  );

  const getArchivedPlayedPushGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingPlayedPushGames(true);
      try {
        const { data } = await api.get<{
          docs: IPlayedPushGameState[];
          page: number;
          pages: number;
          total: number;
        }>('/api/push-played', {
          params: {
            status: false,
            limit: archivedPlayedPushGamesPagination.limit,
            page,
          },
        });

        if (page === 1) {
          setArchivedPlayedPushGames(
            data.docs.map(playedPushGame => {
              const playedPushGameWithAbbrevOnTeams = { ...playedPushGame };

              playedPushGameWithAbbrevOnTeams._push._game.local.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.local.name
                  .substring(0, 3)
                  .toUpperCase();
              playedPushGameWithAbbrevOnTeams._push._game.away.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.away.name
                  .substring(0, 3)
                  .toUpperCase();

              return playedPushGameWithAbbrevOnTeams;
            }),
          );
        } else {
          setArchivedPlayedPushGames(oldState => [
            ...oldState,
            ...data.docs.map(playedPushGame => {
              const playedPushGameWithAbbrevOnTeams = { ...playedPushGame };

              playedPushGameWithAbbrevOnTeams._push._game.local.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.local.name
                  .substring(0, 3)
                  .toUpperCase();
              playedPushGameWithAbbrevOnTeams._push._game.away.abbrev =
                playedPushGameWithAbbrevOnTeams._push._game.away.name
                  .substring(0, 3)
                  .toUpperCase();

              return playedPushGameWithAbbrevOnTeams;
            }),
          ]);
        }
        if (data.total > 0) {
          setArchivedPlayedPushGamesPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.pushGamesHistory.messages.getArchivedPlayedPushGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingPlayedPushGames(false);
    },
    [archivedPlayedPushGamesPagination.limit, intl],
  );

  useEffect(() => {
    if (!showArchivedGames && playedPushGamesPagination.totalPages === 0) {
      getPlayedPushGames();
    } else if (
      showArchivedGames &&
      archivedPlayedPushGamesPagination.totalPages === 0
    ) {
      getArchivedPlayedPushGames();
    }
  }, [
    archivedPlayedPushGamesPagination.totalPages,
    getArchivedPlayedPushGames,
    getPlayedPushGames,
    playedPushGamesPagination.totalPages,
    showArchivedGames,
  ]);

  const handleArchivePushGame = useCallback(
    async (pushGameId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.pushGamesHistory.messages.submitArchivePushGameConfirm.description',
            ),
            okText: intl.getTranslatedText('common.buttons.archive'),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve(true);
            },
          });
        });

        await api.delete(`/api/push-played/${pushGameId}`);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.pushGamesHistory.messages.submitArchivePushGameSuccess.title',
          ),
        });

        setPlayedPushGames(oldPlayedPushGames => {
          return oldPlayedPushGames.filter(
            playedPushGame => playedPushGame._id !== pushGameId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const handleUnarchivePushGame = useCallback(
    async (pushGameId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.pushGamesHistory.messages.submitUnarchivePushGameConfirm.description',
            ),
            okText: intl.getTranslatedText('common.buttons.unarchive'),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            onOk() {
              resolve(true);
            },
          });
        });

        await api.put(`/api/push-played/${pushGameId}`, {
          status: true,
        });

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.pushGamesHistory.messages.submitUnarchivePushGameSuccess.title',
          ),
        });

        setArchivedPlayedPushGames(oldArchivedPlayedPushGames => {
          return oldArchivedPlayedPushGames.filter(
            archivedPlayedPushGame => archivedPlayedPushGame._id !== pushGameId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const playedPushGamesVisualization = useMemo(() => {
    if (!showArchivedGames) {
      return (
        <>
          {playedPushGames.length > 0 ? (
            <CardGameList>
              {playedPushGames.map(playedPushGame => (
                <CardGameItem key={playedPushGame._id}>
                  <CardGameHeader>
                    {playedPushGame._push.isFinished &&
                      playedPushGame.isWinner && <FaCrown size={24} />}
                    <CustomAntButton
                      type="text"
                      useCustomIcon
                      icon={<FiTrash2 size={20} />}
                      onClick={() => handleArchivePushGame(playedPushGame._id)}
                    />
                  </CardGameHeader>
                  <MatchInfoContainer>
                    <TeamsContainer>
                      <div>
                        <Team>
                          <div>
                            <img
                              src={playedPushGame._push._game.local.image}
                              alt={playedPushGame._push._game.local.name}
                            />
                          </div>
                          <strong>
                            {playedPushGame._push._game.local.abbrev}
                          </strong>
                        </Team>
                        <h6>
                          {intl.getTranslatedText('common.vsDescription')}
                        </h6>
                        <Team>
                          <div>
                            <img
                              src={playedPushGame._push._game.away.image}
                              alt={playedPushGame._push._game.away.name}
                            />
                          </div>
                          <strong>
                            {playedPushGame._push._game.away.abbrev}
                          </strong>
                        </Team>
                      </div>
                      <small>{playedPushGame._push._game._champ.name}</small>
                    </TeamsContainer>
                    <AccumulatedValueContainer>
                      <IoTrophy size={18} />
                      <small>
                        T¢{formatMoney(playedPushGame._push.amount)}
                      </small>
                    </AccumulatedValueContainer>
                  </MatchInfoContainer>
                  <PlayedPushGameInfoContainer>
                    <div>
                      <small>
                        {intl.getTranslatedText(
                          'pages.pushGamesHistory.playedPushGameInfo.question.label',
                        )}
                      </small>
                      <small>{playedPushGame._push.question}</small>
                    </div>
                    <div>
                      <small>
                        {intl.getTranslatedText(
                          'pages.pushGamesHistory.playedPushGameInfo.price.label',
                        )}
                      </small>
                      <small>T¢{formatMoney(playedPushGame._push.price)}</small>
                    </div>
                    <div>
                      <small>
                        {intl.getTranslatedText(
                          'pages.pushGamesHistory.playedPushGameInfo.response.label',
                        )}
                      </small>
                      <small>
                        {playedPushGame.answer
                          ? intl.getTranslatedText('common.buttons.yes')
                          : intl.getTranslatedText('common.buttons.no')}
                      </small>
                    </div>
                    {playedPushGame._push.isFinished ? (
                      <>
                        <div>
                          <small>
                            {intl.getTranslatedText(
                              'pages.pushGamesHistory.playedPushGameInfo.result.label',
                            )}
                          </small>
                          <small>
                            {playedPushGame.isWinner
                              ? intl.getTranslatedText(
                                  'pages.pushGamesHistory.playedPushGameInfo.result.won',
                                )
                              : intl.getTranslatedText(
                                  'pages.pushGamesHistory.playedPushGameInfo.result.lost',
                                )}
                          </small>
                        </div>
                        {playedPushGame.isWinner && (
                          <>
                            <div>
                              <small>
                                {intl.getTranslatedText(
                                  'pages.pushGamesHistory.playedPushGameInfo.received.label',
                                )}
                              </small>
                              <small>
                                T¢{formatMoney(playedPushGame.amountReceived)}
                              </small>
                            </div>
                            <div>
                              <small>
                                {intl.getTranslatedText(
                                  'pages.pushGamesHistory.playedPushGameInfo.winners.label',
                                )}
                              </small>
                              <small>
                                {intl.getTranslatedText(
                                  'pages.pushGamesHistory.playedPushGameInfo.winners.value',
                                  {
                                    winnerCount:
                                      playedPushGame._push.winnerCount,
                                  },
                                )}
                              </small>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div>
                        <small>
                          {intl.getTranslatedText(
                            'pages.pushGamesHistory.playedPushGameInfo.result.label',
                          )}
                        </small>
                        <small>-----</small>
                      </div>
                    )}
                  </PlayedPushGameInfoContainer>
                  <small>
                    {format(
                      parseISO(playedPushGame.createdAt),
                      intl.getTranslatedText('common.formatDates.carriedOut'),
                    )}
                  </small>
                </CardGameItem>
              ))}
              {playedPushGamesPagination.currentPage <
                playedPushGamesPagination.totalPages && (
                <LoadMoreCardsButton
                  onClick={() => {
                    if (loadingPlayedPushGames) return;
                    getPlayedPushGames(
                      playedPushGamesPagination.currentPage + 1,
                    );
                  }}
                  disabled={loadingPlayedPushGames}
                >
                  <h6>
                    {!loadingPlayedPushGames
                      ? intl.getTranslatedText('common.buttons.viewMore')
                      : intl.getTranslatedText(
                          'common.messages.defaultLoading',
                        )}
                  </h6>
                </LoadMoreCardsButton>
              )}
            </CardGameList>
          ) : (
            <div>
              <h6>
                {intl.getTranslatedTextWithHTML(
                  'pages.pushGamesHistory.messages.notPushGames',
                )}
              </h6>
              <Link to="/">
                <FiChevronLeft size={16} />
                {intl.getTranslatedText('pages.pushGamesHistory.goToHomeLink')}
              </Link>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <div>
          <small>
            {intl.getTranslatedText(
              'pages.pushGamesHistory.messages.viewingArchivedGames',
            )}
          </small>
          <CustomAntButton
            onClick={() =>
              setShowArchivedGames(
                oldShowArchivedGames => !oldShowArchivedGames,
              )
            }
            type="text"
            danger
          >
            {intl.getTranslatedText(
              'pages.pushGamesHistory.showActiveGamesButton',
            )}
          </CustomAntButton>
        </div>
        {archivedPlayedPushGames.length > 0 ? (
          <CardGameList>
            {archivedPlayedPushGames.map(archivedPlayedPushGame => (
              <CardGameItem key={archivedPlayedPushGame._id} $archived>
                <CardGameHeader>
                  {archivedPlayedPushGame._push.isFinished &&
                    archivedPlayedPushGame.isWinner && <FaCrown size={24} />}
                  <CustomAntButton
                    type="text"
                    useCustomIcon
                    icon={<FiCornerUpLeft size={20} />}
                    onClick={() =>
                      handleUnarchivePushGame(archivedPlayedPushGame._id)
                    }
                  />
                </CardGameHeader>
                <MatchInfoContainer $archived>
                  <TeamsContainer>
                    <div>
                      <Team $archived>
                        <div>
                          <img
                            src={archivedPlayedPushGame._push._game.local.image}
                            alt={archivedPlayedPushGame._push._game.local.name}
                          />
                        </div>
                        <strong>
                          {archivedPlayedPushGame._push._game.local.abbrev}
                        </strong>
                      </Team>
                      <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
                      <Team $archived>
                        <div>
                          <img
                            src={archivedPlayedPushGame._push._game.away.image}
                            alt={archivedPlayedPushGame._push._game.away.name}
                          />
                        </div>
                        <strong>
                          {archivedPlayedPushGame._push._game.away.abbrev}
                        </strong>
                      </Team>
                    </div>
                    <small>
                      {archivedPlayedPushGame._push._game._champ.name}
                    </small>
                  </TeamsContainer>
                  <AccumulatedValueContainer>
                    <IoTrophy size={18} />
                    <small>
                      T¢{formatMoney(archivedPlayedPushGame._push.amount)}
                    </small>
                  </AccumulatedValueContainer>
                </MatchInfoContainer>
                <PlayedPushGameInfoContainer>
                  <div>
                    <small>
                      {intl.getTranslatedText(
                        'pages.pushGamesHistory.playedPushGameInfo.question.label',
                      )}
                    </small>
                    <small>{archivedPlayedPushGame._push.question}</small>
                  </div>
                  <div>
                    <small>
                      {intl.getTranslatedText(
                        'pages.pushGamesHistory.playedPushGameInfo.price.label',
                      )}
                    </small>
                    <small>
                      T¢{formatMoney(archivedPlayedPushGame._push.price)}
                    </small>
                  </div>
                  <div>
                    <small>
                      {intl.getTranslatedText(
                        'pages.pushGamesHistory.playedPushGameInfo.response.label',
                      )}
                    </small>
                    <small>
                      {archivedPlayedPushGame.answer
                        ? intl.getTranslatedText('common.buttons.yes')
                        : intl.getTranslatedText('common.buttons.no')}
                    </small>
                  </div>
                  {archivedPlayedPushGame._push.isFinished ? (
                    <>
                      <div>
                        <small>
                          {intl.getTranslatedText(
                            'pages.pushGamesHistory.playedPushGameInfo.result.label',
                          )}
                        </small>
                        <small>
                          {archivedPlayedPushGame.isWinner
                            ? intl.getTranslatedText(
                                'pages.pushGamesHistory.playedPushGameInfo.result.won',
                              )
                            : intl.getTranslatedText(
                                'pages.pushGamesHistory.playedPushGameInfo.result.lost',
                              )}
                        </small>
                      </div>
                      {archivedPlayedPushGame.isWinner && (
                        <>
                          <div>
                            <small>
                              {intl.getTranslatedText(
                                'pages.pushGamesHistory.playedPushGameInfo.received.label',
                              )}
                            </small>
                            <small>
                              T¢
                              {formatMoney(
                                archivedPlayedPushGame.amountReceived,
                              )}
                            </small>
                          </div>
                          <div>
                            <small>
                              {intl.getTranslatedText(
                                'pages.pushGamesHistory.playedPushGameInfo.winners.label',
                              )}
                            </small>
                            <small>
                              {intl.getTranslatedTextWithHTML(
                                'pages.pushGamesHistory.playedPushGameInfo.winners.value',
                                {
                                  winnerCount:
                                    archivedPlayedPushGame._push.winnerCount,
                                },
                              )}
                            </small>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <small>
                        {intl.getTranslatedText(
                          'pages.pushGamesHistory.playedPushGameInfo.result.label',
                        )}
                      </small>
                      <small>-----</small>
                    </div>
                  )}
                </PlayedPushGameInfoContainer>
                <small>
                  {format(
                    parseISO(archivedPlayedPushGame.createdAt),
                    intl.getTranslatedText('common.formatDates.carriedOut'),
                  )}
                </small>
              </CardGameItem>
            ))}
            {archivedPlayedPushGamesPagination.currentPage <
              archivedPlayedPushGamesPagination.totalPages && (
              <LoadMoreCardsButton
                onClick={() => {
                  if (loadingPlayedPushGames) return;
                  getArchivedPlayedPushGames(
                    archivedPlayedPushGamesPagination.currentPage + 1,
                  );
                }}
                disabled={loadingPlayedPushGames}
              >
                <h6>
                  {!loadingPlayedPushGames
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </h6>
              </LoadMoreCardsButton>
            )}
          </CardGameList>
        ) : (
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.pushGamesHistory.messages.notArchivedPushGames',
              )}
            </h6>
          </div>
        )}
      </>
    );
  }, [
    archivedPlayedPushGames,
    archivedPlayedPushGamesPagination.currentPage,
    archivedPlayedPushGamesPagination.totalPages,
    getArchivedPlayedPushGames,
    getPlayedPushGames,
    handleArchivePushGame,
    handleUnarchivePushGame,
    intl,
    loadingPlayedPushGames,
    playedPushGames,
    playedPushGamesPagination.currentPage,
    playedPushGamesPagination.totalPages,
    showArchivedGames,
  ]);

  if (loadingPlayedPushGames) {
    if (
      (!showArchivedGames && playedPushGamesPagination.totalPages === 0) ||
      (showArchivedGames && archivedPlayedPushGamesPagination.totalPages === 0)
    ) {
      return (
        <Container>
          <Breadcrumbs />
          <Header>
            <h5>{intl.getTranslatedText('pages.pushGamesHistory.title')}</h5>
          </Header>
          <Loading />
        </Container>
      );
    }
  }

  return (
    <Container>
      <Header>
        <h5>{intl.getTranslatedText('pages.pushGamesHistory.title')}</h5>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  setShowArchivedGames(
                    oldShowArchivedGames => !oldShowArchivedGames,
                  )
                }
                danger={!showArchivedGames}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!showArchivedGames ? (
                    <>
                      <FiTrash2 style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.pushGamesHistory.showUnarchivedGamesButton',
                      )}
                    </>
                  ) : (
                    <>
                      <FiCornerUpLeft style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.pushGamesHistory.showActiveGamesButton',
                      )}
                    </>
                  )}
                </div>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <CustomAntButton
            icon={<IoEllipsisVertical size={20} />}
            useCustomIcon
            type="text"
          />
        </Dropdown>
      </Header>
      {playedPushGamesVisualization}
    </Container>
  );
};

export default CardGamesHistory;
