import React from 'react';

import { useIntl } from '../../../../../context/IntlContext';

import { ICoachFriendRoom, IMyDataInTheRoom } from '../..';

import { Container, Header, Content, TeamImageContainer } from './styles';
import { PointsDescription } from '../../styles';

interface IPayGoalMode {
  coachFriendRoom: ICoachFriendRoom;
  myDataInTheRoom: IMyDataInTheRoom;
}

const PayGoalMode: React.FC<IPayGoalMode> = ({
  coachFriendRoom,
  myDataInTheRoom,
}) => {
  const intl = useIntl();

  let sessionScore = 0;
  if (myDataInTheRoom?._meGame?.localTime) sessionScore += 15;
  if (myDataInTheRoom?._meGame?.awayTime) sessionScore += 15;

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText(
            'pages.coachFriend.room.minuteOfFirstGoal.title',
          )}
        </h6>
        <PointsDescription>
          <span>{myDataInTheRoom?._meGame?.timeScore}</span>
          {` / ${sessionScore} `}
          {intl.getTranslatedText('common.scoredPointsDescription')}
        </PointsDescription>
      </Header>
      <Content>
        <div>
          <div>
            <TeamImageContainer>
              <img
                src={coachFriendRoom._game.local.image}
                alt={coachFriendRoom._game.local.name}
              />
            </TeamImageContainer>
            <strong>{coachFriendRoom._game.local.name}</strong>
          </div>
          <div>
            {myDataInTheRoom?._meGame?.localTime ? (
              <>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                  )}
                </small>
                <strong>{myDataInTheRoom._meGame?.localTime}</strong>
              </>
            ) : (
              <p>Esse time não marcou gols</p>
            )}
          </div>
        </div>
        <div>
          <div>
            <TeamImageContainer>
              <img
                src={coachFriendRoom._game.away.image}
                alt={coachFriendRoom._game.away.name}
              />
            </TeamImageContainer>
            <strong>{coachFriendRoom._game.away.name}</strong>
          </div>
          <div>
            {myDataInTheRoom?._meGame?.awayTime ? (
              <>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                  )}
                </small>
                <strong>{myDataInTheRoom._meGame?.awayTime}</strong>
              </>
            ) : (
              <p>Esse time não marcou gols</p>
            )}
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default PayGoalMode;
