import axios from 'axios';

import Environment from '../config/Environment';

const api = axios.create({
  baseURL: Environment.API_URL,
});

api.interceptors.request.use(config =>
  process.env.NODE_ENV === 'development'
    ? new Promise(resolve => setTimeout(() => resolve(config), 500))
    : config,
);

// api.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response.status === 401) {
//       return useAuth().signOut(true);
//     }
//     return Promise.reject(error);
//   },
// );

export default api;
