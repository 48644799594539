import { ChallengeType } from '../types';

export interface INewChallengeContextData {
  selectedModule: CoachFriendModule | string;
  setSelectedModule: React.Dispatch<
    React.SetStateAction<CoachFriendModule | string>
  >;
  selectedChallenge: string;
  setSelectedChallenge: React.Dispatch<React.SetStateAction<string>>;
  fields: IChallengeField[];
  setFields: React.Dispatch<React.SetStateAction<IChallengeField[]>>;
  isCustomPayment: boolean;
  setIsCustomPayment: React.Dispatch<React.SetStateAction<boolean>>;
  price: number | null;
  setPrice: React.Dispatch<React.SetStateAction<number | null>>;
  opponentPrice: number | null;
  setOpponentPrice: React.Dispatch<React.SetStateAction<number | null>>;
  formFieldsContainer: JSX.Element;
  challengeText: string;
  priceValidationMessage: string;
  opponentPriceValidationMessage: string;
  handleSubmit(): Promise<void>;
  isSubmitting: boolean;
}

export enum CoachFriendModule {
  SCOREBOARD = 'SCOREBOARD',
  MINUTE_OF_FIRST_GOAL = 'MINUTE_OF_FIRST_GOAL',
  ATHLETES = 'ATHLETES',
  STATISTICS = 'STATISTICS',
}

export enum ScoreChallenge {
  TEAM_POINTS = 'TEAM_POINTS',
}

export enum MinuteOfFirstGoalChallenge {
  TIME_OF_TEAM_FIRST_GOAL = 'TIME_OF_TEAM_FIRST_GOAL',
}

export enum AthleteChallenge {
  TIME_OF_ATHLETE_GOAL = 'TIME_OF_ATHLETE_GOAL',
}

export enum StatisticsChallenge {
  AMOUNT_OF_YELLOW_CARDS = 'AMOUNT_OF_YELLOW_CARDS',
  AMOUNT_OF_RED_CARDS = 'AMOUNT_OF_RED_CARDS',
  AMOUNT_OF_CORNERS = 'AMOUNT_OF_CORNERS',
  AMOUNT_OF_OFFSIDES = 'AMOUNT_OF_OFFSIDES',
}

export enum ScoreChallengePlaceholder {
  TEAM_POINTS = 'O [____________] irá marcar {__} gols',
}

export enum MinuteOfFirstGoalChallengePlaceholder {
  TIME_OF_TEAM_FIRST_GOAL = 'O primeiro gol do [____________] irá acontecer entre os minutos {__} e (__)',
}

export enum AthleteChallengePlaceholder {
  TIME_OF_ATHLETE_GOAL = '[____________] irá marcar um gol entre os minutos {__} e (__)',
}

export enum StatisticsChallengePlaceholder {
  AMOUNT_OF_YELLOW_CARDS = 'Na partida, haverá um total de [__] cartões amarelos.',
  AMOUNT_OF_RED_CARDS = 'Na partida, haverá um total de [__] cartões vermelhos.',
  AMOUNT_OF_CORNERS = 'Na partida, haverá um total de [__] escanteios.',
  AMOUNT_OF_OFFSIDES = 'Na partida, haverá um total de [__] impedimentos.',
}

export interface IChallengeField {
  id: number;
  marker?: Markers;
  value: unknown;
  viewValue?: string;
}

export enum Markers {
  PARENTHESIS = 'PARENTHESIS',
  CURLY_BRACKETS = 'CURLY_BRACKETS',
  SQUARE_BRACKETS = 'SQUARE_BRACKETS',
}

export enum FormFieldIdentifier {
  TEAM,
  MATCH_SCORE,
  ATHLETE,
  TIME_A,
  TIME_B,
  STATISTIC,
}

export interface INewChallengeRequestDTO {
  price: number;
  mode?: 'custom';
  customPrice?: number;
  question: string;
  gameId: string;
  gameType: ChallengeType;
  details: {
    teamId: string;
    athlete?: string;
    score?: string;
    time?: {
      a: number;
      b: number;
    };
    statistic?: number;
  };
}
