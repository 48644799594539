import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { FiLock, FiSearch, FiHome, FiPlus } from 'react-icons/fi';
import { FaCrown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { Image, Shimmer } from 'react-shimmer';

import CustomAntButton from '../../components/CustomAntButton';
import Loading from '../../components/Loading';
import UserAvatar from '../../components/UserAvatar';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import Env from '../../config/Environment';
import logoAmateur from '../../assets/logo-only-icon.svg';

import api from '../../services/api';
import socket from '../../services/socket';

import SessionList from './SessionList';

import {
  Container,
  Session,
  SessionActionButtonsContainer,
  ChampionshipCard,
  AthletesOfTheWeekSession,
  AthletesOfTheWeekBanner,
  CoachFriendRoomCard,
  CoachFriendRoomTeam,
  RankingsSession,
  RankingSelector,
  RankingBanner,
  RankingTop3Item,
  AdvertisementItemContainer,
  CarouselContainer,
} from './styles';

interface IChampionship {
  _id: string;
  id: string;
  name: string;
  imageUrl: string;
  background?: string;
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

interface ICoachFriendRoom {
  _id: string;
  isPrivate: boolean;
  isFinished: boolean;
  name: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    local: ITeam;
    away: ITeam;
  };
}

interface IVersusRoom {
  _id: string;
  isPrivate: boolean;
  isFinished: boolean;
  name: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    local: ITeam;
    away: ITeam;
  };
}

interface IAthleteOfTheWeekCard {
  _id: string;
  status: boolean;
  name: string;
  startDate: string;
  banner?: {
    _id: string;
    filename: string;
  };
}

export interface IRankingPosition {
  _id: string;
  totalScore: number;
  friendlyId: string;
  createdAt: string;
  _user: {
    _id: string;
    isMe: boolean;
    username: string;
    name: string;
    photo?: {
      _id: string;
      filename: string;
    };
  };
}

interface IRankings {
  global: IRankingPosition[];
  coachFriend: IRankingPosition[];
  versus: IRankingPosition[];
  versusVIP: IRankingPosition[];
  cardGames: IRankingPosition[];
  athletesOfTheWeek: IRankingPosition[];
  pushGames: IRankingPosition[];
}

interface IAds {
  _id: string;
  title: string;
  url?: string;
  _upload: string;
  sponsorUrl: string;
}

interface IAdsSettings {
  time: number;
  amount: number;
}

const Home: React.FC = () => {
  const intl = useIntl();
  const carouselRef = useRef<CarouselRef>(null);

  const [championships, setChampionships] = useState<IChampionship[]>([]);
  const [loadingChampionships, setLoadingChampionships] = useState(false);
  const [championshipsPagination, setChampionshipsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [coachFriendRooms, setCoachFriendRooms] = useState<ICoachFriendRoom[]>(
    [],
  );
  const [loadingCoachFriendRooms, setLoadingCoachFriendRooms] = useState(false);
  const [coachFriendRoomsPagination, setCoachFriendRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [versusRooms, setVersusRooms] = useState<IVersusRoom[]>([]);
  const [loadingVersusRooms, setLoadingVersusRooms] = useState(false);
  const [versusRoomsPagination, setVersusRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [versusVIPRooms, setVersusVIPRooms] = useState<IVersusRoom[]>([]);
  const [loadingVersusVIPRooms, setLoadingVersusVIPRooms] = useState(false);
  const [versusVIPRoomsPagination, setVersusVIPRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [athleteOfTheWeek, setAthleteOfTheWeek] =
    useState<IAthleteOfTheWeekCard | null>();
  const [loadingAthleteOfTheWeek, setLoadingAthleteOfTheWeek] = useState(false);

  const [ranking, setRankings] = useState<IRankings | null>(null);
  const [loadingRankings, setLoadingRankings] = useState(false);
  const [selectedRanking, setSelectedRanking] = useState<
    | 'global'
    | 'coachFriend'
    | 'versus'
    | 'versusVIP'
    | 'cardGames'
    | 'athletesOfTheWeek'
    | 'pushGames'
  >('global');

  const [adverts, setAdverts] = useState<IAds[]>([]);
  const [advertsSettings, setAdvertsSettings] = useState<IAdsSettings | null>(
    null,
  );

  const [loadingData, setLoadingData] = useState(true);

  const handleGetChampionships = useCallback(
    async (page = 1) => {
      setLoadingChampionships(true);

      const { data } = await api.get<{
        docs: IChampionship[];
        pages: number;
        page: number;
      }>('/api/championship', {
        params: {
          limit: championshipsPagination.limit,
          page,
        },
      });

      if (page === 1) {
        setChampionships(data.docs);
      } else {
        setChampionships(oldState => [...oldState, ...data.docs]);
      }

      setChampionshipsPagination({
        currentPage: data.page,
        totalPages: data.pages,
        limit: championshipsPagination.limit,
      });

      setLoadingChampionships(false);
    },
    [championshipsPagination.limit],
  );

  const handleGetCoachFriendRooms = useCallback(
    async (page = 1) => {
      setLoadingCoachFriendRooms(true);

      const { data } = await api.get<{
        docs: ICoachFriendRoom[];
        pages: number;
        page: number;
      }>('/api/coach-friend', {
        params: {
          limit: coachFriendRoomsPagination.limit,
          page,
          detail: true,
        },
      });

      if (page === 1) {
        setCoachFriendRooms(
          data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        );
      } else {
        setCoachFriendRooms(oldState => [
          ...oldState,
          ...data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        ]);
      }

      setCoachFriendRoomsPagination({
        currentPage: data.page,
        totalPages: data.pages,
        limit: coachFriendRoomsPagination.limit,
      });

      setLoadingCoachFriendRooms(false);
    },
    [coachFriendRoomsPagination.limit],
  );

  const handleGetVersusRooms = useCallback(
    async (page = 1) => {
      setLoadingVersusRooms(true);

      const { data } = await api.get<{
        docs: IVersusRoom[];
        pages: number;
        page: number;
      }>('/api/game-vs', {
        params: {
          limit: versusRoomsPagination.limit,
          page,
          detail: true,
        },
      });

      if (page === 1) {
        setVersusRooms(
          data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        );
      } else {
        setVersusRooms(oldState => [
          ...oldState,
          ...data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        ]);
      }

      setVersusRoomsPagination({
        currentPage: data.page,
        totalPages: data.pages,
        limit: versusRoomsPagination.limit,
      });

      setLoadingVersusRooms(false);
    },
    [versusRoomsPagination.limit],
  );

  const handleGetVersusVIPRooms = useCallback(
    async (page = 1) => {
      setLoadingVersusVIPRooms(true);

      const { data } = await api.get<{
        docs: IVersusRoom[];
        pages: number;
        page: number;
      }>('/api/game-vs', {
        params: {
          limit: versusRoomsPagination.limit,
          page,
          detail: true,
          isVip: true,
        },
      });

      if (page === 1) {
        setVersusVIPRooms(
          data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        );
      } else {
        setVersusVIPRooms(oldState => [
          ...oldState,
          ...data.docs.map(room => ({
            ...room,
            _game: {
              ...room._game,
              local: {
                ...room._game.local,
                abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
              },
              away: {
                ...room._game.away,
                abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
              },
            },
          })),
        ]);
      }

      setVersusVIPRoomsPagination({
        currentPage: data.page,
        totalPages: data.pages,
        limit: versusRoomsPagination.limit,
      });

      setLoadingVersusVIPRooms(false);
    },
    [versusRoomsPagination.limit],
  );

  const handleGetFirstAthletesOfTheWeek = useCallback(async () => {
    try {
      setLoadingAthleteOfTheWeek(true);

      const { data } = await api.get<{
        docs: IAthleteOfTheWeekCard[];
      }>('/api/athlete-card', {
        params: {
          limit: 1,
        },
      });

      if (data.docs.length > 0) {
        setAthleteOfTheWeek(data.docs[0]);
      }

      setLoadingAthleteOfTheWeek(false);
    } catch (error) {
      setLoadingAthleteOfTheWeek(false);
    }
  }, []);

  const handleGetRankings = useCallback(async () => {
    setLoadingRankings(true);

    const handleRanking = (
      url: string,
    ): Promise<
      AxiosResponse<{
        docs: IRankingPosition[];
      }>
    > => {
      // return api.get<{
      //   docs: IRankingPosition[];
      // }>('/api/ranking-card', {
      //   params: {
      //     limit: 3,
      //     card: '5fcbd990fe4e3e492cd6607c',
      //   },
      // });

      return api.get<{
        docs: IRankingPosition[];
      }>(url, {
        params: {
          limit: 3,
        },
      });
    };

    const [
      globalRankingResponse,
      coachFriendRankingResponse,
      versusRankingResponse,
      versusVIPRankingResponse,
      cardGamesRankingResponse,
      athletesOfTheWeekRankingResponse,
      pushGamesRankingResponse,
    ] = await Promise.all([
      handleRanking('/api/ranking/global'),
      handleRanking('/api/ranking/coach'),
      handleRanking('/api/ranking/vs'),
      handleRanking('/api/ranking/vs-vip'),
      handleRanking('/api/ranking/card'),
      handleRanking('/api/ranking/athlete'),
      handleRanking('/api/ranking/push'),
    ]);

    setRankings({
      global: globalRankingResponse.data.docs,
      coachFriend: coachFriendRankingResponse.data.docs,
      versus: versusRankingResponse.data.docs,
      versusVIP: versusVIPRankingResponse.data.docs,
      cardGames: cardGamesRankingResponse.data.docs,
      athletesOfTheWeek: athletesOfTheWeekRankingResponse.data.docs,
      pushGames: pushGamesRankingResponse.data.docs,
    });
  }, []);

  const handleStartCarousel = useCallback(
    async (time: number): Promise<void> => {
      setInterval(() => {
        if (carouselRef.current) {
          carouselRef?.current?.next();
        }
      }, time);
    },
    [],
  );

  const handleGetAdvertisement = useCallback(async () => {
    const [adsResponse, adsSettingsResponse] = await Promise.all([
      api.get<IAds[]>('/api/slide-main', {
        params: {
          slide: true,
        },
      }),
      api.get<{
        docs: IAdsSettings[];
      }>('/api/slide-main/settings'),
    ]);

    setAdverts(adsResponse.data);
    if (adsSettingsResponse?.data?.docs.length > 0) {
      setAdvertsSettings(adsSettingsResponse.data.docs[0]);
      handleStartCarousel(adsSettingsResponse.data.docs[0].time * 1000);
    }
  }, [handleStartCarousel]);

  const handleGetHomeData = useCallback(() => {
    Promise.all([
      handleGetChampionships(),
      handleGetCoachFriendRooms(),
      handleGetVersusRooms(),
      handleGetVersusVIPRooms(),
      handleGetFirstAthletesOfTheWeek(),
      handleGetRankings(),
      handleGetAdvertisement(),
    ])
      .then(() => {
        setLoadingData(false);
      })
      .catch(() => {
        setLoadingData(false);
        showToast({
          type: 'error',
          title: intl.getTranslatedText('pages.home.getHomeDataError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      });
  }, [
    handleGetAdvertisement,
    handleGetChampionships,
    handleGetCoachFriendRooms,
    handleGetFirstAthletesOfTheWeek,
    handleGetRankings,
    handleGetVersusRooms,
    handleGetVersusVIPRooms,
    intl,
  ]);

  useEffect(() => {
    handleGetHomeData();
  }, [handleGetHomeData]);

  useEffect(() => {
    socket.on('vs-list', () => {
      handleGetHomeData();
    });
    socket.on('coach-list', () => {
      handleGetHomeData();
    });
    return () => {
      socket.off('vs-list');
      socket.off('coach-list');
    };
  }, [handleGetHomeData]);

  const handleGetMoreChampionships = useCallback(async () => {
    if (
      championshipsPagination.currentPage < championshipsPagination.totalPages
    ) {
      await handleGetChampionships(championshipsPagination.currentPage + 1);
    }
  }, [
    championshipsPagination.currentPage,
    championshipsPagination.totalPages,
    handleGetChampionships,
  ]);

  const handleGetMoreCoachFriendRooms = useCallback(async () => {
    if (
      coachFriendRoomsPagination.currentPage <
      coachFriendRoomsPagination.totalPages
    ) {
      await handleGetCoachFriendRooms(
        coachFriendRoomsPagination.currentPage + 1,
      );
    }
  }, [
    coachFriendRoomsPagination.currentPage,
    coachFriendRoomsPagination.totalPages,
    handleGetCoachFriendRooms,
  ]);

  const handleGetMoreVersusRooms = useCallback(async () => {
    if (versusRoomsPagination.currentPage < versusRoomsPagination.totalPages) {
      await handleGetVersusRooms(versusRoomsPagination.currentPage + 1);
    }
  }, [
    versusRoomsPagination.currentPage,
    versusRoomsPagination.totalPages,
    handleGetVersusRooms,
  ]);

  const handleGetMoreVersusVIPRooms = useCallback(async () => {
    if (
      versusVIPRoomsPagination.currentPage < versusVIPRoomsPagination.totalPages
    ) {
      await handleGetVersusVIPRooms(versusVIPRoomsPagination.currentPage + 1);
    }
  }, [
    versusVIPRoomsPagination.currentPage,
    versusVIPRoomsPagination.totalPages,
    handleGetVersusVIPRooms,
  ]);

  const selectedRankingCardViewer = useMemo(() => {
    if (!ranking) {
      return <></>;
    }

    const selectedRankingData = {
      global: ranking.global,
      coachFriend: ranking.coachFriend,
      versus: ranking.versus,
      versusVIP: ranking.versusVIP,
      cardGames: ranking.cardGames,
      athletesOfTheWeek: ranking.athletesOfTheWeek,
      pushGames: ranking.pushGames,
    };

    const selectedRankingUrls = {
      global: '/rankings/global',
      coachFriend: '/rankings/coach_friend',
      versus: '/rankings/versus',
      versusVIP: '/rankings/versus_vip',
      cardGames: '/rankings/card_games',
      athletesOfTheWeek: '/rankings/athletes_of_the_week',
      pushGames: '/rankings/push_games',
    };

    return (
      <RankingBanner
        to={selectedRankingUrls[selectedRanking]}
        $bg="https://img.freepik.com/free-photo/close-up-soccer-striker-ready-kicks-fiery-ball-stadium_207634-7.jpg?size=626&ext=jpg"
      >
        <div>
          <RankingTop3Item>
            <p>2</p>
            {/* <img
              src={
                selectedRankingData[selectedRanking][1]?._user?.photo
                  ? Env.IMAGE_SERVER_URL +
                    selectedRankingData[selectedRanking][1]?._user?.photo
                      ?.filename
                  : defaultAvatar
              }
              alt={
                selectedRankingData[selectedRanking][1]?._user?.name || '-----'
              }
            /> */}
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][1]?._user?.photo?._id
              }
              size={85}
            />
            <p>
              {selectedRankingData[selectedRanking][1]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][1]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][1]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][1]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
          <RankingTop3Item>
            <p>1</p>
            <FaCrown size={24} />
            {/* <img
              src={
                selectedRankingData[selectedRanking][0]?._user?.photo
                  ? Env.IMAGE_SERVER_URL +
                    selectedRankingData[selectedRanking][0]?._user?.photo
                      ?.filename
                  : defaultAvatar
              }
              alt={
                selectedRankingData[selectedRanking][0]?._user?.name || '-----'
              }
            /> */}
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][0]?._user?.photo?._id
              }
              size={100}
            />
            <p>
              {selectedRankingData[selectedRanking][0]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][0]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][0]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][0]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
          <RankingTop3Item>
            <p>3</p>
            {/* <img
              src={
                selectedRankingData[selectedRanking][2]?._user?.photo
                  ? Env.IMAGE_SERVER_URL +
                    selectedRankingData[selectedRanking][2]?._user?.photo
                      ?.filename
                  : defaultAvatar
              }
              alt={
                selectedRankingData[selectedRanking][2]?._user?.name || '-----'
              }
            /> */}
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][2]?._user?.photo?._id
              }
              size={85}
            />
            <p>
              {selectedRankingData[selectedRanking][2]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][2]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][2]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][2]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
        </div>
        <small>
          {intl.getTranslatedText('pages.home.rankingBannerButtonDescription')}
        </small>
      </RankingBanner>
    );
  }, [intl, ranking, selectedRanking]);

  if (loadingData) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <RankingsSession>
        <div>
          <h5>{intl.getTranslatedText('pages.home.rankingsLabel')}</h5>
        </div>
        <div>
          <RankingSelector
            onClick={() => setSelectedRanking('global')}
            $selected={selectedRanking === 'global'}
          >
            <p>{intl.getTranslatedText('pages.home.globalRankingLabel')}</p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('coachFriend')}
            $selected={selectedRanking === 'coachFriend'}
          >
            <p>{intl.getTranslatedText('common.gamesTitles.coachFriend')}</p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('versus')}
            $selected={selectedRanking === 'versus'}
          >
            <p>{intl.getTranslatedText('common.gamesTitles.versus')}</p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('versusVIP')}
            $selected={selectedRanking === 'versusVIP'}
          >
            <p>{intl.getTranslatedText('common.gamesTitles.versusVIP')}</p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('cardGames')}
            $selected={selectedRanking === 'cardGames'}
          >
            <p>{intl.getTranslatedText('common.gamesTitles.cardGames')}</p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('athletesOfTheWeek')}
            $selected={selectedRanking === 'athletesOfTheWeek'}
          >
            <p>
              {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
            </p>
          </RankingSelector>
          <RankingSelector
            onClick={() => setSelectedRanking('pushGames')}
            $selected={selectedRanking === 'pushGames'}
          >
            <p>{intl.getTranslatedText('common.gamesTitles.pushGames')}</p>
          </RankingSelector>
        </div>
        <div>{selectedRankingCardViewer}</div>
      </RankingsSession>
      {adverts.length ? (
        <CarouselContainer>
          <Carousel ref={carouselRef}>
            {adverts.map(advert => (
              <div key={advert._id}>
                <AdvertisementItemContainer>
                  <a
                    href={advert.url ? advert.url : advert.sponsorUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                      if (!advert.url && !advert.sponsorUrl) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Image
                      src={Env.IMAGE_SERVER_URL + advert._upload}
                      fallback={<Shimmer width={100} height={100} />}
                    />
                  </a>
                </AdvertisementItemContainer>
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      ) : (
        <></>
      )}
      <Session>
        <div>
          <h5>{intl.getTranslatedText('common.gamesTitles.coachFriend')}</h5>
        </div>
        <SessionActionButtonsContainer>
          <ul>
            <Link to="/coach_friend/new_room">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiPlus size={16} />}
              >
                {intl.getTranslatedText('pages.home.newRoomButton')}
              </CustomAntButton>
            </Link>
            <Link to="/coach_friend/public_rooms">
              <CustomAntButton
                useCustomIconWithText
                icon={<FiSearch size={16} />}
                type="primary"
              >
                {intl.getTranslatedText('pages.home.publicRoomsButton')}
              </CustomAntButton>
            </Link>
            <Link to="/coach_friend/my_rooms">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiHome size={16} />}
              >
                {intl.getTranslatedText('pages.home.myRoomsButton')}
              </CustomAntButton>
            </Link>
          </ul>
        </SessionActionButtonsContainer>
        {coachFriendRooms.length > 0 ? (
          <SessionList
            sizeItems={{ width: 272, height: 230 }}
            gapItems={8}
            onEndReached={handleGetMoreCoachFriendRooms}
            hasMoreItems={
              coachFriendRoomsPagination.currentPage <
              coachFriendRoomsPagination.totalPages
            }
            loadingMore={loadingCoachFriendRooms}
          >
            {coachFriendRooms.map(room => (
              <CoachFriendRoomCard
                key={room._id}
                to={`/coach_friend/${room._id}`}
              >
                <div>
                  <strong>{room.name}</strong>
                  <div>
                    {/* <CoachFriendRoomStatusIndicator>
                    Solicitado
                  </CoachFriendRoomStatusIndicator> */}
                    {room.isPrivate && <FiLock size={19} />}
                  </div>
                </div>
                <div>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </CoachFriendRoomTeam>
                  <h6>
                    {intl.getTranslatedTextWithHTML('common.vsDescription')}
                  </h6>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </CoachFriendRoomTeam>
                </div>
                <small>{room._champ.name}</small>
              </CoachFriendRoomCard>
            ))}
          </SessionList>
        ) : (
          <p>
            {intl.getTranslatedTextWithHTML('pages.home.noCoachFriendRooms')}
          </p>
        )}
      </Session>
      <Session>
        <div>
          <h5>{intl.getTranslatedText('common.gamesTitles.versus')}</h5>
        </div>
        <SessionActionButtonsContainer>
          <ul>
            <Link to="/versus/new_room">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiPlus size={16} />}
              >
                {intl.getTranslatedText('pages.home.newRoomButton')}
              </CustomAntButton>
            </Link>
            <Link to="/versus/public_rooms">
              <CustomAntButton
                useCustomIconWithText
                icon={<FiSearch size={16} />}
                type="primary"
              >
                {intl.getTranslatedText('pages.home.publicRoomsButton')}
              </CustomAntButton>
            </Link>
            <Link to="/versus/my_rooms">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiHome size={16} />}
              >
                {intl.getTranslatedText('pages.home.myRoomsButton')}
              </CustomAntButton>
            </Link>
          </ul>
        </SessionActionButtonsContainer>
        {versusRooms.length > 0 ? (
          <SessionList
            sizeItems={{ width: 272, height: 230 }}
            gapItems={8}
            onEndReached={handleGetMoreVersusRooms}
            hasMoreItems={
              versusRoomsPagination.currentPage <
              versusRoomsPagination.totalPages
            }
            loadingMore={loadingVersusRooms}
          >
            {versusRooms.map(room => (
              <CoachFriendRoomCard key={room._id} to={`/versus/${room._id}`}>
                <div>
                  <strong>{room.name}</strong>
                  <div>{room.isPrivate && <FiLock size={19} />}</div>
                </div>
                <div>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </CoachFriendRoomTeam>
                  <h6>
                    {intl.getTranslatedTextWithHTML('common.vsDescription')}
                  </h6>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </CoachFriendRoomTeam>
                </div>
                <small>{room._champ.name}</small>
              </CoachFriendRoomCard>
            ))}
          </SessionList>
        ) : (
          <p>{intl.getTranslatedTextWithHTML('pages.home.noVersusRooms')}</p>
        )}
      </Session>
      <Session>
        <div>
          <h5>{intl.getTranslatedText('common.gamesTitles.versusVIP')}</h5>
        </div>
        <SessionActionButtonsContainer>
          <ul>
            <Link to="/versus_vip/new_room">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiPlus size={16} />}
              >
                {intl.getTranslatedText('pages.home.newRoomButton')}
              </CustomAntButton>
            </Link>
            <Link to="/versus_vip/public_rooms">
              <CustomAntButton
                useCustomIconWithText
                icon={<FiSearch size={16} />}
                type="primary"
              >
                {intl.getTranslatedText('pages.home.publicRoomsButton')}
              </CustomAntButton>
            </Link>
            <Link to="/versus_vip/my_rooms">
              <CustomAntButton
                type="primary"
                useCustomIconWithText
                icon={<FiHome size={16} />}
              >
                {intl.getTranslatedText('pages.home.myRoomsButton')}
              </CustomAntButton>
            </Link>
          </ul>
        </SessionActionButtonsContainer>
        {versusVIPRooms.length > 0 ? (
          <SessionList
            sizeItems={{ width: 272, height: 230 }}
            gapItems={8}
            onEndReached={handleGetMoreVersusVIPRooms}
            hasMoreItems={
              versusVIPRoomsPagination.currentPage <
              versusVIPRoomsPagination.totalPages
            }
            loadingMore={loadingVersusVIPRooms}
          >
            {versusVIPRooms.map(room => (
              <CoachFriendRoomCard key={room._id} to={`/versus/${room._id}`}>
                <div>
                  <strong>{room.name}</strong>
                  <div>{room.isPrivate && <FiLock size={19} />}</div>
                </div>
                <div>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </CoachFriendRoomTeam>
                  <h6>
                    {intl.getTranslatedTextWithHTML('common.vsDescription')}
                  </h6>
                  <CoachFriendRoomTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </CoachFriendRoomTeam>
                </div>
                <small>{room._champ.name}</small>
              </CoachFriendRoomCard>
            ))}
          </SessionList>
        ) : (
          <p>{intl.getTranslatedTextWithHTML('pages.home.noVersusVIPRooms')}</p>
        )}
      </Session>
      <Session>
        <div>
          <h5>{intl.getTranslatedText('common.gamesTitles.cardGames')}</h5>
          {/* <CustomAntButton
            onClick={() => {
              showToast({
                title: 'Ainda não está funcionando...',
                description: 'Essa funcionalidade está em desenvolvimento 🥳',
              });
              // console.log({
              //   scrollWidth: championshipsSessionRef?.current?.scrollWidth,
              //   clientWidth: championshipsSessionRef?.current?.clientWidth,
              //   scrollLeft: championshipsSessionRef?.current?.scrollLeft,
              // });
            }}
            type="text"
          >
            Ver todos
          </CustomAntButton> */}
        </div>
        {championships.length > 0 && (
          <SessionList
            sizeItems={{ width: 272, height: 288 }}
            gapItems={8}
            onEndReached={handleGetMoreChampionships}
            hasMoreItems={
              championshipsPagination.currentPage <
              championshipsPagination.totalPages
            }
            loadingMore={loadingChampionships}
          >
            {championships.map(championship => (
              <ChampionshipCard
                key={championship._id}
                to={`/championships/${championship._id}`}
                $bg={
                  championship.background &&
                  Env.IMAGE_SERVER_URL + championship.background
                }
              >
                <div>
                  <img
                    src={
                      championship.id === 'AMATEUR'
                        ? logoAmateur
                        : championship.imageUrl
                    }
                    alt={championship.name}
                  />
                  <div>
                    <h6>{championship.name}</h6>
                    {/* <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit consequuntur laborum perferendis accusamus vel
                    expedita praesentium deserunt nobis quibusdam aut, sapiente
                    tenetur at odit consequatur nemo. Harum atque distinctio
                    praesentium.
                  </p> */}
                  </div>
                </div>
              </ChampionshipCard>
            ))}
          </SessionList>
        )}
      </Session>
      <AthletesOfTheWeekSession>
        <AthletesOfTheWeekBanner
          $bg={
            athleteOfTheWeek?.banner?._id &&
            Env.IMAGE_SERVER_URL + athleteOfTheWeek?.banner?._id
          }
          to="/athletes_of_the_week"
        >
          <div>
            <div>
              <h6>
                {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
              </h6>
              {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit consequuntur laborum perferendis accusamus vel
              expedita praesentium deserunt nobis quibusdam aut, sapiente
              tenetur at odit consequatur nemo. Harum atque distinctio
              praesentium.
            </p> */}
            </div>
          </div>
        </AthletesOfTheWeekBanner>
      </AthletesOfTheWeekSession>
      <AthletesOfTheWeekSession>
        <AthletesOfTheWeekBanner
          // $bg={
          //   athleteOfTheWeek?.banner?._id &&
          //   Env.IMAGE_SERVER_URL + athleteOfTheWeek?.banner?._id
          // }
          to="/push_games_history"
        >
          <div>
            <div>
              <h6>{intl.getTranslatedText('common.gamesTitles.pushGames')}</h6>
              {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit consequuntur laborum perferendis accusamus vel
              expedita praesentium deserunt nobis quibusdam aut, sapiente
              tenetur at odit consequatur nemo. Harum atque distinctio
              praesentium.
            </p> */}
            </div>
          </div>
        </AthletesOfTheWeekBanner>
      </AthletesOfTheWeekSession>
    </Container>
  );
};

export default Home;
