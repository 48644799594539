import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { FiChevronLeft } from 'react-icons/fi';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import CustomAntButton from '../../../../../components/CustomAntButton';
import { showToast } from '../../../../../hooks/showToast';
import api from '../../../../../services/api';
import { formatSoccerPlayerNumber } from '../../../../../utils/formatters';
import { useIntl } from '../../../../../context/IntlContext';

import MatchCard from './MatchCard';

import { Container, Header } from './styles';
import AppError from '../../../../../errors/AppError';
import NotFound from '../../../../../components/NotFound';
import { IBlockPageMessage } from '../../../../../services/history';
import Loading from '../../../../../components/Loading';

const { confirm } = Modal;

interface IRouteParams {
  card_game_id: string;
  id: string;
}

export interface IMyMatch {
  choise: 'local' | 'draw' | 'away' | '';
  localPlayer: string;
  localPlayerName: string;
  awayPlayer: string;
  awayPlayerName: string;
}

export interface IPlayer {
  _id: string;
  name: string;
  number: string;
  position: string;
}

export interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
  players: IPlayer[];
}

export interface IMatch {
  _id: string;
  info: {
    place: string;
    date: string;
  };
  local: ITeam;
  away: ITeam;
  myMatch: IMyMatch;
}

interface ICardGameState {
  _id: string;
  name: string;
  matchs: IMatch[];
}

interface IGameResponse {
  game: IMatch;
}

interface ICardGameResponse {
  _id: string;
  name: string;
  games: IGameResponse[];
}

const PlayCardGame: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();

  const [cardGame, setCardGame] = useState<ICardGameState | null>(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingSendMyGame, setLoadingSendMyGame] = useState(false);

  const unblockPage = useMemo(() => {
    const message: IBlockPageMessage = {
      title: intl.getTranslatedText(
        'pages.championship.cardGame.play.messages.unblockPageConfirm.title',
      ),
      description: intl.getTranslatedText('common.messages.changesWillBeLost'),
      okText: intl.getTranslatedText('common.buttons.exit'),
      cancelText: intl.getTranslatedText('common.buttons.cancel'),
    };

    return history.block(JSON.stringify(message));
  }, [history, intl]);

  function handleBeforeUnload(e: BeforeUnloadEvent): void {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  useEffect(() => {
    async function getCardGame(): Promise<void> {
      try {
        const { data } = await api.get<ICardGameResponse>(
          `/api/card-game/${params.card_game_id}`,
        );

        const matchs: IMatch[] = data.games.map(({ game }) => {
          return {
            _id: game._id,
            info: game.info,
            local: {
              _id: game.local._id,
              name: game.local.name,
              abbrev: game.local.name.substring(0, 3).toUpperCase(),
              image: game.local.image,
              players: game.local.players.map(player => ({
                _id: player._id,
                name: player.name,
                number: formatSoccerPlayerNumber(player.number),
                position: player.position,
              })),
            },
            away: {
              _id: game.away._id,
              name: game.away.name,
              abbrev: game.away.name.substring(0, 3).toUpperCase(),
              image: game.away.image,
              players: game.away.players.map(player => ({
                _id: player._id,
                name: player.name,
                number: formatSoccerPlayerNumber(player.number),
                position: player.position,
              })),
            },
            myMatch: {
              choise: '',
              localPlayer: '',
              localPlayerName: '',
              awayPlayer: '',
              awayPlayerName: '',
            },
          };
        });

        setCardGame({
          _id: data._id,
          name: data.name,
          matchs,
        });
      } catch (error) {
        // showToast({
        //   type: 'error',
        //   title: 'Ocorreu um erro ao carregar os campeonatos',
        //   description: 'Recarregue a página!',
        // });
      }
      setLoadingCard(false);
    }

    getCardGame();
  }, [history, params]);

  const handleSelectChampionOfMatch = useCallback(
    (match_id: string, selectedChampion: 'local' | 'draw' | 'away'): void => {
      setCardGame(oldCardGame =>
        oldCardGame
          ? {
              ...oldCardGame,
              matchs: oldCardGame?.matchs.map(match => {
                if (match._id === match_id) {
                  return {
                    ...match,
                    myMatch: { ...match.myMatch, choise: selectedChampion },
                  };
                }

                return match;
              }),
            }
          : null,
      );
    },
    [],
  );

  const handleSelectPlayerOfTeam = useCallback(
    (
      match_id: string,
      playerOfTeam: 'local' | 'away',
      player_id: string,
      player_name: string,
    ): void => {
      const selectedPlayerData: { [key: string]: string } = {};
      selectedPlayerData[`${playerOfTeam}Player`] = player_id;
      selectedPlayerData[`${playerOfTeam}PlayerName`] = player_name;

      setCardGame(oldCardGame =>
        oldCardGame
          ? {
              ...oldCardGame,
              matchs: oldCardGame?.matchs.map(match => {
                if (match._id === match_id) {
                  return {
                    ...match,
                    myMatch: { ...match.myMatch, ...selectedPlayerData },
                  };
                }

                return match;
              }),
            }
          : null,
      );
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const myMatchs = cardGame?.matchs.map(match => ({
        ...match.myMatch,
        game: match._id,
      }));

      myMatchs?.forEach(match => {
        if (!_.every(match, propertyValue => !!propertyValue)) {
          throw new AppError(
            intl.getTranslatedText(
              'pages.championship.cardGame.play.messages.submitNewGameValidationError.title',
            ),
            intl.getTranslatedText(
              'pages.championship.cardGame.play.messages.submitNewGameValidationError.description',
            ),
            {
              type: 'validation',
            },
          );
        }
      });

      await new Promise((resolve, reject) => {
        confirm({
          title: intl.getTranslatedText(
            'pages.championship.cardGame.play.messages.submitNewGameConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.championship.cardGame.play.submitNewGameConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCEL_SEND_GAME'));
          },
        });
      });

      const body = {
        _champ: params.id,
        _card: cardGame?._id,
        games: myMatchs,
      };
      setLoadingSendMyGame(true);
      await api.post('/api/played-card', body);
      setLoadingSendMyGame(false);

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.championship.cardGame.play.messages.submitNewGameSuccess.title',
        ),
      });
      history.push(`/championships/${params.id}`);
    } catch (error) {
      if (error instanceof AppError && error.options?.type === 'validation') {
        showToast({
          type: 'warn',
          title: error.title,
          description: error.description,
        });
        return;
      }
      setLoadingSendMyGame(false);
    }
  }, [cardGame?._id, cardGame?.matchs, history, intl, params.id, unblockPage]);

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs />
        <Header>
          <h5>
            {intl.getTranslatedText('pages.championship.cardGame.play.title')}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!cardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.championship.cardGame.play.messages.noCard',
          )}
        </h4>
        <Link to={`/championships/${params.id}`}>
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.championship.cardGame.play.goToChampionshipDetailsLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText('pages.championship.cardGame.play.title')} |{' '}
          {cardGame?.name}
        </h5>
        <Link
          to={`/championships/${params.id}/card_games/${params.card_game_id}`}
        >
          <CustomAntButton type="text" danger>
            {intl.getTranslatedText('common.buttons.cancel')}
          </CustomAntButton>
        </Link>
      </Header>
      <ul>
        {cardGame?.matchs.map(match => (
          <MatchCard
            key={match._id}
            {...match}
            handleSelectChampionOfMatch={handleSelectChampionOfMatch}
            handleSelectPlayerOfTeam={handleSelectPlayerOfTeam}
          />
        ))}
      </ul>
      <CustomAntButton
        onClick={handleSubmit}
        disabled={loadingSendMyGame}
        type="primary"
      >
        {intl.getTranslatedText(
          'pages.championship.cardGame.play.submitNewGameConfirmButton',
        )}
      </CustomAntButton>
    </Container>
  );
};

export default PlayCardGame;
