import React, { CSSProperties, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import { Image, Shimmer } from 'react-shimmer';
import { FiMessageCircle } from 'react-icons/fi';

import Env from '../../config/Environment';
import DefaultAvatar from '../../assets/DefaultAvatar.svg';
import { useAuth } from '../../context/AuthContext';
import { useIntl } from '../../context/IntlContext';

import CustomAntButton from '../CustomAntButton';

import { Container, ModalContent } from './styles';

interface IUserAvatarProps {
  photoId?: string;
  size?: number;
  user?: {
    _id: string;
    name: string;
    username: string;
    email?: string;
  };
  style?: CSSProperties;
}

const UserAvatar: React.FC<IUserAvatarProps> = ({
  photoId,
  size = 32,
  user,
  style,
}) => {
  const intl = useIntl();
  const { user: me } = useAuth();

  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  return (
    <>
      <Container
        style={style}
        $size={size}
        $hasActionForShowUserData={!!user?._id && user._id !== me?._id}
        onClick={() => {
          if (user?._id && user._id !== me?._id) {
            setAvatarModalVisible(true);
          }
        }}
      >
        <Image
          src={photoId ? Env.IMAGE_SERVER_URL + photoId : DefaultAvatar}
          fallback={<Shimmer width={size} height={size} />}
        />
      </Container>
      <Modal
        title={intl.getTranslatedText(
          'components.userAvatar.userAvatarModal.title',
        )}
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        footer={null}
      >
        <ModalContent>
          <Image
            src={photoId ? Env.IMAGE_SERVER_URL + photoId : DefaultAvatar}
            fallback={<Shimmer width={64} height={64} />}
          />
          <div>
            <h6>{user?.name}</h6>
            <small>{user?.username}</small>
          </div>
          <Link to={`/chats/${user?._id}`}>
            <CustomAntButton
              type="primary"
              useCustomIconWithText
              icon={<FiMessageCircle size={16} />}
            >
              {intl.getTranslatedText(
                'components.userAvatar.sendMessageButton',
              )}
            </CustomAntButton>
          </Link>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserAvatar;
