import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import CustomAntButton from '@/components/CustomAntButton';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import { IListWithPagination } from '@/types/application';
import socket from '@/services/socket';
import { useCoachFriendRoom } from '../../CoachFriendRoomContext';
import { Container, NavigationButtonsContainer } from './styles';
import { IChallenge } from '../types';
import ChallengeCard from './ChallengeCard';
import { IChallengeIOEvent } from './types';

const ChallengeList: React.FC = () => {
  const isFirstRender = useRef(true);

  const intlContext = useIntl();
  const coachFriendRoomContext = useCoachFriendRoom();

  const [loadingChallenges, setLoadingChallenges] = useState(true);
  const [challenges, setChallenges] = useState<IChallenge[]>([]);
  const [challengesPagination, setChallengesPagination] = useState<
    Omit<IListWithPagination, 'docs'>
  >({
    limit: 50,
    page: 0,
    pages: 0,
    total: 0,
  });

  const getChallenges = useCallback(
    async (page = 1) => {
      setLoadingChallenges(true);
      try {
        const params = {
          limit: challengesPagination.limit,
          page,
        };

        const { data } = await api.get<IListWithPagination<IChallenge>>(
          `/api/coach-friend/${coachFriendRoomContext.room?._id}/challenger`,
          {
            params,
          },
        );

        setChallenges(oldState => {
          const oldChallenges = page > 1 ? oldState : [];
          return [...oldChallenges, ...data.docs];
        });
        setChallengesPagination(oldState => ({
          ...oldState,
          ...data,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intlContext.getTranslatedText(
            'common.errors.unexpectedError.title',
          ),
          description: intlContext.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingChallenges(false);
    },
    [challengesPagination.limit, coachFriendRoomContext.room, intlContext],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      getChallenges();
    }
    isFirstRender.current = false;

    const challengeEvent = `coach:${coachFriendRoomContext.room?._id}:challenger`;
    const challengeJoinEvent = `coach:${coachFriendRoomContext.room?._id}:challenger-join`;
    socket.on(challengeEvent, (data: IChallengeIOEvent) => {
      if (data.status === 'new' || data.status === 'delete') {
        getChallenges();
      }
    });
    socket.on(challengeJoinEvent, () => getChallenges());
    return () => {
      socket.off(challengeEvent);
      socket.off(challengeJoinEvent);
    };
  }, [coachFriendRoomContext.room?._id, getChallenges]);

  return (
    <Container>
      <NavigationButtonsContainer>
        <CustomAntButton
          type="primary"
          useSecondary
          useCustomIconWithText
          icon={<FiPlus size={16} />}
          onClick={() => coachFriendRoomContext.setCurrentChallengeRoute('new')}
        >
          Novo desafio
        </CustomAntButton>
        <CustomAntButton
          type="default"
          onClick={() =>
            coachFriendRoomContext.setCurrentChallengeRoute('myChallenges')
          }
        >
          Meus desafios
        </CustomAntButton>
      </NavigationButtonsContainer>
      <ul>
        {challenges.map(challenge => (
          <ChallengeCard key={challenge._id} challenge={challenge} />
        ))}
      </ul>
    </Container>
  );
};

export default ChallengeList;
