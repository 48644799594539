import { lighten } from 'polished';
import styled, { css } from 'styled-components';

interface ISelectPlayerOfThrowButton {
  selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  margin-top: 16px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: 48%;

    @media only screen and (max-width: 768px) {
      width: 100%;

      &:nth-child(2) {
        margin-top: 32px;
      }
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};
      margin-bottom: 8px;
    }

    > ul {
      margin-bottom: 0;
    }
  }
`;
export const TeamImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.colors.dark['dark+2']};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  img {
    height: 26px;
  }
`;
export const ThrowContainer = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;

  & + li {
    margin-top: 8px;
  }

  > small {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }
`;
export const SelectPlayerOfThrowButton = styled.button<ISelectPlayerOfThrowButton>`
  border: none;
  background: ${props => props.theme.colors.dark['dark+2']};
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s opacity;
  box-shadow: 2px 2px 16px #00000030;
  border: 2px solid ${props => props.theme.colors.dark['dark+2']};

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props.theme.colors.primary.primary0};
    `}

  &:hover {
    opacity: 0.75;
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    width: 100%;
    text-align: center;
  }

  /* > div {
    display: flex;
    width: 100%;
    align-items: center;

    p:nth-child(1) {
      color: ${props => props.theme.colors.light['light-2']};
      flex: 1;
      text-align: start;
    }

    p:nth-child(2) {
      color: ${props => props.theme.colors.primary['primary+1']};
      margin-left: 16px;
      flex-shrink: 0;
    }
  } */
`;
export const AddThrowContentModal = styled.div`
  width: 100%;

  /* > div:nth-child(1) {
    padding: 8px 0;
    border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};
    margin-bottom: 24px;

    p {
      strong {
        color: ${props => props.theme.colors.light['light-2']};
      }

      color: ${props => props.theme.colors.primary['primary+1']};
    }
  } */

  /* > div:nth-child(2), */
  /* > div:nth-child(3) { */
  > div {
    small {
      color: ${props => props.theme.colors.light['light-2']};
      margin-bottom: 8px;
    }
  }

  /* > div:nth-child(3) {
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  } */
`;
export const TeamPlayerSelector = styled.button`
  width: 100%;
  padding: 8px;
  background: transparent;
  border: 0;
  transition: 0.2s background;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => lighten(0.05, props.theme.colors.dark.dark0)};
  }

  button + & {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: inherit;
      color: inherit;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary.primary0};
  }
`;
