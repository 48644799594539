const ErrorsTexts = {
  'common.errors.unexpectedError.title': 'Unexpected error',
  'common.errors.unexpectedError.description': 'Refresh the page, try again!',
};

const MessagesTexts = {
  'common.messages.defaultLoading': 'Loading...',
  'common.messages.actionCannotBeUndone': 'This action cannot be undone',
  'common.messages.warning': 'Warning!',
  'common.messages.minCharToSearch':
    'Type above 3 characters to perform the search',
  'common.messages.changesWillBeLost': 'The changes will be lost',
};

const ButtonsTexts = {
  'common.buttons.cancel': 'Cancel',
  'common.buttons.no': 'No',
  'common.buttons.yes': 'Yes',
  'common.buttons.goBack': 'Back',
  'common.buttons.next': 'Next',
  'common.buttons.viewMore': 'View more',
  'common.buttons.exit': 'Exit',
  'common.buttons.moreDetails': 'Click to see more details',
  'common.buttons.lessDetails': 'Click to see less details',
  'common.buttons.archive': 'Archive',
  'common.buttons.unarchive': 'active',
  'common.buttons.editPhotoMaskLabel': 'edit',
};

const SelectLanguageTexts = {
  'common.selectLanguage.pt-BR': 'Brazilian Portuguese',
  'common.selectLanguage.en': 'English',
};

const FormatDatesTexts = {
  'common.formatDates.fullDate': "cccc, dd/MM/yyyy 'at' p",
  'common.formatDates.carriedOut': "'Carried out' dd/MM/yyyy 'at' pp",
  'common.formatDates.yesterday': 'yesterday',
};

const GamesTitlesTexts = {
  'common.gamesTitles.coachFriend': 'Family VS Friend',
  'common.gamesTitles.versus': 'Versus',
  'common.gamesTitles.versusVIP': 'Versus VIP',
  'common.gamesTitles.cardGames': 'Championship cards',
  'common.gamesTitles.athletesOfTheWeek': 'Hat-trick',
  'common.gamesTitles.pushGames': 'Push Games',
};

const MatchInfoTexts = {
  'common.matchInfo.date': 'Date and time',
  'common.matchInfo.place': 'Place',
};

const PlayersPositionsTexts = {
  'common.playersPositions.goalKeeper': 'Goalkeeper',
  'common.playersPositions.defense': 'Defense',
  'common.playersPositions.midField': 'Midfield',
  'common.playersPositions.attack': 'Attack',
};

const OthersTexts = {
  'common.scoredPointsDescription': 'points',
  'common.noPointsAbbrev': 'NP', // no points
  'common.position': '{position}°',
  'common.vsDescription': 'VS',
  'common.meIdentifier': 'You',
  'common.meAdminIdentifier': 'Admin',
  'common.requestedIdentifier': 'Requested',
  'common.countDown.days': 'days',
  'common.countDown.hours': 'hours',
  'common.countDown.minutes': 'minutes',
  'common.countDown.seconds': 'seconds',
  'common.selectDefaultText': 'Selecione',
  'common.live': 'Live',
  'common.uninformed': 'Uninformed',
  'common.games': 'games',
};

const commonTexts = {
  ...ErrorsTexts,
  ...MessagesTexts,
  ...ButtonsTexts,
  ...SelectLanguageTexts,
  ...FormatDatesTexts,
  ...OthersTexts,
  ...GamesTitlesTexts,
  ...MatchInfoTexts,
  ...PlayersPositionsTexts,
};

export type TCommonTexts = typeof commonTexts;
// export type TCommonTextsKeys = keyof TCommonTexts;

export default commonTexts;
