import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IStatusIndicator {
  warn?: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 752px;
  margin: 0 auto;
`;
export const SearchAndAddNewFriendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  > button {
    margin-left: 16px;
    flex-shrink: 0;
  }

  input.ant-input {
    border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  }
  button.ant-input-search-button {
    border-color: ${props => props.theme.colors.dark['dark+3']};
  }
`;
export const FriendContainer = styled.li`
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: center;

  & + li {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+3']};
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 16px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    margin-left: auto;
    display: flex;
    align-items: center;

    > small {
      color: ${props => props.theme.colors.light['light-2']};

      & + small {
        margin-left: 8px;
      }
    }

    button {
      margin-left: 8px;
    }
  }
`;
export const StatusIndicator = styled.small<IStatusIndicator>`
  padding: 2px 8px;
  border-radius: 4px;

  background: #e4ce00;
  color: ${props => props.theme.colors.dark.dark0} !important;

  /* ${props => props.warn && css``} */
`;
export const ContentAddFriendsModal = styled.div`
  > div {
    margin-top: 16px;

    > strong {
      color: ${props => props.theme.colors.light['light-2']};
    }

    ul {
      margin-top: 8px;
      display: flex;
      border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};
      flex-wrap: wrap;
    }
  }

  > ul {
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

export const AddedFriend = styled.button`
  border: none;
  width: 24.5%;
  margin-right: 0.5%;
  background: ${props => props.theme.colors.dark.dark0};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  padding: 8px;
  transition: 0.2s background;
  flex-shrink: 0;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  }

  div {
    position: absolute;
    right: 28%;
    width: 12px;
    height: 12px;
    background: ${props => props.theme.colors.dark['dark+4']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
      color: ${props => props.theme.colors.light.light0};
    }
  }

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 4px;
  }

  small {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.light.light0};
  }
`;

export const FindedOnSearchUser = styled.button`
  border: 0;

  width: 100%;
  background: ${props => props.theme.colors.dark.dark0};
  padding: 8px 0;
  display: flex;
  align-items: center;
  transition: 0.2s background;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: no-drop;
    `}

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
  }

  div {
    display: flex;
    flex-direction: column;

    small {
      text-align: start;

      strong {
        font-size: inherit;
        color: inherit;
      }
    }
  }

  > small {
    margin-left: auto;
  }

  /* For ViewMoreButton */
  > p {
    width: 100%;
    text-align: center;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
