import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 56px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }

  ul {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CardGameItem = styled(Link)`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  position: relative;

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    font-size: ${props => props.theme.typograph.sizes.p};
    margin-bottom: 24px;
  }
`;

export const ViewMoreButton = styled.button`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.dark.dark0};
  border: 1px solid ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;
