import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useChatP2P } from '../../../context/ChatP2PContext';
import { useIntl } from '../../../context/IntlContext';

import UserAvatar from '../../../components/UserAvatar';

import MessagesContainer from './MessagesContainer';

import { Container, UserInfo, LoadingAndNotFoundContainer } from './styles';

interface IRouteParams {
  user_id: string;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Chat: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();

  const intl = useIntl();
  const { setCurrentChatData, chatContainerProps, setChatContainerRef, chat } =
    useChatP2P();

  useEffect(() => {
    setCurrentChatData({
      _user: params.user_id,
    });

    return () => {
      setCurrentChatData(null);
    };
  }, [params.user_id, setCurrentChatData]);

  if (chatContainerProps.loadingMessages && !chat) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('pages.chats.chat.loadingChat')}</p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <UserInfo to="/chats">
        <FiChevronLeft size={24} />
        <UserAvatar photoId={chat?.friend?.photo} size={40} />
        <div>
          <p>{chat?.friend?.name}</p>
          <small>{chat?.friend?.username}</small>
        </div>
      </UserInfo>
      <MessagesContainer
        ref={instance => {
          if (instance) {
            setChatContainerRef(instance);
          }
        }}
        // messages={chatContainerProps.messages}
        messages={chat?.messages || []}
        loadingMessages={chatContainerProps.loadingMessages}
        handleSubmitNewMessage={chatContainerProps.handleSubmitNewMessage}
        hasMoreMessages={chatContainerProps.hasMoreMessages}
        handleGetMoreMessages={chatContainerProps.handleGetMoreMessages}
      />
    </Container>
  );
};

export default Chat;
