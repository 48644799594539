import React, { SetStateAction } from 'react';
import { FastField, FastFieldProps, FormikProps } from 'formik';
import { FiClock } from 'react-icons/fi';
import { BiWorld } from 'react-icons/bi';

import { INewRoomFormData } from '../index';
import { ButtonsContainer } from '../styles';

import { useIntl } from '../../../../context/IntlContext';

import Input from '../../../../components/Input';
import CustomAntButton from '../../../../components/CustomAntButton';

import {
  Container,
  RoomTypeSelector,
  RoomTypeSelectorSelectedIndicator,
} from './styles';

interface IRoomProps {
  setFocusedField: React.Dispatch<SetStateAction<null | string>>;
  formik: FormikProps<INewRoomFormData>;
  nextStep(): void;
}

const Room: React.FC<IRoomProps> = ({ formik, setFocusedField, nextStep }) => {
  function eneableNextStepButton({
    errors,
  }: FormikProps<INewRoomFormData>): boolean {
    if (!errors.roomType && !errors.name && !errors.price) {
      return true;
    }

    return false;
  }
  const intl = useIntl();

  return (
    <Container>
      <FastField name="roomType">
        {({ field }: FastFieldProps) => {
          return (
            <RoomTypeSelector
              $selected={field.value === 'private'}
              onClick={() => formik.setFieldValue('roomType', 'private')}
              $type="private"
              type="button"
            >
              <div>
                <FiClock size={22} />
                <h6>
                  {intl.getTranslatedText(
                    'pages.versusVIP.newRoom.form.roomType.privateRoomTitle',
                  )}
                </h6>
                <RoomTypeSelectorSelectedIndicator
                  $selected={field.value === 'private'}
                >
                  <div />
                </RoomTypeSelectorSelectedIndicator>
              </div>
              <p>
                {intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.roomType.privateRoomDescription',
                )}
              </p>
            </RoomTypeSelector>
          );
        }}
      </FastField>
      <FastField name="roomType">
        {({ field }: FastFieldProps) => {
          return (
            <RoomTypeSelector
              $selected={field.value === 'public'}
              onClick={() => formik.setFieldValue('roomType', 'public')}
              $type="public"
              type="button"
            >
              <div>
                <BiWorld size={22} />
                <h6>
                  {intl.getTranslatedText(
                    'pages.versusVIP.newRoom.form.roomType.publicRoomTitle',
                  )}
                </h6>
                <RoomTypeSelectorSelectedIndicator
                  $selected={field.value === 'public'}
                >
                  <div />
                </RoomTypeSelectorSelectedIndicator>
              </div>
              <p>
                {intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.roomType.publicRoomDescription',
                )}
              </p>
            </RoomTypeSelector>
          );
        }}
      </FastField>
      <div>
        <FastField name="name">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                label={intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.name.label',
                )}
                placeholder={intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.name.placeholder',
                )}
                id="NewRoomName"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                onFocus={() => setFocusedField('name')}
              />
            );
          }}
        </FastField>
        <FastField name="price">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                label={intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.price.label',
                )}
                placeholder={intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.price.placeholder',
                )}
                id="NewRoomPrice"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                onFocus={() => setFocusedField('price')}
                onChange={e => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  field.onChange(e);
                }}
              />
            );
          }}
        </FastField>
      </div>
      <ButtonsContainer>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          {intl.getTranslatedText('common.buttons.next')}
        </CustomAntButton>
      </ButtonsContainer>
    </Container>
  );
};

export default Room;
