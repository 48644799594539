import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { ITeam } from '@/pages/CoachFriend/Room';

import Input from '@/components/Input';
import TeamSelector from '../../TeamSelector';
import { useNewChallenge } from '../../../../NewChallengeContext';
import { Markers, FormFieldIdentifier } from '../../../../types';

import { FormFieldsContainer } from '../../styles';

const TeamPointsFormFields: React.FC = () => {
  const isFirstRender = useRef(true);
  const newChallengeContext = useNewChallenge();

  useEffect(() => {
    if (isFirstRender.current) {
      newChallengeContext.setFields([
        {
          id: FormFieldIdentifier.TEAM,
          marker: Markers.SQUARE_BRACKETS,
          value: null,
          viewValue: '',
        },
        {
          id: FormFieldIdentifier.MATCH_SCORE,
          marker: Markers.CURLY_BRACKETS,
          value: null,
          viewValue: '',
        },
      ]);
    }

    isFirstRender.current = false;
  }, [newChallengeContext]);

  const selectedTeam = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.TEAM,
    )?.value as ITeam;
  }, [newChallengeContext.fields]);

  const setSelectedTeam = useCallback(
    (team: ITeam) => {
      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.TEAM) {
            return { ...field, value: team, viewValue: team.name };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  const score = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.MATCH_SCORE,
    )?.value as number;
  }, [newChallengeContext.fields]);

  const setScore = useCallback(
    (amount: string) => {
      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.MATCH_SCORE) {
            return {
              ...field,
              value: amount || '',
              viewValue: amount,
            };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  return (
    <FormFieldsContainer>
      <TeamSelector
        onSelect={team => setSelectedTeam(team)}
        selectedTeamId={selectedTeam?._id}
      />
      <Input
        label="Quantidade de gols"
        placeholder="ex.: 3"
        id="amountOfGoalsInput"
        type="text"
        autoComplete="off"
        value={score}
        onChange={e => {
          const newValue = e.target.value;
          setScore(newValue.replace(/\D/g, ''));
        }}
      />
    </FormFieldsContainer>
  );
};

export default TeamPointsFormFields;
