import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { FiChevronLeft } from 'react-icons/fi';
import { useBullet } from '@/context/BulletContext';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import CustomAntButton from '../../../../components/CustomAntButton';
import { showToast } from '../../../../hooks/showToast';
import api from '../../../../services/api';
import { useIntl } from '../../../../context/IntlContext';

import AthleteCard from './AthleteCard';

import { ICardGameState } from '..';

import { Container, Header } from './styles';
import AppError from '../../../../errors/AppError';
import NotFound from '../../../../components/NotFound';
import { IBlockPageMessage } from '../../../../services/history';
import Loading from '../../../../components/Loading';

const { confirm } = Modal;

interface IRouteParams {
  card_game_id: string;
}

// interface IMyGame {
//   athletes: IMyGameAthlete[];
// }

const PlayCardGame: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const bullet = useBullet();

  const [cardGame, setCardGame] = useState<ICardGameState | null>(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingSendMyGame, setLoadingSendMyGame] = useState(false);

  const unblockPage = useMemo(() => {
    const message: IBlockPageMessage = {
      title: intl.getTranslatedText(
        'pages.athletesOfTheWeek.card.play.messages.unblockPageConfirm.title',
      ),
      description: intl.getTranslatedText('common.messages.changesWillBeLost'),
      okText: intl.getTranslatedText('common.buttons.exit'),
      cancelText: intl.getTranslatedText('common.buttons.cancel'),
    };

    return history.block(JSON.stringify(message));
  }, [history, intl]);

  function handleBeforeUnload(e: BeforeUnloadEvent): void {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  const getAthleteCardGame = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        doc: Omit<ICardGameState, 'amount' | 'cardCount'>;
        amount: number;
        cardCount: number;
      }>(`/api/athlete-card/${params.card_game_id}`);

      if (data.doc.cardType !== 'new') {
        history.push(`/athletes_of_the_week/${params.card_game_id}`);
        return;
      }

      const athletesWithAbbrevOnTeams = data.doc.athletes.map(athlete => ({
        ...athlete,
        _team: {
          ...athlete._team,
          abbrev: athlete._team.name.substring(0, 3).toUpperCase(),
        },
        athleteScore: 0,
      }));

      setCardGame({
        ...data.doc,
        athletes: athletesWithAbbrevOnTeams,
        amount: data.amount,
        cardCount: data.cardCount,
      });
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: 'Recarregue a página!',
      // });
    }
    setLoadingCard(false);
  }, [history, params.card_game_id]);

  useEffect(() => {
    getAthleteCardGame();
  }, [getAthleteCardGame, history, params]);

  const handleChangeQuantityOfPlayerGoals = useCallback(
    (_athleteId: string, score: number): void => {
      if (score < 0) {
        return;
      }

      let updatedCardForm = null;

      if (cardGame) {
        updatedCardForm = {
          ...cardGame,
          athletes: cardGame?.athletes.map(athlete => {
            if (athlete._athletes._id === _athleteId) {
              return {
                ...athlete,
                athleteScore: score,
              };
            }

            return athlete;
          }),
        };
      }

      setCardGame(updatedCardForm);
    },
    [cardGame],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const myAthletesScoreChoices = cardGame?.athletes.map(athlete => ({
        _team: athlete._team._id,
        _athlete: athlete._athletes._id,
        athleteScore: athlete.athleteScore,
      }));

      await new Promise((resolve, reject) => {
        confirm({
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.messages.submitNewGameConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCEL_SEND_GAME'));
          },
        });
      });

      if (cardGame?.price) {
        const hasAvailableBalance = bullet.checkIfBalanceIsAvailableToAction(
          cardGame.price,
        );
        if (!hasAvailableBalance) return;
      }

      const body = {
        _card: cardGame?._id,
        athletes: myAthletesScoreChoices,
      };
      setLoadingSendMyGame(true);
      await api.post('/api/played-athlete', body);
      setLoadingSendMyGame(false);

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.play.messages.submitNewGameSuccess.title',
        ),
      });
      history.push(`/athletes_of_the_week/${params.card_game_id}`);
    } catch (error) {
      if (error instanceof AppError && error.options?.type === 'validation') {
        showToast({
          type: 'warn',
          title: error.title,
          description: error.description,
        });
        return;
      }
      setLoadingSendMyGame(false);
    }
  }, [
    bullet,
    cardGame?._id,
    cardGame?.athletes,
    cardGame?.price,
    history,
    intl,
    params.card_game_id,
    unblockPage,
  ]);

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs />
        <Header>
          <h5>
            {' '}
            {intl.getTranslatedText('pages.athletesOfTheWeek.card.play.title')}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!cardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.card.play.messages.noCard',
          )}
        </h4>
        <Link to="/athletes_of_the_week">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.goToAthletesOfTheWeekLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText('pages.athletesOfTheWeek.card.play.title')} |{' '}
          {cardGame?.name}
        </h5>
        <Link to={`/athletes_of_the_week/${params.card_game_id}`}>
          <CustomAntButton type="text" danger>
            {intl.getTranslatedText('common.buttons.cancel')}
          </CustomAntButton>
        </Link>
      </Header>
      <ul>
        {cardGame?.athletes.map(athlete => (
          <AthleteCard
            key={athlete._athletes._id}
            athlete={athlete}
            handleChangeQuantityOfPlayerGoals={
              handleChangeQuantityOfPlayerGoals
            }
          />
        ))}
      </ul>
      <CustomAntButton
        onClick={handleSubmit}
        disabled={loadingSendMyGame}
        type="primary"
      >
        {intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton',
        )}
      </CustomAntButton>
    </Container>
  );
};

export default PlayCardGame;
