const HomeTexts = {
  /* ------------ Rankings ------------ */
  'pages.home.rankingsLabel': "Ranking's",
  'pages.home.globalRankingLabel': 'Global',

  /* ------------ Buttons ------------ */
  'pages.home.rankingBannerButtonDescription': 'Clique para ver mais detalhes',
  'pages.home.newRoomButton': 'Nova sala',
  'pages.home.publicRoomsButton': 'Salas públicas',
  'pages.home.myRoomsButton': 'Minhas salas',

  /* ------------ Messages ------------ */
  'pages.home.getHomeDataError.title':
    'Ocorreu um erro ao carregar os dados da tela inicial',
  'pages.home.noCoachFriendRooms':
    'Você não está participando de nenhuma <span>sala</span> do Família VS Amigos',
  'pages.home.noVersusRooms':
    'Você não está participando de nenhuma <span>sala</span> do Versus',
  'pages.home.noVersusVIPRooms':
    'Você não está participando de nenhuma <span>sala</span> do Versus VIP',
};

export default {
  ...HomeTexts,
};
