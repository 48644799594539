import React, { useCallback, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import ImageViewer from 'react-viewer';

import Env from '../../../../../config/Environment';
import DefaultAvatar from '../../../../../assets/DefaultAvatar.svg';
import CustomAntButton from '../../../../../components/CustomAntButton';
import { useIntl } from '../../../../../context/IntlContext';

import { IAthlete } from '../..';

import { Container, QuantityOfPlayerGoalsSelectorContainer } from './styles';

interface IAthleteCard {
  athlete: IAthlete;
  handleChangeQuantityOfPlayerGoals(_athleteId: string, score: number): void;
}

const AthleteCard: React.FC<IAthleteCard> = ({
  athlete,
  handleChangeQuantityOfPlayerGoals,
}) => {
  const intl = useIntl();

  const [viewerIsVisible, setViewerIsVisible] = useState(false);

  const getPlayerPositionLabel = useCallback(
    (playerPosition: string): string => {
      switch (playerPosition) {
        case 'G':
          return intl.getTranslatedText('common.playersPositions.goalKeeper');
        case 'D':
          return intl.getTranslatedText('common.playersPositions.defense');
        case 'M':
          return intl.getTranslatedText('common.playersPositions.midField');
        case 'A':
          return intl.getTranslatedText('common.playersPositions.attack');
        default:
          return playerPosition;
      }
    },
    [intl],
  );

  return (
    <>
      <Container key={athlete?._athletes?._id}>
        <button
          type="button"
          onClick={() => {
            if (athlete?._athletes?.photo) {
              setViewerIsVisible(true);
            }
          }}
          disabled={!athlete?._athletes?.photo}
        >
          <img
            src={
              athlete?._athletes?.photo
                ? Env.IMAGE_SERVER_URL + athlete._athletes.photo
                : DefaultAvatar
            }
            alt={athlete?._athletes?.name || ''}
          />
        </button>
        <div>
          <div>
            <strong>{athlete?._athletes?.name}</strong>
            {athlete?._athletes?.number && athlete?._athletes?.position && (
              <small>
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.card.play.athleteCard.athleteNumber',
                  {
                    playerNumber: athlete?._athletes?.number,
                    playerPosition: getPlayerPositionLabel(
                      athlete?._athletes?.position,
                    ),
                  },
                )}
              </small>
            )}
            <div>
              <img
                src={athlete?._team?.image}
                alt={athlete?._team?.name || ''}
              />
              <p>{athlete?._team?.name}</p>
            </div>
          </div>
          <QuantityOfPlayerGoalsSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfPlayerGoals(
                  athlete?._athletes?._id,
                  athlete.athleteScore - 1,
                );
              }}
              disabled={athlete.athleteScore === 0}
            />
            <h6>{athlete.athleteScore}</h6>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfPlayerGoals(
                  athlete?._athletes?._id,
                  athlete.athleteScore + 1,
                );
              }}
            />
          </QuantityOfPlayerGoalsSelectorContainer>
        </div>
      </Container>
      <ImageViewer
        visible={viewerIsVisible}
        onClose={() => setViewerIsVisible(false)}
        onMaskClick={() => setViewerIsVisible(false)}
        noFooter
        drag={false}
        images={[
          {
            src: athlete?._athletes?.photo
              ? Env.IMAGE_SERVER_URL + athlete._athletes.photo
              : DefaultAvatar,
            alt: athlete?._athletes?.name,
          },
        ]}
      />
    </>
  );
};

export default AthleteCard;
