import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Dropdown, Menu } from 'antd';
import { IoEllipsisVertical } from 'react-icons/io5';
import { FiTrash2, FiCheckCircle } from 'react-icons/fi';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';

import {
  Container,
  Header,
  NotificationItem,
  LoadMoreButton,
  LoadingAndNotFoundContainer,
} from './styles';

import CustomAntButton from '../../CustomAntButton';
import api from '../../../services/api';
import socket from '../../../services/socket';
import { showToast } from '../../../hooks/showToast';
import { useIntl } from '../../../context/IntlContext';

interface INotificationUser {
  _id: string;
  username: string;
  name: string;
  email: string;
  photo: {
    _id: string;
    originalname: string;
    mimetype: string;
    filename: string;
  };
}

interface INotification {
  redirect: {
    uri: string;
    origem: string;
  };
  title: string;
  text: string;
  isRead: boolean;
  status: boolean;
  _id: string;
  _from?: INotificationUser;
  _to: INotificationUser;
  createdAt: string;
}

interface INotificationsProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setHasUnreadNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { formatDistance, parseISO } = dateFns;
// const { confirm } = Modal;

const Notifications: React.FC<INotificationsProps> = ({
  opened,
  setOpened,
  setHasUnreadNotifications,
}) => {
  const intl = useIntl();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationsPagination, setNotificationsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const getNotifications = useCallback(
    async (page = 1): Promise<void> => {
      if (page === 1) {
        setNotificationsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
      }

      setLoadingNotifications(true);
      try {
        const { data } = await api.get<{
          docs: INotification[];
          page: number;
          pages: number;
        }>('/api/notification', {
          params: {
            limit: notificationsPagination.limit,
            page,
          },
        });

        if (page === 1) {
          setNotifications(data.docs);
        } else {
          setNotifications(oldState => [...oldState, ...data.docs]);
        }
        setNotificationsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'components.notifications.messages.getNotificationsError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingNotifications(false);
    },
    [intl, notificationsPagination.limit],
  );

  const checkIfHasUnreadNotifications = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        docs: INotification[];
        page: number;
        pages: number;
      }>('/api/notification', {
        params: {
          limit: 1,
          isRead: true,
        },
      });

      if (data.docs.length > 0) {
        setHasUnreadNotifications(true);
      } else {
        setHasUnreadNotifications(false);
      }
    } catch (error) {
      // console.log('IS NOT POSSIBLE GET UNREAD NOTIFICATIONS NUMBER');
    }
    setLoadingNotifications(false);
  }, [setHasUnreadNotifications]);

  useEffect(() => {
    getNotifications();
    checkIfHasUnreadNotifications();
  }, [checkIfHasUnreadNotifications, getNotifications]);

  useEffect(() => {
    socket.on(`notification`, () => {
      getNotifications();
      setHasUnreadNotifications(true);
      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'components.notifications.messages.newNotification.title',
        ),
      });
    });

    return () => {
      socket.off(`notification`);
    };
  }, [getNotifications, intl, setHasUnreadNotifications]);

  const handleDeleteNotification = useCallback(
    async (notificationId: string) => {
      try {
        // await new Promise(resolve => {
        //   confirm({
        //     title: 'Atenção',
        //     content: 'Deseja realmente excluir essa notificação?',
        //     okText: 'Excluir',
        //     cancelText: 'Cancelar',
        //     okButtonProps: {
        //       danger: true,
        //     },
        //     onOk() {
        //       resolve(true);
        //     },
        //   });
        // });

        await api.delete(`/api/notification/${notificationId}`);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'components.notifications.messages.submitDeleteNotificationSuccess.title',
          ),
        });

        setNotifications(oldNotifications => {
          return oldNotifications.filter(
            notification => notification._id !== notificationId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const handleMarkWithReadNotification = useCallback(
    async (notificationId?: string) => {
      try {
        setOpened(false);
        const body = {
          notification: '',
        };

        if (notificationId) {
          body.notification = notificationId;
        }

        await api.put(`/api/notification`, body);

        setNotifications(oldNotifications => {
          return oldNotifications.map(notification => {
            if (notificationId) {
              if (notification._id === notificationId) {
                return {
                  ...notification,
                  isRead: true,
                };
              }

              return notification;
            }
            return {
              ...notification,
              isRead: true,
            };
          });
        });
        if (notificationId) {
          checkIfHasUnreadNotifications();
        } else {
          setHasUnreadNotifications(false);
        }
      } catch (error) {
        const titleToast = notificationId
          ? intl.getTranslatedText(
              'components.notifications.messages.submitMarkWithReadNotificationError.title',
            )
          : intl.getTranslatedText(
              'components.notifications.messages.submitMarkWithReadNotificationsError.title',
            );
        const descriptionToast = notificationId
          ? intl.getTranslatedText(
              'components.notifications.messages.submitMarkWithReadNotificationError.description',
            )
          : intl.getTranslatedText(
              'components.notifications.messages.submitMarkWithReadNotificationsError.description',
            );
        showToast({
          type: 'error',
          title: titleToast,
          description: descriptionToast,
        });
      }
    },
    [checkIfHasUnreadNotifications, intl, setHasUnreadNotifications, setOpened],
  );

  if (opened) {
    if (loadingNotifications && notificationsPagination.totalPages === 0) {
      return (
        <Container>
          <Header>
            <h5>{intl.getTranslatedText('components.notifications.title')}</h5>
          </Header>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            </div>
          </LoadingAndNotFoundContainer>
        </Container>
      );
    }

    if (notifications.length > 0) {
      return (
        <Container>
          <Header>
            <h5>{intl.getTranslatedText('components.notifications.title')}</h5>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      handleMarkWithReadNotification();
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <>
                        <FiCheckCircle style={{ marginRight: 8 }} size={19} />
                        {intl.getTranslatedText(
                          'components.notifications.markAllAsReadButton',
                        )}
                      </>
                    </div>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              arrow
            >
              <CustomAntButton
                icon={<IoEllipsisVertical size={20} />}
                useCustomIcon
                type="text"
              />
            </Dropdown>
          </Header>
          {notifications.map(notification => (
            <NotificationItem
              key={notification._id}
              to={notification.redirect.uri}
              onClick={() => handleMarkWithReadNotification(notification._id)}
            >
              <div>
                <div>
                  <strong>{notification.title}</strong>
                  <small>{notification.text}</small>
                </div>
                <CustomAntButton
                  type="text"
                  htmlType="button"
                  icon={<FiTrash2 size={20} />}
                  useCustomIcon
                  danger
                  onClick={e => {
                    e.preventDefault();
                    handleDeleteNotification(notification._id);
                  }}
                />
              </div>
              <div>
                <small>
                  {notification._from && `${notification._from?.name}, `}
                  {formatDistance(
                    parseISO(notification.createdAt),
                    new Date(),
                    {
                      locale: intl.getDatefnsLocale(),
                      addSuffix: true,
                    },
                  )}
                </small>
                {!notification.isRead && <div />}
              </div>
            </NotificationItem>
          ))}
          {notificationsPagination.currentPage <
            notificationsPagination.totalPages && (
            <LoadMoreButton
              onClick={() => {
                if (loadingNotifications) return;
                getNotifications(notificationsPagination.currentPage + 1);
              }}
              disabled={loadingNotifications}
            >
              <p>
                {!loadingNotifications
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </p>
            </LoadMoreButton>
          )}
        </Container>
      );
    }

    return (
      <Container>
        <Header>
          <h5>{intl.getTranslatedText('components.notifications.title')}</h5>
        </Header>
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'components.notifications.messages.noNotifications',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return <div />;
};

export default Notifications;
