import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  ul {
    margin: 24px 0 32px;
    display: flex;
    flex-wrap: wrap;
  }

  > button {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: block;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export const CardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;

  > div:nth-child(1) {
    flex: 1;

    > div:nth-child(1) {
      width: 100%;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 8px;
      }

      div {
        display: flex;
        flex-direction: column;

        small {
          color: ${props => props.theme.colors.light['light-2']};
        }
      }
    }

    > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      small {
        color: ${props => props.theme.colors.light['light-2']};
      }
    }
  }

  > div:nth-child(2) {
    margin-left: 16px;

    p {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
