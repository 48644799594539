import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Spin, AutoComplete } from 'antd';
import { FaCrown } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import api from '../../../services/api';
import UserAvatar from '../../../components/UserAvatar';

import { useAuth } from '../../../context/AuthContext';
import { useIntl } from '../../../context/IntlContext';
import { showToast } from '../../../hooks/showToast';

import {
  Container,
  Content,
  Top3Item,
  RankingPlayerItem,
  SearchPlayerItem,
  ViewMoreButton,
  LoadingAndNotFoundContainer,
  UserIsMeIndicator,
  SearchAutocompleteContentContainer,
  SelectedOnSearchUser,
} from './styles';

export interface IRankingPosition {
  _id: string;
  totalScore: number;
  friendlyId: string;
  createdAt: string;
  _user: {
    _id: string;
    isMe: boolean;
    username: string;
    name: string;
    photo?: {
      _id: string;
      filename: string;
    };
  };
}

export interface IRankingSearchUser {
  _id: string;
  isMe: boolean;
  username: string;
  name: string;
  photo?: {
    _id: string;
    filename: string;
  };
  totalScore?: number;
  // games: Omit<IRankingPosition, '_user'>[];
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const AthletesOfTheWeekRanking: React.FC = () => {
  const intl = useIntl();
  const { user: me } = useAuth();

  const [ranking, setRanking] = useState<IRankingPosition[]>([]);
  const [loadingRanking, setLoadingRanking] = useState(true);
  const [paginationRanking, setPaginationRanking] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [findedOnSearchUsers, setFindedOnSearchUsers] = useState<
    IRankingSearchUser[]
  >([]);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [paginationSearchUser, setPaginationSearchUser] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  // const [showingUserFinded, setShowingUserFinded] = useState(false);
  const [userSelectedOnSearch, setUserSelectedOnSearch] =
    useState<IRankingSearchUser | null>(null);
  const [loadingUserSelectedOnSearch, setLoadingUserSelectedOnSearch] =
    useState(false);

  const getRanking = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingRanking(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking/athlete', {
          params: {
            limit: 10,
            page,
          },
        });

        if (page === 1) {
          setRanking([
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        } else {
          setRanking(oldRanking => [
            ...oldRanking,
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        }

        setPaginationRanking({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.ranking.messages.getRankingError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingRanking(false);
    },
    [intl, me?._id],
  );

  const searchUserOnRanking = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingSearchUser[];
          page: number;
          pages: number;
        }>('/api/user', {
          params: {
            search,
            page,
            limit: 6,
          },
        });

        setFindedOnSearchUsers(
          data.docs.map(user => {
            return {
              ...user,
              isMe: me?._id === user?._id,
              games: [],
            };
          }),
        );

        setPaginationSearchUser({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingSearch(false);
    },
    [intl, me?._id],
  );

  const handleGetUserSelected = useCallback(
    async (value: string, page = 1): Promise<void> => {
      if (page === 1) {
        setUserSelectedOnSearch(null);
        setSearchUserValue(value);
      }

      const user = findedOnSearchUsers.find(
        findedUser => findedUser.username === value,
      );

      if (!user) return;

      setLoadingUserSelectedOnSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking/athlete', {
          params: {
            // limit: 6,
            user: user._id,
            page,
          },
        });

        if (data.docs.length > 0) {
          user.totalScore = data.docs[0].totalScore;
        }

        setUserSelectedOnSearch(user);
        // setPaginationGamesUserSelectedOnSearch({
        //   currentPage: data.page,
        //   totalPages: data.pages,
        // });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingUserSelectedOnSearch(false);
    },
    [findedOnSearchUsers, intl],
  );

  useEffect(() => {
    getRanking();
  }, [getRanking]);

  const usersSearchDebounced = useMemo(() => {
    return _.debounce(searchUserOnRanking, 500);
  }, [searchUserOnRanking]);

  const handleSearchUser = useCallback(
    (value: string) => {
      usersSearchDebounced.cancel();

      // if (!searchUserValue) {
      //   // setShowingUserFinded(true);
      // } else if (!value.length) {
      // }
      if (userSelectedOnSearch) {
        setUserSelectedOnSearch(null);
      }
      setSearchUserValue(value);

      if (value.length > 3) {
        setLoadingSearch(true);
        usersSearchDebounced(value);
      } else {
        setLoadingSearch(false);
        setFindedOnSearchUsers([]);
      }
    },
    [userSelectedOnSearch, usersSearchDebounced],
  );

  const restRanking = useMemo(() => {
    return ranking.slice(3);
  }, [ranking]);

  const isOnLastPageOfRanking = useMemo(() => {
    return paginationRanking.currentPage === paginationRanking.totalPages;
  }, [paginationRanking.currentPage, paginationRanking.totalPages]);
  const isOnLastPageOfSearchUsers = useMemo(() => {
    return paginationSearchUser.currentPage === paginationSearchUser.totalPages;
  }, [paginationSearchUser.currentPage, paginationSearchUser.totalPages]);

  const searchResultsViewer = useMemo(() => {
    if (searchUserValue.length <= 3) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <p>{intl.getTranslatedText('common.messages.minCharToSearch')}</p>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (loadingSearch) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            </div>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (findedOnSearchUsers.length > 0) {
      return (
        <>
          {findedOnSearchUsers?.map(user => (
            <AutoComplete.Option value={user.username} key={user._id}>
              <SearchPlayerItem>
                <UserAvatar photoId={user?.photo?._id} size={40} />
                <div>
                  <p>{user?.name || '---'}</p>
                  <small>{user?.username || '---'}</small>
                </div>
                {user.isMe && (
                  <UserIsMeIndicator>
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </SearchPlayerItem>
            </AutoComplete.Option>
          ))}
          {!isOnLastPageOfSearchUsers && (
            <AutoComplete.Option value="" disabled>
              <ViewMoreButton
                disabled={loadingSearch}
                onClick={() => {
                  searchUserOnRanking(
                    searchUserValue,
                    paginationSearchUser.currentPage + 1,
                  );
                }}
              >
                {!loadingSearch ? (
                  <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
                ) : (
                  <p>
                    {intl.getTranslatedText('common.messages.defaultLoading')}
                  </p>
                )}
              </ViewMoreButton>
            </AutoComplete.Option>
          )}
        </>
      );
    }
    return (
      <AutoComplete.Option value="" disabled>
        <SearchAutocompleteContentContainer>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.ranking.messages.userNotFound',
            )}
          </h6>
        </SearchAutocompleteContentContainer>
      </AutoComplete.Option>
    );
  }, [
    findedOnSearchUsers,
    intl,
    isOnLastPageOfSearchUsers,
    loadingSearch,
    paginationSearchUser.currentPage,
    searchUserOnRanking,
    searchUserValue,
  ]);

  const contentViewer = useMemo(() => {
    if (userSelectedOnSearch) {
      return (
        <SelectedOnSearchUser>
          <div>
            <UserAvatar
              photoId={userSelectedOnSearch.photo?.filename}
              size={40}
              user={{
                _id: userSelectedOnSearch._id,
                name: userSelectedOnSearch.name,
                username: userSelectedOnSearch.username,
              }}
            />
            <div>
              <p>{userSelectedOnSearch.name}</p>
              <small>{userSelectedOnSearch.username}</small>
              <small>
                {userSelectedOnSearch.totalScore
                  ? userSelectedOnSearch.totalScore
                  : '--'}{' '}
                pontos
              </small>
            </div>
            {userSelectedOnSearch.isMe && (
              <UserIsMeIndicator>
                {intl.getTranslatedText('common.meIdentifier')}
              </UserIsMeIndicator>
            )}
          </div>
        </SelectedOnSearchUser>
      );
    }

    if (ranking.length === 0) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.ranking.messages.noRanking',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    return (
      <>
        <section>
          <Top3Item>
            <p>2</p>
            <UserAvatar
              photoId={ranking[1]?._user?.photo?._id}
              size={85}
              user={{
                _id: ranking[1]?._user?._id,
                name: ranking[1]?._user?.name,
                username: ranking[1]?._user?.username,
              }}
            />
            <p>{ranking[1]?._user?.name || '-----'}</p>
            <small>{ranking[1]?._user?.username || '---'}</small>
            <small>
              {ranking[1]?.totalScore
                ? `${ranking[1]?.totalScore} ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </Top3Item>
          <Top3Item>
            <p>1</p>
            <FaCrown size={24} />
            <UserAvatar
              photoId={ranking[0]?._user?.photo?._id}
              size={100}
              user={{
                _id: ranking[0]?._user?._id,
                name: ranking[0]?._user?.name,
                username: ranking[0]?._user?.username,
              }}
            />
            <p>{ranking[0]?._user?.name || '-----'}</p>
            <small>{ranking[0]?._user?.username || '---'}</small>
            <small>
              {ranking[0]?.totalScore
                ? `${ranking[0]?.totalScore} ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </Top3Item>
          <Top3Item>
            <p>3</p>
            <UserAvatar
              photoId={ranking[2]?._user?.photo?._id}
              size={85}
              user={{
                _id: ranking[2]?._user?._id,
                name: ranking[2]?._user?.name,
                username: ranking[2]?._user?.username,
              }}
            />
            <p>{ranking[2]?._user?.name || '-----'}</p>
            <small>{ranking[2]?._user?.username || '---'}</small>
            <small>
              {ranking[2]?.totalScore
                ? `${ranking[2]?.totalScore} ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </Top3Item>
        </section>
        <ul>
          {restRanking.map((rankPosition, index) => (
            <RankingPlayerItem>
              <p>{index + 4}</p>
              <UserAvatar
                photoId={rankPosition?._user?.photo?._id}
                size={40}
                user={{
                  _id: rankPosition?._user?._id,
                  name: rankPosition?._user?.name,
                  username: rankPosition?._user?.username,
                }}
              />
              <div>
                <p>{rankPosition?._user?.name || '---'}</p>
                <small>{rankPosition?._user?.username || '---'}</small>
              </div>
              <div>
                <small>
                  {rankPosition.totalScore}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </small>
                {rankPosition?._user?.isMe && (
                  <UserIsMeIndicator>
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </div>
            </RankingPlayerItem>
          ))}
          {!isOnLastPageOfRanking && (
            <ViewMoreButton
              disabled={loadingRanking}
              onClick={() => getRanking(paginationRanking.currentPage + 1)}
            >
              {!loadingRanking ? (
                <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
              ) : (
                <p>
                  {intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              )}
            </ViewMoreButton>
          )}
        </ul>
      </>
    );
  }, [
    getRanking,
    intl,
    isOnLastPageOfRanking,
    loadingRanking,
    paginationRanking.currentPage,
    ranking,
    restRanking,
    userSelectedOnSearch,
  ]);

  if (loadingRanking && ranking.length === 0) {
    return (
      <Container>
        <h4>
          Ranking |{' '}
          {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
        </h4>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText('pages.ranking.messages.loadingRanking')}
            </p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  if (loadingUserSelectedOnSearch && !userSelectedOnSearch) {
    return (
      <Container>
        <h4>
          Ranking |{' '}
          {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
        </h4>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.ranking.messages.loadingUserGames',
              )}
            </p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <h4>
        Ranking |{' '}
        {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
      </h4>
      <Content>
        <AutoComplete
          placeholder={intl.getTranslatedText(
            'pages.ranking.searchUserInput.placeholder',
          )}
          onSearch={handleSearchUser}
          onSelect={(value: string) => handleGetUserSelected(value)}
          value={searchUserValue}
          notFoundContent="User not found"
        >
          {searchResultsViewer}
        </AutoComplete>
      </Content>
      {contentViewer}
    </Container>
  );
};

export default AthletesOfTheWeekRanking;
