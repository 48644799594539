import styled, { css } from 'styled-components';

import PrivateRoomBackground from '../../../../assets/private-room-background.png';
import PublicRoomBackground from '../../../../assets/public-room-background.png';

interface IRoomTypeSelector {
  $type: 'private' | 'public';
  $selected?: boolean;
}

interface IRoomTypeSelectorSelectedIndicator {
  $selected?: boolean;
}

export const Container = styled.div`
  > div {
    margin-top: 16px;
  }
`;
export const RoomTypeSelector = styled.button<IRoomTypeSelector>`
  border: 0;

  width: 100%;
  height: 104px;
  margin: 0 auto;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s box-shadow;

  ${props =>
    props.$selected &&
    css`
      box-shadow: 0px 0px 6px rgba(0, 109, 24, 0.25);
    `}

  ${props =>
    props.$type === 'private' &&
    css`
      background: url(${PrivateRoomBackground}) no-repeat center;
    `}
  ${props =>
    props.$type === 'public' &&
    css`
      background: url(${PublicRoomBackground}) no-repeat center;
    `}

  background-size: cover;

  > div {
    width: 100%;
    color: ${props => props.theme.colors.light.light0};
    display: flex;
    align-items: center;

    h6 {
      margin-left: 8px;
      line-height: 100%;
    }

    > div {
      margin-left: auto;
    }
  }
`;
export const RoomTypeSelectorSelectedIndicator = styled.div<IRoomTypeSelectorSelectedIndicator>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.light['light-2']};
  background: ${props => props.theme.colors.dark['dark+3']};

  div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+3']};
  }

  ${props =>
    props.$selected &&
    css`
      border: 2px solid ${props.theme.colors.primary['primary+1']};

      div {
        background: ${props.theme.colors.primary['primary+1']};
      }
    `}
`;
