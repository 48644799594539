interface IEnvironmentVars {
  BASE_URL: string;
  API_URL: string;
  SOCKET_URL: string;
  IMAGE_SERVER_URL: string;
}

const environment: IEnvironmentVars = {} as IEnvironmentVars;

if (process.env.NODE_ENV === 'development') {
  environment.BASE_URL = 'http://localhost:3000';
  environment.API_URL = 'http://localhost:8000';
  environment.SOCKET_URL = 'http://localhost:8001';
} else if (process.env.NODE_ENV === 'production') {
  environment.BASE_URL = 'https://app-l.titan369.com';
  environment.API_URL = 'https://app-l.titan369.com/v1';
  environment.SOCKET_URL = 'https://ws-l.titan369.com';
}

environment.IMAGE_SERVER_URL = `${environment.API_URL}/api/file/`;

export default environment;
