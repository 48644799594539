import { rgba } from 'polished';
import styled, { css } from 'styled-components';

interface IPlayerGame {
  meGame?: boolean;
  isWinner?: boolean;
}

export const Container = styled.div`
  width: 100%;
`;

export const ContentBottom = styled.div`
  padding: 16px;
  max-width: 752px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > small {
    margin-bottom: 16px;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > h6 {
    text-align: center;
    margin-bottom: 16px;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const WinnerContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  background: ${props => props.theme.colors.dark['dark+2']};
  margin-bottom: 16px;

  > div:nth-child(2) {
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid ${props => props.theme.colors.dark['dark+3']};

    > div {
      display: flex;

      /* & + div {
        margin-top: 8px;
      } */

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(2) {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
  }
`;

export const WinnerPlayer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 104px;
  margin: 0 auto;

  &:nth-child(2) {
    margin: 0 16px;
  }

  p:first-child {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  svg {
    color: #f8c700;
    margin-bottom: 8px;
  }

  img {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid ${props => props.theme.colors.primary.primary0};
    margin-bottom: 8px;
  }

  p {
    text-align: center;
  }

  small {
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  /* small:last-child {
    color: ${props => props.theme.colors.primary.primary0};
    margin-top: 8px;
  } */

  &:nth-child(2) {
    img {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 20px
        ${props => rgba(props.theme.colors.primary.primary0, 0.5)};
    }

    transform: translateY(-60px);
  }
`;

export const DetailsContainer = styled.div`
  padding-bottom: 16px;

  > div {
    width: 100%;

    & + div {
      margin-top: 16px;
    }

    strong {
      color: ${props => props.theme.colors.primary.primary0};
      margin-bottom: 4px;
    }
  }
`;

export const PlayerGame = styled.div<IPlayerGame>`
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 8px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 10;
    position: relative;
  }

  > div {
    z-index: 5;
    width: calc(100% - 16px);
    padding: 16px;
    background: ${props => props.theme.colors.dark['dark+2']};
    border-radius: 8px;
    position: relative;

    transform: translate3d(16px, -16px, 0);

    svg {
      color: #e4ce00;
      position: absolute;
      top: -8px;
      right: -8px;
    }

    > div {
      display: flex;

      & + div {
        margin-top: 8px;
      }

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(2) {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
  }

  ${props =>
    props.meGame &&
    css`
      margin-left: auto;
      align-items: flex-end;

      > div {
        width: calc(100% - 16px);
        transform: translate3d(-16px, -16px, 0);

        padding: 16px;
        background: ${props.theme.colors.primary['primary-1']};
        border-radius: 8px;

        svg {
          color: #e4ce00;
          left: -8px;
          right: 0;
        }
      }
    `}
`;

export const AdversaryInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  div {
    display: flex;
    flex-direction: column;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  button {
    margin-left: auto;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
