import React from 'react';
import { Steps as StepsAntd } from 'antd';
import { StepProps } from 'antd/lib/steps';

import {
  Container,
  LargeDevicesContainer,
  SmallDevicesContainer,
} from './styles';

interface IStepsProps {
  current: number;
  stepsList: StepProps[];
}

const Steps: React.FC<IStepsProps> = ({ current, stepsList }) => {
  return (
    <Container>
      <LargeDevicesContainer>
        <StepsAntd labelPlacement="vertical" size="small" current={current}>
          {stepsList.map(({ title, ...rest }) => (
            <StepsAntd.Step key={`step_${title}`} title={title} {...rest} />
          ))}
        </StepsAntd>
      </LargeDevicesContainer>
      <SmallDevicesContainer>
        <StepsAntd direction="vertical" size="small" current={current}>
          {stepsList.map(({ title, ...rest }) => (
            <StepsAntd.Step key={`step_${title}`} title={title} {...rest} />
          ))}
        </StepsAntd>
      </SmallDevicesContainer>
    </Container>
  );
};

export default Steps;
