import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { FiTrash2 } from 'react-icons/fi';
import { useIntl } from '../../../../context/IntlContext';

import Env from '../../../../config/Environment';
import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { IVersusRoom, IMyDataInTheRoom } from '..';

import {
  Container,
  FriendContainer,
  StatusIndicator,
  AdversaryInfoContainer,
} from './styles';
import { showToast } from '../../../../hooks/showToast';
import api from '../../../../services/api';
import CustomAntButton from '../../../../components/CustomAntButton';

interface IRouteParams {
  id: string;
}

// interface IUser {
//   _id: string;
//   username: string;
//   name: string;
//   photo?: {
//     _id: string;
//     filename: string;
//   };
// }

interface ILobbyProps {
  room: IVersusRoom;
  myDataInTheRoom: IMyDataInTheRoom;
  // getPlayersOfRoom(): void;
  handleRemoveOpponent(userId: string): void;
}
const { confirm } = Modal;

const Lobby: React.FC<ILobbyProps> = ({
  room,
  myDataInTheRoom,
  handleRemoveOpponent,
}) => {
  const { params } = useRouteMatch<IRouteParams>();

  const intl = useIntl();

  const [loadingStartGame, setLoadingStartGame] = useState(false);

  const handleStartGame = useCallback(async () => {
    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'pages.versus.room.lobby.messages.startGameConfirm.title',
        ),
        icon: <ExclamationCircleOutlined />,
        content: intl.getTranslatedText('common.messages.actionCannotBeUndone'),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.versus.room.lobby.startGameConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
      });
    });

    try {
      setLoadingStartGame(true);
      await api.put(`/api/game-vs/${params.id}/flip-coin`);
      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.versus.room.lobby.messages.startGameSuccess.title',
        ),
      });
    } catch (error) {
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error?.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
    setLoadingStartGame(false);
  }, [intl, params.id]);

  if (
    myDataInTheRoom._me?.roles?.includes('owner') &&
    !room.adversary?.players.status
  ) {
    return (
      <Container>
        <small>
          {intl.getTranslatedText(
            'pages.versus.room.lobby.messages.waitUserAcceptInvite',
          )}
        </small>
        <FriendContainer key={room.adversary?.players._id}>
          <img
            src={
              room.adversary?._player.photo
                ? Env.IMAGE_SERVER_URL + room.adversary?._player.photo
                : defaultAvatar
            }
            alt={room.adversary?._player.name}
          />
          <p>{room.adversary?._player.name}</p>
          <div>
            <StatusIndicator>
              {intl.getTranslatedText('common.requestedIdentifier')}
            </StatusIndicator>
          </div>
        </FriendContainer>
      </Container>
    );
  }

  return (
    <Container>
      <div>
        <h6>
          <span>
            {intl.getTranslatedText('pages.versus.room.lobby.amount')}
          </span>
        </h6>
        <h6>T¢{intl.getFormattedDecimalNumber(room.amount)}</h6>
      </div>
      <div>
        <h6>
          <span>
            {intl.getTranslatedText('pages.versus.room.lobby.adversary')}
          </span>
        </h6>
        <AdversaryInfoContainer>
          <img
            src={
              room.adversary?._player.photo
                ? Env.IMAGE_SERVER_URL + room.adversary?._player.photo
                : defaultAvatar
            }
            alt={room.adversary?._player.name}
          />
          <div>
            <p>{room.adversary?._player.name}</p>
            <small>{room.adversary?._player.username}</small>
          </div>
          {myDataInTheRoom._me?.roles?.includes('owner') && (
            <CustomAntButton
              type="text"
              danger
              useCustomIcon
              icon={<FiTrash2 size={21} />}
              onClick={() =>
                handleRemoveOpponent(room.adversary?.players._user || '')
              }
            />
          )}
        </AdversaryInfoContainer>
      </div>
      {myDataInTheRoom._me?.roles?.includes('owner') ? (
        <CustomAntButton type="primary" onClick={handleStartGame}>
          {!loadingStartGame
            ? intl.getTranslatedText(
                'pages.versus.room.lobby.startGameConfirmButton',
              )
            : intl.getTranslatedText('common.messages.defaultLoading')}
        </CustomAntButton>
      ) : (
        <small>
          {intl.getTranslatedText(
            'pages.versus.room.lobby.messages.waitOwnerToStartGame',
          )}
        </small>
      )}
    </Container>
  );
};

export default Lobby;
