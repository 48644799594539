import React from 'react';

import { useIntl } from '../../../../../context/IntlContext';

import { ICoachFriendRoom, IMyDataInTheRoom } from '../..';

import {
  Container,
  Header,
  Content,
  ScoreboardContainer,
  ScoreTeamSelectorContainer,
  ScoreboardTeam,
} from './styles';
import { PointsDescription } from '../../styles';

interface IPlayCoachGoalMode {
  coachFriendRoom: ICoachFriendRoom;
  myDataInTheRoom: IMyDataInTheRoom;
}

const PlayCoachGoalMode: React.FC<IPlayCoachGoalMode> = ({
  coachFriendRoom,
  myDataInTheRoom,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.playCoachGoal.title')}
        </h6>
        <PointsDescription>
          <span>{myDataInTheRoom._meGame?.scoreBoard}</span>
          {` / 90 `}
          {intl.getTranslatedText('common.scoredPointsDescription')}
        </PointsDescription>
      </Header>
      <Content>
        <ScoreboardContainer>
          <ScoreboardTeam>
            <div>
              <img
                src={coachFriendRoom._game.local.image}
                alt={coachFriendRoom._game.local.name}
              />
            </div>
            <strong>{coachFriendRoom._game.local.abbrev}</strong>
          </ScoreboardTeam>
          <ScoreTeamSelectorContainer>
            <h6>{myDataInTheRoom._meGame?.localScore}</h6>
          </ScoreTeamSelectorContainer>
          <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
          <ScoreTeamSelectorContainer>
            <h6>{myDataInTheRoom._meGame?.awayScore}</h6>
          </ScoreTeamSelectorContainer>
          <ScoreboardTeam>
            <div>
              <img
                src={coachFriendRoom._game.away.image}
                alt={coachFriendRoom._game.away.name}
              />
            </div>
            <strong>{coachFriendRoom._game.away.abbrev}</strong>
          </ScoreboardTeam>
        </ScoreboardContainer>
      </Content>
    </Container>
  );
};

export default PlayCoachGoalMode;
