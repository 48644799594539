import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 16px;
  max-width: 752px;
  margin: 0 auto;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;

    & + div {
      margin-top: 16px;
    }

    &:nth-child(2) {
      margin-bottom: 32px;
    }

    h6 {
      color: ${props => props.theme.colors.light.light0};
      line-height: 100%;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }

      &:first-child {
        margin-bottom: 4px;
      }
    }
  }

  button.ant-btn-primary {
    width: 100%;
  }

  > small {
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const FriendContainer = styled.li`
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: center;

  /* & + li {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+3']};
  } */

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 16px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    margin-left: auto;
    display: flex;
    align-items: center;

    > small {
      color: ${props => props.theme.colors.light['light-2']};

      & + small {
        margin-left: 8px;
      }
    }

    button {
      margin-left: 8px;
    }
  }
`;
export const StatusIndicator = styled.small`
  padding: 2px 8px;
  border-radius: 4px;

  background: #e4ce00;
  color: ${props => props.theme.colors.dark.dark0} !important;
`;

export const AdversaryInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  div {
    display: flex;
    flex-direction: column;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  button {
    margin-left: auto;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
