import React, { useState } from 'react';
import * as dateFns from 'date-fns';
import { FiX, FiCheck } from 'react-icons/fi';
import { Modal } from 'antd';

import { useIntl } from '../../../../../../context/IntlContext';

import { IMatch } from '../index';

import {
  Container,
  MatchCardSelectMatchChampionContainer,
  MatchCardSelectMatchChampion,
  MatchCardSelectPlayersContainer,
  MatchCardInfoContainer,
  MatchChampionSelector,
  TeamPlayerSelector,
  MatchCardSelectPlayerButton,
} from './styles';

interface IMatchCard extends IMatch {
  handleSelectChampionOfMatch(
    match_id: string,
    selectedChampion: 'local' | 'draw' | 'away',
  ): void;
  handleSelectPlayerOfTeam(
    match_id: string,
    playerOfTeam: 'local' | 'away',
    player_id: string,
    player_name: string,
  ): void;
}

const { format, parseISO } = dateFns;

const MatchCard: React.FC<IMatchCard> = ({
  _id,
  local,
  away,
  info,
  myMatch,
  handleSelectChampionOfMatch,
  handleSelectPlayerOfTeam,
}) => {
  const intl = useIntl();

  const [showModalLocalTeam, setShowModalLocalTeam] = useState(false);
  const [showModalAwayTeam, setShowModalAwayTeam] = useState(false);

  return (
    <Container>
      <Modal
        title={intl.getTranslatedText(
          'pages.championship.cardGame.play.matchCard.selectPlayerModal.title',
          {
            teamAbbrev: local.abbrev,
          },
        )}
        visible={showModalLocalTeam}
        onCancel={() => setShowModalLocalTeam(false)}
        footer={null}
      >
        {local.players.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              handleSelectPlayerOfTeam(
                _id,
                'local',
                player._id,
                `${player.name} - ${player.number}`,
              );
              setShowModalLocalTeam(false);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
                {/* {' • Atacante'} */}
              </p>
              <small>
                {intl.getTranslatedText(
                  'pages.championship.cardGame.play.matchCard.selectPlayerModal.playerNumber',
                  {
                    playerNumber: player.number,
                  },
                )}
              </small>
            </div>
            {player._id === myMatch.localPlayer && <FiCheck size={20} />}
          </TeamPlayerSelector>
        ))}
      </Modal>
      <Modal
        title={intl.getTranslatedText(
          'pages.championship.cardGame.play.matchCard.selectPlayerModal.title',
          {
            teamAbbrev: away.abbrev,
          },
        )}
        visible={showModalAwayTeam}
        onCancel={() => setShowModalAwayTeam(false)}
        footer={null}
      >
        {away.players.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              handleSelectPlayerOfTeam(
                _id,
                'away',
                player._id,
                `${player.name} - ${player.number}`,
              );
              setShowModalAwayTeam(false);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
                {/* {' • Atacante'} */}
              </p>
              <small>
                {intl.getTranslatedText(
                  'pages.championship.cardGame.play.matchCard.selectPlayerModal.playerNumber',
                  {
                    playerNumber: player.number,
                  },
                )}
              </small>
            </div>
            {player._id === myMatch.awayPlayer && <FiCheck size={20} />}
          </TeamPlayerSelector>
        ))}
      </Modal>
      <h6>
        {local.name.toUpperCase()}{' '}
        {intl.getTranslatedText('common.vsDescription')}{' '}
        {away.name.toUpperCase()}
      </h6>
      <MatchCardSelectMatchChampionContainer>
        <MatchCardSelectMatchChampion
          onClick={() => handleSelectChampionOfMatch(_id, 'local')}
        >
          <MatchChampionSelector
            onClick={() => handleSelectChampionOfMatch(_id, 'local')}
            selected={myMatch.choise === 'local'}
          >
            <FiX size={16} />
          </MatchChampionSelector>
          <div>
            <img src={local.image} alt={local.name} />
          </div>
          <strong>{local.abbrev.toUpperCase()}</strong>
        </MatchCardSelectMatchChampion>
        <MatchChampionSelector
          onClick={() => handleSelectChampionOfMatch(_id, 'draw')}
          selected={myMatch.choise === 'draw'}
        >
          <FiX size={16} />
        </MatchChampionSelector>
        <MatchCardSelectMatchChampion
          onClick={() => handleSelectChampionOfMatch(_id, 'away')}
        >
          <strong>{away.abbrev.toUpperCase()}</strong>
          <div>
            <img src={away.image} alt={away.name} />
          </div>
          <MatchChampionSelector
            onClick={() => handleSelectChampionOfMatch(_id, 'away')}
            selected={myMatch.choise === 'away'}
          >
            <FiX size={16} />
          </MatchChampionSelector>
        </MatchCardSelectMatchChampion>
      </MatchCardSelectMatchChampionContainer>
      <MatchCardSelectPlayersContainer>
        <MatchCardSelectPlayerButton
          onClick={() => setShowModalLocalTeam(true)}
          type="button"
          selected={!!myMatch.localPlayer}
        >
          {!myMatch.localPlayerName ? (
            <small>
              {intl.getTranslatedText(
                'pages.championship.cardGame.play.matchCard.selectTeamPlayerButton',
                {
                  teamAbbrev: local.abbrev.toUpperCase(),
                },
              )}
            </small>
          ) : (
            <small>{myMatch.localPlayerName}</small>
          )}
        </MatchCardSelectPlayerButton>
        <MatchCardSelectPlayerButton
          onClick={() => setShowModalAwayTeam(true)}
          type="button"
          selected={!!myMatch.awayPlayer}
        >
          {!myMatch.awayPlayerName ? (
            <small>
              {intl.getTranslatedText(
                'pages.championship.cardGame.play.matchCard.selectTeamPlayerButton',
                {
                  teamAbbrev: away.abbrev.toUpperCase(),
                },
              )}
            </small>
          ) : (
            <small>{myMatch.awayPlayerName}</small>
          )}
        </MatchCardSelectPlayerButton>
      </MatchCardSelectPlayersContainer>
      <MatchCardInfoContainer>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.place')}</small>
          <small>{info.place}</small>
        </div>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.date')}</small>
          <small>
            {format(
              parseISO(info.date),
              intl.getTranslatedText('common.formatDates.fullDate'),
              {
                locale: intl.getDatefnsLocale(),
              },
            )}
          </small>
        </div>
      </MatchCardInfoContainer>
    </Container>
  );
};

export default MatchCard;
