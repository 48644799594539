import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import { useAuth } from '../../../../../context/AuthContext';
import { useIntl } from '../../../../../context/IntlContext';

import Breadcrumbs from '../../../../../components/Breadcrumbs';
import Loading from '../../../../../components/Loading';
import NotFound from '../../../../../components/NotFound';
import api from '../../../../../services/api';

import PlayedCardGameMatch from './PlayedCardGameMatch';

import { Container, Header, CardInfoContainer } from './styles';

interface IRouteParams {
  played_card_game_id: string;
  id: string;
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

interface IMatch {
  _id: string;
  info: {
    place: string;
    date: string;
  };
  local: ITeam;
  away: ITeam;
}

export interface IGame {
  _id: string;
  choise: 'local' | 'draw' | 'away';
  choiceScore: number;
  localPlayer: string;
  localPlayerName: string;
  awayPlayer: string;
  awayPlayerName: string;
  playerScore: number;
  game: IMatch;
}

interface IPlayedCardGameState {
  _id: string;
  name: string;
  totalScore: number;
  games: IGame[];
  _user: {
    _id: string;
    name: string;
    username: string;
  };
  createdAt: string;
}

const CardGameDetails: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();

  const [playedCardGame, setPlayedCardGame] =
    useState<IPlayedCardGameState | null>(null);
  const [loadingPlayedCardGame, setLoadingPlayedCardGame] = useState(true);

  useEffect(() => {
    async function getPlayedGameCard(): Promise<void> {
      try {
        const { data } = await api.get<IPlayedCardGameState>(
          `/api/played-card/${params.played_card_game_id}`,
        );

        if (data._user._id !== me?._id) {
          history.push(`/championships/${params.id}/card_games_history`);
          return;
        }

        const games: IGame[] = data.games.map(responsePlayedGame => {
          return {
            _id: responsePlayedGame._id,
            choise: responsePlayedGame.choise,
            choiceScore: responsePlayedGame.choiceScore,
            localPlayer: responsePlayedGame.localPlayer,
            localPlayerName: responsePlayedGame.localPlayerName,
            awayPlayer: responsePlayedGame.awayPlayer,
            awayPlayerName: responsePlayedGame.awayPlayerName,
            playerScore: responsePlayedGame.playerScore,
            game: {
              _id: responsePlayedGame.game._id,
              away: {
                _id: responsePlayedGame.game.away._id,
                name: responsePlayedGame.game.away.name,
                abbrev: responsePlayedGame.game.away.name
                  .substring(0, 3)
                  .toUpperCase(),
                image: responsePlayedGame.game.away.image,
              },
              local: {
                _id: responsePlayedGame.game.local._id,
                name: responsePlayedGame.game.local.name,
                abbrev: responsePlayedGame.game.local.name
                  .substring(0, 3)
                  .toUpperCase(),
                image: responsePlayedGame.game.local.image,
              },
              info: responsePlayedGame.game.info,
            },
          };
        });

        setPlayedCardGame({
          _id: data._id,
          name: data.name,
          totalScore: data.totalScore,
          games,
          _user: data._user,
          createdAt: data.createdAt,
        });
      } catch (error) {
        // showToast({
        //   type: 'error',
        //   title: 'Ocorreu um erro ao carregar os campeonatos',
        //   description: 'Recarregue a página!',
        // });
      }
      setLoadingPlayedCardGame(false);
    }

    getPlayedGameCard();
  }, [history, me?._id, params]);

  if (loadingPlayedCardGame) {
    return (
      <Container>
        <Breadcrumbs />
        <Header>
          <h5>
            {intl.getTranslatedText(
              'pages.championship.myCardGameDetails.title',
            )}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!playedCardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.championship.myCardGameDetails.messages.noGame',
          )}
        </h4>
        <Link to={`/championships/${params.id}/card_games_history`}>
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.championship.myCardGameDetails.goToGamesHistoryLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText('pages.championship.myCardGameDetails.title')}
        </h5>
      </Header>
      <CardInfoContainer>
        <div>
          <small>{playedCardGame.name}</small>
          <small>
            {format(
              parseISO(playedCardGame.createdAt),
              intl.getTranslatedText('common.formatDates.carriedOut'),
            )}
          </small>
        </div>
        <div>
          <p>
            {playedCardGame.totalScore}{' '}
            {intl.getTranslatedText('common.scoredPointsDescription')}
          </p>
        </div>
      </CardInfoContainer>
      <ul>
        {playedCardGame?.games.map(game => (
          <PlayedCardGameMatch key={game._id} {...game} />
        ))}
      </ul>
    </Container>
  );
};

export default CardGameDetails;
