export enum ScoreChallengeExample {
  TEAM_POINTS = 'O TIME_X irá marcar X gols',
}

export enum MinuteOfFirstGoalChallengeExample {
  TIME_OF_TEAM_FIRST_GOAL = 'O primeiro gol do TIME_X irá acontecer entre os minutos X e Y',
}

export enum AthleteChallengeExample {
  TIME_OF_ATHLETE_GOAL = 'JOGADOR_X irá marcar um gol entre o minuto X e Y',
}

export enum StatisticsChallengeExample {
  AMOUNT_OF_YELLOW_CARDS = 'Na partida, haverá um total de X cartões amarelos.',
  AMOUNT_OF_RED_CARDS = 'Na partida, haverá um total de X cartões vermelhos.',
  AMOUNT_OF_CORNERS = 'Na partida, haverá um total de X escanteios.',
  AMOUNT_OF_OFFSIDES = 'Na partida, haverá um total de X impedimentos.',
}
