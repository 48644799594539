import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const CoachFriend: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/home');
  }, [history]);

  return (
    <Container>
      <h4>Coach Friend</h4>
    </Container>
  );
};

export default CoachFriend;
