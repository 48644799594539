import { TRoutesTexts } from '../../en/routes';

const RoutesListTexts = {
  'routes.routesList.signIn.name': 'Login',
  'routes.routesList.signUp.name': 'Cadastro',
  'routes.routesList.home.name': 'Início',
  'routes.routesList.championships.name': 'Campeonatos',
  'routes.routesList.championship.name': 'Detalhes do campeonato',
  'routes.routesList.cardGame.name': 'Detalhes da cartela',
  'routes.routesList.playCardGame.name': 'Jogar cartela',
  'routes.routesList.cardGameDetails.name': 'Detalhes do meu jogo de cartela',
  'routes.routesList.userCardGameDetails.name': 'Detalhes do jogo de cartela',
  'routes.routesList.cardGamesHistory.name': 'Histórico de cartelas',
  'routes.routesList.coachFriend.name': 'Família VS Amigos',
  'routes.routesList.coachFriendNewRoom.name': 'Criar nova sala',
  'routes.routesList.coachFriendRoom.name': 'Detalhes da sala',
  'routes.routesList.coachFriendRoomNewGame.name': 'Novo jogo',
  'routes.routesList.coachFriendRoomMyGameDetails.name': 'Detalhes do meu jogo',
  'routes.routesList.coachFriendPublicRooms.name': 'Pesquisar sala pública',
  'routes.routesList.coachFriendMyRooms.name': 'Minhas salas',
  'routes.routesList.versus.name': 'Versus',
  'routes.routesList.versusNewRoom.name': 'Criar nova sala',
  'routes.routesList.versusRoom.name': 'Detalhes da sala',
  'routes.routesList.versusGame.name': 'Novo jogo',
  'routes.routesList.versusPublicRooms.name': 'Pesquisar sala pública',
  'routes.routesList.versusMyRooms.name': 'Minhas salas',
  'routes.routesList.versusVIP.name': 'Versus VIP',
  'routes.routesList.versusVIPNewRoom.name': 'Criar nova sala',
  'routes.routesList.versusVIPGame.name': 'Novo jogo',
  'routes.routesList.versusVIPPublicRooms.name': 'Pesquisar sala pública',
  'routes.routesList.versusVIPMyRooms.name': 'Minhas salas',
  'routes.routesList.athletesOfTheWeek.name': 'Hat-trick',
  'routes.routesList.athleteCardGame.name': 'Detalhes da cartela',
  'routes.routesList.playAthleteCardGame.name': 'Jogar cartela',
  'routes.routesList.athleteCardGamesHistory.name': 'Histórico de cartelas',
  'routes.routesList.athleteCardGameDetails.name':
    'Detalhes do meu jogo de cartela',
  'routes.routesList.athleteUserCardGameDetails.name':
    'Detalhes do jogo de cartela',
  'routes.routesList.pushGamesHistory.name': 'Push Games',
  'routes.routesList.profile.name': 'Meu perfil',
  'routes.routesList.globalRanking.name': 'Ranking - Global',
  'routes.routesList.coachFriendRanking.name': 'Ranking - Família VS Amigos',
  'routes.routesList.versusRanking.name': 'Ranking - Versus',
  'routes.routesList.versusVIPRanking.name': 'Ranking - Versus VIP',
  'routes.routesList.cardGamesRanking.name':
    'Ranking - Cartelas de campeonatos',
  'routes.routesList.athletesOfTheWeekRanking.name': 'Ranking - Hat-trick',
  'routes.routesList.pushGamesRanking.name': 'Ranking - Push Games',
  'routes.routesList.chats.name': 'Conversas',
};

const routesTexts: TRoutesTexts = {
  ...RoutesListTexts,
};

export default routesTexts;
