import React, { useCallback, useState } from 'react';

import UserAvatar from '@/components/UserAvatar';
import { getSanitizedText } from '@/utils/sanitize';
import { useAuth } from '@/context/AuthContext';
import CustomAntButton from '@/components/CustomAntButton';
import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import ConfirmAction from '@/components/ConfirmAction';
import { useBullet } from '@/context/BulletContext';
import { IChallengeCardProps } from './types';

import {
  ChallengePrice,
  ChallengePriceContainer,
  Container,
  OwnerNameAndUsername,
} from './styles';
import { convertTextWithMarkersToHTML } from '../../utils';
import { useCoachFriendRoom } from '../../../CoachFriendRoomContext';

const ChallengeCard: React.FC<IChallengeCardProps> = ({ challenge }) => {
  const { user: me } = useAuth();
  const intlContext = useIntl();
  const bulletContext = useBullet();

  const coachFriendRoomContext = useCoachFriendRoom();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isJoinConfirmationVisible, setIsJoinConfirmationVisible] =
    useState(false);

  const handleJoinToChallenge = useCallback(async () => {
    const roomId = challenge._coach._id;
    const challengerId = challenge._id;

    try {
      setIsSubmitting(true);
      await api.post(
        `/api/coach-friend/${roomId}/join/challenger/${challengerId}`,
      );
      coachFriendRoomContext.setCurrentChallengeRoute('myChallenges');
    } catch (error) {
      showToast({
        type: 'error',
        title: intlContext.getTranslatedText(
          'common.errors.unexpectedError.title',
        ),
        description: intlContext.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
    setIsSubmitting(false);
  }, [challenge, coachFriendRoomContext, intlContext]);

  const checkIfUserCanJoinToChallenge = (): boolean => {
    if (bulletContext.balance.amount < challenge.valueChallenger) {
      showToast({
        title: 'Você não possui saldo suficiente para prosseguir',
        type: 'warn',
      });
      return false;
    }

    return true;
  };

  return (
    <Container key={challenge._id}>
      <div>
        <UserAvatar size={32} photoId={challenge._user.photo} />
        <OwnerNameAndUsername>
          <strong>{challenge._user.name}</strong>
          <p>{challenge._user.username}</p>
        </OwnerNameAndUsername>
        <ChallengePriceContainer>
          <div>
            <small>Desafiante</small>
            <ChallengePrice $type="challenger">
              <span>T¢</span>
              {challenge.valueUser}
            </ChallengePrice>
          </div>
          <div>
            <small>Oponente</small>
            <ChallengePrice $type="opponent">
              <span>T¢</span>
              {challenge.valueChallenger}
            </ChallengePrice>
          </div>
        </ChallengePriceContainer>
      </div>
      <h6
        dangerouslySetInnerHTML={{
          __html: getSanitizedText(
            convertTextWithMarkersToHTML(challenge.question),
          ),
        }}
      />
      {challenge._user._id !== me?._id ? (
        <>
          {!isJoinConfirmationVisible && (
            <CustomAntButton
              type="primary"
              onClick={() => {
                const canJoinToChallenge = checkIfUserCanJoinToChallenge();
                if (canJoinToChallenge) {
                  setIsJoinConfirmationVisible(true);
                }
              }}
              loading={isSubmitting}
            >
              Confrontar
            </CustomAntButton>
          )}
          {isJoinConfirmationVisible && (
            <ConfirmAction
              description={`Deseja participar desse desafio por T¢${challenge.valueChallenger}?`}
              confirmButtonText="Sim"
              onConfirm={() => {
                setIsJoinConfirmationVisible(false);
                handleJoinToChallenge();
              }}
              onCancel={() => setIsJoinConfirmationVisible(false)}
            />
          )}
        </>
      ) : (
        <small>Aguardando oponente</small>
      )}
    </Container>
  );
};

export default ChallengeCard;
