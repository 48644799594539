const initialMakersRegexp = /[[|{|(]/g;
const finalMakersRegexp = /[\]|}|)]/g;

export function convertTextWithMarkersToHTML(text: string): string {
  return text
    .replaceAll(initialMakersRegexp, '<span>')
    .replaceAll(finalMakersRegexp, '</span>');
}

export function convertTextWithMarkersToSavingPattern(text: string): string {
  return text
    .replaceAll(initialMakersRegexp, '[')
    .replaceAll(finalMakersRegexp, ']');
}
