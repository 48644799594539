export interface IChallenge {
  details: unknown;
  status: boolean;
  isFinished: boolean;
  winner?: string;
  _id: string;
  _user: {
    _id: string;
    name: string;
    username: string;
    photo?: string;
  };
  _challenger?: {
    _id: string;
    name: string;
    username: string;
    photo?: string;
  };
  _coach: {
    _id: string;
    name: string;
    amount: number;
    gameCount: number;
  };
  _game: {
    _id: string;
    local: {
      initials: string;
      _id: string;
      name: string;
      image: string;
    };
    away: {
      initials: string;
      _id: string;
      name: string;
      image: string;
    };
  };
  gameType: ChallengeType;
  question: string;
  mode: string;
  valueUser: number;
  valueChallenger: number;
  createdAt: string;
}

export enum ChallengeType {
  SCOREBOARD = 'score',
  MINUTE_OF_FIRST_GOAL = 'time',
  ATHLETES = 'athlete',
  YELLOW_CARDS = 'yellow-card',
  RED_CARDS = 'red-card',
  CORNERS = 'corner',
  OFFSIDES = 'offside',
}
