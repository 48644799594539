import React from 'react';

import { useIntl } from '../../../../context/IntlContext';

import { IAthlete } from '../index';
import AthleteCard from './AthleteCard';

import { Container } from './styles';

interface IResultProps {
  athletes: IAthlete[];
  cardType: 'new' | 'running' | 'finished';
}

const Results: React.FC<IResultProps> = ({ cardType, athletes }) => {
  const intl = useIntl();

  return (
    <Container>
      {cardType === 'running' && (
        <small>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.results.messages.partialInfo',
          )}
        </small>
      )}
      <ul>
        {athletes.map(athlete => (
          <AthleteCard key={athlete._athletes._id} athlete={athlete} />
        ))}
      </ul>
    </Container>
  );
};

export default Results;
