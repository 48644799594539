import { PointsDescription } from '@/pages/CoachFriend/Room/styles';
import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

import { IPlayCoachGoalGameState } from '..';
import { ITeam } from '../..';

import CustomAntButton from '../../../../../components/CustomAntButton';
import { useIntl } from '../../../../../context/IntlContext';

import {
  Container,
  Header,
  Content,
  ScoreboardContainer,
  ScoreTeamSelectorContainer,
  ScoreboardTeam,
} from './styles';

interface IPlayCoachGoalMode {
  playCoachGoalGame: IPlayCoachGoalGameState;
  handleChangeScoreOfTeamInPlayCoachGoal(
    team: 'local' | 'away',
    score: number,
  ): void;
  localTeam: ITeam;
  awayTeam: ITeam;
}

const PlayCoachGoalMode: React.FC<IPlayCoachGoalMode> = ({
  playCoachGoalGame,
  handleChangeScoreOfTeamInPlayCoachGoal,
  localTeam,
  awayTeam,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.playCoachGoal.title')}
        </h6>
        <PointsDescription>
          {`${intl.getTranslatedText(
            'pages.coachFriend.room.newGame.maxScoreDescription',
          )} 90 ${intl.getTranslatedText('common.scoredPointsDescription')}`}
        </PointsDescription>
      </Header>
      <Content>
        <ScoreboardContainer>
          <ScoreboardTeam>
            <div>
              <img src={localTeam.image} alt={localTeam.name} />
            </div>
            <strong>{localTeam.abbrev}</strong>
          </ScoreboardTeam>
          <ScoreTeamSelectorContainer>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeamInPlayCoachGoal(
                  'local',
                  playCoachGoalGame.local + 1,
                )
              }
            />
            <h6>{playCoachGoalGame.local}</h6>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeamInPlayCoachGoal(
                  'local',
                  playCoachGoalGame.local - 1,
                )
              }
              disabled={playCoachGoalGame.local === 0}
            />
          </ScoreTeamSelectorContainer>
          <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
          <ScoreTeamSelectorContainer>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeamInPlayCoachGoal(
                  'away',
                  playCoachGoalGame.away + 1,
                )
              }
            />
            <h6>{playCoachGoalGame.away}</h6>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeScoreOfTeamInPlayCoachGoal(
                  'away',
                  playCoachGoalGame.away - 1,
                )
              }
              disabled={playCoachGoalGame.away === 0}
            />
          </ScoreTeamSelectorContainer>
          <ScoreboardTeam>
            <div>
              <img src={awayTeam.image} alt={awayTeam.name} />
            </div>
            <strong>{awayTeam.abbrev}</strong>
          </ScoreboardTeam>
        </ScoreboardContainer>
      </Content>
    </Container>
  );
};

export default PlayCoachGoalMode;
