import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface IMatchChampionSelector {
  selected: boolean;
}

interface IMatchCardSelectPlayerButton {
  selected: boolean;
}

export const Container = styled.li`
  list-style: none;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;

  @media only screen and (min-width: 1450px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  h6 {
    width: 100%;
    margin-bottom: 16px;
    cursor: default;
    text-transform: uppercase;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const MatchCardSelectMatchChampionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const MatchCardSelectMatchChampion = styled.div`
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  transition: 0.2s opacity;

  &:hover {
    opacity: 0.75;
  }

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;

    img {
      height: 26px;
    }
  }

  strong {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    text-transform: uppercase;
  }

  &:nth-child(1) {
    div {
      margin-left: 16px;
    }
  }

  &:nth-child(3) {
    div {
      margin-right: 16px;
    }
  }
`;
export const MatchCardSelectPlayersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};
  margin-bottom: 16px;
  min-height: 69px;

  /* button {
    border: none;
    height: 100%;
    background: ${props => props.theme.colors.dark['dark+2']};
    width: 140px;
    padding: 8px;
    border-radius: 8px;
    transition: 0.2s opacity;
    border: 1px solid ${props => props.theme.colors.dark['dark+2']};

    &:hover {
      opacity: 0.75;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
      width: 100%;
      text-align: center;
    }
  } */
`;

export const MatchCardSelectPlayerButton = styled.button<IMatchCardSelectPlayerButton>`
  border: none;
  height: 100%;
  background: ${props => props.theme.colors.dark['dark+2']};
  width: 140px;
  padding: 8px;
  border-radius: 8px;
  transition: 0.2s opacity;
  box-shadow: 2px 2px 16px #00000030;
  border: 1px solid ${props => props.theme.colors.dark['dark+2']};

  ${props =>
    props.selected &&
    css`
      border: 1px solid ${props.theme.colors.primary.primary0};
    `}

  &:hover {
    opacity: 0.75;
  }

  small {
    color: ${props => props.theme.colors.light['light-2']};
    width: 100%;
    text-align: center;
  }
`;

export const MatchCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;

  div {
    width: 100%;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
export const MatchChampionSelector = styled.button<IMatchChampionSelector>`
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  width: 22px;
  height: 22px;
  background: ${props => props.theme.colors.dark['dark+2']};
  transition: 0.2s opacity;

  svg {
    color: ${props => props.theme.colors.dark['dark+2']};
  }

  &:hover {
    opacity: 0.75;
  }

  ${props =>
    props.selected &&
    css`
      border: 1px solid ${props.theme.colors.primary.primary0};
      background: transparent;

      svg {
        color: ${props.theme.colors.primary.primary0};
      }
    `}
`;
export const TeamPlayerSelector = styled.button`
  width: 100%;
  padding: 8px;
  background: transparent;
  border: 0;
  transition: 0.2s background;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => lighten(0.05, props.theme.colors.dark.dark0)};
  }

  button + & {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: inherit;
      color: inherit;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary.primary0};
  }
`;
