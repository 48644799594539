import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiMinus, FiPlus, FiXCircle, FiCheckCircle } from 'react-icons/fi';
import { HiCurrencyDollar } from 'react-icons/hi';
import { IoMdFootball } from 'react-icons/io';
import { Modal, Spin, Tabs } from 'antd';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../context/AuthContext';
import { useIntl } from '../../../../context/IntlContext';
import { formatNameWithPossessivePronoun } from '../../../../utils/formatters';

import Env from '../../../../config/Environment';
import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { IVersusRoom, IPlayerGame } from '..';

import {
  Container,
  TopContent,
  Header,
  TeamsContainer,
  RoomTeam,
  ScoreTeamSelectorContainer,
  BottomContainer,
  ContentBottom,
  ViewPlayersStatusContainer,
  ViewPlayerStatus,
  PlayerGame,
  LoadingAndNotFoundContainer,
} from './styles';

import CustomAntButton from '../../../../components/CustomAntButton';
import api from '../../../../services/api';
import { showToast } from '../../../../hooks/showToast';

interface IRouteParams {
  id: string;
}

interface IPlayProps {
  versusRoom: IVersusRoom;
  gamesOfRoom: IPlayerGame[];
  loading: boolean;
}

const { TabPane } = Tabs;
const { confirm } = Modal;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Play: React.FC<IPlayProps> = ({ versusRoom, gamesOfRoom, loading }) => {
  const { params } = useRouteMatch<IRouteParams>();

  const intl = useIntl();
  const { user: me } = useAuth();

  const [loadingSubmitNewGame, setLoadingSubmitNewGame] = useState(false);
  const [currentNewGame, setCurrentNewGame] = useState({
    localScore: 0,
    awayScore: 0,
  });

  const myTurnToPlay = useMemo(() => {
    if (gamesOfRoom.length === 0) {
      if (versusRoom.headsTails === me?._id) {
        return true;
      }
      return false;
    }

    const lastGame = [...gamesOfRoom].shift();
    if (lastGame?.games?._user !== me?._id) {
      return true;
    }
    return false;
  }, [gamesOfRoom, me?._id, versusRoom.headsTails]);

  const gamesQuantity = useMemo(() => {
    return {
      my: gamesOfRoom.filter(game => game.games._user === me?._id).length,
      adversary: gamesOfRoom.filter(game => game.games._user !== me?._id)
        .length,
    };
  }, [gamesOfRoom, me?._id]);

  const availableScoreboard = useMemo(() => {
    if (
      gamesOfRoom.find(
        game =>
          game.games.localScore === currentNewGame.localScore &&
          game.games.awayScore === currentNewGame.awayScore,
      )
    ) {
      return false;
    }
    return true;
  }, [currentNewGame.awayScore, currentNewGame.localScore, gamesOfRoom]);

  const handleSubmitNewGame = useCallback(async () => {
    if (
      gamesOfRoom.find(
        game =>
          game.games.localScore === currentNewGame.localScore &&
          game.games.awayScore === currentNewGame.awayScore,
      )
    ) {
      showToast({
        type: 'warn',
        title: intl.getTranslatedText(
          'pages.versus.room.play.messages.submitNewGameWarn.title',
        ),
        description: intl.getTranslatedText(
          'pages.versus.room.play.messages.submitNewGameWarn.description',
        ),
      });
      return;
    }

    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.versus.room.play.messages.submitNewGameWarn.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'pages.versus.room.play.messages.submitNewGameConfirm.title',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.versus.room.play.submitNewGameConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingSubmitNewGame(true);

      const body = {
        ...currentNewGame,
      };

      await api.put(`/api/game-vs/${params.id}/games`, body);
      setLoadingSubmitNewGame(false);
      setCurrentNewGame({
        localScore: 0,
        awayScore: 0,
      });
      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.versus.room.play.messages.submitNewGameSuccess.title',
        ),
      });
    } catch (error) {
      setLoadingSubmitNewGame(false);
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [currentNewGame, gamesOfRoom, intl, params.id]);

  if (loading) {
    return (
      <Container>
        <TopContent>
          <Header>
            <h5>{versusRoom.name}</h5>
          </Header>
        </TopContent>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TopContent>
        {/* <Breadcrumbs /> */}
        <Header>
          <h5>{versusRoom.name}</h5>
        </Header>
        <TeamsContainer availableScoreboard={availableScoreboard}>
          <div>
            <RoomTeam>
              <div>
                <img
                  src={versusRoom._game.local.image}
                  alt={versusRoom._game.local.name}
                />
              </div>
              <strong>{versusRoom._game.local.abbrev}</strong>
            </RoomTeam>
            {myTurnToPlay && (
              <ScoreTeamSelectorContainer>
                <CustomAntButton
                  type="text"
                  textPrimary
                  icon={<FiPlus size={20} />}
                  useCustomIcon
                  onClick={() =>
                    setCurrentNewGame(oldCurrentNewGame => ({
                      ...oldCurrentNewGame,
                      localScore: oldCurrentNewGame.localScore + 1,
                    }))
                  }
                />
                <h6>{currentNewGame.localScore}</h6>
                <CustomAntButton
                  type="text"
                  danger
                  icon={<FiMinus size={20} />}
                  useCustomIcon
                  onClick={() => {
                    if (currentNewGame.localScore > 0) {
                      setCurrentNewGame(oldCurrentNewGame => ({
                        ...oldCurrentNewGame,
                        localScore: oldCurrentNewGame.localScore - 1,
                      }));
                    }
                  }}
                  disabled={currentNewGame.localScore === 0}
                />
              </ScoreTeamSelectorContainer>
            )}
            <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
            {myTurnToPlay && (
              <ScoreTeamSelectorContainer>
                <CustomAntButton
                  type="text"
                  textPrimary
                  icon={<FiPlus size={20} />}
                  useCustomIcon
                  onClick={() =>
                    setCurrentNewGame(oldCurrentNewGame => ({
                      ...oldCurrentNewGame,
                      awayScore: oldCurrentNewGame.awayScore + 1,
                    }))
                  }
                />
                <h6>{currentNewGame.awayScore}</h6>
                <CustomAntButton
                  type="text"
                  danger
                  icon={<FiMinus size={20} />}
                  useCustomIcon
                  onClick={() => {
                    if (currentNewGame.awayScore > 0) {
                      setCurrentNewGame(oldCurrentNewGame => ({
                        ...oldCurrentNewGame,
                        awayScore: oldCurrentNewGame.awayScore - 1,
                      }));
                    }
                  }}
                  disabled={currentNewGame.awayScore === 0}
                />
              </ScoreTeamSelectorContainer>
            )}
            <RoomTeam>
              <div>
                <img
                  src={versusRoom._game.away.image}
                  alt={versusRoom._game.away.name}
                />
              </div>
              <strong>{versusRoom._game.away.abbrev}</strong>
            </RoomTeam>
            {myTurnToPlay && (
              <>
                {availableScoreboard ? (
                  <FiCheckCircle size={20} />
                ) : (
                  <FiXCircle size={20} />
                )}
              </>
            )}
          </div>
        </TeamsContainer>
        {myTurnToPlay ? (
          <>
            <p>
              {intl.getTranslatedText(
                'pages.versus.room.play.messages.meTurnToPlay',
              )}
            </p>
            <CustomAntButton
              type="primary"
              onClick={() => {
                if (!loadingSubmitNewGame) {
                  handleSubmitNewGame();
                }
              }}
              disabled={loadingSubmitNewGame}
            >
              {intl.getTranslatedText('pages.versus.room.play.playButton')}
            </CustomAntButton>
          </>
        ) : (
          <p>
            {intl.getTranslatedText(
              'pages.versus.room.play.messages.adversaryTurnToPlay',
              {
                adversaryName: formatNameWithPossessivePronoun(
                  versusRoom.adversary?._player.name || '',
                ),
              },
            )}
          </p>
        )}
      </TopContent>
      <BottomContainer>
        <Tabs
          activeKey="plays"
          // onTabClick={tabKey => {
          //   if (tabKey === 'games' || tabKey === 'friends') {
          //     setCurrentView(tabKey);
          //   }
          // }}
          centered
        >
          <TabPane
            tab={intl.getTranslatedText('pages.versus.room.play.tabs.plays')}
            key="plays"
          >
            <ContentBottom>
              <ViewPlayersStatusContainer>
                <ViewPlayerStatus
                  selectedPlayer={myTurnToPlay}
                  thisUserIsTheFirst={versusRoom.headsTails === me?._id}
                >
                  <img
                    src={
                      me?.photo
                        ? Env.IMAGE_SERVER_URL + me?.photo
                        : defaultAvatar
                    }
                    alt={intl.getTranslatedText('common.meIdentifier')}
                  />
                  <p>{intl.getTranslatedText('common.meIdentifier')}</p>
                  <div>
                    <IoMdFootball size={19} />
                    <HiCurrencyDollar size={19} />
                    <p>{gamesQuantity.my}/12</p>
                  </div>
                </ViewPlayerStatus>
                <ViewPlayerStatus
                  selectedPlayer={!myTurnToPlay}
                  thisUserIsTheFirst={versusRoom.headsTails !== me?._id}
                >
                  <img
                    src={
                      versusRoom.adversary?._player.photo
                        ? Env.IMAGE_SERVER_URL +
                          versusRoom.adversary?._player.photo
                        : defaultAvatar
                    }
                    alt={versusRoom.adversary?._player.name}
                  />
                  <p>{versusRoom.adversary?._player.name}</p>
                  <div>
                    <IoMdFootball size={19} />
                    <HiCurrencyDollar size={19} />
                    <p>{gamesQuantity.adversary}/12</p>
                  </div>
                </ViewPlayerStatus>
              </ViewPlayersStatusContainer>
              <div>
                {gamesOfRoom.map(game => (
                  <PlayerGame
                    key={game.games._id}
                    meGame={game.games._user === me?._id}
                  >
                    <img
                      src={
                        game._player.photo
                          ? Env.IMAGE_SERVER_URL + game._player.photo
                          : defaultAvatar
                      }
                      alt={game._player.name}
                    />
                    <div>
                      <div>
                        <h6>{versusRoom._game.local.name}</h6>
                        <h6>{game.games.localScore}</h6>
                      </div>
                      <div>
                        <h6>{versusRoom._game.away.name}</h6>
                        <h6>{game.games.awayScore}</h6>
                      </div>
                    </div>
                  </PlayerGame>
                ))}
              </div>
            </ContentBottom>
          </TabPane>
        </Tabs>
      </BottomContainer>
    </Container>
  );
};

export default Play;
