import React, { useState, useEffect, useCallback } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';
import { BiCheckDouble } from 'react-icons/bi';

import { showToast } from '../../../hooks/showToast';
import { useAuth } from '../../../context/AuthContext';
import { IChatMessage } from '../../../context/ChatP2PContext';
import { useIntl } from '../../../context/IntlContext';

import UserAvatar from '../../../components/UserAvatar';

import api from '../../../services/api';
import socket from '../../../services/socket';

import {
  Container,
  Chat,
  LastMessageInfoContainer,
  LastMessageTime,
  UnreadMessagesIndicator,
  LoadingAndNotFoundContainer,
  LoadMoreChatsButton,
} from './styles';

interface IChatUser {
  _id: string;
  username: string;
  name: string;
  email: string;
  photo: string;
}

interface IChatState {
  _id: string;
  messages?: Omit<IChatMessage, 'user'>;
  users: IChatUser[];
  friend?: IChatUser;
  status: boolean;
  createdAt: string;
  updatedAt: string;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const {
  parseISO,
  format,
  isEqual,
  addDays,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} = dateFns;

const ChatsList: React.FC = () => {
  const intl = useIntl();
  const { user: me } = useAuth();

  const formatMessageDate = useCallback(
    (messageDate?: string): string => {
      if (!messageDate) return '';

      const isoMessageDate = parseISO(messageDate);

      const onlyMessageDate = setHours(
        setMinutes(setSeconds(setMilliseconds(isoMessageDate, 0), 0), 0),
        0,
      );
      const onlyNewDate = setHours(
        setMinutes(setSeconds(setMilliseconds(new Date(), 0), 0), 0),
        0,
      );

      if (isEqual(onlyMessageDate, onlyNewDate)) {
        return format(isoMessageDate, 'p', {
          locale: intl.getDatefnsLocale(),
        });
      }

      if (isEqual(addDays(onlyMessageDate, 1), onlyNewDate)) {
        return intl.getTranslatedText('common.formatDates.yesterday');
      }

      return format(isoMessageDate, 'dd/MM/yyyy');
    },
    [intl],
  );

  const [chats, setChats] = useState<IChatState[]>([]);
  const [loadingChats, setLoadingChats] = useState(true);
  const [chatsPagination, setChatsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const getChats = useCallback(
    async (page = 1) => {
      try {
        setLoadingChats(true);
        const { data } = await api.get<{
          doc: IChatState[];
          page: number;
          pages: number;
          unread: number;
        }>('/api/chat/p2p', {
          params: {
            page,
            limit: chatsPagination.limit,
          },
        });

        const chatsWithFriendProperty = data.doc.map(chat => ({
          ...chat,
          friend: chat.users.find(user => user._id !== me?._id),
        }));

        if (page === 1) {
          setChats(chatsWithFriendProperty);
        } else {
          setChats(oldChats => [...oldChats, ...chatsWithFriendProperty]);
        }

        setChatsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.chats.chatsList.messages.getChatsError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingChats(false);
    },
    [chatsPagination.limit, intl, me?._id],
  );

  useEffect(() => {
    getChats();
  }, [getChats]);

  useEffect(() => {
    socket.on(
      `chat-p2p-new-msg`,
      (data: {
        _id: string;
        messages: IChatMessage;
        chat: Omit<IChatState, 'messages'>;
      }) => {
        setChats(oldState => {
          const chatsWithoutUpdatedChat = oldState.filter(
            chat => chat._id !== data._id,
          );
          const updatedChat: IChatState = {
            ...data.chat,
            friend: data.chat.users.find(user => user._id !== me?._id),
            messages: data.messages,
          };

          return [updatedChat, ...chatsWithoutUpdatedChat];
        });
      },
    );

    socket.on(
      `chat-p2p-delete-msg`,
      (data: { _id: string; _message: string; doc: IChatState[] }) => {
        setChats(oldState => {
          const chatsWithUpdatedChat = oldState.map(chat => {
            if (chat._id === data._id) {
              return {
                ...chat,
                messages:
                  data.doc.length > 0 ? data.doc[0].messages : undefined,
              };
            }

            return chat;
          });

          return chatsWithUpdatedChat;
        });
      },
    );

    socket.on(`chat-p2p-read`, (data: { _id: string; _user: string }) => {
      setChats(oldState => {
        const chatsWithUpdatedChat = oldState.map(chat => {
          if (chat.messages && chat._id === data._id) {
            return {
              ...chat,
              messages: {
                ...chat.messages,
                isRead: true,
              },
            };
          }

          return chat;
        });

        return chatsWithUpdatedChat;
      });
    });

    return () => {
      socket.off(`chat-p2p-new-msg`);
      socket.off(`chat-p2p-delete-msg`);
      socket.off(`chat-p2p-read`);
    };
  }, [me?._id]);

  function renderChat(chat: IChatState): JSX.Element {
    if (!chat.messages) {
      return (
        <Chat to={`/chats/${chat.friend?._id}` || ''} key={chat._id}>
          <UserAvatar photoId={chat.friend?.photo} size={40} />
          <div>
            <p>{chat.friend?.name}</p>
          </div>
        </Chat>
      );
    }

    return (
      <Chat
        // onClick={() => {
        //   if (chat.messages?._user !== me?._id && !chat.messages?.isRead) {
        //     markAsRead();
        //   }
        // }}
        to={`/chats/${chat.friend?._id}` || ''}
        key={chat._id}
      >
        <UserAvatar photoId={chat.friend?.photo} size={40} />
        <div>
          <p>{chat.friend?.name}</p>
          <LastMessageInfoContainer $isRead={chat.messages.isRead}>
            {chat.messages?._user === me?._id && <BiCheckDouble size={18} />}
            <small>
              <b>
                {chat.messages?._user === me?._id
                  ? intl.getTranslatedText('common.meIdentifier')
                  : chat.friend?.name.split(' ')[0]}
                :{' '}
              </b>
              {chat.messages?.text
                ? chat.messages?.text
                : intl.getTranslatedText('pages.chats.chatsList.audioMessage')}
            </small>
          </LastMessageInfoContainer>
        </div>
        <div>
          <LastMessageTime
            $hasNewMessages={
              chat.messages._user !== me?._id && !chat.messages.isRead
            }
          >
            {formatMessageDate(chat.messages?.createdAt)}
          </LastMessageTime>
          {chat.messages._user !== me?._id && !chat.messages.isRead && (
            <UnreadMessagesIndicator />
          )}
        </div>
      </Chat>
    );
  }

  if (loadingChats && chats.length === 0) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.chats.chatsList.messages.loadingChats',
              )}
            </p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  if (chats.length === 0) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.chats.chatsList.messages.noChats',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      {chats.map(chat => renderChat(chat))}
      {chatsPagination.currentPage < chatsPagination.totalPages && (
        <LoadMoreChatsButton
          onClick={() => {
            if (!loadingChats) {
              getChats(chatsPagination.currentPage + 1);
            }
          }}
          disabled={loadingChats}
        >
          {!loadingChats ? (
            intl.getTranslatedText('pages.chats.chatsList.viewMoreChatsButton')
          ) : (
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          )}
        </LoadMoreChatsButton>
      )}
    </Container>
  );
};

export default ChatsList;
