const MyVersusRoomsTexts = {
  'pages.versus.myRooms.title': 'My room',

  // /* ------------ Buttons ------------ */
  'pages.versus.myRooms.submitArchiveRoomConfirmButton': 'Archive',
  'pages.versus.myRooms.submitUnarchiveRoomConfirmButton': 'Turn active',
  'pages.versus.myRooms.goToHomeLink': 'Back to home',
  'pages.versus.myRooms.goToActiveRoomsButton': 'Back to active room',
  'pages.versus.myRooms.showArchivedRoomsButton': 'Show archived rooms',
  'pages.versus.myRooms.showActiveRoomsButton': 'Show active rooms',
  /* ------------ Messages ------------ */
  'pages.versus.myRooms.messages.getRoomsError.title':
    'Unexpected error to load rooms',
  'pages.versus.myRooms.messages.getArchivedRoomsError.title':
    'Unexpected error to load hidden rooms',
  'pages.versus.myRooms.messages.submitArchiveRoomConfirm.description':
    'Do you really want to archive this room?',
  'pages.versus.myRooms.messages.submitArchiveRoomSuccess.title':
    'Room archived!',
  'pages.versus.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Do you really want to unarchive this room?',
  'pages.versus.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Room unarchive!',
  'pages.versus.myRooms.messages.noRooms':
    "You aren't in any <span>room</span> yet",
  'pages.versus.myRooms.messages.viewingArchivedRooms':
    'You are viewing your archived rooms',
  'pages.versus.myRooms.messages.noArchivedRooms':
    "You haven't any <span>room archived</span>",
};

const PublicVersusRoomsTexts = {
  'pages.versus.publicRooms.title': 'Public rooms',
  'pages.versus.publicRooms.searchInput.placeholder':
    'Search by room name, team, or user',

  /* ------------ Messages ------------ */
  'pages.versus.publicRooms.messages.viewingFindedOnSearchRooms':
    'Loading found rooms',
  'pages.versus.publicRooms.messages.noPublicRooms':
    'No public room available at <span>Versus VIP</span>',
  'pages.versus.publicRooms.messages.noRooms': 'No room found',
};

const NewVersusRoomTexts = {
  'pages.versus.newRoom.title': 'New room',
  'pages.versus.newRoom.step1': 'Room',
  'pages.versus.newRoom.step2': 'Match',
  'pages.versus.newRoom.step3': 'Accept',

  'pages.versus.newRoom.submitNewRoomConfirmButton': 'New room',

  'pages.versus.newRoom.form.roomType.validation.invalid': 'Invalid room type',
  'pages.versus.newRoom.form.roomType.validation.required':
    'Room type is required',
  'pages.versus.newRoom.form.name.validation.required': 'Room name is required',
  'pages.versus.newRoom.form.price.validation.required':
    'Room price is required',

  'pages.versus.newRoom.messages.blockPage.title':
    'Do you really want to quit this page?',
  'pages.versus.newRoom.messages.getChampionshipsError.title':
    'Unexpected error to load championships',
  'pages.versus.newRoom.messages.getMatchsOfChampionshipError.title':
    'Unexpected error to load championship games',
  'pages.versus.newRoom.messages.submitNewRoomConfirm.title':
    'Do you wish to create this room?',
  'pages.versus.newRoom.messages.submitNewRoomSuccess.title': 'Done!',
  'pages.versus.newRoom.messages.submitNewRoomSuccess.description':
    'Invite your friend and then do your game. Enjoy!',
  /* Room */
  ...{
    /* roomType - form */
    'pages.versus.newRoom.form.roomType.label': 'Room type',
    'pages.versus.newRoom.form.roomType.privateRoomTitle': 'Private room',
    'pages.versus.newRoom.form.roomType.privateRoomDescription':
      'Only your friends or invited can join',
    'pages.versus.newRoom.form.roomType.publicRoomTitle': 'Public room',
    'pages.versus.newRoom.form.roomType.publicRoomDescription':
      'Open to wide world',
    /* name - form */
    'pages.versus.newRoom.form.name.label': 'Room name',
    'pages.versus.newRoom.form.name.placeholder': 'Type a room name',
    /* price - form */
    'pages.versus.newRoom.form.price.label': 'Game price',
    'pages.versus.newRoom.form.price.placeholder': 'for example: 99',
  },
  /* Match */
  ...{
    'pages.versus.newRoom.form.selectedChampionship.label': 'Championship',
    'pages.versus.newRoom.form.selectedMatch.label': 'Match',

    'pages.versus.newRoom.form.matchInfo.date.label': 'Date and hour',
    'pages.versus.newRoom.form.matchInfo.place.label': 'Place',

    'pages.versus.newRoom.messages.selectChampionship': 'Select a championship',
    'pages.versus.newRoom.messages.loadingChampionships':
      'Loading championships...',
    'pages.versus.newRoom.messages.selectMatch': 'Select a match',
    'pages.versus.newRoom.messages.loadingMatchs':
      'Loading championship games...',
    'pages.versus.newRoom.messages.noChampionshipMatchs':
      'This championship has no <span>matches</span> available yet',
  },
};

const VersusRoomTexts = {
  'pages.versus.room.submitRejectInviteForThisRoomConfirmButton': 'Refuse',
  'pages.versus.room.submitAcceptInviteForThisRoomConfirmButton': 'Accept',
  'pages.versus.room.submitGetInOnPublicRoomConfirmButton': 'Join room',
  'pages.versus.room.submitDeleteRoomConfirmButton': 'Delete room',
  'pages.versus.room.submitLogOutForThisRoomConfirmButton': 'left the room',
  'pages.versus.room.submitRemoveOpponentConfirmButton': 'Remove player',
  'pages.versus.room.acceptInviteButton': 'Accept invite',
  'pages.versus.room.rejectInviteButton': 'refuse',
  'pages.versus.room.goToListRoomsLink': 'Back to rooms list',

  'pages.versus.room.messages.submitRejectInviteForThisRoomConfirm.title':
    'Do you really want to refuse this entry request?',
  'pages.versus.room.messages.submitAcceptInviteForThisRoomConfirm.title':
    'Would you like to join this room?',
  'pages.versus.room.messages.submitGetInOnPublicRoomConfirm.title':
    'Do you want to play here?',
  'pages.versus.room.messages.submitDeleteRoomConfirm.title':
    'Do you really want to delete this room?',
  'pages.versus.room.messages.submitDeleteRoomLoading': 'Working...',
  'pages.versus.room.messages.submitDeleteRoomSuccess.title': 'Deleted room!',
  'pages.versus.room.messages.submitLogOutForThisRoomConfirm.title':
    'Do you want to left this room?',
  'pages.versus.room.messages.submitLogOutForThisRoomLoading': 'Working...',
  'pages.versus.room.messages.submitLogOutForThisRoomSuccess.title':
    'You left!',
  'pages.versus.room.messages.submitLogOutForThisRoomSuccess.description':
    "You can't play anymore here",
  'pages.versus.room.messages.submitRemoveOpponentConfirm.title':
    'Do you really want to kick out this user?',
  'pages.versus.room.messages.submitRemoveOpponentLoading': 'Working...',
  'pages.versus.room.messages.submitRemoveOpponentSuccess.title':
    'User kicked out!',
  'pages.versus.room.messages.submitRemoveOpponentSuccess.description':
    'User was been kicked out from this room',
  'pages.versus.room.messages.inviteDescription':
    '{playerName}({playerUsername}) invite you to play. (Cost to play T¢{price})',
  'pages.versus.room.messages.itsNotPossibleToEnterTheRoom':
    'Nobody can join in this room',
  'pages.versus.room.messages.youIsNotOnRoom': "You didn't join in this room.",
  'pages.versus.room.messages.roomNotFound': '<span>Room</span> not found',
  'pages.versus.room.messages.playerIsTheFirst':
    '<strong>{playerUsername}</strong> is 1st!',

  /* ChooseOpponent */
  ...{
    'pages.versus.room.chooseOpponent.searchUserInput.placeholder':
      'Search user',

    'pages.versus.room.chooseOpponent.submitInviteUserConfirmButton.title':
      'Send invite',

    'pages.versus.room.chooseOpponent.messages.searchUserError.title':
      'Unexpected error to load user',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserConfirm.title':
      'Would you like to invite this user?',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserLoading':
      'Working...',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.title':
      'Invite sent successfully!',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.description':
      'Your room invites has been sent.',
    'pages.versus.room.chooseOpponent.messages.userNotFound':
      '<span>User</span> not found',
    'pages.versus.room.chooseOpponent.messages.waitForYourGameToBeAccept':
      'Wait until a player accept to join.',
  },

  /* Game details */
  ...{
    'pages.versus.room.gameDetails.tabs.details': 'Details',
    'pages.versus.room.gameDetails.tabs.plays': 'Plays',

    'pages.versus.room.gameDetails.adversary': 'Rival',
    'pages.versus.room.gameDetails.amount': 'Amount',
    'pages.versus.room.gameDetails.status': 'Status',
    'pages.versus.room.gameDetails.status.waitingFinish':
      'Waiting until official match ends',
    'pages.versus.room.gameDetails.status.finished': 'Official match is over.',

    'pages.versus.room.gameDetails.messages.winnerInformationIsPartial':
      'Winner information is partial.',
    'pages.versus.room.gameDetails.messages.playersDidNotScorePoints':
      '<span>No one scored</span>',
  },

  /* Lobby */
  ...{
    'pages.versus.room.lobby.amount': 'Amount',
    'pages.versus.room.lobby.adversary': 'rival',

    'pages.versus.room.lobby.startGameConfirmButton': 'Start game',

    'pages.versus.room.lobby.messages.startGameConfirm.title':
      'Would you like to start the game?',
    'pages.versus.room.lobby.messages.startGameSuccess.title':
      'Game has been started!',
    'pages.versus.room.lobby.messages.waitUserAcceptInvite':
      'Please wait while your invitation is sent.',
    'pages.versus.room.lobby.messages.waitOwnerToStartGame':
      'Please wait until owner starts the game.',
  },

  /* Play */
  ...{
    'pages.versus.room.play.tabs.plays': 'Plays',

    'pages.versus.room.play.submitNewGameConfirmButton': 'Add guess',
    'pages.versus.room.play.playButton': 'Confirm your guess!',

    'pages.versus.room.play.messages.submitNewGameWarn.title':
      'This guess was added before!',
    'pages.versus.room.play.messages.submitNewGameWarn.description':
      'Choose another. Try again!',
    'pages.versus.room.play.messages.submitNewGameConfirm.title':
      'Do you want to submit this guess?',
    'pages.versus.room.play.messages.submitNewGameSuccess.title':
      'Guess submitted!',
    'pages.versus.room.play.messages.meTurnToPlay': 'Your turn!',
    'pages.versus.room.play.messages.adversaryTurnToPlay':
      '{adversaryName} turn!', // we have a problem => Silva's turn! | Sales' turn!
  },
};

export default {
  ...MyVersusRoomsTexts,
  ...PublicVersusRoomsTexts,
  ...NewVersusRoomTexts,
  ...VersusRoomTexts,
};
