import React, { useCallback, useState, useEffect } from 'react';
import { Spin, Modal } from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  FiX,
  FiChevronUp,
  FiChevronDown,
  FiThumbsUp,
  FiThumbsDown,
} from 'react-icons/fi';
import { IoTrophy } from 'react-icons/io5';
import Countdown from 'react-countdown';
import * as dateFns from 'date-fns';
import { useTransition } from 'react-spring';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import CustomAntButton from '../CustomAntButton';

import api from '../../services/api';
import socket from '../../services/socket';
import { formatMoney } from '../../utils/formatters';

import {
  Container,
  BackgroundMask,
  PushGameContainer,
  Header,
  GamePrice,
  MatchInfoContainer,
  TeamsContainer,
  Team,
  AccumulatedValueContainer,
  MatchInfoDetailsContainer,
  ButtonsContainer,
  ResponseButton,
  RestTimerContainer,
  SubmittingContainer,
} from './styles';

export interface ITeam {
  _id: string;
  name: string;
  abbrev?: string;
  image: string;
}

interface IPushGameState {
  _id: string;
  amountReceived: number;
  answer: boolean | null;
  friendlyId: string;
  from: string;
  isWinner: boolean;
  status: boolean;
  createdAt: string;
  _push: {
    _id: string;
    question: string;
    price: number;
    amount: number;
    buttonType: number;
    answer: boolean | null;
    createdAt: string;
    friendlyId: string;
    from: string;
    isFinished: boolean;
    status: boolean;
    _createdBy: string;
    _game: {
      _id: string;
      info: {
        place: string;
        date: string;
      };
      _champ: {
        _id: string;
        name: string;
      };
      local: ITeam;
      away: ITeam;
    };
  };
}

const { confirm } = Modal;
const { addSeconds, parseISO, format } = dateFns;

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const PushGameModal: React.FC = () => {
  const intl = useIntl();

  const [showMoreDetailsOfMatch, setShowMoreDetailsOfMatch] = useState(false);
  const [pushGame, setPushGame] = useState<IPushGameState | null>(null);

  const [submitting, setSubmitting] = useState(false);

  const transitions = useTransition(!!pushGame, null, {
    from: {
      bottom: '-100%',
      opacity: 0,
    },
    enter: {
      bottom: '15%',
      opacity: 1,
    },
    leave: {
      bottom: '-100%',
      opacity: 0,
    },
  });

  const closePushGameModal = useCallback(() => {
    setShowMoreDetailsOfMatch(false);
    setPushGame(null);
  }, []);

  useEffect(() => {
    window.addEventListener('blur', closePushGameModal);
    socket.on(`push-game`, async (data: { stmt: IPushGameState }) => {
      if (data.stmt) {
        setPushGame(() => {
          const pushGameWithAbbrevOnTeams = { ...data.stmt };
          pushGameWithAbbrevOnTeams._push._game.local.abbrev =
            pushGameWithAbbrevOnTeams._push._game.local.name
              .substring(0, 3)
              .toUpperCase();
          pushGameWithAbbrevOnTeams._push._game.away.abbrev =
            pushGameWithAbbrevOnTeams._push._game.away.name
              .substring(0, 3)
              .toUpperCase();

          return pushGameWithAbbrevOnTeams;
        });
      }
    });
    // if (coachFriendRoom?._game._id) {
    //   socket.on(`coach-end:${coachFriendRoom?._game._id}`, () => {
    //     getGamesOfRoom();
    //   });
    // }
    return () => {
      socket.off(`push-game`);
      window.removeEventListener('blur', closePushGameModal);
      // if (coachFriendRoom?._game._id) {
      //   socket.off(`coach-end:${coachFriendRoom?._game._id}`);
      // }
    };
  }, [closePushGameModal]);

  const handleClosePushGameModal = useCallback(async () => {
    if (submitting) {
      return;
    }

    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'components.pushGameModal.messages.closePushGameModalConfirm.title',
        ),
        icon: <ExclamationCircleOutlined />,
        content: intl.getTranslatedText('common.messages.actionCannotBeUndone'),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'components.pushGameModal.messages.closePushGameModalConfirm.confirmButton',
        ),
        okButtonProps: {
          danger: true,
        },
        onOk() {
          resolve(true);
        },
      });
    });

    setShowMoreDetailsOfMatch(false);
    setPushGame(null);
  }, [intl, submitting]);

  const handleSubmit = useCallback(
    async (answer: boolean) => {
      try {
        const body = {
          answer,
          // endGame: true,
        };

        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'components.pushGameModal.messages.submitPushGameConfirm.title',
            ),
            icon: <CheckCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'components.pushGameModal.messages.submitPushGameConfirm.confirmButton',
            ),
            onOk() {
              resolve(true);
            },
          });
        });

        setSubmitting(true);
        await api.put(`/api/push-played/${pushGame?._id}`, body);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'components.pushGameModal.messages.submitPushGameSuccess.title',
          ),
        });

        setSubmitting(false);
        setShowMoreDetailsOfMatch(false);
        setPushGame(null);
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
        setSubmitting(false);
      }
    },
    [intl, pushGame?._id],
  );

  if (pushGame === null) {
    return <></>;
  }

  return (
    <Container>
      <BackgroundMask onClick={() => handleClosePushGameModal()} />
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <PushGameContainer key={key} style={props}>
              <Header>
                <GamePrice>T¢{formatMoney(pushGame._push.price)}</GamePrice>
                {!submitting && (
                  <CustomAntButton
                    type="text"
                    useCustomIcon
                    icon={<FiX size={20} />}
                    onClick={() => handleClosePushGameModal()}
                  />
                )}
              </Header>
              <MatchInfoContainer
                onClick={() => setShowMoreDetailsOfMatch(oldState => !oldState)}
              >
                <TeamsContainer>
                  <div>
                    <Team>
                      <div>
                        <img
                          src={pushGame._push._game.local.image}
                          alt={pushGame._push._game.local.name}
                        />
                      </div>
                      <strong>{pushGame._push._game.local.abbrev}</strong>
                    </Team>
                    <h6>VS</h6>
                    <Team>
                      <div>
                        <img
                          src={pushGame._push._game.away.image}
                          alt={pushGame._push._game.away.name}
                        />
                      </div>
                      <strong>{pushGame._push._game.away.abbrev}</strong>
                    </Team>
                  </div>
                  <small>{pushGame._push._game._champ.name}</small>
                </TeamsContainer>
                <AccumulatedValueContainer>
                  <IoTrophy size={18} />
                  <small>T¢{formatMoney(pushGame._push.amount)}</small>
                </AccumulatedValueContainer>
                {showMoreDetailsOfMatch && (
                  <MatchInfoDetailsContainer>
                    <div>
                      <div>
                        <small>
                          {intl.getTranslatedText(
                            'components.pushGameModal.matchInfoDetails.date',
                          )}
                        </small>
                        <small>
                          {format(
                            parseISO(pushGame._push._game.info.date),
                            intl.getTranslatedText(
                              'common.formatDates.fullDate',
                            ),
                            {
                              locale: intl.getDatefnsLocale(),
                            },
                          )}
                        </small>
                      </div>
                      <div>
                        <small>
                          {intl.getTranslatedText(
                            'components.pushGameModal.matchInfoDetails.place',
                          )}
                        </small>
                        <small>{pushGame._push._game.info.place}</small>
                      </div>
                    </div>
                  </MatchInfoDetailsContainer>
                )}
                <div>
                  {!showMoreDetailsOfMatch ? (
                    <>
                      <FiChevronDown size={16} />
                      <small>
                        {intl.getTranslatedText(
                          'components.pushGameModal.showMoreDetailsOfMatchButton',
                        )}
                      </small>
                    </>
                  ) : (
                    <>
                      <FiChevronUp size={16} />
                      <small>
                        {intl.getTranslatedText(
                          'components.pushGameModal.showLessDetailsOfMatchButton',
                        )}
                      </small>
                    </>
                  )}
                </div>
              </MatchInfoContainer>
              <p>{pushGame._push.question}</p>
              {!submitting ? (
                <>
                  <ButtonsContainer>
                    {/* <CustomAntButton
                      onClick={() => handleSubmit(false)}
                      type="primary"
                      danger
                    >
                      Não
                    </CustomAntButton> */}
                    {(pushGame._push.buttonType === 0 ||
                      pushGame._push.buttonType === 2) && (
                      <ResponseButton
                        $typeOfButton={false}
                        onClick={() => handleSubmit(false)}
                      >
                        <FiThumbsDown size={32} />
                      </ResponseButton>
                    )}
                    {(pushGame._push.buttonType === 0 ||
                      pushGame._push.buttonType === 1) && (
                      <ResponseButton
                        $typeOfButton
                        onClick={() => handleSubmit(true)}
                      >
                        <FiThumbsUp size={32} />
                      </ResponseButton>
                    )}
                    {/* <CustomAntButton
                      type="primary"
                      onClick={() => handleSubmit(true)}
                      // disabled={!eneableNextStepButton(formik)}
                    >
                      Sim
                    </CustomAntButton> */}
                  </ButtonsContainer>
                  <>
                    <Countdown
                      // date={parseISO(coachFriendRoom._game.info.date)}
                      date={addSeconds(parseISO(pushGame.createdAt), 30)}
                      renderer={({ seconds, completed }) => {
                        if (completed) {
                          return <></>;
                        }

                        return (
                          <RestTimerContainer>
                            <p>
                              {intl.getTranslatedText(
                                'components.pushGameModal.countdown',
                                {
                                  seconds,
                                },
                              )}
                            </p>
                          </RestTimerContainer>
                        );
                      }}
                      onComplete={() => {
                        if (submitting) {
                          return;
                        }
                        setShowMoreDetailsOfMatch(false);
                        setPushGame(null);
                      }}
                    />
                  </>
                </>
              ) : (
                <SubmittingContainer>
                  <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
                  {/* <p>Carregando...</p> */}
                </SubmittingContainer>
              )}
            </PushGameContainer>
          ),
      )}
    </Container>
  );
};

export default PushGameModal;
