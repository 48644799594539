import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, Modal } from 'antd';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FiChevronLeft } from 'react-icons/fi';
import { parseISO } from 'date-fns';
import Countdown from 'react-countdown';

import { IoTrophy } from 'react-icons/io5';
import { CheckCircleOutlined } from '@ant-design/icons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import CustomAntButton from '../../../../components/CustomAntButton';

import Ranking from './Ranking';
import Results from './Results';
import MyGames from './MyGames';

import {
  Container,
  Header,
  AccumulatedValueContainer,
  RestTimerContainer,
  Content,
  ContentTab,
} from './styles';
import api from '../../../../services/api';
import socket from '../../../../services/socket';
import { formatMoney } from '../../../../utils/formatters';

import Loading from '../../../../components/Loading';
import NotFound from '../../../../components/NotFound';
import { showToast } from '../../../../hooks/showToast';
import { useAuth } from '../../../../context/AuthContext';
import { useChat } from '../../../../context/ChatContext';
import { useIntl } from '../../../../context/IntlContext';

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
  card_game_id: string;
}

export interface IRankingPosition {
  _id: string;
  totalScore: number;
  friendlyId: string;
  createdAt: string;
  _user: {
    _id: string;
    isMe: boolean;
    username: string;
    name: string;
    photo?: {
      _id: string;
      filename: string;
    };
  };
}

export interface IRankingSearchUser {
  _id: string;
  isMe: boolean;
  username: string;
  name: string;
  photo?: {
    _id: string;
    filename: string;
  };
  games: Omit<IRankingPosition, '_user'>[];
}

export interface IMyGame {
  _id: string;
  _card: string;
  status: boolean;
  name: string;
  createdAt: string;
}

interface IMatchPlayer {
  _id: string;
  player: string;
  playerName: string;
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export interface IMatch {
  _id: string;
  info: {
    place: string;
    date: string;
  };
  local: ITeam;
  away: ITeam;
  finished: boolean;
  localScore: number;
  awayScore: number;
  localPlayers: IMatchPlayer[];
  awayPlayers: IMatchPlayer[];
}

interface ICardGameState {
  _id: string;
  name: string;
  startDate: string;
  championshipName: string;
  cardType: 'new' | 'running' | 'finished';
  matchs: IMatch[];
  price: number;
  amount: number;
  gameCount: number;
}

interface IGameResponse {
  game: IMatch;
}

interface ICardGameResponse {
  _id: string;
  name: string;
  startDate: string;
  cardType: 'new' | 'running' | 'finished';
  price: number;
  amount: number;
  gameCount: number;
  _champ: {
    _id: string;
    name: string;
  };
  games: IGameResponse[];
}

const { confirm } = Modal;

const CardGame: React.FC = () => {
  const { url, params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();
  const { setChat } = useChat();

  const [cardGame, setCardGame] = useState<ICardGameState | null>(null);
  const [loadingCard, setLoadingCard] = useState(true);

  const [ranking, setRanking] = useState<IRankingPosition[]>([]);
  const [loadingRanking, setLoadingRanking] = useState(true);
  const [paginationRanking, setPaginationRanking] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [findedOnSearchUsers, setFindedOnSearchUsers] = useState<
    IRankingSearchUser[]
  >([]);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [paginationSearchUser, setPaginationSearchUser] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  // const [showingUserFinded, setShowingUserFinded] = useState(false);
  const [userSelectedOnSearch, setUserSelectedOnSearch] =
    useState<IRankingSearchUser | null>(null);
  const [loadingUserSelectedOnSearch, setLoadingUserSelectedOnSearch] =
    useState(false);
  const [
    paginationGamesUserSelectedOnSearch,
    setPaginationGamesUserSelectedOnSearch,
  ] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [myGames, setMyGames] = useState<IMyGame[]>([]);
  const [loadingMyGames, setLoadingMyGames] = useState(false);
  const [myGamesPagination, setMyGamesPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [currentView, setCurrentView] = useState<
    'ranking' | 'results' | 'myGames'
  >('myGames');

  const getRanking = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingRanking(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking-card', {
          params: {
            card: params.card_game_id,
            limit: 10,
            page,
          },
        });

        if (page === 1) {
          setRanking([
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        } else {
          setRanking(oldRanking => [
            ...oldRanking,
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        }

        setPaginationRanking({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.championship.cardGame.messages.getRankingError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingRanking(false);
    },
    [intl, me?._id, params.card_game_id],
  );

  const searchUserOnRanking = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingSearchUser[];
          page: number;
          pages: number;
        }>('/api/user', {
          params: {
            champ: params.id,
            search,
            page,
            limit: 6,
          },
        });

        setFindedOnSearchUsers(
          data.docs.map(user => {
            return {
              ...user,
              isMe: me?._id === user?._id,
              games: [],
            };
          }),
        );

        setPaginationSearchUser({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingSearch(false);
    },
    [intl, me?._id, params.id],
  );

  const handleGetUserSelected = useCallback(
    async (value: string, page = 1): Promise<void> => {
      if (page === 1) {
        setUserSelectedOnSearch(null);
        setSearchUserValue(value);
      }

      const user = findedOnSearchUsers.find(
        findedUser => findedUser.username === value,
      );

      if (!user) return;

      setLoadingUserSelectedOnSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking-card', {
          params: {
            card: params.card_game_id,
            limit: 6,
            user: user._id,
            page,
          },
        });

        if (page === 1) {
          user.games = data.docs;
        } else {
          user.games = [...user.games, ...data.docs];
        }

        setUserSelectedOnSearch(user);
        setPaginationGamesUserSelectedOnSearch({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingUserSelectedOnSearch(false);
    },
    [findedOnSearchUsers, intl, params.card_game_id],
  );

  const getMyGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingMyGames(true);
      try {
        const { data } = await api.get<{
          docs: IMyGame[];
          page: number;
          pages: number;
        }>('/api/played-card', {
          params: {
            card: params.card_game_id,
            limit: 6,
            page,
          },
        });

        setMyGames(oldMyGames => [
          ...oldMyGames,
          ...data.docs.map(playedCardGameResponse => ({
            _id: playedCardGameResponse._id,
            _card: playedCardGameResponse._card,
            name: playedCardGameResponse.name,
            status: playedCardGameResponse.status,
            createdAt: playedCardGameResponse.createdAt,
          })),
        ]);
        setMyGamesPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.championship.cardGame.messages.getMyGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMyGames(false);
    },
    [intl, params.card_game_id],
  );

  const getCardGame = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<ICardGameResponse>(
        `/api/card-game/${params.card_game_id}`,
      );

      const matchs: IMatch[] = data.games.map(({ game }) => {
        return {
          _id: game._id,
          info: game.info,
          local: {
            _id: game.local._id,
            name: game.local.name,
            abbrev: game.local.name.substring(0, 3).toUpperCase(),
            image: game.local.image,
          },
          away: {
            _id: game.away._id,
            name: game.away.name,
            abbrev: game.away.name.substring(0, 3).toUpperCase(),
            image: game.away.image,
          },
          finished: game.finished,
          localScore: game.localScore,
          awayScore: game.awayScore,
          localPlayers: game.localPlayers,
          awayPlayers: game.awayPlayers,
        };
      });

      setCardGame({
        _id: data._id,
        name: data.name,
        startDate: data.startDate,
        championshipName: data._champ.name,
        cardType: data.cardType,
        matchs,
        price: data.price,
        amount: data.amount,
        gameCount: data.gameCount,
      });

      if (data.cardType !== 'new') {
        setCurrentView('ranking');
        getRanking();
      }
      setChat({
        _resourceId: data._id,
        name: data.name,
        from: intl.getTranslatedText('common.gamesTitles.cardGames'),
        fromCode: 'cardGames',
      });
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: intl.getTranslatedText('common.errors.unexpectedError.description'),
      // });
    }
    setLoadingCard(false);
  }, [getRanking, intl, params.card_game_id, setChat]);

  useEffect(() => {
    getCardGame();

    return () => {
      setChat(null);
    };
  }, [getCardGame, setChat]);

  useEffect(() => {
    socket.on(`card:${params.card_game_id}`, (data: { status: 'new_game' }) => {
      if (data.status === 'new_game') {
        setCardGame(oldCardGame => {
          if (oldCardGame) {
            return {
              ...oldCardGame,
              gameCount: oldCardGame.gameCount + 1,
              amount: oldCardGame.amount + oldCardGame.price,
            };
          }
          return null;
        });
      }
    });

    socket.on(`card-end:${params.card_game_id}`, () => {
      getCardGame();
    });

    return () => {
      socket.off(`card:${params.card_game_id}`);
      socket.off(`card-end:${params.card_game_id}`);
    };
  }, [getCardGame, params.card_game_id]);

  useEffect(() => {
    if (currentView === 'myGames' && myGames.length === 0) {
      getMyGames();
    }
  }, [currentView, getMyGames, myGames.length]);

  const usersSearchDebounced = useMemo(() => {
    return _.debounce(searchUserOnRanking, 500);
  }, [searchUserOnRanking]);

  const handleSearchUser = useCallback(
    (value: string) => {
      usersSearchDebounced.cancel();

      // if (!searchUserValue) {
      //   // setShowingUserFinded(true);
      // } else if (!value.length) {
      // }
      if (userSelectedOnSearch) {
        setUserSelectedOnSearch(null);
      }
      setSearchUserValue(value);

      if (value.length > 3) {
        setLoadingSearch(true);
        usersSearchDebounced(value);
      } else {
        setLoadingSearch(false);
        setFindedOnSearchUsers([]);
      }
    },
    [userSelectedOnSearch, usersSearchDebounced],
  );

  const handleGoToPlay = useCallback(async () => {
    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'pages.championship.cardGame.messages.goToPlayConfirm.title',
        ),
        icon: <CheckCircleOutlined />,
        content: intl.getTranslatedText(
          'pages.championship.cardGame.messages.goToPlayConfirm.description',
          {
            price: cardGame?.price ? formatMoney(cardGame.price) : '',
          },
        ),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.championship.cardGame.goToPlayConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
      });
    });

    history.push(`${url}/play`);
  }, [cardGame?.price, history, intl, url]);

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs />
        <Loading />
      </Container>
    );
  }

  if (!cardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.championship.cardGame.messages.noCardGame',
          )}
        </h4>
        <Link to={`/championships/${params.id}`}>
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.championship.cardGame.goToChampionshipDetails',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        {cardGame.cardType === 'new' && (
          <Countdown
            date={parseISO(cardGame.startDate)}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <></>;
              }

              return (
                <RestTimerContainer>
                  <div>
                    <h6>
                      {days.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.days')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {hours.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.hours')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {minutes.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.minutes')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {seconds.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.seconds')}
                    </small>
                  </div>
                </RestTimerContainer>
              );
            }}
            onComplete={() => {
              setLoadingCard(true);
              getCardGame();
            }}
          />
        )}
        <div>
          <h6>{cardGame.name}</h6>
          <p>{cardGame.championshipName}</p>
        </div>
        <div>
          <AccumulatedValueContainer>
            <IoTrophy size={18} />
            <small>T¢{formatMoney(cardGame.amount)}</small>
          </AccumulatedValueContainer>
          {cardGame.cardType === 'new' && (
            <CustomAntButton onClick={handleGoToPlay} type="primary">
              {intl.getTranslatedText(
                'pages.championship.cardGame.playNowButton',
              )}
            </CustomAntButton>
            // <Link to={`${url}/play`}>
            // </Link>
          )}
        </div>
      </Header>
      <Content>
        <div>
          {cardGame.cardType === 'new' && (
            <CustomAntButton onClick={handleGoToPlay} type="primary">
              {intl.getTranslatedText(
                'pages.championship.cardGame.playNowButton',
              )}
            </CustomAntButton>
            // <Link to={`${url}/play`}>
            // </Link>
          )}
        </div>
        <Tabs
          activeKey={currentView}
          onTabClick={tabKey => {
            if (
              tabKey === 'ranking' ||
              tabKey === 'results' ||
              tabKey === 'myGames'
            ) {
              setCurrentView(tabKey);
            }
          }}
          centered
        >
          {cardGame.cardType !== 'new' && (
            <>
              <TabPane
                tab={intl.getTranslatedText(
                  'pages.championship.cardGame.tabs.ranking',
                )}
                key="ranking"
              >
                <ContentTab>
                  <Ranking
                    ranking={ranking}
                    loading={loadingRanking}
                    cardType={cardGame.cardType}
                    loadMoreRanking={() =>
                      getRanking(paginationRanking.currentPage + 1)
                    }
                    isOnLastPageOfRanking={
                      paginationRanking.currentPage ===
                      paginationRanking.totalPages
                    }
                    handleSearchUser={handleSearchUser}
                    searchUserValue={searchUserValue}
                    loadingSearchUsers={loadingSearch}
                    findedOnSearchUsers={findedOnSearchUsers}
                    isOnLastPageOfSearchUsers={
                      paginationSearchUser.currentPage ===
                      paginationSearchUser.totalPages
                    }
                    loadMoreSearchUsers={() =>
                      searchUserOnRanking(
                        searchUserValue,
                        paginationSearchUser.currentPage + 1,
                      )
                    }
                    handleGetUserSelected={handleGetUserSelected}
                    userSelectedOnSearch={userSelectedOnSearch}
                    isOnLastPageOfGamesUserSelectedOnSearch={
                      paginationGamesUserSelectedOnSearch.currentPage ===
                      paginationGamesUserSelectedOnSearch.totalPages
                    }
                    loadMoreGamesUserSelectedOnSearch={() =>
                      handleGetUserSelected(
                        userSelectedOnSearch?.username || '',
                        paginationGamesUserSelectedOnSearch.currentPage + 1,
                      )
                    }
                    loadingUserSelectedOnSearch={loadingUserSelectedOnSearch}
                  />
                </ContentTab>
              </TabPane>
              <TabPane
                tab={intl.getTranslatedText(
                  'pages.championship.cardGame.tabs.results',
                )}
                key="results"
              >
                <ContentTab>
                  <Results
                    matchs={cardGame.matchs}
                    cardType={cardGame.cardType}
                  />
                </ContentTab>
              </TabPane>
            </>
          )}
          <TabPane
            tab={intl.getTranslatedText(
              'pages.championship.cardGame.tabs.myGames',
            )}
            key="myGames"
          >
            <ContentTab>
              <MyGames
                myGames={myGames}
                loading={loadingMyGames}
                loadMore={() => getMyGames(myGamesPagination.currentPage + 1)}
                isOnLastPage={
                  myGamesPagination.currentPage === myGamesPagination.totalPages
                }
              />
            </ContentTab>
          </TabPane>
        </Tabs>
      </Content>
    </Container>
  );
};

export default CardGame;
