import CustomAntButton from '@/components/CustomAntButton';
import { useIntl } from '@/context/IntlContext';
import React from 'react';

import { Container } from './styles';

interface IConfirmActionProps {
  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  danger?: boolean;
  onCancel(): void;
  onConfirm(): void;
}

const ConfirmAction: React.FC<IConfirmActionProps> = ({
  description,
  cancelButtonText,
  confirmButtonText,
  danger,
  onCancel,
  onConfirm,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <p>{description || 'Deseja realmente prosseguir?'}</p>
      <div>
        <CustomAntButton type="default" onClick={() => onCancel()}>
          {cancelButtonText || intl.getTranslatedText('common.buttons.no')}
        </CustomAntButton>
        <CustomAntButton
          type="primary"
          danger={danger}
          onClick={() => onConfirm()}
        >
          {confirmButtonText || intl.getTranslatedText('common.buttons.yes')}
        </CustomAntButton>
      </div>
    </Container>
  );
};

export default ConfirmAction;
