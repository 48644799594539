import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  ChangeEvent,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import {
  Formik,
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import logoImg from '../../assets/logo-vertical.svg';

import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import CustomAntButton from '../../components/CustomAntButton';

import api from '../../services/api';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  SelectImageButton,
} from './styles';
import { validateCpf } from '../../utils/validate';
import Radio from '../../components/Radio';

interface ISignUpFormData {
  name: string;
  username: string;
  docNumber: string;
  gender: 'M' | 'F' | 'O' | string;
  email: string;
  password: string;
  confirmPassword?: string;
  phone: string;
  photo: {
    file: Blob | null;
    previewUrl: string;
  };
}

const SignUp: React.FC = () => {
  const { getTranslatedText, getTranslatedTextWithHTML } = useIntl();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<ISignUpFormData>>(null);

  const signUpFormData: ISignUpFormData = {
    name: '',
    username: '',
    docNumber: '',
    gender: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    photo: {
      file: null,
      previewUrl: '',
    },
  };
  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState({
    checkingSomeField: false,
    username: false,
    email: false,
    docNumber: false,
  });
  const [focusedField, setFocusedField] = useState('');
  const [fieldsAvailables, setFieldsAvailables] = useState({
    username: true,
    email: true,
    docNumber: true,
  });

  // const showToast = useToast();

  const checkIfFieldIsAvailable = useCallback(
    async (
      value: string,
      fieldName: string,
      resolve: (fieldAvailable: boolean) => void,
    ): Promise<void> => {
      const params = {
        username: fieldName === 'username' ? value : undefined,
        email: fieldName === 'email' ? value : undefined,
        docNumber: fieldName === 'docNumber' ? value : undefined,
      };

      try {
        setLoadingsOfAsyncValidations({
          checkingSomeField: true,
          username: !!(fieldName === 'username'),
          email: !!(fieldName === 'email'),
          docNumber: !!(fieldName === 'docNumber'),
        });
        const response = await api.get('/api/account/checker', {
          params,
        });

        resolve(!response.data.length);
        setLoadingsOfAsyncValidations({
          checkingSomeField: false,
          username: false,
          email: false,
          docNumber: false,
        });
      } catch (error) {
        setLoadingsOfAsyncValidations({
          checkingSomeField: false,
          username: false,
          email: false,
          docNumber: false,
        });
        showToast({
          type: 'error',
          title: getTranslatedText('common.errors.unexpectedError.title'),
          description: getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [getTranslatedText],
  );

  function handleSelectFile(e: ChangeEvent<HTMLInputElement>): void {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        formikRef.current?.setFieldValue('photo', {
          file: e.target.files && e.target.files[0],
          previewUrl: reader.result,
        }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const usernameValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const docNumberValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const handleSubmit = useCallback(
    async (data: ISignUpFormData) => {
      /* actions: FormikHelpers<ISignUpFormData> */
      const body = {
        name: data.name,
        username: data.username,
        email: data.email,
        docNumber: data.docNumber.replace(/\./g, '').replace(/-/g, ''),
        gender: data.gender,
        password: data.password,
        phones: [
          data.phone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
        ],
        photo: null,
      };

      if (data.photo.file) {
        const formData = new FormData();
        formData.append('file', data.photo.file);
        formData.append('from', 'userAvatar');

        try {
          const { data: responseData } = await api.post(
            '/api/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );

          body.photo = responseData._id;
        } catch (error) {
          showToast({
            type: 'error',
            title: getTranslatedText(
              'pages.signUp.messages.signUpSubmitAvatarError.title',
            ),
            description: getTranslatedText(
              'pages.signUp.messages.signUpSubmitAvatarError.description',
            ),
          });

          return;
        }
      }

      try {
        await api.post('/api/user', body);

        showToast({
          type: 'success',
          title: getTranslatedText(
            'pages.signUp.messages.signUpSubmitSuccess.title',
          ),
          description: getTranslatedText(
            'pages.signUp.messages.signUpSubmitSuccess.description',
          ),
        });

        history.push('/');
      } catch (err) {
        showToast({
          type: 'error',
          title: getTranslatedText(
            'pages.signUp.messages.signUpSubmitError.title',
          ),
          description: getTranslatedText(
            'pages.signUp.messages.signUpSubmitError.description',
          ),
        });
      }
    },
    [getTranslatedText, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Titan369" />

          <Link to="/">
            <FiArrowLeft />
            {getTranslatedText('pages.signUp.links.signInLink')}
          </Link>

          <Formik
            innerRef={formikRef}
            initialValues={signUpFormData}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.signUp.form.username.validation.required',
                  ),
                )
                .test(
                  'usernameUnavailable',
                  getTranslatedText(
                    'pages.signUp.form.username.validation.unavailable',
                  ),
                  function validate(value) {
                    usernameValidationDebounced.cancel();
                    if (focusedField === 'username') {
                      if (!value) {
                        return true;
                      }

                      return new Promise(resolve =>
                        usernameValidationDebounced(
                          value,
                          'username',
                          fieldAvailable => {
                            resolve(fieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              username: fieldAvailable,
                            });
                          },
                        ),
                      );
                    }
                    return fieldsAvailables.username;
                  },
                ),
              email: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.signUp.form.email.validation.required',
                  ),
                )
                .email(
                  getTranslatedText(
                    'pages.signUp.form.email.validation.invalid',
                  ),
                )
                .test(
                  'emailUnavailable',
                  getTranslatedText(
                    'pages.signUp.form.email.validation.unavailable',
                  ),
                  async function validate(value) {
                    emailValidationDebounced.cancel();
                    if (focusedField === 'email') {
                      if (
                        !value ||
                        !(await Yup.string().email().isValid(value))
                      ) {
                        return true;
                      }

                      return new Promise(resolve =>
                        emailValidationDebounced(
                          value,
                          'email',
                          fieldAvailable => {
                            resolve(fieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              email: fieldAvailable,
                            });
                          },
                        ),
                      );
                    }
                    return fieldsAvailables.email;
                  },
                ),
              password: Yup.string()
                .min(
                  6,
                  getTranslatedText(
                    'pages.signUp.form.password.validation.min',
                  ),
                )
                .required(
                  getTranslatedText(
                    'pages.signUp.form.password.validation.required',
                  ),
                ),
              confirmPassword: Yup.string().when(
                'password',
                (password: string, field: Yup.StringSchema) => {
                  return password
                    ? field
                        .required(
                          getTranslatedText(
                            'pages.signUp.form.confirmPassword.validation.required',
                          ),
                        )
                        .oneOf(
                          [Yup.ref('password')],
                          getTranslatedText(
                            'pages.signUp.form.confirmPassword.validation.incorrect',
                          ),
                        )
                    : field;
                },
              ),
              name: Yup.string().required(
                getTranslatedText('pages.signUp.form.name.validation.required'),
              ),
              gender: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.signUp.form.gender.validation.required',
                  ),
                )
                .oneOf(
                  ['M', 'F', 'O'],
                  getTranslatedText(
                    'pages.signUp.form.gender.validation.invalid',
                  ),
                ),
              docNumber: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.signUp.form.docNumber.validation.required',
                  ),
                )
                .transform(value => value.replace(/\./g, '').replace(/-/g, ''))
                .test(
                  'cpf',
                  getTranslatedText(
                    'pages.signUp.form.docNumber.validation.invalid',
                  ),
                  value => {
                    const cpfValid = validateCpf(value);

                    if (cpfValid === 'valid') {
                      return true;
                    }
                    return false;
                  },
                )
                .test(
                  'docNumberUnavailable',
                  getTranslatedText(
                    'pages.signUp.form.docNumber.validation.unavailable',
                  ),
                  function validate(value) {
                    docNumberValidationDebounced.cancel();
                    if (focusedField === 'docNumber') {
                      if (!value || validateCpf(value) !== 'valid') {
                        return true;
                      }

                      return new Promise(resolve =>
                        docNumberValidationDebounced(
                          value,
                          'docNumber',
                          fieldAvailable => {
                            resolve(fieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              docNumber: fieldAvailable,
                            });
                          },
                        ),
                      );
                    }
                    return fieldsAvailables.docNumber;
                  },
                ),
              phone: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.signUp.form.phone.validation.required',
                  ),
                )
                .transform(value =>
                  value.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
                )
                .matches(/[0-9]{11}/, {
                  excludeEmptyString: true,
                  message: getTranslatedText(
                    'pages.signUp.form.phone.validation.invalid',
                  ),
                }),
              photo: Yup.object().shape({
                previewUrl: Yup.string().required(
                  getTranslatedText(
                    'pages.signUp.form.photo.validation.required',
                  ),
                ),
              }),
            })}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!loadingsOfAsyncValidations.checkingSomeField) {
                    if (Object.entries(formikProps.errors).length > 0) {
                      showToast({
                        type: 'warn',
                        title: getTranslatedText(
                          'pages.signUp.messages.signUpValidationFailure.title',
                        ),
                        description: getTranslatedText(
                          'pages.signUp.messages.signUpValidationFailure.description',
                        ),
                      });
                    }
                    formikProps.handleSubmit(e);
                  }
                }}
              >
                <h5>{getTranslatedText('pages.signUp.title')}</h5>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={inputRef}
                  accept="image/*"
                  onChange={handleSelectFile}
                />
                <SelectImageButton
                  type="button"
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  error={
                    !!formikProps.touched.photo?.previewUrl &&
                    !!formikProps.errors.photo?.previewUrl
                  }
                >
                  {formikProps.values.photo.previewUrl ? (
                    <>
                      <img
                        src={formikProps.values.photo.previewUrl}
                        alt="User"
                      />
                      <p>
                        {getTranslatedText(
                          'pages.signUp.form.photo.changeImageButton',
                        )}
                      </p>
                    </>
                  ) : (
                    <p>
                      {getTranslatedTextWithHTML(
                        'pages.signUp.form.photo.selectImageButton',
                      )}
                    </p>
                  )}
                </SelectImageButton>
                <FastField name="name">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.name.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.name.placeholder',
                        )}
                        id="SignInName"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('name')}
                      />
                    );
                  }}
                </FastField>
                <Field name="username">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.username.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.username.placeholder',
                        )}
                        id="SignUpUsername"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!loadingsOfAsyncValidations.username}
                        onFocus={() => setFocusedField('username')}
                        onChange={e => {
                          e.target.value = e.target.value
                            .replace(/\s+/g, '')
                            .toLowerCase();
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </Field>
                <Field name="docNumber">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <InputMask
                        {...field}
                        mask="999.999.999-99"
                        label={getTranslatedText(
                          'pages.signUp.form.docNumber.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.docNumber.placeholder',
                        )}
                        id="SignUpDocNumber"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!loadingsOfAsyncValidations?.docNumber}
                        onFocus={() => setFocusedField('docNumber')}
                      />
                    );
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.email.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.email.placeholder',
                        )}
                        id="SignUpEmail"
                        type="email"
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!loadingsOfAsyncValidations.email}
                        onFocus={() => setFocusedField('email')}
                      />
                    );
                  }}
                </Field>
                <Field name="gender">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Radio
                        {...field}
                        items={[
                          {
                            value: 'M',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.male',
                            ),
                          },
                          {
                            value: 'F',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.female',
                            ),
                          },
                          {
                            value: 'O',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.other',
                            ),
                          },
                        ]}
                        label={getTranslatedText(
                          'pages.signUp.form.gender.label',
                        )}
                        error={meta?.touched && meta?.error && meta?.error}
                        onChange={e => {
                          if (focusedField !== 'gender') {
                            setFocusedField('gender');
                          }
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </Field>
                <FastField name="password">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.password.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.password.placeholder',
                        )}
                        id="SignInPassword"
                        type="password"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('password')}
                      />
                    );
                  }}
                </FastField>
                <FastField name="confirmPassword">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.confirmPassword.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.confirmPassword.placeholder',
                        )}
                        id="SignInConfirmPassword"
                        type="password"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('confirmPassword')}
                      />
                    );
                  }}
                </FastField>
                <FastField name="phone">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <InputMask
                        {...field}
                        mask="(99)99999-9999"
                        id="SignUpPhone"
                        label={getTranslatedText(
                          'pages.signUp.form.phone.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.phone.placeholder',
                        )}
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('phone')}
                      />
                    );
                  }}
                </FastField>
                <CustomAntButton
                  type="primary"
                  htmlType="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {!formikProps.isSubmitting
                    ? getTranslatedText('pages.signUp.form.submitButton')
                    : getTranslatedText(
                        'pages.signUp.form.submitButtonLoading',
                      )}
                </CustomAntButton>
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
