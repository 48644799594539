import styled from 'styled-components';
import { rgba, shade } from 'polished';

interface IScrollButtonProps {
  height: number;
}

export const Container = styled.div`
  ul {
    display: flex;
    margin-top: 24px;
    margin-bottom: 0;
    overflow-x: auto;
    position: relative;

    &::after,
    &::before {
      content: '';

      min-width: 56px;
      @media only screen and (max-width: 1199px) {
        min-width: 40px;
      }

      @media only screen and (max-width: 600px) {
        min-width: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const ScrollButton = styled.button<IScrollButtonProps>`
  position: absolute;
  z-index: 10;
  bottom: 0;
  height: ${props => props.height}px;
  width: 56px;
  background: ${props => rgba(props.theme.colors.dark.dark0, 0.6)};
  display: flex;
  align-items: center;
  border: 0;
  transition: background 0.2s;

  @media only screen and (max-width: 1199px) {
    width: 40px;
  }

  &:first-child {
    left: 0;

    svg {
      margin-left: 4px;
    }
  }

  &:last-child {
    right: 0;
    justify-content: flex-end;

    svg {
      margin-right: 4px;
    }
  }

  &:hover {
    background: ${props => rgba(props.theme.colors.dark.dark0, 0.8)};
  }

  svg {
    color: ${props => props.theme.colors.light['light-2']};
  }
`;

export const LoadingContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: default;

  svg {
    color: ${props => props.theme.colors.light['light-2']};
  }

  p {
    margin-top: 8px;
    color: ${props => props.theme.colors.light['light-2']};
  }
`;

export const ViewMoreButton = styled.button`
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  border: none;
  background: ${props => props.theme.colors.dark.dark0};
  padding: 32px;
  transition: 0.2s background;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* cursor: default; */

  svg {
    color: ${props => props.theme.colors.light['light-2']};
  }

  p {
    margin-top: 8px;
    color: ${props => props.theme.colors.light['light-2']};
    white-space: nowrap;
  }
`;
