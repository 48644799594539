import styled, { css } from 'styled-components';

interface IChallengePriceProps {
  $type: 'challenger' | 'opponent';
}

export const Container = styled.div`
  width: calc(50% - 0.5rem);
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  > div {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.75rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};

    > small {
      font-size: ${props => props.theme.typograph.sizes.small}px;
      font-weight: ${props => props.theme.typograph.weights.bold};
      color: ${props => props.theme.colors.secondary.secondary0};
      line-height: 1.2;

      @media only screen and (max-width: 768px) {
        font-size: ${props => props.theme.typograph.sizes.xs}px;
      }
    }
  }

  > h6 {
    width: 100%;
    font-size: ${props => props.theme.typograph.sizes.h6}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    color: ${props => props.theme.colors.light.light0};
    line-height: 1.2;
    flex: 1;
    margin-bottom: 1rem;

    span {
      font-weight: ${props => props.theme.typograph.weights.medium};
    }
  }

  button,
  > small {
    display: block;
    margin-left: auto;
  }
`;

export const OwnerNameAndUsername = styled.div`
  flex: 1;

  strong {
    display: block;
    font-weight: ${props => props.theme.typograph.weights.bold};
    font-size: ${props => props.theme.typograph.sizes.p}px;
    color: ${props => props.theme.colors.light.light0};
    line-height: 1.2;

    @media only screen and (max-width: 768px) {
      font-size: ${props => props.theme.typograph.sizes.small}px;
    }
  }
  p {
    display: block;
    font-weight: ${props => props.theme.typograph.weights.medium};
    font-size: ${props => props.theme.typograph.sizes.small}px;
    color: ${props => props.theme.colors.light['light-2']};
    line-height: 1.2;

    @media only screen and (max-width: 768px) {
      font-size: ${props => props.theme.typograph.sizes.xs}px;
    }
  }
`;

export const ChallengePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    small {
      font-size: ${props => props.theme.typograph.sizes.xs}px;
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      line-height: 1.2;
    }
  }
`;

export const ChallengePrice = styled.p<IChallengePriceProps>`
  font-size: ${props => props.theme.typograph.sizes.p}px;
  font-weight: ${props => props.theme.typograph.weights.bold};
  line-height: 1.2;

  ${props =>
    props.$type === 'challenger' &&
    css`
      color: ${props.theme.colors.secondary.secondary0};
    `}
  ${props =>
    props.$type === 'opponent' &&
    css`
      color: ${props.theme.colors.primary['primary+1']};
    `}

    span {
    font-size: ${props => props.theme.typograph.sizes.small}px;
  }
`;
