import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import { useBullet } from '@/context/BulletContext';
import { IPlayerOfTeam, ITeam } from '../..';
import {
  INewChallengeContextData,
  AthleteChallenge,
  CoachFriendModule,
  FormFieldIdentifier,
  IChallengeField,
  INewChallengeRequestDTO,
  MinuteOfFirstGoalChallenge,
  ScoreChallenge,
  Markers,
  ScoreChallengePlaceholder,
  MinuteOfFirstGoalChallengePlaceholder,
  AthleteChallengePlaceholder,
  StatisticsChallenge,
  StatisticsChallengePlaceholder,
} from './types';

import TeamPointsFormFields from './FillChallengeOptionsContainer/SelectedChallengeCard/ScoreChallengesFormFields/TeamPointsFormFields';
import TimeOfTeamFirstGoalFormFields from './FillChallengeOptionsContainer/SelectedChallengeCard/MinuteOfFirstGoalChallengesFormFields/TimeOfTeamFirstGoalFormFields';
import TimeOfAthleteGoalFormFields from './FillChallengeOptionsContainer/SelectedChallengeCard/AthleteChallenges/TimeOfAthleteGoalFormFields';
import AmountOfStatisticItemFormFields from './FillChallengeOptionsContainer/SelectedChallengeCard/StatisticsChallenges/AmountOfStatisticItemFormFields';

import { useCoachFriendRoom } from '../../CoachFriendRoomContext';
import { convertTextWithMarkersToSavingPattern } from '../utils';
import { ChallengeType } from '../types';

const NewChallengeContext = createContext<INewChallengeContextData>(
  {} as INewChallengeContextData,
);

const NewChallengeProvider: React.FC = ({ children }) => {
  const intlContext = useIntl();
  const bulletContext = useBullet();
  const coachFriendRoomContext = useCoachFriendRoom();

  const [selectedModule, setSelectedModule] = useState<
    CoachFriendModule | string
  >('');
  const [selectedChallenge, setSelectedChallenge] = useState<string>('');
  const [isCustomPayment, setIsCustomPayment] = useState(false);
  const [fields, setFields] = useState<IChallengeField[]>([]);
  const [price, setPrice] = useState<number | null>(null);
  const [opponentPrice, setOpponentPrice] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formFieldsContainer = useMemo(() => {
    if (selectedChallenge === ScoreChallenge.TEAM_POINTS) {
      return <TeamPointsFormFields />;
    }
    if (
      selectedChallenge === MinuteOfFirstGoalChallenge.TIME_OF_TEAM_FIRST_GOAL
    ) {
      return <TimeOfTeamFirstGoalFormFields />;
    }
    if (selectedChallenge === AthleteChallenge.TIME_OF_ATHLETE_GOAL) {
      return <TimeOfAthleteGoalFormFields />;
    }
    if (
      selectedChallenge === StatisticsChallenge.AMOUNT_OF_YELLOW_CARDS ||
      selectedChallenge === StatisticsChallenge.AMOUNT_OF_RED_CARDS ||
      selectedChallenge === StatisticsChallenge.AMOUNT_OF_CORNERS ||
      selectedChallenge === StatisticsChallenge.AMOUNT_OF_OFFSIDES
    ) {
      return <AmountOfStatisticItemFormFields />;
    }

    return <></>;
  }, [selectedChallenge]);

  const getInitialChallengeText = useCallback(() => {
    switch (selectedModule) {
      case CoachFriendModule.SCOREBOARD:
        return ScoreChallengePlaceholder[selectedChallenge as ScoreChallenge];
      case CoachFriendModule.MINUTE_OF_FIRST_GOAL:
        return MinuteOfFirstGoalChallengePlaceholder[
          selectedChallenge as MinuteOfFirstGoalChallenge
        ];
      case CoachFriendModule.ATHLETES:
        return AthleteChallengePlaceholder[
          selectedChallenge as AthleteChallenge
        ];
      case CoachFriendModule.STATISTICS:
        return StatisticsChallengePlaceholder[
          selectedChallenge as StatisticsChallenge
        ];
      default:
        return '';
    }
  }, [selectedChallenge, selectedModule]);

  const challengeText = useMemo(() => {
    const challengeFields = fields;
    let text = getInitialChallengeText();

    challengeFields.forEach(field => {
      if (!field.viewValue || !field.marker) return;

      let markerStartIndex = 0;
      let markerEndIndex = 0;

      if (field.marker === Markers.SQUARE_BRACKETS) {
        markerStartIndex = text.indexOf('[');
        markerEndIndex = text.indexOf(']');
      } else if (field.marker === Markers.CURLY_BRACKETS) {
        markerStartIndex = text.indexOf('{');
        markerEndIndex = text.indexOf('}');
      } else if (field.marker === Markers.PARENTHESIS) {
        markerStartIndex = text.indexOf('(');
        markerEndIndex = text.indexOf(')');
      }

      text =
        text.substring(0, markerStartIndex + 1) +
        field.viewValue +
        text.substring(markerEndIndex);
    });

    return text;
  }, [getInitialChallengeText, fields]);

  const getStatisticGameType = useCallback((): ChallengeType => {
    switch (selectedChallenge) {
      case StatisticsChallenge.AMOUNT_OF_YELLOW_CARDS:
        return ChallengeType.YELLOW_CARDS;
      case StatisticsChallenge.AMOUNT_OF_RED_CARDS:
        return ChallengeType.RED_CARDS;
      case StatisticsChallenge.AMOUNT_OF_CORNERS:
        return ChallengeType.CORNERS;
      case StatisticsChallenge.AMOUNT_OF_OFFSIDES:
        return ChallengeType.OFFSIDES;
      default:
        return '' as ChallengeType;
    }
  }, [selectedChallenge]);

  const getNewChallengeRequestBody = useCallback(() => {
    const gameId = coachFriendRoomContext.room?._game._id;
    if (!price || !gameId) return {};

    const body: INewChallengeRequestDTO = {
      price,
      gameId,
      question: convertTextWithMarkersToSavingPattern(challengeText),
      details: {},
    } as INewChallengeRequestDTO;

    if (isCustomPayment) {
      body.mode = 'custom';
      body.customPrice = opponentPrice as number;
    }

    switch (selectedModule) {
      case CoachFriendModule.SCOREBOARD:
        body.gameType = ChallengeType.SCOREBOARD;
        break;
      case CoachFriendModule.MINUTE_OF_FIRST_GOAL:
        body.gameType = ChallengeType.MINUTE_OF_FIRST_GOAL;
        break;
      case CoachFriendModule.ATHLETES:
        body.gameType = ChallengeType.ATHLETES;
        break;
      case CoachFriendModule.STATISTICS:
        body.gameType = getStatisticGameType();
        break;
      default:
        break;
    }

    const teamFormField = fields.find(
      field => field.id === FormFieldIdentifier.TEAM,
    )?.value as ITeam;
    const athleteFormField = fields.find(
      field => field.id === FormFieldIdentifier.ATHLETE,
    )?.value as IPlayerOfTeam;
    const matchScoreFormField = fields.find(
      field => field.id === FormFieldIdentifier.MATCH_SCORE,
    )?.value as string;
    const timeAFormField = fields.find(
      field => field.id === FormFieldIdentifier.TIME_A,
    )?.value as number;
    const timeBFormField = fields.find(
      field => field.id === FormFieldIdentifier.TIME_B,
    )?.value as number;
    const statisticFormField = fields.find(
      field => field.id === FormFieldIdentifier.STATISTIC,
    )?.value as string;

    if (teamFormField) {
      body.details.teamId = teamFormField._id;
    }
    if (athleteFormField) {
      body.details.athlete = athleteFormField._id;
    }
    if (matchScoreFormField) {
      body.details.score = matchScoreFormField;
    }
    if (timeAFormField && timeBFormField) {
      body.details.time = {
        a: timeAFormField,
        b: timeBFormField,
      };
    }
    if (statisticFormField) {
      body.details.statistic = parseInt(statisticFormField);
    }

    return body;
  }, [
    challengeText,
    coachFriendRoomContext.room?._game._id,
    fields,
    isCustomPayment,
    opponentPrice,
    getStatisticGameType,
    price,
    selectedModule,
  ]);

  const priceValidationMessage = useMemo(() => {
    try {
      Yup.number()
        .max(
          bulletContext.balance.amount,
          'O valor não pode ser maior que o seu saldo',
        )
        .min(1, 'O valor deve ser no mínimo T¢1')
        .validateSync(price || 0);

      return '';
    } catch (error) {
      return error.message;
    }
  }, [bulletContext.balance.amount, price]);

  const opponentPriceValidationMessage = useMemo(() => {
    if (!isCustomPayment) return '';

    try {
      Yup.number()
        .min(1, 'O valor deve ser no mínimo T¢1')
        .validateSync(opponentPrice || 0);

      return '';
    } catch (error) {
      return error.message;
    }
  }, [isCustomPayment, opponentPrice]);

  const handleSubmit = useCallback(async () => {
    const roomId = coachFriendRoomContext.room?._id;
    if (!roomId) return;

    try {
      setIsSubmitting(true);

      const body = getNewChallengeRequestBody();
      await api.post(`/api/coach-friend/${roomId}/challenger`, body);
      showToast({
        type: 'success',
        title: 'Desafio criado como sucesso!',
        description: 'Outros jogadores nessa sala já podem confrontar você',
      });
      coachFriendRoomContext.setCurrentChallengeRoute('list');
    } catch (error) {
      showToast({
        type: 'error',
        title: intlContext.getTranslatedText(
          'common.errors.unexpectedError.title',
        ),
        description: intlContext.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
    setIsSubmitting(false);
  }, [coachFriendRoomContext, getNewChallengeRequestBody, intlContext]);

  return (
    <NewChallengeContext.Provider
      value={{
        selectedModule,
        setSelectedModule,
        selectedChallenge,
        setSelectedChallenge,
        fields,
        setFields,
        price,
        setPrice,
        isCustomPayment,
        setIsCustomPayment,
        opponentPrice,
        setOpponentPrice,
        formFieldsContainer,
        challengeText,
        priceValidationMessage,
        opponentPriceValidationMessage,
        handleSubmit,
        isSubmitting,
      }}
    >
      {children}
    </NewChallengeContext.Provider>
  );
};

function useNewChallenge(): INewChallengeContextData {
  const context = useContext(NewChallengeContext);

  if (!context) {
    throw new Error(
      'useNewChallenge must be used within a NewChallengeProvider',
    );
  }

  return context;
}

export { NewChallengeProvider, useNewChallenge };
