import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiChevronLeft,
  FiLock,
  FiTrash2,
  FiChevronUp,
  FiChevronDown,
  FiShare2,
} from 'react-icons/fi';
import { Tabs, Modal } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import * as dateFns from 'date-fns';
import Countdown from 'react-countdown';

import { IoTrophy } from 'react-icons/io5';

import CustomAntButton from '@/components/CustomAntButton';

import { useLoadingMasked } from '@/context/LoadingMaskedContext';
import { useAuth } from '@/context/AuthContext';
import { useChat } from '@/context/ChatContext';
import { useIntl } from '@/context/IntlContext';

import api from '@/services/api';
import socket from '@/services/socket';
import { formatMoney } from '@/utils/formatters';
import { useShare } from '@/context/ShareContext';
import { useBullet } from '@/context/BulletContext';
import Breadcrumbs from '../../../components/Breadcrumbs';

import Friends from './Friends';
import Games from './Games';

import {
  Container,
  Header,
  MatchInfoContainer,
  TeamsContainer,
  CoachFriendRoomTeam,
  AccumulatedValueContainer,
  RestTimerContainer,
  MatchInfoDetailsContainer,
  ViewMyGameContainer,
  TopContent,
  ViewMyGameButton,
  TabsContainer,
  ContentTab,
  EnterOnRoomContainer,
} from './styles';
import Loading from '../../../components/Loading';
import NotFound from '../../../components/NotFound';
import { showToast } from '../../../hooks/showToast';
import {
  CoachFriendRoomProvider,
  useCoachFriendRoom,
} from './CoachFriendRoomContext';

import Challenge from './Challenge';

interface IRouteParams {
  id: string;
}

export interface IPlayerOfTeam {
  _id: string;
  name: string;
  number: string;
  position: string;
}

export interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
  players: IPlayerOfTeam[];
}

export interface IMe {
  _id: string;
  roles?: 'owner'[];
  status: boolean;
}

export interface IMeGame {
  _id: string;
  totalScore: number;
  localScore: number;
  awayScore: number;
  redCards: number;
  yellowCards: number;
  cornerNumber: number;
  offsideNumber: number;
  localTime: string;
  awayTime: string;
  localPlayers: IThrowOfPayGoalMode[];
  awayPlayers: IThrowOfPayGoalMode[];
  collapsedOnList?: boolean;
  _user: string;
  scoreBoard: number;
  timeScore: number;
  playerScore: number;
  cardsScore: number;
  offsideScore: number;
  cornerScore: number;
}

export interface IPlayer {
  players: {
    _id: string;
    _user: string;
    roles?: 'owner'[];
    status: boolean;
  };
  _player: {
    username: string;
    name: string;
    email: string;
    photo?: string;
  };
  games: boolean;
}

export interface IPlayerGame {
  games: {
    _id: string;
    totalScore: number;
    localScore: number;
    awayScore: number;
    redCards: number;
    yellowCards: number;
    cornerNumber: number;
    offsideNumber: number;
    localTime: string;
    awayTime: string;
    localPlayers: IThrowOfPayGoalMode[];
    awayPlayers: IThrowOfPayGoalMode[];
    _user: string;
  };
  collapsedOnList?: boolean;
  _player: {
    username: string;
    name: string;
    email: string;
    photo?: string;
  };
}

interface IThrowOfPayGoalMode {
  _id: string;
  player: string;
  playerName: string;
}

export interface ICoachFriendRoom {
  _id: string;
  price: number;
  isPrivate: boolean;
  isFinished: boolean;
  name: string;
  started: boolean;
  blocked: boolean;
  gameCount: number;
  amount: number;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    _id: string;
    info: {
      place: string;
      date: string;
    };
    local: ITeam;
    away: ITeam;
  };
  players: IPlayer[];
  games: IPlayerGame[];
}

export interface IMyDataInTheRoom {
  _me: IMe | null;
  _meGame: IMeGame | null;
}

const { TabPane } = Tabs;
const { confirm } = Modal;
const { parseISO, isAfter, sub, format } = dateFns;

const Room: React.FC = () => {
  const { url, params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const { showLoading, hideLoading } = useLoadingMasked();
  const { openShareAssistant } = useShare();
  const { user: me } = useAuth();
  const { setChat } = useChat();
  const intl = useIntl();
  const bullet = useBullet();

  const coachFriendRoomContext = useCoachFriendRoom();

  const [currentView, setCurrentView] = useState<
    'games' | 'friends' | 'challenge'
  >('friends');
  const [showMoreDetailsOfMatch, setShowMoreDetailsOfMatch] = useState(false);

  const [coachFriendRoom, setCoachFriendRoom] =
    useState<ICoachFriendRoom | null>(null);
  const [myDataInTheRoom, setMyDataInTheRoom] =
    useState<IMyDataInTheRoom | null>(null);
  const [loadingCoachFriendRoom, setLoadingCoachFriendRoom] = useState(true);

  const [loadingPlayersOfRoom, setLoadingPlayersOfRoom] = useState(false);
  const [loadingGamesOfRoom, setLoadingGamesOfRoom] = useState(false);

  const getPlayersOfRoom = useCallback(async (): Promise<void> => {
    setLoadingPlayersOfRoom(true);
    try {
      const { data } = await api.get<{
        doc: IPlayer[];
      }>(`/api/coach-friend/${params.id}/players`);

      setCoachFriendRoom(oldCoachFriendRoom => {
        if (oldCoachFriendRoom) {
          return {
            ...oldCoachFriendRoom,
            players: data.doc,
          };
        }
        return null;
      });
    } catch (error) {
      /* */
    }
    setLoadingPlayersOfRoom(false);
  }, [params.id]);

  const getGamesOfRoom = useCallback(async (): Promise<void> => {
    setLoadingGamesOfRoom(true);
    try {
      const { data } = await api.get<{
        doc: IPlayerGame[];
      }>(`/api/coach-friend/${params.id}/games`);

      setCoachFriendRoom(oldCoachFriendRoom => {
        if (oldCoachFriendRoom) {
          return {
            ...oldCoachFriendRoom,
            games: data.doc.map(game => ({
              ...game,
              collapsedOnList: false,
            })),
          };
        }
        return null;
      });
    } catch (error) {
      /* */
    }
    setLoadingGamesOfRoom(false);
  }, [params.id]);

  const getCoachFriendRoom = useCallback(async (): Promise<void> => {
    setLoadingCoachFriendRoom(true);
    try {
      const { data } = await api.get<{
        doc: ICoachFriendRoom;
        _me: IMe | null;
        _meGame: IMeGame | null;
      }>(`/api/coach-friend/${params.id}`);

      setChat(null);

      const room: ICoachFriendRoom = { ...data.doc };
      room._game.local.abbrev = room._game.local.name
        .substring(0, 3)
        .toUpperCase();
      room._game.away.abbrev = room._game.away.name
        .substring(0, 3)
        .toUpperCase();

      room.started = isAfter(new Date(), parseISO(room._game.info.date));
      const limitForActionsOnRoomData = sub(parseISO(room._game.info.date), {
        minutes: 15,
      });
      room.blocked = isAfter(new Date(), limitForActionsOnRoomData);

      room.players = [];
      room.games = [];

      if (room.started) {
        setCurrentView('games');
      } else if (me?.roles.some(role => role === 'admin')) {
        setCurrentView('challenge');
      }

      setCoachFriendRoom(room);
      setMyDataInTheRoom({
        _me: data._me,
        _meGame: data._meGame,
      });

      if (data._me?.status) {
        getPlayersOfRoom();
        if (room.started) {
          getGamesOfRoom();
        }

        setChat({
          _resourceId: data.doc._id,
          name: data.doc.name,
          from: intl.getTranslatedText('common.gamesTitles.coachFriend'),
          fromCode: 'coachFriend',
        });
      }
    } catch (error) {
      setCoachFriendRoom(null);
      setMyDataInTheRoom(null);
    }
    setLoadingCoachFriendRoom(false);
  }, [getGamesOfRoom, getPlayersOfRoom, intl, me, params.id, setChat]);

  useEffect(() => {
    getCoachFriendRoom();

    return () => {
      setChat(null);
    };
  }, [getCoachFriendRoom, setChat]);

  useEffect(() => {
    socket.on(
      `coach:${params.id}`,
      async (data: {
        stmt: {
          [key: string]: string;
        };
        amount: number;
        status:
          | 'remove_player'
          | 'reject_player'
          | 'accept_player'
          | 'delete_room'
          | 'new_game';
      }) => {
        if (
          data.status === 'reject_player' ||
          data.status === 'accept_player'
        ) {
          getPlayersOfRoom();
          return;
        }
        if (data.status === 'new_game') {
          setCoachFriendRoom(oldCoachFriendRoom => {
            if (oldCoachFriendRoom) {
              return {
                ...oldCoachFriendRoom,
                gameCount: oldCoachFriendRoom.gameCount + 1,
                amount: data.amount,
              };
            }
            return null;
          });
          getPlayersOfRoom();
          return;
        }
        getCoachFriendRoom();
      },
    );
    if (coachFriendRoom?._game._id) {
      socket.on(`coach-end:${coachFriendRoom?._game._id}`, () => {
        getGamesOfRoom();
      });
    }
    return () => {
      socket.off(`coach:${params.id}`);
      if (coachFriendRoom?._game._id) {
        socket.off(`coach-end:${coachFriendRoom?._game._id}`);
      }
    };
  }, [
    coachFriendRoom?._game._id,
    getCoachFriendRoom,
    getGamesOfRoom,
    getPlayersOfRoom,
    params.id,
  ]);

  const handleAcceptOrRejectInviteForThisRoom = useCallback(
    async (action: 'reject' | 'accept'): Promise<void> => {
      if (action === 'reject') {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.coachFriend.room.messages.submitRejectInviteForThisRoomConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.coachFriend.room.rejectInviteButton',
            ),
            onOk() {
              resolve(true);
            },
            okButtonProps: {
              danger: true,
            },
          });
        });
      } else {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.coachFriend.room.messages.submitGetInOnPublicRoomConfirm.title',
            ),
            icon: <CheckCircleOutlined />,
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton',
            ),
            onOk() {
              resolve(true);
            },
          });
        });
      }

      try {
        const body: { status?: boolean; action?: 'reject' } = {};
        if (action === 'accept') {
          body.status = true;
        } else {
          body.action = 'reject';
        }

        await api.put(`/api/coach-friend/${params.id}/invite`, body);

        if (action === 'reject') {
          history.push('/coach_friend');
        } else {
          getCoachFriendRoom();
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [getCoachFriendRoom, history, intl, params.id],
  );

  const handleGetInOnPublicRoom = useCallback(async () => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.coachFriend.room.messages.submitGetInOnPublicRoomConfirm.title',
          ),
          icon: <CheckCircleOutlined />,
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      // setLoadingAddUsers(true);

      const body = {
        users: [
          {
            _user: me?._id,
          },
        ],
      };

      await api.put(`/api/coach-friend/${params.id}/players`, body);

      // setLoadingAddUsers(false);
      // handleCloseAddFriendsModal();
      getCoachFriendRoom();
    } catch (error) {
      // setLoadingAddUsers(false);
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description: intl.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
  }, [getCoachFriendRoom, intl, me?._id, params.id]);

  const handleGoToPlay = useCallback(async () => {
    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'pages.coachFriend.room.messages.goToPlayConfirm.title',
        ),
        icon: <CheckCircleOutlined />,
        content: intl.getTranslatedText(
          'pages.coachFriend.room.messages.goToPlayConfirm.description',
          {
            price: coachFriendRoom?.price
              ? formatMoney(coachFriendRoom?.price)
              : '',
          },
        ),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.coachFriend.room.goToPlayConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
      });
    });

    if (coachFriendRoom?.price) {
      const hasAvailableBalance = bullet.checkIfBalanceIsAvailableToAction(
        coachFriendRoom?.price,
      );
      if (!hasAvailableBalance) return;
    }

    history.push(`${url}/new`);
  }, [bullet, coachFriendRoom?.price, history, intl, url]);

  const handleDeleteRoom = useCallback(async (): Promise<void> => {
    await new Promise(resolve => {
      confirm({
        title: intl.getTranslatedText(
          'pages.coachFriend.room.messages.submitDeleteRoomConfirm.title',
        ),
        icon: <ExclamationCircleOutlined />,
        content: intl.getTranslatedText('common.messages.actionCannotBeUndone'),
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.coachFriend.room.submitDeleteRoomConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
        okButtonProps: {
          danger: true,
        },
      });
    });

    try {
      showLoading(
        intl.getTranslatedText(
          'pages.coachFriend.room.messages.submitDeleteRoomLoading',
        ),
      );
      await api.delete(`/api/coach-friend/${params.id}/room`);
      hideLoading();
      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.coachFriend.room.messages.submitDeleteRoomSuccess.title',
        ),
      });
      history.push('/coach_friend');
    } catch (error) {
      hideLoading();
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description: intl.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
  }, [hideLoading, history, intl, params.id, showLoading]);

  const handleCollapseGame = useCallback((gameId: string) => {
    setCoachFriendRoom(oldState => {
      if (oldState) {
        return {
          ...oldState,
          games: oldState?.games.map(game => {
            if (game.games._id === gameId) {
              return {
                ...game,
                collapsedOnList: !game.collapsedOnList,
              };
            }

            return {
              ...game,
              collapsedOnList: false,
            };
          }),
        };
      }

      return null;
    });
  }, []);

  const topContentViewer = useMemo(() => {
    if (!coachFriendRoom) return <></>;

    return (
      <TopContent>
        <Breadcrumbs />
        <Header>
          <h5>{coachFriendRoom.name}</h5>
          <div>
            {coachFriendRoom.isPrivate && <FiLock size={19} />}
            <CustomAntButton
              type="text"
              useCustomIcon
              icon={<FiShare2 size={21} />}
              onClick={() => openShareAssistant({ url: window.location.href })}
            />
            {coachFriendRoom.gameCount === 0 &&
              myDataInTheRoom?._me?.roles?.includes('owner') && (
                <CustomAntButton
                  type="text"
                  danger
                  useCustomIcon
                  icon={<FiTrash2 size={21} />}
                  onClick={() => handleDeleteRoom()}
                />
              )}
          </div>
        </Header>
        <MatchInfoContainer
          onClick={() => setShowMoreDetailsOfMatch(oldState => !oldState)}
        >
          <TeamsContainer>
            <div>
              <CoachFriendRoomTeam>
                <div>
                  <img
                    src={coachFriendRoom._game.local.image}
                    alt={coachFriendRoom._game.local.name}
                  />
                </div>
                <strong>{coachFriendRoom._game.local.abbrev}</strong>
              </CoachFriendRoomTeam>
              <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
              <CoachFriendRoomTeam>
                <div>
                  <img
                    src={coachFriendRoom._game.away.image}
                    alt={coachFriendRoom._game.away.name}
                  />
                </div>
                <strong>{coachFriendRoom._game.away.abbrev}</strong>
              </CoachFriendRoomTeam>
            </div>
            <small>{coachFriendRoom._champ.name}</small>
          </TeamsContainer>
          <AccumulatedValueContainer>
            <IoTrophy size={18} />
            <small>
              T¢{intl.getFormattedDecimalNumber(coachFriendRoom.amount)}
            </small>
          </AccumulatedValueContainer>
          {!coachFriendRoom.started && (
            <Countdown
              date={parseISO(coachFriendRoom._game.info.date)}
              renderer={({ days, hours, minutes, seconds, completed }) => {
                if (completed) {
                  return <></>;
                }

                return (
                  <RestTimerContainer>
                    <div>
                      <h4>
                        {days.toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </h4>
                      <small>
                        {intl.getTranslatedText('common.countDown.days')}
                      </small>
                    </div>
                    <div>
                      <h4>
                        {hours.toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </h4>
                      <small>
                        {intl.getTranslatedText('common.countDown.hours')}
                      </small>
                    </div>
                    <div>
                      <h4>
                        {minutes.toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </h4>
                      <small>
                        {intl.getTranslatedText('common.countDown.minutes')}
                      </small>
                    </div>
                    <div>
                      <h4>
                        {seconds.toLocaleString(undefined, {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                        })}
                      </h4>
                      <small>
                        {intl.getTranslatedText('common.countDown.seconds')}
                      </small>
                    </div>
                  </RestTimerContainer>
                );
              }}
              onComplete={() => {
                getCoachFriendRoom();
              }}
            />
          )}
          {showMoreDetailsOfMatch && (
            <MatchInfoDetailsContainer>
              <div>
                <div>
                  <small>
                    {intl.getTranslatedText('common.matchInfo.date')}
                  </small>
                  <small>
                    {format(
                      parseISO(coachFriendRoom._game.info.date),
                      intl.getTranslatedText('common.formatDates.fullDate'),
                      {
                        locale: intl.getDatefnsLocale(),
                      },
                    )}
                  </small>
                </div>
                <div>
                  <small>
                    {intl.getTranslatedText('common.matchInfo.place')}
                  </small>
                  <small>{coachFriendRoom._game.info.place}</small>
                </div>
              </div>
            </MatchInfoDetailsContainer>
          )}
          <div>
            {!showMoreDetailsOfMatch ? (
              <>
                <FiChevronDown size={16} />
                <small>
                  {intl.getTranslatedText('common.buttons.moreDetails')}
                </small>
              </>
            ) : (
              <>
                <FiChevronUp size={16} />
                <small>
                  {intl.getTranslatedText('common.buttons.lessDetails')}
                </small>
              </>
            )}
          </div>
        </MatchInfoContainer>
        {myDataInTheRoom?._me?.status && (
          <ViewMyGameContainer>
            {!myDataInTheRoom?._meGame ? (
              <>
                {!coachFriendRoom.blocked ? (
                  <>
                    <p>
                      {intl.getTranslatedText(
                        'pages.coachFriend.room.messages.youNotHaveGame',
                      )}
                    </p>
                    <CustomAntButton type="primary" onClick={handleGoToPlay}>
                      {intl.getTranslatedText(
                        'pages.coachFriend.room.playButton',
                      )}
                    </CustomAntButton>
                  </>
                ) : (
                  <p>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.messages.itsNotPossibleToPlayOnRoom',
                    )}
                  </p>
                )}
              </>
            ) : (
              <ViewMyGameButton to={`${url}/my_game`}>
                <div>
                  <div>
                    <h6>{coachFriendRoom._game.local.name}</h6>
                    <h6>{myDataInTheRoom?._meGame.localScore}</h6>
                  </div>
                  <div>
                    <h6>{coachFriendRoom._game.away.name}</h6>
                    <h6>{myDataInTheRoom?._meGame.awayScore}</h6>
                  </div>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.showMyGame',
                    )}
                  </small>
                </div>
              </ViewMyGameButton>
            )}
          </ViewMyGameContainer>
        )}
      </TopContent>
    );
  }, [
    coachFriendRoom,
    getCoachFriendRoom,
    handleDeleteRoom,
    handleGoToPlay,
    intl,
    myDataInTheRoom?._me?.roles,
    myDataInTheRoom?._me?.status,
    myDataInTheRoom?._meGame,
    openShareAssistant,
    showMoreDetailsOfMatch,
    url,
  ]);

  const bottomContentViewer = useMemo(() => {
    if (!coachFriendRoom) return <></>;

    if (!myDataInTheRoom?._me?.status) {
      if (coachFriendRoom.isPrivate) {
        return (
          <TabsContainer>
            <EnterOnRoomContainer>
              <p>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.messages.inviteDescription',
                  {
                    price: formatMoney(coachFriendRoom.price),
                  },
                )}
              </p>
              {!coachFriendRoom.blocked ? (
                <div>
                  <CustomAntButton
                    onClick={() =>
                      handleAcceptOrRejectInviteForThisRoom('reject')
                    }
                    danger
                  >
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.rejectInviteButton',
                    )}
                  </CustomAntButton>
                  <CustomAntButton
                    onClick={() =>
                      handleAcceptOrRejectInviteForThisRoom('accept')
                    }
                    type="primary"
                  >
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton',
                    )}
                  </CustomAntButton>
                </div>
              ) : (
                <h6>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.messages.itsNotPossibleToEnterTheRoom',
                  )}
                </h6>
              )}
            </EnterOnRoomContainer>
          </TabsContainer>
        );
      }
      return (
        <TabsContainer>
          <EnterOnRoomContainer>
            <p>
              {intl.getTranslatedText(
                'pages.coachFriend.room.messages.enterOnPublicRoomDescription',
                {
                  price: formatMoney(coachFriendRoom.price),
                },
              )}
            </p>
            {!coachFriendRoom.blocked ? (
              <div>
                <CustomAntButton
                  onClick={() => handleGetInOnPublicRoom()}
                  type="primary"
                >
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton',
                  )}
                </CustomAntButton>
              </div>
            ) : (
              <h6>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.messages.itsNotPossibleToEnterTheRoom',
                )}
              </h6>
            )}
          </EnterOnRoomContainer>
        </TabsContainer>
      );
    }

    return (
      <TabsContainer>
        <Tabs
          activeKey={currentView}
          onTabClick={tabKey => {
            if (
              tabKey === 'games' ||
              tabKey === 'friends' ||
              tabKey === 'challenge'
            ) {
              setCurrentView(tabKey);
            }
          }}
          centered
        >
          {me?.roles.some(role => role === 'admin') && (
            <TabPane tab="Vai!" key="challenge">
              <ContentTab>
                <Challenge />
              </ContentTab>
            </TabPane>
          )}
          {coachFriendRoom.started && (
            <>
              <TabPane
                tab={intl.getTranslatedText(
                  'pages.coachFriend.room.tabs.games',
                )}
                key="games"
              >
                <ContentTab>
                  <Games
                    games={coachFriendRoom.games}
                    match={coachFriendRoom._game}
                    room={coachFriendRoom}
                    handleCollapseGame={handleCollapseGame}
                    loadingGamesOfRoom={loadingGamesOfRoom}
                  />
                </ContentTab>
              </TabPane>
            </>
          )}
          <TabPane
            tab={intl.getTranslatedText('pages.coachFriend.room.tabs.friends')}
            key="friends"
          >
            <ContentTab>
              <Friends
                players={coachFriendRoom.players}
                roomBlocked={coachFriendRoom.blocked}
                myDataInTheRoom={myDataInTheRoom?._me}
                myGame={myDataInTheRoom?._meGame}
                getPlayersOfRoom={() => {
                  getPlayersOfRoom();
                }}
                loadingPlayersOfRoom={loadingPlayersOfRoom}
                // getCoachFriendRoom={() => {
                //   getCoachFriendRoom();
                // }}
              />
              {/* <MyGames
              myGames={myGames}
              loading={loadingMyGames}
              loadMore={() => getMyGames(myGamesPagination.currentPage + 1)}
              isOnLastPage={
                myGamesPagination.currentPage === myGamesPagination.totalPages
              }
            /> */}
            </ContentTab>
          </TabPane>
        </Tabs>
      </TabsContainer>
    );
  }, [
    coachFriendRoom,
    currentView,
    getPlayersOfRoom,
    handleAcceptOrRejectInviteForThisRoom,
    handleCollapseGame,
    handleGetInOnPublicRoom,
    intl,
    loadingGamesOfRoom,
    loadingPlayersOfRoom,
    me,
    myDataInTheRoom?._me,
    myDataInTheRoom?._meGame,
  ]);

  if (loadingCoachFriendRoom || coachFriendRoomContext.isLoadingRoom) {
    return (
      <Container>
        <TopContent>
          <Breadcrumbs />
          <Loading />
        </TopContent>
      </Container>
    );
  }

  if (!coachFriendRoom) {
    return (
      <NotFound showBreadcrumbs>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.coachFriend.room.messages.roomNotFound',
          )}
        </h4>
        <Link to="/coach_friend">
          <FiChevronLeft size={16} />
          Voltar para a listagem de salas
          {intl.getTranslatedText('pages.coachFriend.room.goToListRoomsLink')}
        </Link>
      </NotFound>
    );
  }

  return (
    <>
      <Container>
        {topContentViewer}
        {bottomContentViewer}
      </Container>
    </>
  );
};

const RoomWrapper: React.FC = () => {
  return (
    <CoachFriendRoomProvider>
      <Room />
    </CoachFriendRoomProvider>
  );
};

export default RoomWrapper;
