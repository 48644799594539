const MyCoachFriendRoomsTexts = {
  'pages.coachFriend.myRooms.title': 'Minhas salas',

  // /* ------------ Buttons ------------ */
  'pages.coachFriend.myRooms.submitArchiveRoomConfirmButton': 'Arquivar',
  'pages.coachFriend.myRooms.submitUnarchiveRoomConfirmButton': 'Tornar ativa',
  'pages.coachFriend.myRooms.goToHomeLink': 'Voltar para o início',
  'pages.coachFriend.myRooms.goToActiveRoomsButton':
    'Voltar para as salas ativas',
  'pages.coachFriend.myRooms.showArchivedRoomsButton':
    'Mostrar salas arquivadas',
  'pages.coachFriend.myRooms.showActiveRoomsButton': 'Mostrar salas ativas',
  /* ------------ Messages ------------ */
  'pages.coachFriend.myRooms.messages.getRoomsError.title':
    'Erro ao carregar as salas',
  'pages.coachFriend.myRooms.messages.getArchivedRoomsError.title':
    'Erro ao carregar as salas ocultas',
  'pages.coachFriend.myRooms.messages.submitArchiveRoomConfirm.description':
    'Deseja realmente arquivar esse sala?',
  'pages.coachFriend.myRooms.messages.submitArchiveRoomSuccess.title':
    'Sala arquivada!',
  'pages.coachFriend.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Deseja realmente tornar essa sala ativa?',
  'pages.coachFriend.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Esse sala está ativa!',
  'pages.coachFriend.myRooms.messages.noRooms':
    'Você não está participando de nenhuma <span>sala</span>',
  'pages.coachFriend.myRooms.messages.viewingArchivedRooms':
    'Você está visualizando suas salas arquivadas',
  'pages.coachFriend.myRooms.messages.noArchivedRooms':
    'Você não possui nenhuma <span>sala arquivada</span>',
};

const PublicCoachFriendRoomsTexts = {
  'pages.coachFriend.publicRooms.title': 'Salas públicas',
  'pages.coachFriend.publicRooms.searchInput.placeholder':
    'Pesquisar pelo nome da sala, times ou usuário',

  /* ------------ Messages ------------ */
  'pages.coachFriend.publicRooms.messages.viewingFindedOnSearchRooms':
    'Mostrando salas encontradas na busca',
  'pages.coachFriend.publicRooms.messages.noPublicRooms':
    'Nenhuma sala pública do <span>Família VS Amigos</span> disponível',
  'pages.coachFriend.publicRooms.messages.noRooms': 'Nenhuma sala encontrada',
};

const NewCoachFriendRoomTexts = {
  'pages.coachFriend.newRoom.title': 'Criar nova sala',
  'pages.coachFriend.newRoom.step1': 'Sala',
  'pages.coachFriend.newRoom.step2': 'Partida',
  'pages.coachFriend.newRoom.step3': 'Confirmar',

  'pages.coachFriend.newRoom.submitNewRoomConfirmButton': 'Criar sala',

  'pages.coachFriend.newRoom.form.roomType.validation.invalid':
    'O tipo da sala é inválido',
  'pages.coachFriend.newRoom.form.roomType.validation.required':
    'O tipo da sala é obrigatório',
  'pages.coachFriend.newRoom.form.name.validation.required':
    'O nome da sala é obrigatório',
  'pages.coachFriend.newRoom.form.price.validation.required':
    'O valor do jogo é obrigatório',

  'pages.coachFriend.newRoom.messages.blockPage.title':
    'Deseja realmente sair da criação da sala?',
  'pages.coachFriend.newRoom.messages.getChampionshipsError.title':
    'Ocorreu um erro ao carregar os campeonatos',
  'pages.coachFriend.newRoom.messages.getMatchsOfChampionshipError.title':
    'Ocorreu um erro ao carregar os jogos desse campeonato',
  'pages.coachFriend.newRoom.messages.submitNewRoomConfirm.title':
    'Deseja realmente criar essa sala?',
  'pages.coachFriend.newRoom.messages.submitNewRoomSuccess.title':
    'Sala criada com sucesso!',
  'pages.coachFriend.newRoom.messages.submitNewRoomSuccess.description':
    'Você já pode convidar seus amigos e realizar seu jogo',
  //   /* Room */
  ...{
    /* roomType - form */
    'pages.coachFriend.newRoom.form.roomType.label': 'Tipo da sala',
    'pages.coachFriend.newRoom.form.roomType.privateRoomTitle':
      'Sala particular',
    'pages.coachFriend.newRoom.form.roomType.privateRoomDescription':
      'Apenas seus amigos e convidados podem entrar',
    'pages.coachFriend.newRoom.form.roomType.publicRoomTitle': 'Sala pública',
    'pages.coachFriend.newRoom.form.roomType.publicRoomDescription':
      'Todos na rede podem entrar',
    /* name - form */
    'pages.coachFriend.newRoom.form.name.label': 'Nome da sala',
    'pages.coachFriend.newRoom.form.name.placeholder': 'Digite o nome da sala',
    /* price - form */
    'pages.coachFriend.newRoom.form.price.label': 'Valor do jogo',
    'pages.coachFriend.newRoom.form.price.placeholder': 'ex.: 99',
  },
  //   /* Match */
  ...{
    'pages.coachFriend.newRoom.form.selectedChampionship.label': 'Campeonato',
    'pages.coachFriend.newRoom.form.selectedMatch.label': 'Partida',

    'pages.coachFriend.newRoom.form.matchInfo.date.label': 'Data e hora',
    'pages.coachFriend.newRoom.form.matchInfo.place.label': 'Local',

    'pages.coachFriend.newRoom.messages.selectChampionship':
      'Selecione um campeonato',
    'pages.coachFriend.newRoom.messages.loadingChampionships':
      'Carregando campeonatos...',
    'pages.coachFriend.newRoom.messages.selectMatch': 'Selecione uma partida',
    'pages.coachFriend.newRoom.messages.loadingMatchs':
      'Carregando partidas do campeonato...',
    'pages.coachFriend.newRoom.messages.noChampionshipMatchs':
      'Esse campeonato não possui <span>partidas</span> disponíveis',
  },
};

const CoachFriendRoomTexts = {
  'pages.coachFriend.room.tabs.games': 'Jogos',
  'pages.coachFriend.room.tabs.friends': 'Amigos',
  'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton':
    'Participar da sala',
  'pages.coachFriend.room.goToPlayConfirmButton': 'Jogar',
  'pages.coachFriend.room.submitDeleteRoomConfirmButton': 'Excluir sala',
  'pages.coachFriend.room.rejectInviteButton': 'Recusar',
  'pages.coachFriend.room.playButton': 'Jogar agora',
  'pages.coachFriend.room.showMyGame': 'Clique para mais detalhes do seu jogo',
  'pages.coachFriend.room.goToListRoomsLink': 'Voltar para a listagem de salas',

  'pages.coachFriend.room.messages.submitRejectInviteForThisRoomConfirm.title':
    'Deseja realmente recusar a solicitação de ingresso nessa sala?',
  'pages.coachFriend.room.messages.submitGetInOnPublicRoomConfirm.title':
    'Deseja realmente participar dessa sala?',
  'pages.coachFriend.room.messages.goToPlayConfirm.title':
    'Deseja jogar nessa sala?',
  'pages.coachFriend.room.messages.goToPlayConfirm.description':
    'Esse jogo custa T¢{price}',
  'pages.coachFriend.room.messages.submitDeleteRoomConfirm.title':
    'Deseja realmente excluir essa sala?',
  'pages.coachFriend.room.messages.submitDeleteRoomLoading':
    'Excluindo sala...',
  'pages.coachFriend.room.messages.submitDeleteRoomSuccess.title':
    'Essa sala foi excluída!',
  'pages.coachFriend.room.messages.youNotHaveGame':
    'Você ainda não realizou o seu jogo',
  'pages.coachFriend.room.messages.itsNotPossibleToPlayOnRoom':
    'Não é mais possível jogar nessa sala',
  'pages.coachFriend.room.messages.inviteDescription':
    'Você recebeu um convite para participar dessa sala. O jogo custa T¢{price}',
  'pages.coachFriend.room.messages.enterOnPublicRoomDescription':
    'Você não está participando dessa sala. Deseja participar? O jogo custa T¢{price}',
  'pages.coachFriend.room.messages.itsNotPossibleToEnterTheRoom':
    'Não é mais possível entrar nessa sala',
  'pages.coachFriend.room.messages.roomNotFound':
    'Essa <span>sala</span> não foi encontrada',

  /* Friend */
  ...{
    'pages.coachFriend.room.friends.addFriendsModal.title':
      'Adicionar amigos na sala',
    'pages.coachFriend.room.friends.addFriendsModal.okButton':
      'Adicionar amigos',
    'pages.coachFriend.room.friends.addFriendsModal.searchUserInput.placeholder':
      'Pesquisar usuário',
    'pages.coachFriend.room.friends.addFriendsModal.selectedUsers':
      'Participantes selecionados',

    'pages.coachFriend.room.friends.userAddedAddFriendModalDescription':
      'Adicionado',
    'pages.coachFriend.room.friends.searchFriendInput.placeholder':
      'Pesquisar amigo',
    'pages.coachFriend.room.friends.userWasRequestedDescription': 'Solicitado',

    'pages.coachFriend.room.friends.submitAddUsersConfirmButton': 'Adicionar',
    'pages.coachFriend.room.friends.submitDeleteUserConfirmButton':
      'Remover participante',
    'pages.coachFriend.room.friends.submitLogOutForThisRoomConfirmButton':
      'Sair da sala',

    'pages.coachFriend.room.friends.messages.submitSearchUserError.title':
      'Ocorreu um erro ao carregar os usuários',
    'pages.coachFriend.room.friends.messages.userNotFound':
      'Esse <span>usuário</span> não foi encontrado',
    'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.title':
      'Deseja realmente adicionar esses participantes na sala?',
    'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.description':
      'Eles não poderão ser removidos posteriormente',
    'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.title':
      'Solicitação enviada com sucesso!',
    'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.description':
      'A solicitação de ingresso nessa sala foi enviada para os usuário selecionados',
    'pages.coachFriend.room.friends.messages.submitDeleteUserConfirm.title':
      'Deseja realmente remover esse participante?',
    'pages.coachFriend.room.friends.messages.submitDeleteUserLoading':
      'Removendo amigo...',
    'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.title':
      'Amigo removido!',
    'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.description':
      'Esse usuário não faz mais parte dessa sala',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomConfirm.title':
      'Deseja realmente sair dessa sala?',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomLoading':
      'Saindo da sala...',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.title':
      'Você saiu da sala!',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.description':
      'Você não é mais participante dessa sala',
    'pages.coachFriend.room.friends.messages.loadingFriends':
      'Carregando amigos...',
  },

  'pages.coachFriend.room.playCoachGoal.title': 'Gols na partida',

  'pages.coachFriend.room.minuteOfFirstGoal.title': 'Minuto do primeiro gol',
  'pages.coachFriend.room.minuteOfFirstGoal.interval.label':
    'Intervalo do minuto',

  'pages.coachFriend.room.athletes.title': 'Artilheiros',
  'pages.coachFriend.room.athletes.throw1': 'Lance 1',
  'pages.coachFriend.room.athletes.throw2': 'Lance 2',
  'pages.coachFriend.room.athletes.throw3': 'Lance 3',
  'pages.coachFriend.room.athletes.throw.notSelected': 'Não selecionado',

  'pages.coachFriend.room.statistics.title': 'Estatísticas',
  'pages.coachFriend.room.statistics.yellowCards.label': 'Cartão amarelo',
  'pages.coachFriend.room.statistics.redCards.label': 'Cartão vermelho',
  'pages.coachFriend.room.statistics.offsideNumber.label': 'Impedimento',
  'pages.coachFriend.room.statistics.cornerNumber.label': 'Escanteio',

  /* Games */
  ...{
    'pages.coachFriend.room.games.searchGameInput.placeholder':
      'Pesquisar jogo',

    'pages.coachFriend.room.games.messages.loadingGames':
      'Carregando jogos dos amigos...',
    'pages.coachFriend.room.games.messages.partialInfo':
      'As informações abaixo são parciais',
    'pages.coachFriend.room.games.messages.noGames':
      'Essa sala <span>não</span> possui jogos',
  },

  /* My Game Details */
  ...{
    'pages.coachFriend.room.myGameDetails.title': 'Detalhes do meu jogo',

    'pages.coachFriend.room.myGameDetails.roomIdentifier.label': 'SALA: ',
    'pages.coachFriend.room.myGameDetails.totalScore': '{totalScore} pontos',

    'pages.coachFriend.room.myGameDetails.goToRoomsListLink':
      'Voltar para a listagem de salas',

    'pages.coachFriend.room.myGameDetails.messages.gameNotFount':
      'Esse <span>jogo</span> não foi encontrado',
  },

  /* New Game */
  ...{
    'pages.coachFriend.room.newGame.title': 'Jogar',

    'pages.coachFriend.room.newGame.maxScoreDescription': 'até',

    'pages.coachFriend.room.newGame.submitNewGameConfirmButton':
      'Finalizar jogo',
    'pages.coachFriend.room.newGame.goToRoomsListLink':
      'Voltar para a listagem de salas',
    'pages.coachFriend.room.newGame.goldenGoalButton': 'Gol de Ouro',

    'pages.coachFriend.room.newGame.messages.unblockPageConfirm.title':
      'Deseja realmente sair do jogo?',
    'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.title':
      'Ops...',
    'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.description':
      'Selecione todas as opções disponíveis antes de continuar',
    'pages.coachFriend.room.newGame.messages.submitNewGameConfirm.title':
      'Deseja realmente finalizar esse jogo?',
    'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.title':
      'Jogo realizado com sucesso!',
    'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.description':
      'Você já pode interagir na sala com seus amigos',
    'pages.coachFriend.room.newGame.messages.noGame':
      'Esse <span>jogo</span> não está disponível',

    /* Statistics */
    'pages.coachFriend.room.newGame.statistics.cornerRange.label':
      '{firstRangeValue} a {lastRangeValue}',

    /* MinuteOfFirstGoal */
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range1.label':
      '00min - 09min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range2.label':
      '10min - 19min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range3.label':
      '20min - 29min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range4.label':
      '30min - 39min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range5.label':
      '40min - 49min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range6.label':
      '50min - 59min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range7.label':
      '60min - 69min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range8.label':
      '70min - 79min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range9.label':
      '80min - 89min',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range10.label':
      '90min - 99min',

    /* Athletes */
    'pages.coachFriend.room.newGame.athletes.selectPlayer1Modal.title':
      'Selecionar jogador do Lance 1 | {teamNameAbbrev}',
    'pages.coachFriend.room.newGame.athletes.selectPlayer2Modal.title':
      'Selecionar jogador do Lance 2 | {teamNameAbbrev}',
    'pages.coachFriend.room.newGame.athletes.selectPlayer3Modal.title':
      'Selecionar jogador do Lance 3 | {teamNameAbbrev}',

    'pages.coachFriend.room.newGame.athletes.playerNumber':
      'Camisa {playerNumber}',
    'pages.coachFriend.room.newGame.athletes.addPlayerButton':
      'Clique para adicionar',
  },
};

export default {
  ...MyCoachFriendRoomsTexts,
  ...PublicCoachFriendRoomsTexts,
  ...NewCoachFriendRoomTexts,
  ...CoachFriendRoomTexts,
};
