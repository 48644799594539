import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ITeamSelectorContainer {
  $selected?: boolean;
}

export const Container = styled.div`
  > small {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
    color: ${props => props.theme.colors.light.light0};
    margin-bottom: 0.5rem;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const TeamSelectorContainer = styled.button<ITeamSelectorContainer>`
  width: calc(50% - 0.25rem);
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  border-radius: 4px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: 0.2s all;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${props =>
      darken(0.05, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.$selected &&
    css`
      border: 1px solid ${props.theme.colors.primary['primary+1']};
      box-shadow: 0px 2px 10px 1px
        ${`${props.theme.colors.primary['primary+1']}25`};
    `}

  div {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+3']};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      height: 1.5rem;
    }
  }

  strong {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    font-weight: ${props => props.theme.typograph.weights.bold};
    color: ${props => props.theme.colors.light.light0};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
