import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, Modal } from 'antd';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { FiChevronLeft } from 'react-icons/fi';
import { parseISO } from 'date-fns';
import Countdown from 'react-countdown';
import { IoFootball, IoTrophy } from 'react-icons/io5';

import { CheckCircleOutlined } from '@ant-design/icons';
import { useBullet } from '@/context/BulletContext';
import Breadcrumbs from '../../../components/Breadcrumbs';
import CustomAntButton from '../../../components/CustomAntButton';

import Ranking from './Ranking';
import Results from './Results';
import MyGames from './MyGames';

import {
  Container,
  Header,
  AccumulatedValueContainer,
  RestTimerContainer,
  Content,
  ContentTab,
  CardCountContainer,
} from './styles';

import Env from '../../../config/Environment';

import api from '../../../services/api';
import socket from '../../../services/socket';
import { formatMoney } from '../../../utils/formatters';

import Loading from '../../../components/Loading';
import NotFound from '../../../components/NotFound';
import { showToast } from '../../../hooks/showToast';
import { useAuth } from '../../../context/AuthContext';
import { useChat } from '../../../context/ChatContext';
import { useIntl } from '../../../context/IntlContext';

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
  card_game_id: string;
}

export interface IRankingPosition {
  _id: string;
  totalScore: number;
  friendlyId: string;
  createdAt: string;
  _user: {
    _id: string;
    isMe: boolean;
    username: string;
    name: string;
    photo?: {
      _id: string;
      filename: string;
    };
  };
}

export interface IRankingSearchUser {
  _id: string;
  isMe: boolean;
  username: string;
  name: string;
  photo?: {
    _id: string;
    filename: string;
  };
  games: Omit<IRankingPosition, '_user'>[];
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export interface IAthlete {
  _team: ITeam;
  _athletes: {
    _id: string;
    status: boolean;
    name: string;
    url: string;
    number: string;
    position: string;
    age: string;
    height: string;
    weight: string;
    from: string;
    updatedAt: string;
    createdAt: string;
    photo: string;
  };
  athleteScore: number;
}

export interface ICardGameState {
  _id: string;
  name: string;
  startDate: string;
  cardType: 'new' | 'running' | 'finished';
  price: number;
  amount: number;
  cardCount: number;
  status: boolean;
  endDate: string;
  finished: boolean;
  banner?: string;
  athletes: IAthlete[];
}

export interface IMyGame {
  _id: string;
  _card: ICardGameState;
  totalScore: number;
  status: boolean;
  finished: boolean;
  name: string;
  createdAt: string;
  _user: {
    altName: string;
    email: string;
    name: string;
    photo: {
      _id: string;
      filename: string;
    };
    username: string;
    _id: string;
  };
}

const { confirm } = Modal;

const CardGame: React.FC = () => {
  const { url, params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();
  const { setChat } = useChat();
  const bullet = useBullet();

  const [cardGame, setCardGame] = useState<ICardGameState | null>(null);
  const [loadingCard, setLoadingCard] = useState(true);

  const [ranking, setRanking] = useState<IRankingPosition[]>([]);
  const [loadingRanking, setLoadingRanking] = useState(true);
  const [paginationRanking, setPaginationRanking] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [findedOnSearchUsers, setFindedOnSearchUsers] = useState<
    IRankingSearchUser[]
  >([]);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [paginationSearchUser, setPaginationSearchUser] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [userSelectedOnSearch, setUserSelectedOnSearch] =
    useState<IRankingSearchUser | null>(null);
  const [loadingUserSelectedOnSearch, setLoadingUserSelectedOnSearch] =
    useState(false);
  const [
    paginationGamesUserSelectedOnSearch,
    setPaginationGamesUserSelectedOnSearch,
  ] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [myGames, setMyGames] = useState<IMyGame[]>([]);
  const [loadingMyGames, setLoadingMyGames] = useState(false);
  const [myGamesPagination, setMyGamesPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [currentView, setCurrentView] = useState<
    'ranking' | 'results' | 'myGames'
  >('myGames');

  const getRanking = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingRanking(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking-athlete', {
          params: {
            card: params.card_game_id,
            limit: 10,
            page,
          },
        });

        if (page === 1) {
          setRanking([
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        } else {
          setRanking(oldRanking => [
            ...oldRanking,
            ...data.docs.map(rankPosition => {
              return {
                ...rankPosition,
                _user: {
                  ...rankPosition?._user,
                  isMe: me?._id === rankPosition?._user?._id,
                },
              };
            }),
          ]);
        }

        setPaginationRanking({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.messages.getRankingError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingRanking(false);
    },
    [intl, me?._id, params.card_game_id],
  );

  const searchUserOnRanking = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingSearchUser[];
          page: number;
          pages: number;
        }>('/api/user', {
          params: {
            champ: params.id,
            search,
            page,
            limit: 6,
          },
        });

        setFindedOnSearchUsers(
          data.docs.map(user => {
            return {
              ...user,
              isMe: me?._id === user?._id,
              games: [],
            };
          }),
        );

        setPaginationSearchUser({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingSearch(false);
    },
    [intl, me?._id, params.id],
  );

  const handleGetUserSelected = useCallback(
    async (value: string, page = 1): Promise<void> => {
      if (page === 1) {
        setUserSelectedOnSearch(null);
        setSearchUserValue(value);
      }

      const user = findedOnSearchUsers.find(
        findedUser => findedUser.username === value,
      );

      if (!user) return;

      setLoadingUserSelectedOnSearch(true);
      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          page: number;
          pages: number;
        }>('/api/ranking-athlete', {
          params: {
            card: params.card_game_id,
            limit: 6,
            user: user._id,
            page,
          },
        });

        if (page === 1) {
          user.games = data.docs;
        } else {
          user.games = [...user.games, ...data.docs];
        }

        setUserSelectedOnSearch(user);
        setPaginationGamesUserSelectedOnSearch({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingUserSelectedOnSearch(false);
    },
    [findedOnSearchUsers, intl, params.card_game_id],
  );

  const getMyGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingMyGames(true);
      try {
        const { data } = await api.get<{
          docs: IMyGame[];
          page: number;
          pages: number;
        }>('/api/played-athlete', {
          params: {
            card: params.card_game_id,
            limit: 6,
            page,
          },
        });

        setMyGames(oldMyGames => [...oldMyGames, ...data.docs]);
        setMyGamesPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.messages.getMyGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMyGames(false);
    },
    [intl, params.card_game_id],
  );

  const getAthleteCardGame = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        doc: Omit<ICardGameState, 'amount' | 'cardCount'>;
        amount: number;
        cardCount: number;
      }>(`/api/athlete-card/${params.card_game_id}`);

      const athletesWithAbbrevOnTeams = data.doc.athletes.map(athlete => ({
        ...athlete,
        _team: {
          ...athlete._team,
          abbrev: athlete._team.name.substring(0, 3).toUpperCase(),
        },
      }));

      setCardGame({
        ...data.doc,
        athletes: athletesWithAbbrevOnTeams,
        amount: data.amount,
        cardCount: data.cardCount,
      });

      if (data.doc.cardType !== 'new') {
        setCurrentView('ranking');
        getRanking();
      }
      setChat({
        _resourceId: data.doc._id,
        name: data.doc.name,
        from: intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek'),
        fromCode: 'athletesCard',
      });
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: 'Recarregue a página!',
      // });
    }
    setLoadingCard(false);
  }, [getRanking, intl, params.card_game_id, setChat]);

  useEffect(() => {
    getAthleteCardGame();

    return () => {
      setChat(null);
    };
  }, [getAthleteCardGame, setChat]);

  useEffect(() => {
    socket.on(
      `athlete:${params.card_game_id}`,
      (data: { status: 'new_game' }) => {
        if (data.status === 'new_game') {
          setCardGame(oldCardGame => {
            if (oldCardGame) {
              return {
                ...oldCardGame,
                cardCount: oldCardGame.cardCount + 1,
                amount: oldCardGame.amount + oldCardGame.price,
              };
            }
            return null;
          });
        }
      },
    );

    socket.on(`athlete-end:${params.card_game_id}`, () => {
      getAthleteCardGame();
    });

    return () => {
      socket.off(`athlete:${params.card_game_id}`);
      socket.off(`athlete-end:${params.card_game_id}`);
    };
  }, [getAthleteCardGame, params.card_game_id]);

  useEffect(() => {
    if (currentView === 'myGames' && myGames.length === 0) {
      getMyGames();
    }
  }, [currentView, getMyGames, myGames.length]);

  const usersSearchDebounced = useMemo(() => {
    return _.debounce(searchUserOnRanking, 500);
  }, [searchUserOnRanking]);

  const handleSearchUser = useCallback(
    (value: string) => {
      usersSearchDebounced.cancel();

      // if (!searchUserValue) {
      //   // setShowingUserFinded(true);
      // } else if (!value.length) {
      // }
      if (userSelectedOnSearch) {
        setUserSelectedOnSearch(null);
      }
      setSearchUserValue(value);

      if (value.length > 3) {
        setLoadingSearch(true);
        usersSearchDebounced(value);
      } else {
        setLoadingSearch(false);
        setFindedOnSearchUsers([]);
      }
    },
    [userSelectedOnSearch, usersSearchDebounced],
  );

  const handleGoToPlay = useCallback(async () => {
    await new Promise(resolve => {
      const content = cardGame?.price
        ? intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.description',
            {
              price: formatMoney(cardGame.price),
            },
          )
        : intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.freeCardDescription',
          );

      confirm({
        title: intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.title',
        ),
        icon: <CheckCircleOutlined />,
        content,
        cancelText: intl.getTranslatedText('common.buttons.cancel'),
        okText: intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.goToPlayConfirmButton',
        ),
        onOk() {
          resolve(true);
        },
      });
    });

    if (cardGame?.price) {
      const hasAvailableBalance = bullet.checkIfBalanceIsAvailableToAction(
        cardGame.price,
      );
      if (!hasAvailableBalance) return;
    }

    history.push(`${url}/play`);
  }, [bullet, cardGame?.price, history, intl, url]);

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs />
        <Loading />
      </Container>
    );
  }

  if (!cardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.card.messages.noCardGame',
          )}
        </h4>
        <Link to="/athletes_of_the_week">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.goToAthletesOfTheWeekLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header $bg={cardGame.banner && Env.IMAGE_SERVER_URL + cardGame.banner}>
        {cardGame.cardType === 'new' && (
          <Countdown
            date={parseISO(cardGame.startDate)}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <></>;
              }

              return (
                <RestTimerContainer>
                  <div>
                    <h6>
                      {days.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {' '}
                      {intl.getTranslatedText('common.countDown.days')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {hours.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {' '}
                      {intl.getTranslatedText('common.countDown.hours')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {minutes.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.minutes')}
                    </small>
                  </div>
                  <div>
                    <h6>
                      {seconds.toLocaleString(undefined, {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })}
                    </h6>
                    <small>
                      {intl.getTranslatedText('common.countDown.seconds')}
                    </small>
                  </div>
                </RestTimerContainer>
              );
            }}
            onComplete={() => {
              setLoadingCard(true);
              getAthleteCardGame();
            }}
          />
        )}
        <div>
          <h6>{cardGame.name}</h6>
        </div>
        <div>
          <CardCountContainer>
            <IoFootball size={18} />
            <small>
              {cardGame.cardCount} {intl.getTranslatedText('common.games')}
            </small>
          </CardCountContainer>
          <AccumulatedValueContainer>
            <IoTrophy size={18} />
            <small>T¢{intl.getFormattedDecimalNumber(cardGame.amount)}</small>
          </AccumulatedValueContainer>
          {cardGame.cardType === 'new' && (
            <CustomAntButton onClick={handleGoToPlay} type="primary">
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.card.playNowButton',
              )}
            </CustomAntButton>
            // <Link to={`${url}/play`}>
            // </Link>
          )}
        </div>
      </Header>
      <Content>
        <div>
          {cardGame.cardType === 'new' && (
            <CustomAntButton onClick={handleGoToPlay} type="primary">
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.card.playNowButton',
              )}
            </CustomAntButton>
            // <Link to={`${url}/play`}>
            // </Link>
          )}
        </div>
        <Tabs
          activeKey={currentView}
          onTabClick={tabKey => {
            if (
              tabKey === 'ranking' ||
              tabKey === 'results' ||
              tabKey === 'myGames'
            ) {
              setCurrentView(tabKey);
            }
          }}
          centered
        >
          {cardGame.cardType !== 'new' && (
            <>
              <TabPane
                tab={intl.getTranslatedText(
                  'pages.athletesOfTheWeek.card.tabs.ranking',
                )}
                key="ranking"
              >
                <ContentTab>
                  <Ranking
                    ranking={ranking}
                    loading={loadingRanking}
                    cardType={cardGame.cardType}
                    loadMoreRanking={() =>
                      getRanking(paginationRanking.currentPage + 1)
                    }
                    isOnLastPageOfRanking={
                      paginationRanking.currentPage ===
                      paginationRanking.totalPages
                    }
                    handleSearchUser={handleSearchUser}
                    searchUserValue={searchUserValue}
                    loadingSearchUsers={loadingSearch}
                    findedOnSearchUsers={findedOnSearchUsers}
                    isOnLastPageOfSearchUsers={
                      paginationSearchUser.currentPage ===
                      paginationSearchUser.totalPages
                    }
                    loadMoreSearchUsers={() =>
                      searchUserOnRanking(
                        searchUserValue,
                        paginationSearchUser.currentPage + 1,
                      )
                    }
                    handleGetUserSelected={handleGetUserSelected}
                    userSelectedOnSearch={userSelectedOnSearch}
                    isOnLastPageOfGamesUserSelectedOnSearch={
                      paginationGamesUserSelectedOnSearch.currentPage ===
                      paginationGamesUserSelectedOnSearch.totalPages
                    }
                    loadMoreGamesUserSelectedOnSearch={() =>
                      handleGetUserSelected(
                        userSelectedOnSearch?.username || '',
                        paginationGamesUserSelectedOnSearch.currentPage + 1,
                      )
                    }
                    loadingUserSelectedOnSearch={loadingUserSelectedOnSearch}
                  />
                </ContentTab>
              </TabPane>
              <TabPane
                tab={intl.getTranslatedText(
                  'pages.athletesOfTheWeek.card.tabs.results',
                )}
                key="results"
              >
                <ContentTab>
                  <Results
                    athletes={cardGame.athletes}
                    cardType={cardGame.cardType}
                  />
                </ContentTab>
              </TabPane>
            </>
          )}
          <TabPane
            tab={intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.tabs.myGames',
            )}
            key="myGames"
          >
            <ContentTab>
              <MyGames
                myGames={myGames}
                loading={loadingMyGames}
                loadMore={() => getMyGames(myGamesPagination.currentPage + 1)}
                isOnLastPage={
                  myGamesPagination.currentPage === myGamesPagination.totalPages
                }
              />
            </ContentTab>
          </TabPane>
        </Tabs>
      </Content>
    </Container>
  );
};

export default CardGame;
