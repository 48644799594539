import styled, { css } from 'styled-components';

interface ITeamsContainer {
  availableScoreboard?: boolean;
}

interface IViewPlayerStatus {
  selectedPlayer: boolean;
  thisUserIsTheFirst?: boolean;
}

interface IPlayerGame {
  meGame?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 72px);

  padding-top: 40px;

  @media only screen and (max-width: 1199px) {
    padding-top: 32px;

    min-height: calc(100vh - 61px);
  }

  @media only screen and (max-width: 600px) {
    padding-top: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;
  width: 100%;

  div {
    display: flex;
    align-items: center;

    > svg {
      color: ${props => props.theme.colors.primary.primary0};
    }

    button {
      margin-left: 8px;
    }
  }
`;

export const TopContent = styled.div`
  padding: 0 56px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1199px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    margin-bottom: 8px;
  }
`;

export const TeamsContainer = styled.div<ITeamsContainer>`
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  /* border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']}; */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    max-width: 752px;
    margin: 0 auto;
    position: relative;

    > svg {
      position: absolute;
      bottom: 0;
      color: ${props => props.theme.colors.light['light-2']};

      ${props =>
        props.availableScoreboard &&
        css`
          color: ${props.theme.colors.primary['primary+1']};
        `}
    }

    > h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      /* margin: 0 24px; */
    }
  }

  > small {
    color: ${props => props.theme.colors.primary.primary0};
    text-align: center;
    width: 100%;
  }
`;

export const RoomTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    div {
      width: 104px;
      height: 104px;

      img {
        height: 56px;
      }
    }

    strong {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
    }
  }
`;

export const ScoreTeamSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;

  h6 {
    color: ${props => props.theme.colors.light.light0};
    margin: 8px 0;
  }
`;

export const BottomContainer = styled.div`
  background: ${props => props.theme.colors.dark['dark+1']};
  width: 100%;
  flex: 1;
  margin-top: 16px;
`;

export const ContentBottom = styled.div`
  padding: 16px;
  padding-top: 0;

  > div {
    max-width: 752px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      flex: 1;
      padding-top: 24px;
    }
  }
`;

export const ViewPlayersStatusContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']};
`;

export const ViewPlayerStatus = styled.div<IViewPlayerStatus>`
  width: 100%;
  display: flex;
  align-items: center;

  border-left: 2px solid ${props => props.theme.colors.dark['dark+1']};
  padding-left: 8px;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.selectedPlayer &&
    css`
      border-left: 2px solid ${props.theme.colors.primary['primary-1']};
    `}

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 16px;
  }

  p {
    ${props =>
      props.selectedPlayer &&
      css`
        font-weight: ${props.theme.typograph.weights.bold};
      `}
  }

  div {
    margin-left: auto;
    display: flex;
    align-items: center;

    svg:nth-child(1) {
      color: ${props => props.theme.colors.dark['dark+1']};
      margin-right: 16px;

      ${props =>
        props.selectedPlayer &&
        css`
          color: ${props.theme.colors.primary['primary+1']};
        `}
    }

    svg:nth-child(2) {
      color: ${props => props.theme.colors.dark['dark+1']};

      ${props =>
        props.thisUserIsTheFirst &&
        css`
          color: #e4ce00;
        `}
    }

    p {
      margin-left: 32px;
    }
  }
`;

export const PlayerGame = styled.div<IPlayerGame>`
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 8px;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 10;
    position: relative;
  }

  > div {
    z-index: 5;
    width: calc(100% - 16px);
    padding: 16px;
    background: ${props => props.theme.colors.dark['dark+2']};
    border-radius: 8px;

    transform: translate3d(16px, -16px, 0);

    > div {
      display: flex;

      & + div {
        margin-top: 8px;
      }

      h6 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(2) {
          margin-left: auto;
          flex-shrink: 0;
        }
      }
    }
  }

  ${props =>
    props.meGame &&
    css`
      margin-left: auto;
      align-items: flex-end;

      > div {
        width: calc(100% - 16px);
        transform: translate3d(-16px, -16px, 0);

        padding: 16px;
        background: ${props.theme.colors.primary['primary-1']};
        border-radius: 8px;
      }
    `}
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
