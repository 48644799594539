const MyVersusRoomsTexts = {
  'pages.versusVIP.myRooms.title': 'Minhas salas',

  // /* ------------ Buttons ------------ */
  'pages.versusVIP.myRooms.submitArchiveRoomConfirmButton': 'Arquivar',
  'pages.versusVIP.myRooms.submitUnarchiveRoomConfirmButton': 'Tornar ativa',
  'pages.versusVIP.myRooms.goToHomeLink': 'Voltar para o início',
  'pages.versusVIP.myRooms.goToActiveRoomsButton':
    'Voltar para as salas ativas',
  'pages.versusVIP.myRooms.showArchivedRoomsButton': 'Mostrar salas arquivadas',
  'pages.versusVIP.myRooms.showActiveRoomsButton': 'Mostrar salas ativas',
  /* ------------ Messages ------------ */
  'pages.versusVIP.myRooms.messages.getRoomsError.title':
    'Erro ao carregar as salas',
  'pages.versusVIP.myRooms.messages.getArchivedRoomsError.title':
    'Erro ao carregar as salas ocultas',
  'pages.versusVIP.myRooms.messages.submitArchiveRoomConfirm.description':
    'Deseja realmente arquivar esse sala?',
  'pages.versusVIP.myRooms.messages.submitArchiveRoomSuccess.title':
    'Sala arquivada!',
  'pages.versusVIP.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Deseja realmente tornar essa sala ativa?',
  'pages.versusVIP.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Esse sala está ativa!',
  'pages.versusVIP.myRooms.messages.noRooms':
    'Você não está participando de nenhuma <span>sala</span>',
  'pages.versusVIP.myRooms.messages.viewingArchivedRooms':
    'Você está visualizando suas salas arquivadas',
  'pages.versusVIP.myRooms.messages.noArchivedRooms':
    'Você não possui nenhuma <span>sala arquivada</span>',
};

const PublicVersusRoomsTexts = {
  'pages.versusVIP.publicRooms.title': 'Salas públicas',
  'pages.versusVIP.publicRooms.searchInput.placeholder':
    'Pesquisar pelo nome da sala, times ou usuário',

  /* ------------ Messages ------------ */
  'pages.versusVIP.publicRooms.messages.viewingFindedOnSearchRooms':
    'Mostrando salas encontradas na busca',
  'pages.versusVIP.publicRooms.messages.noPublicRooms':
    'Nenhuma sala pública do <span>Versus</span> disponível',
  'pages.versusVIP.publicRooms.messages.noRooms': 'Nenhuma sala encontrada',
};

const NewVersusRoomTexts = {
  'pages.versusVIP.newRoom.title': 'Criar nova sala',
  'pages.versusVIP.newRoom.step1': 'Sala',
  'pages.versusVIP.newRoom.step2': 'Partida',
  'pages.versusVIP.newRoom.step3': 'Confirmar',

  'pages.versusVIP.newRoom.submitNewRoomConfirmButton': 'Criar sala',

  'pages.versusVIP.newRoom.form.roomType.validation.invalid':
    'O tipo da sala é inválido',
  'pages.versusVIP.newRoom.form.roomType.validation.required':
    'O tipo da sala é obrigatório',
  'pages.versusVIP.newRoom.form.name.validation.required':
    'O nome da sala é obrigatório',
  'pages.versusVIP.newRoom.form.price.validation.min':
    'O valor mínimo é T¢10.000',
  'pages.versusVIP.newRoom.form.price.validation.required':
    'O valor do jogo é obrigatório',

  'pages.versusVIP.newRoom.messages.blockPage.title':
    'Deseja realmente sair da criação da sala?',
  'pages.versusVIP.newRoom.messages.getChampionshipsError.title':
    'Ocorreu um erro ao carregar os campeonatos',
  'pages.versusVIP.newRoom.messages.getMatchsOfChampionshipError.title':
    'Ocorreu um erro ao carregar os jogos desse campeonato',
  'pages.versusVIP.newRoom.messages.submitNewRoomConfirm.title':
    'Deseja realmente criar essa sala?',
  'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.title':
    'Sala criada com sucesso!',
  'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.description':
    'Você já pode convidar seus amigos e realizar seu jogo',
  /* Room */
  ...{
    /* roomType - form */
    'pages.versusVIP.newRoom.form.roomType.label': 'Tipo da sala',
    'pages.versusVIP.newRoom.form.roomType.privateRoomTitle': 'Sala particular',
    'pages.versusVIP.newRoom.form.roomType.privateRoomDescription':
      'Apenas seus amigos e convidados podem entrar',
    'pages.versusVIP.newRoom.form.roomType.publicRoomTitle': 'Sala pública',
    'pages.versusVIP.newRoom.form.roomType.publicRoomDescription':
      'Todos na rede podem entrar',
    /* name - form */
    'pages.versusVIP.newRoom.form.name.label': 'Nome da sala',
    'pages.versusVIP.newRoom.form.name.placeholder': 'Digite o nome da sala',
    /* price - form */
    'pages.versusVIP.newRoom.form.price.label': 'Valor do jogo',
    'pages.versusVIP.newRoom.form.price.placeholder':
      'Digite um valor a partir de T¢10.000',
  },
  /* Match */
  ...{
    'pages.versusVIP.newRoom.form.selectedChampionship.label': 'Campeonato',
    'pages.versusVIP.newRoom.form.selectedMatch.label': 'Partida',

    'pages.versusVIP.newRoom.form.matchInfo.date.label': 'Data e hora',
    'pages.versusVIP.newRoom.form.matchInfo.place.label': 'Local',

    'pages.versusVIP.newRoom.messages.selectChampionship':
      'Selecione um campeonato',
    'pages.versusVIP.newRoom.messages.loadingChampionships':
      'Carregando campeonatos...',
    'pages.versusVIP.newRoom.messages.selectMatch': 'Selecione uma partida',
    'pages.versusVIP.newRoom.messages.loadingMatchs':
      'Carregando partidas do campeonato...',
    'pages.versusVIP.newRoom.messages.noChampionshipMatchs':
      'Esse campeonato não possui <span>partidas</span> disponíveis',
  },
};

export default {
  ...MyVersusRoomsTexts,
  ...PublicVersusRoomsTexts,
  ...NewVersusRoomTexts,
};
