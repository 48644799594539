import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  border-radius: 0.25rem;
`;

export const CardHeader = styled.div`
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']};
  display: flex;
  gap: 1rem;

  p {
    font-size: ${props => props.theme.typograph.sizes.h6}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    margin: 0;
    color: ${props => props.theme.colors.light.light0};
    flex: 1;

    span {
      color: ${props => props.theme.colors.secondary['secondary+1']};
      font-weight: ${props => props.theme.typograph.weights.medium};
    }
  }
`;

export const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
