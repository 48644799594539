import React, { useMemo } from 'react';
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route,
} from 'react-router-dom';
// import { HiOutlineExclamationCircle } from 'react-icons/hi';
// import { RiImageAddFill } from 'react-icons/ri';

import {
  Container,
  Header,
  ContentWrapper,
  Content,
  ImageWrapper,
  UserInformationsHeader,
} from './styles';

import Env from '../../config/Environment';

import { useAuth } from '../../context/AuthContext';
import { useIntl } from '../../context/IntlContext';

import DefaultAvatar from '../../assets/DefaultAvatar.svg';

import Details from './Details';
import ChangePassword from './ChangePassword';
import UpdateAvatar from './UpdateAvatar';
// import EditUserInformations from './EditUserInformations';
// import EditPersonalData from './EditPersonalData';

// const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
// const { confirm } = Modal;

const Profile: React.FC = () => {
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();
  // const [confirmRemoveAvatar, setConfirmRemoveAvatar] = useState(false);
  // const [loadingRemoveUserAvatar, setLoadingRemoveUserAvatar] = useState(false);

  // useEffect(() => {
  //   async function getUser(_id) {
  //     if (!_id) {
  //       return console.log('Erro!!');
  //     }

  //     try {
  //       const { data } = await api.get(`/api/user-admin/${_id}`);

  //       const isUser = !!data?.roles.find(role => role === 'user');

  //       if (!isUser) {
  //         toast.warn('Esse identificador não pertence a um usuário');
  //         return history.push('/users');
  //       }

  //       setUser({
  //         userInformations: {
  //           username: data?.username || 'Não informado',
  //           email: data?.email || 'Não informado',
  //         },
  //         personalData: {
  //           name: data?.name || 'Não informado',
  //           gender: data?.gender || 'Não informado',
  //           docNumber: data?.docNumber || 'Não informado',
  //           phone: data?.phones[0] || 'Não informado',
  //         },
  //         avatar: data?.photo?.filename || null,
  //         status: data?.status,
  //       });
  //       setLoadingUser(false);
  //     } catch (error) {
  //       setLoadingUser(false);
  //     }
  //     return '';
  //   }

  //   getUser(params?.id);
  // }, [history, params.id]);

  // const handleDisableUser = useCallback(async () => {
  //   const _id = params.id;

  //   if (!_id) {
  //     return toast.warn(
  //       'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
  //     );
  //   }

  //   try {
  //     await new Promise((resolve, reject) => {
  //       confirm({
  //         title: 'Deseja realmente desabilitar esse usuário?',
  //         icon: <ExclamationCircleOutlined />,
  //         content: 'Ele não terá mais acesso a plataforma Titan369',
  //         cancelText: 'Não',
  //         okText: 'Desativar usuário',
  //         okButtonProps: {
  //           danger: true,
  //         },
  //         onOk() {
  //           resolve();
  //         },
  //         onCancel() {
  //           reject(new Error('CANCELED_USER_DELETE_ACTION'));
  //         },
  //       });
  //     });

  //     await api.delete(`/api/user-admin/${_id}`);

  //     toast.success('Usuário desativado com sucesso');
  //     setUser({ ...user, status: 0 });
  //     // history.push('/users');
  //   } catch (error) {
  //     if (error.message === 'CANCELED_USER_DELETE_ACTION') {
  //       return null;
  //     }
  //   }

  //   return null;
  // }, [params.id, user]);

  // const handleEneableUser = useCallback(async () => {
  //   const _id = params.id;

  //   if (!_id) {
  //     return toast.warn(
  //       'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
  //     );
  //   }

  //   try {
  //     await new Promise((resolve, reject) => {
  //       confirm({
  //         title: 'Deseja realmente habilitar esse usuário?',
  //         icon: <ExclamationCircleOutlined />,
  //         content: 'Ele poderá ter acesso a plataforma Titan369',
  //         cancelText: 'Não',
  //         okText: 'Ativar usuário',
  //         okButtonProps: {
  //           // danger: true,
  //         },
  //         onOk() {
  //           resolve();
  //         },
  //         onCancel() {
  //           reject(new Error('CANCELED_USER_ENEABLE_ACTION'));
  //         },
  //       });
  //     });

  //     await api.put(`/api/user-admin/${_id}`, {
  //       status: 1,
  //     });

  //     toast.success('Usuário ativo com sucesso');
  //     setUser({ ...user, status: 1 });
  //   } catch (error) {
  //     if (error.message === 'CANCELED_USER_ENEABLE_ACTION') {
  //       return null;
  //     }
  //   }

  //   return null;
  // }, [params.id, user]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_user_informations') ||
      page.includes('/edit_personal_data')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  // if (loadingUser) {
  //   return (
  //     <Container>
  //       <Spin
  //         style={{
  //           alignSelf: 'center',
  //           marginTop: 'auto',
  //           marginBottom: 'auto',
  //         }}
  //         indicator={antIcon}
  //       />
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <Header>
        {/* <Breadcrums match={routeMatch} /> */}
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                history.push('/profile/update_avatar');
              }
            }}
            clickEneabled={currentLocation === 'main'}
          >
            <div>
              <strong>
                {intl.getTranslatedText('common.buttons.editPhotoMaskLabel')}
              </strong>
            </div>
            <img
              src={me?.photo ? Env.IMAGE_SERVER_URL + me?.photo : DefaultAvatar}
              alt={me?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationsHeader>
            <div>
              <div>
                <h5>{me?.name}</h5>
              </div>
              <p>{me?.username}</p>
              <p>{me?.email}</p>
            </div>
          </UserInformationsHeader>
        </div>
      </Header>
      <ContentWrapper>
        <Content>
          <Switch>
            <Route exact path={routeMatch.path}>
              <Details />
            </Route>
            <Route path={`${routeMatch.path}/change_password`}>
              <ChangePassword />
            </Route>
            <Route path={`${routeMatch.path}/update_avatar`}>
              <UpdateAvatar />
            </Route>
            <Route path={`${routeMatch.path}/edit_user_informations`}>
              {/* <EditUserInformations
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            /> */}
            </Route>
            <Route path={`${routeMatch.path}/edit_personal_data`}>
              {/* <EditPersonalData
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            /> */}
            </Route>
          </Switch>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default Profile;
