import React, { useMemo } from 'react';

import Select from '@/components/Select';

import { useNewChallenge } from '../NewChallengeContext';
import {
  AthleteChallenge,
  CoachFriendModule,
  MinuteOfFirstGoalChallenge,
  ScoreChallenge,
  StatisticsChallenge,
} from '../types';

import { Container } from './styles';
import {
  AthleteChallengeExample,
  MinuteOfFirstGoalChallengeExample,
  ScoreChallengeExample,
  StatisticsChallengeExample,
} from './types';

const SelectChallengeContainer: React.FC = () => {
  const newChallengeContext = useNewChallenge();

  const challengeSelectOptions = useMemo(() => {
    if (!newChallengeContext.selectedModule) return [];

    let challengeEnum = {} as { [key: string]: string };
    let challengeExampleEnum = {} as { [key: string]: string };

    if (newChallengeContext.selectedModule === CoachFriendModule.SCOREBOARD) {
      challengeEnum = ScoreChallenge;
      challengeExampleEnum = ScoreChallengeExample;
    } else if (
      newChallengeContext.selectedModule ===
      CoachFriendModule.MINUTE_OF_FIRST_GOAL
    ) {
      challengeEnum = MinuteOfFirstGoalChallenge;
      challengeExampleEnum = MinuteOfFirstGoalChallengeExample;
    } else if (
      newChallengeContext.selectedModule === CoachFriendModule.ATHLETES
    ) {
      challengeEnum = AthleteChallenge;
      challengeExampleEnum = AthleteChallengeExample;
    } else if (
      newChallengeContext.selectedModule === CoachFriendModule.STATISTICS
    ) {
      challengeEnum = StatisticsChallenge;
      challengeExampleEnum = StatisticsChallengeExample;
    }

    return Object.values(challengeEnum).map(challenge => {
      return { label: challengeExampleEnum[challenge], value: challenge };
    });
  }, [newChallengeContext.selectedModule]);

  return (
    <Container>
      <Select
        id="moduleSelect"
        label="Módulo"
        bordered
        placeholder="Selecione"
        options={[
          {
            label: `Placar`,
            value: CoachFriendModule.SCOREBOARD,
          },
          {
            label: `Minuto do primeiro gol`,
            value: CoachFriendModule.MINUTE_OF_FIRST_GOAL,
          },
          {
            label: `Artilheiros`,
            value: CoachFriendModule.ATHLETES,
          },
          {
            label: `Estatísticas`,
            value: CoachFriendModule.STATISTICS,
          },
        ]}
        onChange={value => {
          newChallengeContext.setSelectedChallenge('');
          newChallengeContext.setSelectedModule(value as string);
        }}
        value={newChallengeContext.selectedModule || null}
      />
      <Select
        id="challengeSelect"
        label="Desafio"
        placeholder="Selecione"
        bordered
        disabled={!newChallengeContext.selectedModule}
        options={challengeSelectOptions}
        onChange={value => {
          newChallengeContext.setSelectedChallenge(value as string);
        }}
        value={newChallengeContext.selectedChallenge || null}
      />
    </Container>
  );
};

export default SelectChallengeContainer;
