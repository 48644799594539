import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { IChatContainerRefMethods } from '../pages/Chats/Chat/MessagesContainer';
import { showToast } from '../hooks/showToast';
import { useIntl } from './IntlContext';

import { useAuth } from './AuthContext';

import api from '../services/api';
import socket from '../services/socket';

export interface IChatMessage {
  _id: string;
  status: boolean;
  isRead: boolean;
  text?: string;
  voiceMsg?: string;
  // voiceMsg?: {
  //   _id: string;
  //   filename: string;
  // };
  _user: string;
  user: {
    _id: string;
    name: string;
    username: string;
    email: string;
    photo: string;
  };
  createdAt: string;
}

interface IChatUser {
  _id: string;
  username: string;
  name: string;
  email: string;
  photo: string;
}

interface IChatState {
  _id: string;
  users: IChatUser[];
  messages: IChatMessage[];
  friend?: IChatUser;
  // status: boolean;
  // createdAt: string;
  // updatedAt: string;
}

interface IChatMessageResponse {
  messages: IChatMessage;
}

interface IChatResponse extends Omit<IChatState, 'messages'> {
  doc: IChatMessageResponse[];
  limit: number;
  page: number;
  pages: number;
  total: number;
}

export interface ISendNewMessageData {
  text?: string;
  audio?: File | null;
}

export interface IChatData {
  _user: string;
}

export interface IChatContainer {
  messages: IChatMessage[];
  loadingMessages: boolean;
  handleSubmitNewMessage({ text, audio }: ISendNewMessageData): Promise<void>;
  hasMoreMessages: boolean;
  handleGetMoreMessages(): void;
}

interface ChatP2PContextData {
  unreadChatsCount: number;
  markAsRead(): void;
  setCurrentChatData(data: IChatData | null): void;
  setChatContainerRef(ref: IChatContainerRefMethods): void;
  chatContainerProps: IChatContainer;
  chat: IChatState | null;
  handleSubmitMessageReport(_messageId: string, report: string): Promise<void>;
  handleSubmitDeleteMessage(_messageId: string): Promise<void>;
  loadingSendMessageReport: boolean;
  currentPlayAudioMessageId: string;
  setCurrentPlayAudioMessageId: React.Dispatch<React.SetStateAction<string>>;
}

const { confirm } = Modal;

const ChatP2PContext = createContext<ChatP2PContextData>(
  {} as ChatP2PContextData,
);

const ChatP2PProvider: React.FC = ({ children }) => {
  const chatContainerRef = useRef<IChatContainerRefMethods | null>(null);
  const firstRender = useRef(true);

  const { user: me } = useAuth();
  const intl = useIntl();
  const [unreadChatsCount, setUnreadChatsCount] = useState(0);

  const [chatData, setChatData] = useState<IChatData | null>(null);
  const [chat, setChat] = useState<IChatState | null>(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [currentPlayAudioMessageId, setCurrentPlayAudioMessageId] =
    useState('');

  const [loadingSendMessageReport, setLoadingSendMessageReport] =
    useState(false);

  const getChatsCount = useCallback(async () => {
    try {
      const { data } = await api.get<{
        doc: IChatState[];
        page: number;
        pages: number;
        unread: number;
      }>('/api/chat/p2p', {
        params: {
          limit: 1,
        },
      });

      setUnreadChatsCount(data.unread);
    } catch (error) {
      // console.log(error);
    }
  }, []);

  const getChat = useCallback(
    async (lastContent = '') => {
      try {
        setLoadingMessages(true);

        await api.get(`/api/chat/p2p/${chatData?._user}`);

        const { data } = await api.get<IChatResponse>(`/api/chat/msg`, {
          params: {
            id: chatData?._user,
            limit: 20,
            lastContent,
          },
        });

        const friend = data.users.find(user => user._id !== me?._id);

        const orderedMessages = [
          ...data.doc.map(message => {
            if (message.messages._user === me?._id) {
              return {
                ...message.messages,
                user:
                  data.users.find(user => user._id === me?._id) ||
                  ({} as IChatUser),
              };
            }
            return {
              ...message.messages,
              user: friend || ({} as IChatUser),
            };
          }),
        ];
        orderedMessages.reverse();

        if (!lastContent) {
          setChat({
            ...data,
            friend: data.users.find(user => user._id !== me?._id),
            messages: orderedMessages,
          });
          chatContainerRef.current?.scrollMessagesToBottom();
        } else {
          setChat(oldState => {
            if (oldState) {
              return {
                ...oldState,
                messages: [...orderedMessages, ...oldState.messages],
              };
            }
            return null;
          });
        }

        if (data.pages > 1) {
          setHasMoreMessages(true);
        } else {
          setHasMoreMessages(false);
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMessages(false);
    },
    [chatData?._user, me?._id, intl],
  );

  const handleSubmitNewMessage = useCallback(
    async ({ text, audio }: ISendNewMessageData): Promise<void> => {
      if ((!text && !audio) || !chat) return;

      try {
        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'contexts.chatP2PContext.messages.submitNewMessageSuccess.title',
          ),
        });

        if (text) {
          await api.post('/api/chat/p2p/msg', {
            id: chat?._id,
            text,
          });
        } else if (audio) {
          const formData = new FormData();
          formData.append('audio', audio);
          formData.append('id', chat._id);

          await api.post('/api/chat/p2p/msg', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'contexts.chatP2PContext.messages.submitNewMessageError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [chat, intl],
  );

  const handleSubmitMessageReport = useCallback(
    async (_messageId: string, report: string): Promise<void> => {
      if (!_messageId || !report) return;

      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'contexts.chatP2PContext.messages.messageReportSubmitConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'contexts.chatP2PContext.messages.messageReportSubmitConfirm.confirmButton',
            ),
            onOk() {
              resolve(true);
            },
          });
        });

        // const body = {
        //   _id: _messageId,
        //   denounce: report,
        // };
        setLoadingSendMessageReport(true);
        // await api.post(chatUrls.denounce.post, body);
        setLoadingSendMessageReport(false);

        showToast({
          type: 'warn',
          title: 'Report not sent!',
          description: 'This functionality is unstable',
        });

        // showToast({
        //   type: 'success',
        //   title: 'Denúncia enviada!',
        //   description:
        //     'Sua denúncia foi enviada e será analisada pelo suporte da plataforma Titan369',
        // });
        // history.push(`/athletes_of_the_week/${params.card_game_id}`);
      } catch (error) {
        setLoadingSendMessageReport(false);
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const handleSubmitDeleteMessage = useCallback(
    async (_messageId: string): Promise<void> => {
      if (!_messageId) return;

      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.confirmButton',
            ),
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve(true);
            },
          });
        });

        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'contexts.chatP2PContext.messages.deleteMessageSubmitSuccess.title',
          ),
        });

        await api.delete(`/api/chat/${chat?._id}/msg/${_messageId}`);
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'contexts.chatP2PContext.messages.deleteMessageSubmitError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [chat?._id, intl],
  );

  const setCurrentChatData = useCallback((data: IChatData | null) => {
    setChatData(data);
    if (!data) {
      setChat(null);
    }
  }, []);

  const setChatContainerRef = useCallback((ref: IChatContainerRefMethods) => {
    chatContainerRef.current = ref;
  }, []);

  useEffect(() => {
    if (firstRender.current && me) {
      firstRender.current = false;

      getChatsCount();
    }

    if (chatData) {
      getChat();
    }
  }, [chatData, getChat, getChatsCount, me]);

  useEffect(() => {
    socket.on(`chat-p2p-unread`, (data: { unread: number }) => {
      setUnreadChatsCount(data.unread);
    });

    return () => {
      socket.off(`chat-p2p-unread`);
    };
  }, []);

  useEffect(() => {
    if (chat?._id) {
      socket.on(
        `chat-p2p:${chat._id}`,
        (data: { _id: string; messages: IChatMessage }) => {
          if (data.messages._user !== me?._id) {
            api.get('/api/chat/mark-as-read', {
              params: {
                id: chatData?._user,
              },
            });
          }
          setChat(oldState => {
            if (oldState) {
              const userIsFriend = oldState.users.find(
                user => user._id !== me?._id,
              );
              const userIsMe = oldState.users.find(
                user => user._id === me?._id,
              );
              let newMessageWithUserProperty;

              if (data.messages._user === me?._id) {
                newMessageWithUserProperty = {
                  ...data.messages,
                  user: userIsMe || ({} as IChatUser),
                };
              } else {
                newMessageWithUserProperty = {
                  ...data.messages,
                  user: userIsFriend || ({} as IChatUser),
                };
              }

              return {
                ...oldState,
                messages: [...oldState?.messages, newMessageWithUserProperty],
              };
            }
            return null;
          });
          if (chatContainerRef.current) {
            chatContainerRef.current.scrollMessagesToBottom();
          }
        },
      );
      socket.on(
        `chat-p2p-delete-msg:${chat?._id}`,
        (data: { _id: string; _message: string }) => {
          setChat(oldState => {
            if (oldState) {
              return {
                ...oldState,
                messages: oldState.messages.filter(
                  message => message._id !== data._message,
                ),
              };
            }

            return null;
          });
        },
      );
      socket.on(`chat-p2p-read:${chat?._id}`, () => {
        /* data: { _id: string; _user: string } */
        setChat(oldState => {
          if (oldState) {
            return {
              ...oldState,
              messages: oldState.messages.map(message => {
                if (message._user === me?._id) {
                  return {
                    ...message,
                    isRead: true,
                  };
                }

                return message;
              }),
            };
          }

          return null;
        });
      });
    }

    return () => {
      if (chat?._id) {
        socket.off(`chat-p2p:${chat._id}`);
        socket.off(`chat-p2p-delete-msg:${chat?._id}`);
        socket.off(`chat-p2p-read:${chat?._id}`);
      }
    };
  }, [chat?._id, chatData?._user, me?._id]);

  const markAsRead = useCallback(() => {
    setUnreadChatsCount(oldState => (oldState > 0 ? oldState - 1 : 0));
  }, []);

  return (
    <ChatP2PContext.Provider
      value={{
        unreadChatsCount,
        markAsRead,
        setCurrentChatData,
        setChatContainerRef,
        chat,
        chatContainerProps: {
          messages: chat?.messages || [],
          loadingMessages,
          hasMoreMessages,
          handleSubmitNewMessage,
          handleGetMoreMessages: () => {
            if (chat) {
              getChat(chat.messages[0]._id);
            }
          },
        },
        handleSubmitMessageReport,
        handleSubmitDeleteMessage,
        loadingSendMessageReport,
        currentPlayAudioMessageId,
        setCurrentPlayAudioMessageId,
      }}
    >
      {children}
    </ChatP2PContext.Provider>
  );
};

function useChatP2P(): ChatP2PContextData {
  const context = useContext(ChatP2PContext);

  if (!context) {
    throw new Error('useChatP2P must be used within a ChatP2PProvider');
  }

  return context;
}

export { ChatP2PProvider, useChatP2P };
