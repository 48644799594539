import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent,
} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';
import Countdown from 'react-countdown';

import Env from '../../config/Environment';

import CustomAntButton from '../../components/CustomAntButton';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import {
  Container,
  Header,
  Content,
  CardGameItem,
  LoadMoreCardsButton,
  RestTimerContainer,
  FilterItem,
} from './styles';
import api from '../../services/api';

const { Search } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO } = dateFns;

interface ICard {
  _id: string;
  status: boolean;
  name: string;
  startDate: string;
  banner?: {
    _id: string;
    filename: string;
  };
}

const AthletesOfTheWeek: React.FC = () => {
  const { url } = useRouteMatch();

  const intl = useIntl();

  const [cardsAvailableForPlay, setCardsAvailableForPlay] = useState<ICard[]>(
    [],
  );
  const [cardsAvailableForPlayPagination, setCardsAvailableForPlayPagination] =
    useState({
      limit: 10,
      currentPage: 1,
      totalPages: 0,
    });

  const [runningCards, setRunningCards] = useState<ICard[]>([]);
  const [runningCardsPagination, setRunningCardsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [finishedCards, setFinishedCards] = useState<ICard[]>([]);
  const [finishedCardsPagination, setFinishedCardsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [loadingCardGames, setLoadingCardGames] = useState(true);
  const [selectedViewer, setSelectedViewer] = useState<
    'availableForPlay' | 'running' | 'finished'
  >('availableForPlay');

  const [findedOnSearchCards, setFindedOnSearchCards] = useState<ICard[]>([]);
  const [findedOnSearchCardsPagination, setFindedOnSearchCardsPagination] =
    useState({
      limit: 10,
      currentPage: 1,
      totalPages: 0,
    });

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const getCardsAvailablesForPlay = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'new',
            page,
            limit: cardsAvailableForPlayPagination.limit,
          },
        });

        if (page === 1) {
          setCardsAvailableForPlay(data.docs);
        } else {
          setCardsAvailableForPlay(oldState => [...oldState, ...data.docs]);
        }
        setCardsAvailableForPlayPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [cardsAvailableForPlayPagination.limit, intl],
  );

  const getRunningCards = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'running',
            page,
            limit: runningCardsPagination.limit,
          },
        });

        if (page === 1) {
          setRunningCards(data.docs);
        } else {
          setRunningCards(oldState => [...oldState, ...data.docs]);
        }
        setRunningCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [intl, runningCardsPagination.limit],
  );

  const getFinishedCards = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'finished',
            page,
            limit: finishedCardsPagination.limit,
          },
        });

        if (page === 1) {
          setFinishedCards(data.docs);
        } else {
          setFinishedCards(oldState => [...oldState, ...data.docs]);
        }
        setFinishedCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [finishedCardsPagination.limit, intl],
  );

  useEffect(() => {
    if (
      selectedViewer === 'availableForPlay' &&
      cardsAvailableForPlay.length === 0
    ) {
      getCardsAvailablesForPlay();
    } else if (selectedViewer === 'running' && runningCards.length === 0) {
      getRunningCards();
    } else if (selectedViewer === 'finished' && finishedCards.length === 0) {
      getFinishedCards();
    }
  }, [
    cardsAvailableForPlay.length,
    finishedCards.length,
    getCardsAvailablesForPlay,
    getFinishedCards,
    getRunningCards,
    runningCards.length,
    selectedViewer,
  ]);

  const handleGetCardGames = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingSearch(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            search,
            page,
            limit: findedOnSearchCardsPagination.limit,
          },
        });

        if (page === 1) {
          setFindedOnSearchCards(data.docs);
        } else {
          setFindedOnSearchCards(oldState => [...oldState, ...data.docs]);
        }
        setFindedOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingSearch(false);
    },
    [findedOnSearchCardsPagination.limit, intl],
  );

  const cardSearchDebounced = useMemo(() => {
    return _.debounce(handleGetCardGames, 500);
  }, [handleGetCardGames]);

  const handleSearchCardGame = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      cardSearchDebounced.cancel();

      if (!searchValue) {
        setSearching(true);
      } else if (!e.target.value.length) {
        setSearching(false);
      }
      setSearchValue(e.target.value);

      if (e.target.value.length > 3) {
        setFindedOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
        setLoadingSearch(true);
        cardSearchDebounced(e.target.value);
      } else {
        setLoadingSearch(false);
        setFindedOnSearchCards([]);
        setFindedOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
      }
    },
    [searchValue, cardSearchDebounced],
  );

  const cardGamesViewer = useMemo(() => {
    const viewerPaginationOptionsData = {
      availableForPlay: cardsAvailableForPlayPagination,
      running: runningCardsPagination,
      finished: finishedCardsPagination,
    };

    if (
      loadingCardGames &&
      viewerPaginationOptionsData[selectedViewer].totalPages === 1
    ) {
      return (
        <div>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.messages.loadingCards',
              )}
            </p>
          </div>
        </div>
      );
    }

    const viewerOptionsData = {
      availableForPlay: cardsAvailableForPlay,
      running: runningCards,
      finished: finishedCards,
    };

    const loadMoreOptionsData = {
      availableForPlay: getCardsAvailablesForPlay,
      running: getRunningCards,
      finished: getFinishedCards,
    };

    if (viewerOptionsData[selectedViewer].length > 0) {
      return (
        <section>
          {viewerOptionsData[selectedViewer]?.map(cardGame => (
            <CardGameItem
              $bg={
                cardGame.banner && Env.IMAGE_SERVER_URL + cardGame.banner?._id
              }
              to={`${url}/${cardGame._id}`}
              key={cardGame._id}
            >
              {/* <IoMdFootball size={24} /> */}
              <div>
                {selectedViewer === 'availableForPlay' && (
                  <Countdown
                    date={parseISO(cardGame.startDate)}
                    renderer={({
                      days,
                      hours,
                      minutes,
                      seconds,
                      completed,
                    }) => {
                      if (completed) {
                        return <></>;
                      }

                      return (
                        <RestTimerContainer>
                          <div>
                            <h6>
                              {days.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText('common.countDown.days')}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {hours.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText('common.countDown.hours')}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {minutes.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText(
                                'common.countDown.minutes',
                              )}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {seconds.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText(
                                'common.countDown.seconds',
                              )}
                            </small>
                          </div>
                        </RestTimerContainer>
                      );
                    }}
                  />
                )}
                <h6>{cardGame.name}</h6>
                {/* <p>
                        Vestibulum ac diam sit amet quam vehicula elementum sed
                        sit Praesent sapien massa
                      </p> */}
              </div>
            </CardGameItem>
          ))}
          {viewerPaginationOptionsData[selectedViewer].currentPage <
            viewerPaginationOptionsData[selectedViewer].totalPages && (
            <LoadMoreCardsButton
              onClick={() => {
                if (loadingCardGames) return;

                loadMoreOptionsData[selectedViewer](
                  viewerPaginationOptionsData[selectedViewer].currentPage + 1,
                );
                // handleSearchRooms(
                //   searchInputValue,
                //   roomsPagination.currentPage + 1,
                // );
              }}
              disabled={loadingCardGames}
            >
              <h6>
                {!loadingCardGames
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </h6>
            </LoadMoreCardsButton>
          )}
        </section>
      );
    }

    const viewerDescription = {
      availableForPlay: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.availableForPlay',
      ),
      running: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.running',
      ),
      finished: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.finished',
      ),
    };

    return (
      <div>
        <h6>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.messages.noCards',
            {
              viewerDescription: viewerDescription[selectedViewer],
            },
          )}
        </h6>
      </div>
    );
  }, [
    cardsAvailableForPlay,
    cardsAvailableForPlayPagination,
    finishedCards,
    finishedCardsPagination,
    getCardsAvailablesForPlay,
    getFinishedCards,
    getRunningCards,
    intl,
    loadingCardGames,
    runningCards,
    runningCardsPagination,
    selectedViewer,
    url,
  ]);

  const searchResultsViewer = useMemo(() => {
    if (searchValue.length <= 3) {
      return (
        <div>
          <h6>{intl.getTranslatedText('common.messages.minCharToSearch')}</h6>
        </div>
      );
    }

    if (loadingSearch && findedOnSearchCardsPagination.totalPages === 1) {
      return (
        <div>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
          </div>
        </div>
      );
    }

    if (findedOnSearchCards.length > 0) {
      return (
        <section>
          {findedOnSearchCards?.map(cardGame => (
            <CardGameItem
              $bg={
                cardGame.banner && Env.IMAGE_SERVER_URL + cardGame.banner?._id
              }
              to={`${url}/${cardGame._id}`}
              key={cardGame._id}
            >
              <div>
                <h6>{cardGame.name}</h6>
              </div>
            </CardGameItem>
          ))}
          {findedOnSearchCardsPagination.currentPage <
            findedOnSearchCardsPagination.totalPages && (
            <LoadMoreCardsButton
              onClick={() => {
                if (loadingSearch) return;

                handleGetCardGames(
                  searchValue,
                  findedOnSearchCardsPagination.currentPage + 1,
                );
              }}
              disabled={loadingSearch}
            >
              <h6>
                {!loadingSearch
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </h6>
            </LoadMoreCardsButton>
          )}
        </section>
      );
    }

    return (
      <div>
        <h6>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.messages.cardNotFound',
          )}
        </h6>
      </div>
    );
  }, [
    findedOnSearchCards,
    findedOnSearchCardsPagination.currentPage,
    findedOnSearchCardsPagination.totalPages,
    handleGetCardGames,
    intl,
    loadingSearch,
    searchValue,
    url,
  ]);

  return (
    <Container>
      <Header>
        <div>
          {/* <img src={championship?.imageUrl} alt={championship?.name} /> */}
          <div>
            <h6>
              {intl.getTranslatedText('common.gamesTitles.athletesOfTheWeek')}
            </h6>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Reprehenderit consequuntur laborum perferendis accusamus vel
              expedita praesentium deserunt nobis quibusdam aut, sapiente
              tenetur at odit consequatur nemo. Harum atque distinctio
              praesentium.
            </p> */}
          </div>
        </div>
      </Header>
      <div>
        <Content>
          <div>
            <Search
              placeholder={intl.getTranslatedText(
                'pages.athletesOfTheWeek.searchCardsInput.placeholder',
              )}
              onChange={handleSearchCardGame}
              value={searchValue}
            />
            <Link to={`${url}/card_games_history`}>
              <CustomAntButton
                type="default"
                // useCustomIcon
                // textPrimary
                // icon={<FiClock size={20} />}
              >
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.myGamesButton',
                )}
              </CustomAntButton>
            </Link>
          </div>
          {!searching ? (
            <>
              <div>
                <FilterItem
                  disabled={loadingCardGames}
                  onClick={() => setSelectedViewer('finished')}
                  $selected={selectedViewer === 'finished'}
                >
                  <p>
                    {intl.getTranslatedText(
                      'pages.athletesOfTheWeek.views.finished',
                    )}
                  </p>
                </FilterItem>
                <FilterItem
                  disabled={loadingCardGames}
                  onClick={() => setSelectedViewer('availableForPlay')}
                  $selected={selectedViewer === 'availableForPlay'}
                >
                  <p>
                    {intl.getTranslatedText(
                      'pages.athletesOfTheWeek.views.availableForPlay',
                    )}
                  </p>
                </FilterItem>
                <FilterItem
                  disabled={loadingCardGames}
                  onClick={() => setSelectedViewer('running')}
                  $selected={selectedViewer === 'running'}
                >
                  <p>
                    {intl.getTranslatedText(
                      'pages.athletesOfTheWeek.views.running',
                    )}
                  </p>
                </FilterItem>
              </div>
              {cardGamesViewer}
            </>
          ) : (
            <>
              <div />
              {searchResultsViewer}
            </>
          )}

          {/* <section>
            {cardsAvailableForPlay?.map(cardGame => (
              <CardGameItem
                to={`${url}/card_games/${cardGame._id}`}
                key={cardGame._id}
              >
                <div>
                  <h6>{cardGame.name}</h6>
                </div>
              </CardGameItem>
            ))}
          </section> */}
        </Content>
      </div>
    </Container>
  );
};

export default AthletesOfTheWeek;
