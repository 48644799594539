import styled from 'styled-components';

export const Container = styled.li`
  list-style: none;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  cursor: default;

  @media only screen and (min-width: 1450px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;

  h6 {
    flex: 1;
    margin-bottom: 0;
    text-transform: uppercase;
    line-height: 120%;
  }

  small {
    max-width: 30%;
    margin-left: 16px;
  }
`;

export const MatchCardSelectedMatchChampionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
    color: ${props => props.theme.typograph.sizes.p};
  }
`;
export const MatchCardSelectedMatchChampion = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    img {
      height: 26px;
    }
  }

  strong {
    font-size: ${props => props.theme.typograph.sizes.p}px;
  }
`;
export const MatchCardSelectPlayersContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};

  strong {
    color: ${props => props.theme.typograph.sizes.p};
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: default;

    div {
      width: 100%;

      small {
        color: ${props => props.theme.colors.primary.primary0};
      }

      p {
        color: ${props => props.theme.colors.light['light-2']};
      }
    }
  }

  /* > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      background: ${props => props.theme.colors.dark['dark+2']};
      max-width: 136px;
      padding: 8px;
      border-radius: 8px;
      transition: 0.2s opacity;

      small {
        color: ${props => props.theme.colors.light['light-2']};
        width: 100%;
        text-align: center;
      }
    }
  } */
`;
export const MatchCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;

  div {
    width: 100%;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
