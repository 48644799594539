import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  UIEvent,
} from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { isBrowser } from 'react-device-detect';

import { Container, ScrollButton, ViewMoreButton } from './styles';

interface ISessionList {
  sizeItems: {
    width: number;
    height: number;
  };
  gapItems: number;
  onEndReached?: () => Promise<void>;
  hasMoreItems?: boolean;
  loadingMore?: boolean;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const SessionList: React.FC<ISessionList> = ({
  children,
  sizeItems,
  gapItems,
  onEndReached,
  hasMoreItems,
  loadingMore,
}) => {
  const sessionListRef = useRef<HTMLUListElement>(null);
  // const loadingRef = useRef<HTMLDivElement>(null);

  const [showScrollLeftButton, setShowScrollLeftButton] = useState(false);
  const [showScrollRightButton, setShowScrollRightButton] = useState(false);

  const handleSessionResize = useCallback(() => {
    if (sessionListRef.current) {
      if (
        sessionListRef?.current?.scrollWidth ===
        sessionListRef?.current?.clientWidth
      ) {
        setShowScrollLeftButton(false);
        setShowScrollRightButton(false);

        // if (showScrollLeftButton) {
        //   setShowScrollLeftButton(false);
        // }
        // if (showScrollRightButton) {
        //   setShowScrollRightButton(false);
        // }
      } else {
        // if (
        //   !showScrollLeftButton &&
        //   sessionListRef?.current?.scrollLeft !== 0
        // ) {
        //   setShowScrollLeftButton(true);
        // }
        // if (!showScrollRightButton) {
        //   setShowScrollRightButton(true);
        // }
        if (sessionListRef?.current?.scrollLeft !== 0) {
          setShowScrollLeftButton(true);
        }
        setShowScrollRightButton(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleSessionResize);

    return () => {
      window.removeEventListener('resize', handleSessionResize);
    };
  }, [handleSessionResize]);

  // const handleObserver = useCallback(
  //   async (entities: IntersectionObserverEntry[]): Promise<void> => {
  //     const y = entities[0];
  //     if (y.isIntersecting) {
  //       console.log('chegou no fim');
  //       if (onEndReached && !loadingMore) {
  //         // setOnEndReachedHasCalled(true);
  //         await onEndReached();
  //         setShowScrollRightButton(true);
  //         // setOnEndReachedHasCalled(false);
  //       }
  //     }
  //   },
  //   [loadingMore, onEndReached],
  // );

  // const observer = useMemo(() => {
  //   return new IntersectionObserver(
  //     async (entities: IntersectionObserverEntry[]): Promise<void> => {
  //       const y = entities[0];
  //       if (y.isIntersecting) {
  //         if (onEndReached && !loadingMore) {
  //           // setOnEndReachedHasCalled(true);
  //           await onEndReached();
  //           setShowScrollRightButton(true);
  //           // setOnEndReachedHasCalled(false);
  //         }
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: '0px',
  //       threshold: 1.0,
  //     },
  //   );
  // }, [loadingMore, onEndReached]);

  // useEffect(() => {
  //   // observer.observe(this.loadingRef);
  //   console.log('alterou!');
  //   if (loadingRef.current) {
  //     observer.observe(loadingRef.current);
  //   }
  // }, [observer]);

  const handleScrollSession = useCallback(
    (e: UIEvent<HTMLUListElement>): void => {
      const { clientWidth, scrollWidth, scrollLeft } = e.currentTarget;

      if (scrollLeft > 0) {
        if (!showScrollLeftButton) {
          setShowScrollLeftButton(true);
        }
      } else {
        setShowScrollLeftButton(false);
      }

      if (clientWidth + scrollLeft === scrollWidth) {
        setShowScrollRightButton(false);
      } else if (!showScrollRightButton) {
        setShowScrollRightButton(true);
      }
    },
    [showScrollLeftButton, showScrollRightButton],
  );

  useEffect(() => {
    const session = sessionListRef.current;
    if (session) {
      session.addEventListener('scroll', (e: any) => handleScrollSession(e));
    }

    return () => {
      if (session) {
        session.addEventListener('scroll', (e: any) => handleScrollSession(e));
      }
    };
  }, [handleScrollSession]);

  useEffect(() => {
    handleSessionResize();
  }, [handleSessionResize]);

  const handleScrollListForRight = useCallback(() => {
    if (sessionListRef.current) {
      sessionListRef.current.scrollLeft +=
        Math.floor(
          (sessionListRef.current.clientWidth - 112) /
            (sizeItems.width + gapItems),
        ) *
        (sizeItems.width + gapItems);
      if (
        sessionListRef.current.scrollLeft +
          sessionListRef.current.clientWidth ===
        sessionListRef.current.scrollWidth
      ) {
        setShowScrollRightButton(false);
      }
      setShowScrollLeftButton(true);
    }
  }, [gapItems, sizeItems.width]);

  const handleScrollListForLeft = useCallback(() => {
    if (sessionListRef.current) {
      sessionListRef.current.scrollLeft -=
        Math.floor(
          (sessionListRef.current.clientWidth - 112) /
            (sizeItems.width + gapItems),
        ) *
        (sizeItems.width + gapItems);
      if (sessionListRef.current.scrollLeft === 0) {
        setShowScrollLeftButton(false);
      }
      setShowScrollRightButton(true);
    }
  }, [gapItems, sizeItems.width]);

  return (
    <Container>
      {showScrollLeftButton && isBrowser && (
        <ScrollButton
          onClick={handleScrollListForLeft}
          type="button"
          height={sizeItems.height}
        >
          <FiChevronLeft size={32} />
        </ScrollButton>
      )}
      <ul ref={sessionListRef}>
        {children}
        {/* <LoadingContainer
          ref={loadingRef}
          style={{ display: hasMoreItems ? 'flex' : 'none' }}
        >
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>Carregando...</p>
        </LoadingContainer> */}
        {hasMoreItems && (
          <ViewMoreButton
            onClick={async () => {
              if (!loadingMore && onEndReached) {
                await onEndReached();
                setShowScrollRightButton(true);
              }
            }}
            disabled={loadingMore}
          >
            {!loadingMore ? (
              <p>Ver mais</p>
            ) : (
              <>
                <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
                <p>Carregando...</p>
              </>
            )}
          </ViewMoreButton>
        )}
      </ul>
      {showScrollRightButton && isBrowser && (
        <ScrollButton
          onClick={handleScrollListForRight}
          type="button"
          height={sizeItems.height}
        >
          <FiChevronRight size={32} />
        </ScrollButton>
      )}
    </Container>
  );
};

export default SessionList;
