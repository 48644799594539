import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import api from '../services/api';
import socket from '../services/socket';
import { showToast } from '../hooks/showToast';
import { useIntl } from './IntlContext';

interface IUser {
  _id: string;
  name: string;
  email: string;
  username: string;
  docNumber: string;
  gender: 'M' | 'F' | '0';
  phone: string;
  photo: string;
  roles: string[];
}

interface SignInCredentials {
  username: string;
  password: string;
}

interface AuthState {
  token: string | null;
  user: IUser | null;
}

interface AuthContextData extends AuthState {
  loadingUserData: boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(expiredToken?: boolean): Promise<void>;
  updateAvatar(file: File | null): Promise<void>;
  loadingUpdateAvatar: boolean;
}

const { confirm } = Modal;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const intl = useIntl();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@LanceFutebol:AuthToken');

    if (token) {
      api.defaults.headers.Authorization = token;
    }

    return {
      user: null,
      token,
    };
  });
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [loadingUpdateAvatar, setLoadingUpdateAvatar] = useState(false);

  const getAuthenticatedUserData = useCallback(async (): Promise<void> => {
    if (data.token) {
      const { data: responseData } = await api.get<IUser>('/api/me');
      setData(oldData => ({ ...oldData, user: responseData }));

      socket.io.opts.query = {
        _user: responseData._id,
      };

      socket.connect();
    }

    setLoadingUserData(false);
  }, [data.token]);

  useEffect(() => {
    getAuthenticatedUserData();
  }, [getAuthenticatedUserData]);

  const signIn = useCallback(
    async ({ username, password }: SignInCredentials) => {
      const response = await api.post('/auth/local', {
        username,
        password,
      });

      const { token } = response.data;

      localStorage.setItem('@LanceFutebol:AuthToken', token);
      api.defaults.headers.Authorization = token;

      setData({
        token,
        user: null,
      });

      getAuthenticatedUserData();
    },
    [getAuthenticatedUserData],
  );

  const signOut = useCallback(async (expiredToken = false) => {
    try {
      if (!expiredToken) {
        await api.delete('/auth/logout');
      }

      localStorage.removeItem('@LanceFutebol:AuthToken');
      api.defaults.headers.Authorization = null;

      setData({
        token: null,
        user: null,
      });
    } catch (error) {
      console.log('signOut error');
      console.log(error);
    }
    setLoadingUserData(false);
  }, []);

  const updateAvatar = useCallback(
    async (file: File | null) => {
      if (!file) {
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
        return;
      }

      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'contexts.authContext.messages.updateAvatarSubmitConfirm.title',
          ),
          icon: <CheckCircleOutlined />,
          content: intl.getTranslatedText(
            'contexts.authContext.messages.updateAvatarSubmitConfirm.description',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'contexts.authContext.messages.updateAvatarSubmitConfirm.confirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingUpdateAvatar(true);

      let newPhotoId = '';

      const formData = new FormData();
      formData.append('file', file);
      formData.append('from', 'userAvatar');

      try {
        const { data: responseData } = await api.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        newPhotoId = responseData._id;
        // newPhotoFilename = responseData.filename;
      } catch (error) {
        // console.log(error);
        // setSubmitting(false);
        setLoadingUpdateAvatar(false);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });

        return;
      }

      const body = {
        photo: newPhotoId,
      };

      try {
        await api.put(`/api/user`, body);

        showToast({
          title: intl.getTranslatedText(
            'contexts.authContext.messages.updateAvatarSubmitSuccess.title',
          ),
          type: 'success',
        });
        setData(oldState => {
          if (!oldState.user) {
            return oldState;
          }
          return {
            ...oldState,
            user: {
              ...oldState.user,
              photo: newPhotoId,
            },
          };
        });

        setLoadingUpdateAvatar(false);
      } catch (error) {
        // console.log(error);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
        setLoadingUpdateAvatar(false);
        // setSubmitting(false);
      }
    },
    [intl],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        loadingUserData,
        signIn,
        signOut,
        updateAvatar,
        loadingUpdateAvatar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
