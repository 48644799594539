import React, { useEffect } from 'react';

import Bullet from '@/components/Bullet';
import LoadingUserData from '../../../components/LoadingUserData';
import Header from '../../../components/Header';
// import MobileSidebar from '../../../components/Header/MobileSidebar';

import { useAuth } from '../../../context/AuthContext';
import { useLoadingMasked } from '../../../context/LoadingMaskedContext';

import api from '../../../services/api';

import { Container, ContentWrapper } from './styles';
import LoadingWithMask from '../../../components/LoadingWithMask';
import PushGameModal from '../../../components/PushGameModal';

const Default: React.FC = ({ children }) => {
  const { loadingUserData, signOut } = useAuth();
  const { loadingData } = useLoadingMasked();

  useEffect(() => {
    api.interceptors.response.use(
      response => response,
      async error => {
        if (error.response.status === 401) {
          console.log('signOut called');
          signOut(true);
        }
        return Promise.reject(error);
      },
    );
  }, [signOut]);

  if (loadingUserData) {
    return <LoadingUserData />;
  }

  return (
    <>
      {loadingData.shouldShow && (
        <LoadingWithMask description={loadingData.description} />
      )}
      <PushGameModal />
      <Bullet />
      <Container>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </Container>
    </>
  );
};

export default Default;
