import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Content = styled.div``;
export const ScoreboardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > h6 {
    color: ${props => props.theme.colors.dark['dark+4']};
  }
`;
export const ScoreTeamSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 16px;

  h6 {
    color: ${props => props.theme.colors.light.light0};
    margin: 8px 0;
  }
`;
export const ScoreboardTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  /* @media only screen and (min-width: 1200px) {
    div {
      width: 104px;
      height: 104px;

      img {
        height: 56px;
      }
    }

    strong {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
    }
  } */
`;
