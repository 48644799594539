const AthletesOfTheWeekTexts = {
  'pages.athletesOfTheWeek.viewersDescriptions.availableForPlay':
    'available to play',
  'pages.athletesOfTheWeek.viewersDescriptions.running': 'running',
  'pages.athletesOfTheWeek.viewersDescriptions.finished': 'finished',

  'pages.athletesOfTheWeek.views.finished': 'Finished',
  'pages.athletesOfTheWeek.views.availableForPlay': 'Available to play',
  'pages.athletesOfTheWeek.views.running': 'Running',

  'pages.athletesOfTheWeek.searchCardsInput.placeholder': 'Search cards',

  // /* ------------ Buttons ------------ */
  'pages.athletesOfTheWeek.myGamesButton': 'My games',
  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.messages.getCards.title':
    'Unexpected error to load cards game',
  'pages.athletesOfTheWeek.messages.loadingCards': 'Loading cards...',
  'pages.athletesOfTheWeek.messages.noCards':
    'No cards <span>{viewerDescription}</span>',
  'pages.athletesOfTheWeek.messages.cardNotFound':
    '<span>Card</span> not found',
  'pages.athletesOfTheWeek.messages.championshipNotFound':
    '<span>Championship</span> not found',
};

const CardGamesHistoryTexts = {
  'pages.athletesOfTheWeek.cardGamesHistory.title': 'Card History',

  'pages.athletesOfTheWeek.cardGamesHistory.goToAthletesOfTheWeekLink':
    'Back to hat-trick',
  'pages.athletesOfTheWeek.cardGamesHistory.showActiveGamesButton':
    'Show active game',
  'pages.athletesOfTheWeek.cardGamesHistory.showArchivedGamesButton':
    'Show archived game',

  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.cardGamesHistory.messages.getPlayedCardGamesError.title':
    'Error to load games history',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameConfirm.description':
    'Do you want archive this game?',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameSuccess.title':
    'Game archived!',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameConfirm.description':
    'Do you really want archive this game?',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameSuccess.title':
    'This game are active!',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.noGames':
    "You don't have <span>game</span>",
  'pages.athletesOfTheWeek.cardGamesHistory.messages.viewingArchivedGames':
    'You are seeing your archived games',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.noArchivedGames':
    "You don't have <span>archived game</span>",
};

const MyCardGameDetailsTexts = {
  'pages.athletesOfTheWeek.myCardGameDetails.title': 'My card games details',

  'pages.athletesOfTheWeek.myCardGameDetails.goToGamesHistoryLink':
    'Back to games history',

  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.myCardGameDetails.messages.noGame':
    '<span>Game</span> not found',

  /* PlayedCardGameAthlete */
  ...{
    'pages.athletesOfTheWeek.myCardGameDetails.playedCardGameAthlete.playedAthleteNumber':
      'Shirt - {playerNumber} · {playerPosition}',
  },
};

const CardTexts = {
  'pages.athletesOfTheWeek.card.tabs.ranking': 'Ranking',
  'pages.athletesOfTheWeek.card.tabs.results': 'Results',
  'pages.athletesOfTheWeek.card.tabs.myGames': 'My games',

  'pages.athletesOfTheWeek.card.goToPlayConfirmButton': 'Play',
  'pages.athletesOfTheWeek.card.playNowButton': 'Play now',
  'pages.athletesOfTheWeek.card.goToAthletesOfTheWeekLink': 'Back to hat-trick',

  'pages.athletesOfTheWeek.card.messages.getRankingError.title':
    'Error to load this card ranking',
  'pages.athletesOfTheWeek.card.messages.getMyGamesError.title':
    'Error to load your games',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.title':
    'Do you want to play on this card?',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.description':
    'This game cost T¢{price}',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.freeCardDescription':
    'This game is free',
  'pages.athletesOfTheWeek.card.messages.noCardGame':
    '<span>Card game</span> not found',

  /* My Games */
  ...{
    'pages.athletesOfTheWeek.card.myGames.loadingMyGames':
      'Loading your game...',
    'pages.athletesOfTheWeek.card.myGames.noGames':
      "You <span>didn't</span> play any <span>games</span> on this card",
  },
  /* Play */
  ...{
    'pages.athletesOfTheWeek.card.play.title': 'Play',

    'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton': 'End game',
    'pages.athletesOfTheWeek.card.play.goToAthletesOfTheWeekLink':
      'Back to hat-trick',

    'pages.athletesOfTheWeek.card.play.messages.unblockPageConfirm.title':
      'Do really want to quit the game?',
    'pages.athletesOfTheWeek.card.play.messages.submitNewGameConfirm.title':
      'Do you really want to play this card game?',
    'pages.athletesOfTheWeek.card.play.messages.submitNewGameSuccess.title':
      'Card game successfully done!',
    'pages.athletesOfTheWeek.card.play.messages.noCard':
      '<span>Card game</span> not found',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.play.athleteCard.athleteNumber':
        'Shirt - {playerNumber} · {playerPosition}',
    },
  },
  /* Ranking */
  ...{
    'pages.athletesOfTheWeek.card.ranking.searchUserInput.placeholder':
      'Search user',

    'pages.athletesOfTheWeek.card.ranking.messages.userNotFound':
      '<span>User</span> not found',
    'pages.athletesOfTheWeek.card.ranking.messages.userNotHasGameOnThisCard':
      "This user hasn't played on this card yet",
    'pages.athletesOfTheWeek.card.ranking.messages.noRanking':
      '<span>Ranking</span> of this card is not available',
    'pages.athletesOfTheWeek.card.ranking.messages.loadingRanking':
      'Loading ranking...',
    'pages.athletesOfTheWeek.card.ranking.messages.loadingUserGames':
      "Loading user's games...",
    'pages.athletesOfTheWeek.card.ranking.messages.partialInfo':
      'The information below is partial',
  },
  /* Results */
  ...{
    'pages.athletesOfTheWeek.card.results.messages.partialInfo':
      'The information below is partial',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.results.athleteCard.athleteNumber':
        'Shirt - {playerNumber} · {playerPosition}',
    },
  },
  /* UserCardGameDetails */
  ...{
    'pages.athletesOfTheWeek.card.userCardGameDetails.title':
      'Card game details',

    'pages.athletesOfTheWeek.card.userCardGameDetails.goToCardDetailsLink':
      'Back to card details',

    /* ------------ Messages ------------ */
    'pages.athletesOfTheWeek.card.userCardGameDetails.messages.noGame':
      '<span>Game</span> not found',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.userCardGameDetails.playedCardAthlete.athleteNumber':
        'Shirt - {playerNumber} · {playerPosition}',
    },
  },
};

export default {
  ...AthletesOfTheWeekTexts,
  ...CardGamesHistoryTexts,
  ...MyCardGameDetailsTexts,
  ...CardTexts,
};
