import React from 'react';

import ChallengeList from './ChallengeList';
import NewChallenge from './NewChallenge';
import MyChallenges from './MyChallenges';
import { useCoachFriendRoom } from '../CoachFriendRoomContext';

const Challenge: React.FC = () => {
  const coachFriendRoomContext = useCoachFriendRoom();

  return (
    <>
      {coachFriendRoomContext.currentChallengeRoute === 'list' && (
        <ChallengeList />
      )}
      {coachFriendRoomContext.currentChallengeRoute === 'new' && (
        <NewChallenge />
      )}
      {coachFriendRoomContext.currentChallengeRoute === 'myChallenges' && (
        <MyChallenges />
      )}
    </>
  );
};

export default Challenge;
