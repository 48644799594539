import React from 'react';
import { FormikProps } from 'formik';
import * as dateFns from 'date-fns';

import CustomAntButton from '../../../../components/CustomAntButton';
import { formatMoney } from '../../../../utils/formatters';
import { useIntl } from '../../../../context/IntlContext';
import logoAmateur from '../../../../assets/logo-only-icon.svg';

import { INewRoomFormData } from '..';

import {
  Container,
  DescriptionItem,
  Session,
  ChampionshipSelected,
  MatchSelected,
  MatchSelectedTeam,
  MatchInfoContainer,
} from './styles';
import { ButtonsContainer } from '../styles';

interface IConfirmProps {
  formik: FormikProps<INewRoomFormData>;
  prevStep(): void;
}

const { format, parseISO } = dateFns;

const Confirm: React.FC<IConfirmProps> = ({ formik, prevStep }) => {
  const intl = useIntl();
  return (
    <Container>
      <Session>
        <h6>{intl.getTranslatedText('pages.versusVIP.newRoom.step1')}</h6>
        <ul>
          <DescriptionItem>
            <small>
              {intl.getTranslatedText(
                'pages.versusVIP.newRoom.form.roomType.label',
              )}
            </small>
            <p>
              {formik.values.roomType === 'private'
                ? intl.getTranslatedText(
                    'pages.versusVIP.newRoom.form.roomType.privateRoomTitle',
                  )
                : intl.getTranslatedText(
                    'pages.versusVIP.newRoom.form.roomType.publicRoomTitle',
                  )}
            </p>
          </DescriptionItem>
          <DescriptionItem>
            <small>
              {intl.getTranslatedText(
                'pages.versusVIP.newRoom.form.name.label',
              )}
            </small>
            <p>{formik.values.name}</p>
          </DescriptionItem>
          <DescriptionItem>
            <small>
              {intl.getTranslatedText(
                'pages.versusVIP.newRoom.form.price.label',
              )}
            </small>
            <p>T¢{formatMoney(parseInt(formik.values.price))}</p>
          </DescriptionItem>
        </ul>
      </Session>
      <Session>
        <h6>{intl.getTranslatedText('pages.versusVIP.newRoom.step2')}</h6>
        <ChampionshipSelected>
          <img
            src={
              formik?.values?.championship?.id === 'AMATEUR'
                ? logoAmateur
                : formik?.values?.championship?.imageUrl
            }
            alt={formik?.values?.championship?.name}
          />
          <h6>{formik.values.championship?.name}</h6>
        </ChampionshipSelected>
        <MatchSelected>
          <div>
            <MatchSelectedTeam>
              <div>
                <img
                  src={formik.values.match?.local.image}
                  alt={formik.values.match?.local.name}
                />
              </div>
              <strong>{formik.values.match?.local.name}</strong>
            </MatchSelectedTeam>
            <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
            <MatchSelectedTeam>
              <div>
                <img
                  src={formik.values.match?.away.image}
                  alt={formik.values.match?.away.name}
                />
              </div>
              <strong>{formik.values.match?.away.name}</strong>
            </MatchSelectedTeam>
          </div>
          <MatchInfoContainer>
            <div>
              <small>
                {intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.matchInfo.date.label',
                )}
              </small>
              <small>
                {format(
                  parseISO(formik.values.match?.info.date || ''),
                  intl.getTranslatedText('common.formatDates.fullDate'),
                  {
                    locale: intl.getDatefnsLocale(),
                  },
                )}
              </small>
            </div>
            <div>
              <small>
                {intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.matchInfo.place.label',
                )}
              </small>
              <small>{formik.values.match?.info.place}</small>
            </div>
          </MatchInfoContainer>
        </MatchSelected>
      </Session>
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          {intl.getTranslatedText('common.buttons.goBack')}
        </CustomAntButton>
        <CustomAntButton
          disabled={formik.isSubmitting}
          type="primary"
          htmlType="submit"
        >
          {!formik.isSubmitting
            ? intl.getTranslatedText(
                'pages.versusVIP.newRoom.submitNewRoomConfirmButton',
              )
            : intl.getTranslatedText('common.buttons.cancel')}
        </CustomAntButton>
      </ButtonsContainer>
    </Container>
  );
};

export default Confirm;
