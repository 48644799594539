import React, { useMemo, useState } from 'react';
import { Modal } from 'antd';

import { FiCheck } from 'react-icons/fi';
import { PointsDescription } from '@/pages/CoachFriend/Room/styles';
import { useIntl } from '../../../../../context/IntlContext';

import { IPlayCoachGoalGameState, IPayGoalGameState } from '..';
import { ITeam } from '../..';

import {
  Container,
  Header,
  Content,
  TeamImageContainer,
  ThrowContainer,
  SelectPlayerOfThrowButton,
  TeamPlayerSelector,
} from './styles';

interface IPayGoalMode {
  payGoalGame: IPayGoalGameState;
  handleSelectPlayerOfThrowOnPayGoal(
    team: 'local' | 'away',
    throwOfTeam: 'oneThrow' | 'twoThrow' | 'threeThrow',
    playerId: string,
    playerName: string,
  ): void;
  localTeam: ITeam;
  awayTeam: ITeam;
  selectedScore: IPlayCoachGoalGameState;
}

const PayGoalMode: React.FC<IPayGoalMode> = ({
  payGoalGame,
  handleSelectPlayerOfThrowOnPayGoal,
  localTeam,
  awayTeam,
  selectedScore,
}) => {
  let sessionScore = 0;
  if (selectedScore.local >= 1) sessionScore += 10;
  if (selectedScore.local >= 2) sessionScore += 10;
  if (selectedScore.local >= 3) sessionScore += 10;
  if (selectedScore.away >= 1) sessionScore += 10;
  if (selectedScore.away >= 2) sessionScore += 10;
  if (selectedScore.away >= 3) sessionScore += 10;

  const intl = useIntl();

  const [openedLocalTeamModal, setOpenedLocalTeamModal] = useState<
    'oneThrow' | 'twoThrow' | 'threeThrow' | null
  >(null);
  const [openedAwayTeamModal, setOpenedAwayTeamModal] = useState<
    'oneThrow' | 'twoThrow' | 'threeThrow' | null
  >(null);

  const modalValues = useMemo(() => {
    return {
      localTeam: {
        titleLabel: {
          oneThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer1Modal.title',
            {
              teamNameAbbrev: localTeam.abbrev,
            },
          ),
          twoThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer2Modal.title',
            {
              teamNameAbbrev: localTeam.abbrev,
            },
          ),
          threeThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer3Modal.title',
            {
              teamNameAbbrev: localTeam.abbrev,
            },
          ),
        },
        players: {
          oneThrow: payGoalGame.local.oneThrow.player,
          twoThrow: payGoalGame.local.twoThrow.player,
          threeThrow: payGoalGame.local.threeThrow.player,
        },
      },
      awayTeam: {
        titleLabel: {
          oneThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer1Modal.title',
            {
              teamNameAbbrev: awayTeam.abbrev,
            },
          ),
          twoThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer2Modal.title',
            {
              teamNameAbbrev: awayTeam.abbrev,
            },
          ),
          threeThrow: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.athletes.selectPlayer3Modal.title',
            {
              teamNameAbbrev: awayTeam.abbrev,
            },
          ),
        },
        players: {
          oneThrow: payGoalGame.away.oneThrow.player,
          twoThrow: payGoalGame.away.twoThrow.player,
          threeThrow: payGoalGame.away.threeThrow.player,
        },
      },
    };
  }, [
    awayTeam.abbrev,
    intl,
    localTeam.abbrev,
    payGoalGame.away.oneThrow.player,
    payGoalGame.away.threeThrow.player,
    payGoalGame.away.twoThrow.player,
    payGoalGame.local.oneThrow.player,
    payGoalGame.local.threeThrow.player,
    payGoalGame.local.twoThrow.player,
  ]);

  return (
    <Container>
      <Modal
        title={
          openedLocalTeamModal
            ? modalValues.localTeam.titleLabel[openedLocalTeamModal]
            : ''
        }
        visible={!!openedLocalTeamModal}
        onCancel={() => setOpenedLocalTeamModal(null)}
        footer={null}
      >
        {localTeam.players.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              if (openedLocalTeamModal) {
                handleSelectPlayerOfThrowOnPayGoal(
                  'local',
                  openedLocalTeamModal,
                  player._id,
                  `${player.name} - ${player.number}`,
                );
              }
              setOpenedLocalTeamModal(null);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
              </p>
              <small>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.newGame.athletes.playerNumber',
                  {
                    playerNumber: player.number,
                  },
                )}
              </small>
            </div>
            {openedLocalTeamModal &&
              player._id ===
                modalValues.localTeam.players[openedLocalTeamModal] && (
                <FiCheck size={20} />
              )}
          </TeamPlayerSelector>
        ))}
      </Modal>
      <Modal
        title={
          openedAwayTeamModal
            ? modalValues.awayTeam.titleLabel[openedAwayTeamModal]
            : ''
        }
        visible={!!openedAwayTeamModal}
        onCancel={() => setOpenedAwayTeamModal(null)}
        footer={null}
      >
        {awayTeam.players.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              if (openedAwayTeamModal) {
                handleSelectPlayerOfThrowOnPayGoal(
                  'away',
                  openedAwayTeamModal,
                  player._id,
                  `${player.name} - ${player.number}`,
                );
              }
              setOpenedAwayTeamModal(null);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
              </p>
              <small>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.newGame.athletes.playerNumber',
                  {
                    playerNumber: player.number,
                  },
                )}
              </small>
            </div>
            {openedAwayTeamModal &&
              player._id ===
                modalValues.awayTeam.players[openedAwayTeamModal] && (
                <FiCheck size={20} />
              )}
          </TeamPlayerSelector>
        ))}
      </Modal>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.athletes.title')}
        </h6>
        <PointsDescription>
          {`${intl.getTranslatedText(
            'pages.coachFriend.room.newGame.maxScoreDescription',
          )} ${sessionScore} ${intl.getTranslatedText(
            'common.scoredPointsDescription',
          )}`}
        </PointsDescription>
      </Header>
      <Content>
        <div>
          <div>
            <TeamImageContainer>
              <img src={localTeam.image} alt={localTeam.name} />
            </TeamImageContainer>
            <strong>{localTeam.name}</strong>
          </div>
          <ul>
            {selectedScore.local >= 1 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw1',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.local.oneThrow.playerName}
                  onClick={() => setOpenedLocalTeamModal('oneThrow')}
                >
                  <p>
                    {payGoalGame.local.oneThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.local >= 2 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw2',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.local.twoThrow.playerName}
                  onClick={() => setOpenedLocalTeamModal('twoThrow')}
                >
                  <p>
                    {payGoalGame.local.twoThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.local >= 3 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw3',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.local.threeThrow.playerName}
                  onClick={() => setOpenedLocalTeamModal('threeThrow')}
                >
                  <p>
                    {payGoalGame.local.threeThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.local === 0 && <p>Esse time não marcou gols</p>}
          </ul>
        </div>
        <div>
          <div>
            <TeamImageContainer>
              <img src={awayTeam.image} alt={awayTeam.name} />
            </TeamImageContainer>
            <strong>{awayTeam.name}</strong>
          </div>
          <ul>
            {selectedScore.away >= 1 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw1',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.away.oneThrow.playerName}
                  onClick={() => setOpenedAwayTeamModal('oneThrow')}
                >
                  <p>
                    {payGoalGame.away.oneThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.away >= 2 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw2',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.away.twoThrow.playerName}
                  onClick={() => setOpenedAwayTeamModal('twoThrow')}
                >
                  <p>
                    {payGoalGame.away.twoThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.away >= 3 && (
              <ThrowContainer>
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.athletes.throw3',
                  )}
                </small>
                <SelectPlayerOfThrowButton
                  selected={!!payGoalGame.away.threeThrow.playerName}
                  onClick={() => setOpenedAwayTeamModal('threeThrow')}
                >
                  <p>
                    {payGoalGame.away.threeThrow.playerName ||
                      intl.getTranslatedText(
                        'pages.coachFriend.room.newGame.athletes.addPlayerButton',
                      )}
                  </p>
                </SelectPlayerOfThrowButton>
              </ThrowContainer>
            )}
            {selectedScore.away === 0 && <p>Esse time não marcou gols</p>}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default PayGoalMode;
