import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 64px 56px;
  min-height: calc(100vh - 72px);

  @media only screen and (max-width: 1199px) {
    padding: 40px 32px;
    min-height: calc(100vh - 61px);
  }

  @media only screen and (max-width: 600px) {
    /* padding: 32px 16px; */
    padding: 32px 16px;
  }

  h4 {
    color: ${props => props.theme.colors.light.light0};
    margin-bottom: 16px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  margin-top: 8px;

  /* AntD Button Changes */
  > button {
    margin-left: 16px;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const NamePageForIndicator = styled.span`
  color: ${props => props.theme.colors.primary.primary0};
`;

export const RoomsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  /* justify-content: space-between; */

  h5,
  h6,
  div.ant-spin {
    text-align: center;
    margin: auto 0;
  }

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }

  > small {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 16px;
  }
`;

export const RoomsList = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const RoomItem = styled(Link)`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;

  @media only screen and (min-width: 1450px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  > div:nth-child(1) {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      &:nth-child(1) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        flex-shrink: 0;
        color: ${props => props.theme.colors.primary['primary+1']};
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    p {
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 16px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary['primary+1']};
    width: 100%;
    text-align: center;
  }
`;

export const RoomItemTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    img {
      height: 40px;
    }
  }

  small {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }
`;

export const LoadMoreRoomsButton = styled.button`
  border: 0;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  @media only screen and (min-width: 1450px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;
