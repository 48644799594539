import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Modal } from 'antd';

import { FiChevronLeft, FiLock } from 'react-icons/fi';

import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import { IoEllipsisVertical } from 'react-icons/io5';

import Breadcrumbs from '../../../components/Breadcrumbs';
import { showToast } from '../../../hooks/showToast';
import api from '../../../services/api';

import { useIntl } from '../../../context/IntlContext';

import {
  Container,
  Header,
  RoomsList,
  CardRoomItem,
  RoomTeam,
  LoadMoreCardsButton,
} from './styles';
import CustomAntButton from '../../../components/CustomAntButton';
import Loading from '../../../components/Loading';

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

interface IVersusRoom {
  _id: string;
  isPrivate: boolean;
  isFinished: boolean;
  name: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    local: ITeam;
    away: ITeam;
  };
}

const { confirm } = Modal;

const MyGames: React.FC = () => {
  const intl = useIntl();

  const [rooms, setRooms] = useState<IVersusRoom[]>([]);
  const [roomsPagination, setRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [archivedRooms, setArchivedRooms] = useState<IVersusRoom[]>([]);
  const [archivedRoomsPagination, setArchivedRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [showArchivedRooms, setShowArchivedRooms] = useState(false);
  const [loadingRooms, setLoadingRooms] = useState(true);

  const getRooms = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingRooms(true);
      try {
        const { data } = await api.get<{
          docs: IVersusRoom[];
          page: number;
          pages: number;
          total: number;
        }>('/api/game-vs', {
          params: {
            limit: roomsPagination.limit,
            page,
            detail: true,
            isVip: true,
          },
        });

        if (page === 1) {
          setRooms(data.docs);
        } else {
          setRooms(oldState => [...oldState, ...data.docs]);
        }
        if (data.total > 0) {
          setRoomsPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.versusVIP.myRooms.messages.getRoomsError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingRooms(false);
    },
    [intl, roomsPagination.limit],
  );

  const getArchivedRooms = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingRooms(true);
      try {
        const { data } = await api.get<{
          docs: IVersusRoom[];
          page: number;
          pages: number;
          total: number;
        }>('/api/game-vs', {
          params: {
            hidden: true,
            limit: archivedRoomsPagination.limit,
            page,
            detail: true,
            isVip: true,
          },
        });

        if (page === 1) {
          setArchivedRooms(data.docs);
        } else {
          setArchivedRooms(oldState => [...oldState, ...data.docs]);
        }
        if (data.total > 0) {
          setArchivedRoomsPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.versusVIP.myRooms.messages.getArchivedRoomsError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingRooms(false);
    },
    [archivedRoomsPagination.limit, intl],
  );

  useEffect(() => {
    // const { id } = params;
    if (!showArchivedRooms) {
      getRooms();
    } else if (showArchivedRooms) {
      getArchivedRooms();
    }
  }, [getArchivedRooms, getRooms, showArchivedRooms]);

  const handleArchiveRoom = useCallback(
    async (roomId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.versusVIP.myRooms.messages.submitArchiveRoomConfirm.description',
            ),
            okText: intl.getTranslatedText(
              'pages.versusVIP.myRooms.submitArchiveRoomConfirmButton',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve(true);
            },
          });
        });

        await api.put(`/api/game-vs/${roomId}/hidden`, {
          hidden: true,
        });

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.versusVIP.myRooms.messages.submitArchiveRoomSuccess.title',
          ),
        });

        setRooms(oldRooms => {
          return oldRooms.filter(room => room._id !== roomId);
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const handleUnarchiveRoom = useCallback(
    async (roomId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.versusVIP.myRooms.messages.submitUnarchiveRoomConfirm.description',
            ),
            okText: intl.getTranslatedText(
              'pages.versusVIP.myRooms.submitUnarchiveRoomConfirmButton',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            onOk() {
              resolve(true);
            },
          });
        });

        await api.put(`/api/game-vs/${roomId}/hidden`, {
          hidden: false,
        });

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.versusVIP.myRooms.messages.submitUnarchiveRoomSuccess.title',
          ),
        });

        setArchivedRooms(oldArchivedRooms => {
          return oldArchivedRooms.filter(
            archivedRoom => archivedRoom._id !== roomId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const roomsVisualization = useMemo(() => {
    if (!showArchivedRooms) {
      return (
        <>
          {rooms.length > 0 ? (
            <RoomsList>
              {rooms.map(room => (
                <CardRoomItem key={room._id} to={`/versus/${room._id}`}>
                  <div>
                    <strong>{room.name}</strong>
                    <div>
                      {room.isPrivate && <FiLock size={19} />}
                      <CustomAntButton
                        type="text"
                        htmlType="button"
                        icon={<BiArchiveIn size={20} />}
                        useCustomIcon
                        danger
                        onClick={e => {
                          e.preventDefault();
                          handleArchiveRoom(room._id);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <RoomTeam>
                      <div>
                        <img
                          src={room._game.local.image}
                          alt={room._game.local.name}
                        />
                      </div>
                      <strong>{room._game.local.abbrev}</strong>
                    </RoomTeam>
                    <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
                    <RoomTeam>
                      <div>
                        <img
                          src={room._game.away.image}
                          alt={room._game.away.name}
                        />
                      </div>
                      <strong>{room._game.away.abbrev}</strong>
                    </RoomTeam>
                  </div>
                  <small>{room._champ.name}</small>
                </CardRoomItem>
              ))}
              {roomsPagination.currentPage < roomsPagination.totalPages && (
                <LoadMoreCardsButton
                  onClick={() => {
                    if (loadingRooms) return;
                    getRooms(roomsPagination.currentPage + 1);
                  }}
                  disabled={loadingRooms}
                >
                  <h6>
                    {!loadingRooms
                      ? intl.getTranslatedText('common.buttons.viewMore')
                      : intl.getTranslatedText(
                          'common.messages.defaultLoading',
                        )}
                  </h6>
                </LoadMoreCardsButton>
              )}
            </RoomsList>
          ) : (
            <div>
              <h6>
                {intl.getTranslatedTextWithHTML(
                  'pages.versusVIP.myRooms.messages.noRooms',
                )}
              </h6>
              <Link to="/">
                <FiChevronLeft size={16} />
                {intl.getTranslatedText('pages.versusVIP.myRooms.goToHomeLink')}
              </Link>
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div>
          <small>
            {intl.getTranslatedText(
              'pages.versusVIP.myRooms.messages.viewingArchivedRooms',
            )}
          </small>
          <CustomAntButton
            onClick={() =>
              setShowArchivedRooms(
                oldShowArchivedRooms => !oldShowArchivedRooms,
              )
            }
            type="text"
            danger
          >
            {intl.getTranslatedText(
              'pages.versusVIP.myRooms.goToActiveRoomsButton',
            )}
          </CustomAntButton>
        </div>
        {archivedRooms.length > 0 ? (
          <RoomsList>
            {archivedRooms.map(room => (
              <CardRoomItem key={room._id} to={`/versus/${room._id}`}>
                <div>
                  <strong>{room.name}</strong>
                  <div>
                    {room.isPrivate && <FiLock size={19} />}
                    <CustomAntButton
                      type="text"
                      htmlType="button"
                      icon={<BiArchiveOut size={20} />}
                      useCustomIcon
                      onClick={e => {
                        e.preventDefault();
                        handleUnarchiveRoom(room._id);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <RoomTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </RoomTeam>
                  <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
                  <RoomTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </RoomTeam>
                </div>
                <small>{room._champ.name}</small>
              </CardRoomItem>
            ))}
            {archivedRoomsPagination.currentPage <
              archivedRoomsPagination.totalPages && (
              <LoadMoreCardsButton
                onClick={() => {
                  if (loadingRooms) return;
                  getArchivedRooms(archivedRoomsPagination.currentPage + 1);
                }}
                disabled={loadingRooms}
              >
                <h6>
                  {!loadingRooms
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </h6>
              </LoadMoreCardsButton>
            )}
          </RoomsList>
        ) : (
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.versusVIP.myRooms.messages.noArchivedRooms',
              )}
            </h6>
          </div>
        )}
      </>
    );
  }, [
    archivedRooms,
    archivedRoomsPagination.currentPage,
    archivedRoomsPagination.totalPages,
    getArchivedRooms,
    getRooms,
    handleArchiveRoom,
    handleUnarchiveRoom,
    intl,
    loadingRooms,
    rooms,
    roomsPagination.currentPage,
    roomsPagination.totalPages,
    showArchivedRooms,
  ]);

  if (loadingRooms) {
    if (
      (!showArchivedRooms && roomsPagination.totalPages === 0) ||
      (showArchivedRooms && archivedRoomsPagination.totalPages === 0)
    ) {
      return (
        <Container>
          <Breadcrumbs />
          <Header>
            <div>
              <h4>{intl.getTranslatedText('common.gamesTitles.versusVIP')}</h4>
              <h5>{intl.getTranslatedText('pages.versusVIP.myRooms.title')}</h5>
            </div>
          </Header>
          <Loading />
        </Container>
      );
    }
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <div>
          <h4>{intl.getTranslatedText('common.gamesTitles.versusVIP')}</h4>
          <h5>{intl.getTranslatedText('pages.versusVIP.myRooms.title')}</h5>
        </div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  setShowArchivedRooms(
                    oldShowArchivedRooms => !oldShowArchivedRooms,
                  )
                }
                danger={!showArchivedRooms}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!showArchivedRooms ? (
                    <>
                      <BiArchiveIn style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.versusVIP.myRooms.showArchivedRoomsButton',
                      )}
                    </>
                  ) : (
                    <>
                      <BiArchiveOut style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.versusVIP.myRooms.showActiveRoomsButton',
                      )}
                    </>
                  )}
                </div>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <CustomAntButton
            icon={<IoEllipsisVertical size={20} />}
            useCustomIcon
            type="text"
          />
        </Dropdown>
      </Header>
      {roomsVisualization}
    </Container>
  );
};

export default MyGames;
