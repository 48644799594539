import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Input, Spin, Modal } from 'antd';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import { useAuth } from '../../../../context/AuthContext';
import { useLoadingMasked } from '../../../../context/LoadingMaskedContext';
import { useIntl } from '../../../../context/IntlContext';

import Env from '../../../../config/Environment';
import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { IVersusRoom } from '..';

import {
  Container,
  FindedOnSearchUser,
  LoadingAndNotFoundContainer,
} from './styles';
import { showToast } from '../../../../hooks/showToast';
import api from '../../../../services/api';

interface IRouteParams {
  id: string;
}

interface IUser {
  _id: string;
  username: string;
  name: string;
  photo?: {
    _id: string;
    filename: string;
  };
}

interface IChooseOpponentProps {
  room: IVersusRoom;
  getPlayersOfRoom(): void;
}

const { Search } = Input;
const { confirm } = Modal;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const ChooseOpponent: React.FC<IChooseOpponentProps> = ({
  getPlayersOfRoom,
  room,
}) => {
  const { params } = useRouteMatch<IRouteParams>();

  const intl = useIntl();
  const { user: me } = useAuth();
  const { showLoading, hideLoading } = useLoadingMasked();

  const [searchValue, setSearchValue] = useState('');
  const [loadingUsersSearch, setLoadingUsersSearch] = useState(false);
  const [findedOnUsersSearch, setFindedOnUsersSearch] = useState<IUser[]>([]);
  const [findedOnUsersSearchPagination, setFindedOnUsersSearchPagination] =
    useState({
      currentPage: 1,
      totalPages: 0,
      limit: 6,
    });

  const handleSearchUser = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingUsersSearch(true);
      if (page === 1) {
        setFindedOnUsersSearch([]);
      }

      try {
        const { data } = await api.get<{
          docs: IUser[];
          page: number;
          pages: number;
        }>('/api/user', {
          params: {
            search,
            page,
            limit: findedOnUsersSearchPagination.limit,
          },
        });

        if (page === 1) {
          setFindedOnUsersSearch(data.docs);
        } else {
          setFindedOnUsersSearch(oldUsersSearchState => [
            ...oldUsersSearchState,
            ...data.docs,
          ]);
        }

        setFindedOnUsersSearchPagination(oldPaginationState => ({
          ...oldPaginationState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.versus.room.chooseOpponent.messages.searchUserError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingUsersSearch(false);
    },
    [findedOnUsersSearchPagination.limit, intl],
  );

  const userSearchDebounced = useMemo(() => {
    return _.debounce(handleSearchUser, 500);
  }, [handleSearchUser]);

  const handleChangeUserSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      userSearchDebounced.cancel();
      setSearchValue(e.target.value);

      if (e.target.value.length > 3) {
        setLoadingUsersSearch(true);
        userSearchDebounced(e.target.value);
      } else {
        setLoadingUsersSearch(false);
        setFindedOnUsersSearch([]);
      }
    },
    [userSearchDebounced],
  );

  const handleInviteUserSubmit = useCallback(
    async (userId: string) => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.versus.room.chooseOpponent.messages.submitInviteUserConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            // content: 'Eles não poderão ser removidos posteriormente',
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.versus.room.chooseOpponent.submitInviteUserConfirmButton.title',
            ),
            onOk() {
              resolve(true);
            },
          });
        });

        showLoading(
          intl.getTranslatedText(
            'pages.versus.room.chooseOpponent.messages.submitInviteUserLoading',
          ),
        );

        const body = {
          users: [
            {
              _user: userId,
            },
          ],
        };

        await api.put(`/api/game-vs/${params.id}/players`, body);

        hideLoading();
        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.title',
          ),
          description: intl.getTranslatedText(
            'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.description',
          ),
        });
        getPlayersOfRoom();
      } catch (error) {
        hideLoading();

        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description:
            error.response?.data?.message ||
            intl.getTranslatedText('common.errors.unexpectedError.description'),
        });
      }
    },
    [getPlayersOfRoom, hideLoading, intl, params.id, showLoading],
  );

  const searchUsersContent = useMemo(() => {
    const findedUsersWithoutMe = findedOnUsersSearch.filter(
      findedUser => findedUser._id !== me?._id,
    );

    if (searchValue.length <= 3) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>{intl.getTranslatedText('common.messages.minCharToSearch')}</h6>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    if (loadingUsersSearch && findedUsersWithoutMe.length === 0) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    if (findedUsersWithoutMe.length > 0) {
      return (
        <ul>
          {findedUsersWithoutMe.map(user => (
            <FindedOnSearchUser
              onClick={() => handleInviteUserSubmit(user._id)}
              key={user._id}
            >
              <img
                src={
                  user.photo
                    ? Env.IMAGE_SERVER_URL + user.photo?.filename
                    : defaultAvatar
                }
                alt={user.name}
              />
              <div>
                <small>
                  <strong>{user.username}</strong>
                </small>
                <small>{user.name}</small>
              </div>
            </FindedOnSearchUser>
          ))}
          {findedOnUsersSearchPagination.currentPage <
            findedOnUsersSearchPagination.totalPages && (
            <FindedOnSearchUser
              onClick={() =>
                handleSearchUser(
                  searchValue,
                  findedOnUsersSearchPagination.currentPage + 1,
                )
              }
              disabled={loadingUsersSearch}
            >
              <p>
                {!loadingUsersSearch
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </p>
            </FindedOnSearchUser>
          )}
        </ul>
      );
    }

    return (
      <LoadingAndNotFoundContainer>
        <div>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.versus.room.chooseOpponent.messages.userNotFound',
            )}
          </h6>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }, [
    findedOnUsersSearch,
    findedOnUsersSearchPagination.currentPage,
    findedOnUsersSearchPagination.totalPages,
    handleInviteUserSubmit,
    handleSearchUser,
    intl,
    loadingUsersSearch,
    me?._id,
    searchValue,
  ]);

  if (!room.isPrivate) {
    return (
      <Container>
        <p>
          {intl.getTranslatedTextWithHTML(
            'pages.versus.room.chooseOpponent.messages.waitForYourGameToBeAccept',
          )}
        </p>
      </Container>
    );
  }

  return (
    <Container>
      <Search
        placeholder={intl.getTranslatedText(
          'pages.versus.room.chooseOpponent.searchUserInput.placeholder',
        )}
        value={searchValue}
        onChange={handleChangeUserSearch}
      />
      {searchUsersContent}
    </Container>
  );
};

export default ChooseOpponent;
