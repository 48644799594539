import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { GiCardRandom } from 'react-icons/gi';
import { IoIosFlag } from 'react-icons/io';
import { FaFlagCheckered } from 'react-icons/fa';

import { PointsDescription } from '@/pages/CoachFriend/Room/styles';
import { ICoachGameState, CornerRange } from '..';

import CustomAntButton from '../../../../../components/CustomAntButton';
import Select, { ISelectOption } from '../../../../../components/Select';
import { useIntl } from '../../../../../context/IntlContext';

import {
  Container,
  Header,
  Content,
  QuantityOfStatisticSelectorContainer,
  SelectQuantitityOfStatisticContainer,
} from './styles';

interface ICoachMode {
  coachGame: ICoachGameState;
  handleChangeQuantityOfStatistic(
    type: 'redCards' | 'yellowCards' | 'cornerNumber' | 'offsideNumber',
    quantity: number,
  ): void;
  handleChangeCornerRange(range: CornerRange): void;
}

const CoachMode: React.FC<ICoachMode> = ({
  coachGame,
  handleChangeQuantityOfStatistic,
  handleChangeCornerRange,
}) => {
  const intl = useIntl();

  function getCornerRanges(): ISelectOption[] {
    let arrBase: ISelectOption[] = [];
    let base = 0;
    let i = 0;
    const items = 15;
    const range = 5;
    for (i; i < items; i++)
      arrBase.push({
        label: '',
        value: '',
      });

    arrBase = arrBase.map(() => {
      let arr: number[] = [];
      let ind = 0;
      for (ind; ind < range; ind++) {
        arr = [...arr, base];
        base += 1;
      }

      return {
        label: intl.getTranslatedText(
          'pages.coachFriend.room.newGame.statistics.cornerRange.label',
          {
            firstRangeValue: arr[0],
            lastRangeValue: arr[arr.length - 1],
          },
        ),
        value: `${arr[0]}-${arr[arr.length - 1]}`,
      };
    });

    return arrBase;
  }

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.statistics.title')}
        </h6>
        <PointsDescription>
          {`${intl.getTranslatedText(
            'pages.coachFriend.room.newGame.maxScoreDescription',
          )} 46 ${intl.getTranslatedText('common.scoredPointsDescription')}`}
        </PointsDescription>
      </Header>
      <Content>
        <SelectQuantitityOfStatisticContainer $typeOfStatistic="yellowCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.yellowCards.label',
            )}
          </strong>
          <QuantityOfStatisticSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'yellowCards',
                  coachGame.yellowCards - 1,
                )
              }
              disabled={coachGame.yellowCards === 0}
            />
            <h6>{coachGame.yellowCards}</h6>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'yellowCards',
                  coachGame.yellowCards + 1,
                )
              }
            />
          </QuantityOfStatisticSelectorContainer>
        </SelectQuantitityOfStatisticContainer>
        <SelectQuantitityOfStatisticContainer $typeOfStatistic="redCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.redCards.label',
            )}
          </strong>
          <QuantityOfStatisticSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'redCards',
                  coachGame.redCards - 1,
                )
              }
              disabled={coachGame.redCards === 0}
            />
            <h6>{coachGame.redCards}</h6>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() =>
                handleChangeQuantityOfStatistic(
                  'redCards',
                  coachGame.redCards + 1,
                )
              }
            />
          </QuantityOfStatisticSelectorContainer>
        </SelectQuantitityOfStatisticContainer>
        <SelectQuantitityOfStatisticContainer $typeOfStatistic="offside">
          <div>
            <FaFlagCheckered size={16} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.offsideNumber.label',
            )}
          </strong>
          <QuantityOfStatisticSelectorContainer>
            <CustomAntButton
              type="text"
              danger
              icon={<FiMinus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'offsideNumber',
                  coachGame.offsideNumber - 1,
                );
              }}
              disabled={coachGame.offsideNumber === 0}
            />
            <h6>{coachGame.offsideNumber}</h6>
            <CustomAntButton
              type="text"
              textPrimary
              icon={<FiPlus size={20} />}
              useCustomIcon
              onClick={() => {
                handleChangeQuantityOfStatistic(
                  'offsideNumber',
                  coachGame.offsideNumber + 1,
                );
              }}
            />
          </QuantityOfStatisticSelectorContainer>
        </SelectQuantitityOfStatisticContainer>
        <SelectQuantitityOfStatisticContainer $typeOfStatistic="corner">
          <div>
            <IoIosFlag size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.cornerNumber.label',
            )}
          </strong>
          <QuantityOfStatisticSelectorContainer>
            <Select
              id="rangeOfLocalTeam"
              placeholder={intl.getTranslatedText('common.selectDefaultText')}
              bordered
              options={getCornerRanges()}
              value={coachGame.cornerNumber}
              onChange={value => {
                if (
                  value === '0-4' ||
                  value === '5-9' ||
                  value === '10-14' ||
                  value === '15-19' ||
                  value === '20-24' ||
                  value === '25-29' ||
                  value === '30-34' ||
                  value === '35-39' ||
                  value === '40-44' ||
                  value === '45-49' ||
                  value === '50-54' ||
                  value === '55-59' ||
                  value === '60-64' ||
                  value === '65-69' ||
                  value === '70-74'
                ) {
                  handleChangeCornerRange(value);
                }
              }}
            />
          </QuantityOfStatisticSelectorContainer>
        </SelectQuantitityOfStatisticContainer>
      </Content>
    </Container>
  );
};

export default CoachMode;
