import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 0 56px;

  > div:nth-child(1) {
    margin-left: 56px;
  }

  @media only screen and (max-width: 1199px) {
    padding: 32px 0 56px;

    > div:nth-child(1) {
      margin-left: 32px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 0 56px;

    > div:nth-child(1) {
      margin-left: 16px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 230px;
  margin-top: 24px;
  background: linear-gradient(
      180deg,
      rgba(43, 43, 43, 0.95) 0%,
      rgba(43, 43, 43, 0.9) 26.04%,
      rgba(43, 43, 43, 0.7) 51.04%,
      rgba(43, 43, 43, 0.9) 69.73%,
      rgba(43, 43, 43, 0.95) 100%
    ),
    url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBP2zNNRqebdTAs6101-g1ZP-AENruAakJhA&usqp=CAU')
      no-repeat center;
  background-size: cover;
  position: relative;

  padding: 16px 56px;
  cursor: default;

  @media only screen and (max-width: 1199px) {
    padding: 16px 32px;
  }

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  p {
    margin-top: 8px;
    color: ${props => props.theme.colors.light['light-2']};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  div:last-child {
    display: flex;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }

  @media only screen and (max-width: 600px) {
    div:last-child {
      button {
        display: none;
      }
    }
  }
`;

export const AccumulatedValueContainer = styled.div`
  padding: 8px 16px;
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.primary.primary0};
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;

  svg {
    color: #e4ce00;
  }

  small {
    margin-left: 8px;
  }
`;

export const RestTimerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 16px;

  div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 16px;
    }

    h6 {
      color: ${props => props.theme.colors.primary.primary0};
    }
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 56px;

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin-top: 16px;
      margin-bottom: 4px;
    }

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1199px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const ContentTab = styled.div`
  margin-top: 16px;
`;
