import React from 'react';

import { useIntl } from '../../../../../context/IntlContext';

import { IMatch } from '../index';
import MatchCard from './MatchCard';

import { Container } from './styles';

interface IResultProps {
  matchs: IMatch[];
  cardType: 'new' | 'running' | 'finished';
}

const Results: React.FC<IResultProps> = ({ matchs, cardType }) => {
  const intl = useIntl();

  return (
    <Container>
      {cardType === 'running' && (
        <small>
          {intl.getTranslatedText(
            'pages.championship.cardGame.results.messages.partialInfo',
          )}
        </small>
      )}
      <ul>
        {matchs.map(match => (
          <MatchCard key={match._id} {...match} />
        ))}
      </ul>
    </Container>
  );
};

export default Results;
