import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import Input from '@/components/Input';
import { useNewChallenge } from '../../../../NewChallengeContext';
import { Markers, FormFieldIdentifier } from '../../../../types';

import { FormFieldsContainer } from '../../styles';

const AmountOfStatisticItemFormFields: React.FC = () => {
  const isFirstRender = useRef(true);
  const newChallengeContext = useNewChallenge();

  useEffect(() => {
    if (isFirstRender.current) {
      newChallengeContext.setFields([
        {
          id: FormFieldIdentifier.STATISTIC,
          marker: Markers.SQUARE_BRACKETS,
          value: null,
          viewValue: '',
        },
      ]);
    }

    isFirstRender.current = false;
  }, [newChallengeContext]);

  const amount = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.STATISTIC,
    )?.value as number;
  }, [newChallengeContext.fields]);

  const setAmount = useCallback(
    (value: string) => {
      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.STATISTIC) {
            return {
              ...field,
              value: value || '',
              viewValue: value,
            };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  return (
    <FormFieldsContainer>
      <Input
        label="Quantidade"
        placeholder="ex.: 3"
        id="amount"
        type="text"
        autoComplete="off"
        value={amount}
        onChange={e => {
          const newValue = e.target.value;
          setAmount(newValue.replace(/\D/g, ''));
        }}
      />
    </FormFieldsContainer>
  );
};

export default AmountOfStatisticItemFormFields;
