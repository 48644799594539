import React, { useMemo, useState } from 'react';
import { Spin, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FaCrown } from 'react-icons/fa';

import { useAuth } from '../../../../context/AuthContext';
import { useIntl } from '../../../../context/IntlContext';

import Env from '../../../../config/Environment';

import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { IVersusRoom, IPlayerGame } from '..';

import {
  Container,
  ContentBottom,
  PlayerGame,
  AdversaryInfoContainer,
  DetailsContainer,
  WinnerContainer,
  WinnerPlayer,
  LoadingAndNotFoundContainer,
} from './styles';

interface IGameDetailsProps {
  gamesOfRoom: IPlayerGame[];
  versusRoom: IVersusRoom;
  loading: boolean;
}

const { TabPane } = Tabs;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const GameDetails: React.FC<IGameDetailsProps> = ({
  gamesOfRoom,
  versusRoom,
  loading,
}) => {
  const intl = useIntl();
  const { user: me } = useAuth();

  const [currentView, setCurrentView] = useState<'details' | 'plays'>(
    'details',
  );

  const winnerViewer = useMemo(() => {
    const partialWinnerGame = gamesOfRoom.find(
      game =>
        game.games.localScore === versusRoom._game.localScore &&
        game.games.awayScore === versusRoom._game.awayScore,
    );

    if (!versusRoom.started) return <></>;

    if (versusRoom.gameWinner) {
      return (
        <WinnerContainer>
          <WinnerPlayer>
            <FaCrown size={24} />
            <img
              src={
                versusRoom.gameWinner?._user.photo?.filename
                  ? Env.IMAGE_SERVER_URL +
                    versusRoom.gameWinner?._user.photo?.filename
                  : defaultAvatar
              }
              alt={versusRoom.gameWinner?._user.name || '-----'}
            />
            <p>{versusRoom.gameWinner?._user.name || '-----'}</p>
            <small>{versusRoom.gameWinner?._user.username || '---'}</small>
          </WinnerPlayer>
          <div>
            <div>
              <h6>{versusRoom._game.local.name}</h6>
              <h6>{versusRoom.gameWinner?.localScore}</h6>
            </div>
            <div>
              <h6>{versusRoom._game.away.name}</h6>
              <h6>{versusRoom.gameWinner?.awayScore}</h6>
            </div>
          </div>
        </WinnerContainer>
      );
    }
    if (partialWinnerGame) {
      return (
        <>
          <small>
            {intl.getTranslatedText(
              'pages.versus.room.gameDetails.messages.winnerInformationIsPartial',
            )}
          </small>
          <WinnerContainer>
            <WinnerPlayer>
              <FaCrown size={24} />
              <img
                src={
                  partialWinnerGame._player.photo
                    ? Env.IMAGE_SERVER_URL + partialWinnerGame._player.photo
                    : defaultAvatar
                }
                alt={partialWinnerGame._player.name || '-----'}
              />
              <p>{partialWinnerGame._player.name || '-----'}</p>
              <small>{partialWinnerGame._player.username || '---'}</small>
            </WinnerPlayer>
            <div>
              <div>
                <h6>{versusRoom._game.local.name}</h6>
                <h6>{partialWinnerGame.games.localScore}</h6>
              </div>
              <div>
                <h6>{versusRoom._game.away.name}</h6>
                <h6>{partialWinnerGame.games.awayScore}</h6>
              </div>
            </div>
          </WinnerContainer>
        </>
      );
    }

    return (
      <>
        {versusRoom.info !== 'finished' && (
          <small>
            {intl.getTranslatedText(
              'pages.versus.room.gameDetails.messages.winnerInformationIsPartial',
            )}
          </small>
        )}
        <h6>
          <span>
            {intl.getTranslatedTextWithHTML(
              'pages.versus.room.gameDetails.messages.playersDidNotScorePoints',
            )}
          </span>
        </h6>
      </>
    );
  }, [
    gamesOfRoom,
    intl,
    versusRoom._game.away.name,
    versusRoom._game.awayScore,
    versusRoom._game.local.name,
    versusRoom._game.localScore,
    versusRoom.gameWinner,
    versusRoom.info,
    versusRoom.started,
  ]);

  if (loading) {
    return (
      <Container>
        <ContentBottom>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            </div>
          </LoadingAndNotFoundContainer>
        </ContentBottom>
      </Container>
    );
  }

  return (
    <Container>
      <Tabs
        activeKey={currentView}
        onTabClick={tabKey => {
          if (tabKey === 'details' || tabKey === 'plays') {
            setCurrentView(tabKey);
          }
        }}
        centered
      >
        <TabPane
          tab={intl.getTranslatedText(
            'pages.versus.room.gameDetails.tabs.details',
          )}
          key="details"
        >
          <ContentBottom>
            {winnerViewer}
            <DetailsContainer>
              <div>
                <strong>
                  {intl.getTranslatedText(
                    'pages.versus.room.gameDetails.adversary',
                  )}
                </strong>
                <AdversaryInfoContainer>
                  <img
                    src={
                      versusRoom.adversary?._player.photo
                        ? Env.IMAGE_SERVER_URL +
                          versusRoom.adversary?._player.photo
                        : defaultAvatar
                    }
                    alt={versusRoom.adversary?._player.name}
                  />
                  <div>
                    <p>{versusRoom.adversary?._player.name}</p>
                    <small>{versusRoom.adversary?._player.username}</small>
                  </div>
                </AdversaryInfoContainer>
              </div>
              <div>
                <strong>
                  {intl.getTranslatedText(
                    'pages.versus.room.gameDetails.amount',
                  )}
                </strong>
                <p>${versusRoom.price * 2}</p>
              </div>
              <div>
                <strong>
                  {intl.getTranslatedText(
                    'pages.versus.room.gameDetails.status',
                  )}
                </strong>
                {versusRoom.info !== 'finished' ? (
                  <p>
                    {intl.getTranslatedText(
                      'pages.versus.room.gameDetails.status.waitingFinish',
                    )}
                  </p>
                ) : (
                  <p>
                    {intl.getTranslatedText(
                      'pages.versus.room.gameDetails.status.finished',
                    )}
                  </p>
                )}
              </div>
            </DetailsContainer>
          </ContentBottom>
        </TabPane>
        <TabPane
          tab={intl.getTranslatedText(
            'pages.versus.room.gameDetails.tabs.plays',
          )}
          key="plays"
        >
          <ContentBottom>
            {gamesOfRoom.map(game => (
              <PlayerGame
                key={game.games._id}
                meGame={game.games._user === me?._id}
              >
                <img
                  src={
                    game._player.photo
                      ? Env.IMAGE_SERVER_URL + game._player.photo
                      : defaultAvatar
                  }
                  alt={game._player.name}
                />
                <div>
                  {game.games.isWinner && <FaCrown size={21} />}
                  <div>
                    <h6>{versusRoom._game.local.name}</h6>
                    <h6>{game.games.localScore}</h6>
                  </div>
                  <div>
                    <h6>{versusRoom._game.away.name}</h6>
                    <h6>{game.games.awayScore}</h6>
                  </div>
                </div>
              </PlayerGame>
            ))}
          </ContentBottom>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default GameDetails;
