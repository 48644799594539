import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Breadcrumbs from '../../../components/Breadcrumbs';
import CustomAntButton from '../../../components/CustomAntButton';
import Steps from '../../../components/Steps';

import { showToast } from '../../../hooks/showToast';
import api from '../../../services/api';
import { useIntl } from '../../../context/IntlContext';

import Confirm from './Confirm';
import Match from './Match';
import Room from './Room';

import { Container, Header, Content } from './styles';
import { IBlockPageMessage } from '../../../services/history';

export interface IChampionship {
  _id: string;
  id: string;
  name: string;
  imageUrl: string;
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export interface IMatch {
  _id: string;
  info: {
    place: string;
    date: string;
  };
  local: ITeam;
  away: ITeam;
}

export interface INewRoomFormData {
  roomType: 'private' | 'public' | null;
  name: string;
  championship: IChampionship | null;
  match: IMatch | null;
  price: string;
}

const { confirm } = Modal;

const NewRoom: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();

  const [currentStep, setCurrentStep] = useState(0);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const [championships, setChampionships] = useState<IChampionship[]>([]);
  const [loadingChampionships, setLoadingChampionships] = useState(true);
  const [championshipsPagination, setChampionshipsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [matchsOfChampionship, setMatchsOfChampionship] = useState<IMatch[]>(
    [],
  );
  const [loadingMatchsOfChampionship, setLoadingMatchsOfChampionship] =
    useState(false);
  const [matchsOfChampionshipPagination, setMatchsOfChampionshipPagination] =
    useState({
      currentPage: 1,
      totalPages: 0,
    });

  const newRoomFormData: INewRoomFormData = {
    roomType: null,
    name: '',
    championship: null,
    match: null,
    price: '',
  };

  const unblockPage = useMemo(() => {
    const message: IBlockPageMessage = {
      title: intl.getTranslatedText(
        'pages.versusVIP.newRoom.messages.blockPage.title',
      ),
      description: intl.getTranslatedText('common.messages.changesWillBeLost'),
      okText: intl.getTranslatedText('common.buttons.exit'),
      cancelText: intl.getTranslatedText('common.buttons.cancel'),
    };

    return history.block(JSON.stringify(message));
  }, [history, intl]);

  function handleBeforeUnload(e: BeforeUnloadEvent): void {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  const handleGetChampionships = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingChampionships(true);
      try {
        const { data } = await api.get<{
          docs: IChampionship[];
          pages: number;
          page: number;
        }>('/api/championship', {
          params: {
            page,
            limit: 10,
          },
        });
        setChampionships(oldChampionships => [
          ...oldChampionships,
          ...data.docs,
        ]);
        setChampionshipsPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.versusVIP.newRoom.messages.getChampionshipsError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingChampionships(false);
    },
    [intl],
  );

  const handleGetMatchsOfChampionship = useCallback(
    async (championshipId: string | undefined, page = 1): Promise<void> => {
      if (!championshipId) {
        setMatchsOfChampionship([]);
        return;
      }
      setLoadingMatchsOfChampionship(true);
      try {
        const { data } = await api.get<{
          docs: IMatch[];
          pages: number;
          page: number;
        }>(`/api/championship/${championshipId}/games`, {
          params: {
            page,
            limit: 10,
          },
        });

        const matchsWithAbbrevOfTeams = data.docs.map(match => ({
          ...match,
          local: {
            ...match.local,
            abbrev: match.local.name.substring(0, 3).toUpperCase(),
          },
          away: {
            ...match.away,
            abbrev: match.away.name.substring(0, 3).toUpperCase(),
          },
        }));

        if (page === 1) {
          setMatchsOfChampionship(matchsWithAbbrevOfTeams);
        } else {
          setMatchsOfChampionship(oldMatchs => [
            ...oldMatchs,
            ...matchsWithAbbrevOfTeams,
          ]);
        }

        setMatchsOfChampionshipPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.versusVIP.newRoom.messages.getMatchsOfChampionshipError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMatchsOfChampionship(false);
    },
    [intl],
  );

  const handleToogleCurrentStep = useCallback(
    (newCurrentStep: number) => {
      if (newCurrentStep < 0) {
        setCurrentStep(0);
        return;
      }

      if (newCurrentStep === 1 && championships.length === 0) {
        handleGetChampionships();
      }

      setCurrentStep(newCurrentStep);
    },
    [championships.length, handleGetChampionships],
  );

  const handleSubmit = useCallback(
    async (data: INewRoomFormData) => {
      await new Promise((resolve, reject) => {
        confirm({
          title: intl.getTranslatedText(
            'pages.versusVIP.newRoom.messages.submitNewRoomConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.versusVIP.newRoom.submitNewRoomConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCEL_CREATE_NEW_ROOM'));
          },
        });
      });

      const body = {
        champ: data.championship?._id,
        game: data.match?._id,
        name: data.name,
        price: data.price,
        isPrivate: data.roomType === 'private',
      };

      try {
        const { data: responseData } = await api.post('/api/game-vs', body);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.title',
          ),
          description: intl.getTranslatedText(
            'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.description',
          ),
        });

        window.removeEventListener('beforeunload', handleBeforeUnload);
        unblockPage();

        history.push(`/versus/${responseData._id}`);
      } catch (err) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [history, intl, unblockPage],
  );

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>{intl.getTranslatedText('pages.versusVIP.newRoom.title')}</h5>
        <Link to="/versus_vip">
          <CustomAntButton type="text" danger>
            {intl.getTranslatedText('common.buttons.cancel')}
          </CustomAntButton>
        </Link>
      </Header>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: intl.getTranslatedText('pages.versusVIP.newRoom.step1') },
            { title: intl.getTranslatedText('pages.versusVIP.newRoom.step2') },
            { title: intl.getTranslatedText('pages.versusVIP.newRoom.step3') },
          ]}
        />
        <Formik
          initialValues={newRoomFormData}
          validateOnMount
          validationSchema={Yup.object().shape({
            roomType: Yup.string()
              .oneOf(
                ['private', 'public'],
                intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.roomType.validation.invalid',
                ),
              )
              .required(
                intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.roomType.validation.required',
                ),
              ),
            name: Yup.string().required(
              intl.getTranslatedText(
                'pages.versusVIP.newRoom.form.name.validation.required',
              ),
            ),
            price: Yup.number()
              .min(
                10000,
                intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.price.validation.min',
                ),
              )
              .required(
                intl.getTranslatedText(
                  'pages.versusVIP.newRoom.form.price.validation.required',
                ),
              ),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <Room
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                  // loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 1 && (
                <Match
                  formik={formikProps}
                  championships={championships}
                  loadingChampionships={loadingChampionships}
                  isOnLastPageOfChampionships={
                    championshipsPagination.currentPage ===
                    championshipsPagination.totalPages
                  }
                  loadMoreChampionships={() => {
                    if (
                      championshipsPagination.currentPage <
                      championshipsPagination.totalPages
                    ) {
                      handleGetChampionships(
                        championshipsPagination.currentPage + 1,
                      );
                    }
                  }}
                  handleGetMatchsOfChampionship={handleGetMatchsOfChampionship}
                  matchsOfChampionship={matchsOfChampionship}
                  loadingMatchsOfChampionship={loadingMatchsOfChampionship}
                  isOnLastPageOfMatchsOfChampionship={
                    matchsOfChampionshipPagination.currentPage ===
                    matchsOfChampionshipPagination.totalPages
                  }
                  loadMoreMatchsOfChampionship={() => {
                    if (
                      matchsOfChampionshipPagination.currentPage <
                      matchsOfChampionshipPagination.totalPages
                    ) {
                      handleGetMatchsOfChampionship(
                        formikProps.values.championship?._id,
                        matchsOfChampionshipPagination.currentPage + 1,
                      );
                    }
                  }}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                  // loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  // setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 2 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default NewRoom;
