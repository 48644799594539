import * as yup from 'yup';

import { getTranslatedText } from './intl';

export function validateCpf(cpfParam: string | null | undefined): string {
  if (!cpfParam) return 'valid';

  const cpf = cpfParam.replace(/-/g, '').replace(/\./g, '');

  try {
    let soma = 0;
    let resto;

    /* Verifica se o CPF tem os 11 dígitos */
    const testLengthCpf = new RegExp('^[0-9]{11}$');
    if (!testLengthCpf.test(cpf)) {
      throw new Error('lengthInvalid');
    }

    /* Verifica se o CPF tem dos os dígitos iguais(inválido) */
    const testCPFInvalid = new RegExp(
      '^((1{11})|(2{11})|(3{11})|(4{11})|(5{11})|(6{11})|(7{11})|(8{11})|(9{11}))$',
    );
    if (testCPFInvalid.test(cpf)) {
      throw new Error('cpfInvalid');
    }

    /* ALGORITMO DE VALIDAÇÃO */
    for (let i = 1; i <= 9; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) throw new Error('cpfInvalid');

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11)))
      throw new Error('cpfInvalid');
    return 'valid';
  } catch (error) {
    return getTranslatedText('utils.validate.cpf.invalid');
  }
}

export function validateCnpj(cnpjParam: string | null | undefined): string {
  if (!cnpjParam) return 'valid';

  const cnpj = cnpjParam
    .replace(/-/g, '')
    .replace(/\./g, '')
    .replace(/\\/g, '')
    .replace(/\//g, '');

  try {
    /* Verifica se o CPF tem os 11 dígitos */
    const testLengthCnpj = new RegExp('^[0-9]{14}$');
    if (!testLengthCnpj.test(cnpj)) {
      throw new Error('lengthInvalid');
    }

    /* Verifica se o CPF tem dos os dígitos iguais(inválido) */
    const testCNPJInvalid = new RegExp(
      '^((1{14})|(2{14})|(3{14})|(4{14})|(5{14})|(6{14})|(7{14})|(8{14})|(9{14}))$',
    );
    if (testCNPJInvalid.test(cnpj)) {
      throw new Error('cnpjInvalid');
    }

    /* ALGORITMO DE VALIDAÇÃO */
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    let resultado;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * (pos -= 1);
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(`${digitos.charAt(0)}`))
      throw new Error('lengthInvalid');
    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * (pos -= 1);
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1)))
      throw new Error('lengthInvalid');

    return 'valid';
  } catch (error) {
    return getTranslatedText('utils.validate.cnpj.invalid');
  }
}

export function registerYupCustomValidationMethods(): void {
  yup.addMethod<yup.StringSchema>(yup.string, 'objectId', function validate() {
    return this.matches(/^[0-9a-fA-F]{24}$/, {
      message: getTranslatedText(
        'utils.validate.yupCustomValidationMethods.objectId.invalid',
      ),
      excludeEmptyString: true,
    });
  });
}
// export function validateCnpj(cnpj) {
//   if (!cnpj) return 'valid';

//   cnpj = cnpj.replace(/[^\d]+/g, '');

//   try {
//     if (cnpj.length !== 14)
//         return false;

//     // Elimina CNPJs invalidos conhecidos
//     if (cnpj == "00000000000000" ||
//         cnpj == "11111111111111" ||
//         cnpj == "22222222222222" ||
//         cnpj == "33333333333333" ||
//         cnpj == "44444444444444" ||
//         cnpj == "55555555555555" ||
//         cnpj == "66666666666666" ||
//         cnpj == "77777777777777" ||
//         cnpj == "88888888888888" ||
//         cnpj == "99999999999999")
//         return false;

//     // Valida DVs
//     tamanho = cnpj.length - 2
//     numeros = cnpj.substring(0,tamanho);
//     digitos = cnpj.substring(tamanho);
//     soma = 0;
//     pos = tamanho - 7;
//     for (i = tamanho; i >= 1; i--) {
//       soma += numeros.charAt(tamanho - i) * pos--;
//       if (pos < 2)
//             pos = 9;
//     }
//     resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//     if (resultado != digitos.charAt(0))
//         return false;

//     tamanho = tamanho + 1;
//     numeros = cnpj.substring(0,tamanho);
//     soma = 0;
//     pos = tamanho - 7;
//     for (i = tamanho; i >= 1; i--) {
//       soma += numeros.charAt(tamanho - i) * pos--;
//       if (pos < 2)
//             pos = 9;
//     }
//     resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
//     if (resultado != digitos.charAt(1))
//           return false;

//     return 'valid';
//   } catch (error) {
//     return 'O CNPJ informado não é válido';
//   }
// }

// export const validatePhoneRegEx = /^\([0-9]{2}\)([0-9]{5}|[0-9]{4})-[0-9]{4}$/;
// export const validatePhoneRegEx = /^\([0-9]{2}\)([0-9]{5}|[0-9]{4})-[0-9]{4}$/;
