import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiBell, FiMessageCircle } from 'react-icons/fi';
import { HiCurrencyDollar } from 'react-icons/hi';

import { useBullet } from '@/context/BulletContext';
import logoDesktopImg from '../../assets/logo-horizontal.svg';
import logoMobileImg from '../../assets/logo-only-icon.svg';
import defaultAvatar from '../../assets/DefaultAvatar.svg';

import Env from '../../config/Environment';

import { useAuth } from '../../context/AuthContext';
import { useChatP2P } from '../../context/ChatP2PContext';

import Notifications from './Notifications';

import { Container, LogoImage, NotificationButton, ChatButton } from './styles';
import CustomAntButton from '../CustomAntButton';

const Header: React.FC = () => {
  const { user: me } = useAuth();
  const { unreadChatsCount } = useChatP2P();
  const { toggleBulletOpened } = useBullet();

  const [pageScrolled, setPageScrolled] = useState(false);

  const [notificationsContainerOpened, setNotificationsContainerOpened] =
    useState(false);

  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const handlePageScrolled = useCallback(() => {
    if (window.scrollY > 0) {
      if (!pageScrolled) {
        setPageScrolled(true);
      }
    } else if (pageScrolled) {
      setPageScrolled(false);
    }
  }, [pageScrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handlePageScrolled);

    return () => {
      window.removeEventListener('scroll', handlePageScrolled);
    };
  }, [handlePageScrolled]);

  return (
    <>
      <Container scrolled={pageScrolled}>
        <Link to="/home">
          <LogoImage $type="desktop" src={logoDesktopImg} alt="Titan 369" />
          <LogoImage $type="mobile" src={logoMobileImg} alt="Titan 369" />
        </Link>
        <div>
          <CustomAntButton
            type="text"
            useCustomIcon
            icon={<HiCurrencyDollar size={24} />}
            onClick={() => toggleBulletOpened()}
          />
          <Link to="/chats">
            <ChatButton
              $unreadChats={unreadChatsCount}
              type="text"
              useCustomIcon
              icon={<FiMessageCircle size={24} />}
            />
          </Link>
          <NotificationButton
            type="text"
            onClick={async () => {
              setNotificationsContainerOpened(oldState => !oldState);
            }}
            useCustomIcon
            textPrimary={notificationsContainerOpened}
            $unreadNotifications={hasUnreadNotifications}
            icon={<FiBell size={24} />}
          />
          <Link to="/profile">
            <img
              src={me?.photo ? Env.IMAGE_SERVER_URL + me?.photo : defaultAvatar}
              alt={me?.name}
            />
          </Link>
        </div>
      </Container>
      <Notifications
        opened={notificationsContainerOpened}
        setOpened={setNotificationsContainerOpened}
        setHasUnreadNotifications={setHasUnreadNotifications}
      />
    </>
  );
};

export default Header;
