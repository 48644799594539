import React from 'react';

import { PointsDescription } from '@/pages/CoachFriend/Room/styles';
import Select from '../../../../../components/Select';
import { useIntl } from '../../../../../context/IntlContext';

import { IPlayCoachGoalGameState, IMinuteOfFirstGoalGameState } from '..';
import { ITeam } from '../..';

import { Container, Header, Content, TeamImageContainer } from './styles';

interface IPayGoalMode {
  minuteOfFirstGoalGame: IMinuteOfFirstGoalGameState;
  setMinuteOfFirstGoalGame: React.Dispatch<
    React.SetStateAction<IMinuteOfFirstGoalGameState>
  >;
  localTeam: ITeam;
  awayTeam: ITeam;
  selectedScore: IPlayCoachGoalGameState;
}

const PayGoalMode: React.FC<IPayGoalMode> = ({
  minuteOfFirstGoalGame,
  setMinuteOfFirstGoalGame,
  localTeam,
  awayTeam,
  selectedScore,
}) => {
  const intl = useIntl();

  let sessionScore = 0;
  if (selectedScore.local > 0) sessionScore += 15;
  if (selectedScore.away > 0) sessionScore += 15;

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText(
            'pages.coachFriend.room.minuteOfFirstGoal.title',
          )}
        </h6>
        <PointsDescription>
          {`${intl.getTranslatedText(
            'pages.coachFriend.room.newGame.maxScoreDescription',
          )} ${sessionScore} ${intl.getTranslatedText(
            'common.scoredPointsDescription',
          )}`}
        </PointsDescription>
      </Header>
      <Content>
        <div>
          <div>
            <TeamImageContainer>
              <img src={localTeam.image} alt={localTeam.name} />
            </TeamImageContainer>
            <strong>{localTeam.name}</strong>
          </div>
          <ul>
            {selectedScore.local > 0 ? (
              <Select
                label={intl.getTranslatedText(
                  'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                )}
                id="rangeOfLocalTeam"
                placeholder={intl.getTranslatedText('common.selectDefaultText')}
                options={[
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range1.label',
                    ),
                    value: '0-9',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range2.label',
                    ),
                    value: '10-19',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range3.label',
                    ),
                    value: '20-29',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range4.label',
                    ),
                    value: '30-39',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range5.label',
                    ),
                    value: '40-49',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range6.label',
                    ),
                    value: '50-59',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range7.label',
                    ),
                    value: '60-69',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range8.label',
                    ),
                    value: '70-79',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range9.label',
                    ),
                    value: '80-89',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range10.label',
                    ),
                    value: '90-99',
                  },
                ]}
                value={minuteOfFirstGoalGame.localTime}
                onChange={value => {
                  if (
                    value === undefined ||
                    value === '0-9' ||
                    value === '10-19' ||
                    value === '20-29' ||
                    value === '30-39' ||
                    value === '40-49' ||
                    value === '50-59' ||
                    value === '60-69' ||
                    value === '70-79' ||
                    value === '80-89' ||
                    value === '90-99'
                  ) {
                    setMinuteOfFirstGoalGame(oldState => ({
                      ...oldState,
                      localTime: value,
                    }));
                  }
                }}
              />
            ) : (
              <p>Esse time não marcou gols</p>
            )}
          </ul>
        </div>
        <div>
          <div>
            <TeamImageContainer>
              <img src={awayTeam.image} alt={awayTeam.name} />
            </TeamImageContainer>
            <strong>{awayTeam.name}</strong>
          </div>
          <ul>
            {selectedScore.away > 0 ? (
              <Select
                label={intl.getTranslatedText(
                  'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                )}
                id="rangeOfAwayTeam"
                placeholder={intl.getTranslatedText('common.selectDefaultText')}
                options={[
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range1.label',
                    ),
                    value: '0-9',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range2.label',
                    ),
                    value: '10-19',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range3.label',
                    ),
                    value: '20-29',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range4.label',
                    ),
                    value: '30-39',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range5.label',
                    ),
                    value: '40-49',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range6.label',
                    ),
                    value: '50-59',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range7.label',
                    ),
                    value: '60-69',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range8.label',
                    ),
                    value: '70-79',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range9.label',
                    ),
                    value: '80-89',
                  },
                  {
                    label: intl.getTranslatedText(
                      'pages.coachFriend.room.newGame.minuteOfFirstGoal.range10.label',
                    ),
                    value: '90-99',
                  },
                ]}
                value={minuteOfFirstGoalGame.awayTime}
                onChange={value => {
                  if (
                    value === undefined ||
                    value === '0-9' ||
                    value === '10-19' ||
                    value === '20-29' ||
                    value === '30-39' ||
                    value === '40-49' ||
                    value === '50-59' ||
                    value === '60-69' ||
                    value === '70-79' ||
                    value === '80-89' ||
                    value === '90-99'
                  ) {
                    setMinuteOfFirstGoalGame(oldState => ({
                      ...oldState,
                      awayTime: value,
                    }));
                  }
                }}
              />
            ) : (
              <p>Esse time não marcou gols</p>
            )}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default PayGoalMode;
