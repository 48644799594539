/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Input, Spin } from 'antd';
import {
  FiMessageCircle,
  FiX,
  FiSend,
  FiMic,
  FiCheck,
  FiTrash2,
  FiPlayCircle,
  FiPauseCircle,
} from 'react-icons/fi';
import { LoadingOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import * as dateFns from 'date-fns';
// import Recorder from 'react-mp3-recorder';
import { Recorder } from 'vmsg';

import { useAuth } from '../../context/AuthContext';
import {
  IChatData,
  IChatMessage,
  ISendNewMessageData,
} from '../../context/ChatContext';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import { useIntl } from '../../context/IntlContext';

import RecorderService from '../../utils/AudioRecorder/RecorderService';

import CustomAntButton from '../CustomAntButton';

import UserMessagesGroup from './UserMessagesGroup';
import MyMessagesGroup from './MyMessagesGroup';

import NewVoiceMessagePreviewAudioBar from './NewVoiceMessagePreviewAudioBar';

import {
  OpenChatButton,
  Container,
  Header,
  Content,
  Footer,
  RecordAudioMessageFooter,
  NewVoiceMessagePreviewPlayerContainer,
  PlayOrStopButton,
  LoadMoreMessagesButton,
  LoadingMessagesContainer,
} from './styles';
import { showToast } from '../../hooks/showToast';

const recorderSrvc = new RecorderService();

export interface IChatContainerRefMethods {
  scrollMessagesToBottom(): void;
}

interface IChatContainer {
  data: IChatData | null;
  messages: IChatMessage[];
  loadingMessages: boolean;
  handleSubmitNewMessage({ text, audio }: ISendNewMessageData): Promise<void>;
  hasMoreMessages: boolean;
  handleGetMoreMessages(): void;
}

export interface IGroupMessages {
  _id: string;
  _user: {
    _id: string;
    name: string;
    username: string;
    email: string;
    photo: string;
  };
  messages: IChatMessage[];
}

interface INewVoiceMessageState {
  isRecording: boolean;
  recordedTime: number;
  blobURL: string;
  file: null | File;
  loadingStartRecorder: boolean;
}

interface INewRecorderEvent extends Event {
  detail?: {
    recording: {
      blobUrl: string;
      blob: Blob;
      mimeType: string;
      size: number;
      ts: number;
    };
  };
}

const { TextArea } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const { format, addSeconds } = dateFns;

// function convertBlobToInt16Array(blob: Blob): Promise<Int16Array> {
//   return new Promise((resolve, reject) => {
//     const fr = new FileReader();
//     fr.readAsArrayBuffer(blob);

//     fr.onload = e => {
//       const result = e.target?.result;
//       if (result) {
//         if (typeof result !== 'string') {
//           resolve(new Int16Array(result));
//           // resolve(result);
//         }
//       }
//       // ...use the array here...
//     };
//     fr.onerror = e => {
//       reject(e);
//     };
//   });
// }

// async function convertWavBlobToMp3Blob(wavBlob: Blob): Promise<Blob> {
//   const channels = 1; // 1 for mono or 2 for stereo
//   const sampleRate = 44100; // 44.1khz (normal mp3 samplerate)
//   const kbps = 96; // encode 128kbps mp3
//   const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
//   const mp3Data = [];

//   // const samples = new Int16Array(44100); // one second of silence (get your data from the source you have)
//   const start = Date.now();
//   const samples = await convertBlobToInt16Array(wavBlob);
//   console.log(Date.now() - start);
//   const sampleBlockSize = 1152; // can be anything but make it a multiple of 576 to make encoders life easier

//   // for (let i = 0; i < samples.length; i += sampleBlockSize) {
//   //   const sampleChunk = samples.subarray(i, i + sampleBlockSize);
//   //   const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
//   //   if (mp3buf.length > 0) {
//   //     mp3Data.push(mp3buf);
//   //   }
//   // }
//   const mp3buf = mp3encoder.flush(); // finish writing mp3

//   if (mp3buf.length > 0) {
//     mp3Data.push(new Int8Array(mp3buf));
//   }

//   const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });

//   return mp3Blob;
// }

const ChatContainer: React.ForwardRefRenderFunction<
  IChatContainerRefMethods,
  IChatContainer
> = (
  {
    data,
    messages,
    handleSubmitNewMessage,
    hasMoreMessages,
    handleGetMoreMessages,
    loadingMessages,
  },
  ref,
) => {
  const intl = useIntl();

  const contentRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer({
      audioElement: audioRef.current,
    });

  const { user: me } = useAuth();

  const [opened, setOpened] = useState(false);

  const [currentFooter, setCurrentFooter] = useState<'text' | 'voice'>('text');

  const [newMessageValue, setNewMessageValue] = useState('');
  const [newVoiceMessage, setNewVoiceMessage] = useState<INewVoiceMessageState>(
    {
      isRecording: false,
      recordedTime: 0,
      blobURL: '',
      file: null,
      loadingStartRecorder: false,
    },
  );

  // const [recorder, setRecorder] = useState<Recorder | null>(null);

  // const mp3Recorder = useMemo(() => {
  //   return new MicRecorder({ bitRate: 160 });
  // }, []);
  const recorder = useMemo(() => {
    return new Recorder({
      wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
    });
  }, []);

  const messagesGroups = useMemo<IGroupMessages[]>(() => {
    const groups: IGroupMessages[] = [];

    messages.forEach(message => {
      if (
        groups.length === 0 ||
        message._user._id !== groups[groups.length - 1]._user._id
      ) {
        groups.push({
          _id: uuid(),
          _user: message._user,
          messages: [message],
        });
      } else {
        groups[groups.length - 1].messages.push(message);
      }
    });

    return groups;
  }, [messages]);

  const scrollContentToBottom = useCallback(() => {
    const contentDiv = contentRef.current;

    if (contentDiv) {
      contentDiv.scrollTop = contentDiv.scrollHeight - contentDiv.clientHeight;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    scrollMessagesToBottom: scrollContentToBottom,
  }));

  useEffect(() => {
    if (opened) {
      scrollContentToBottom();
    }
  }, [opened, scrollContentToBottom]);

  useEffect(() => {
    if (messagesGroups.length === 0) {
      setOpened(false);
    }
  }, [messagesGroups.length]);

  // useEffect(() => {
  //   recorderSrvc.em.addEventListener('recording', (env: INewRecorderEvent) => {
  //     if (!env.detail) {
  //       return;
  //     }
  //     const { blob, blobUrl, mimeType } = env.detail.recording;

  //     console.log({
  //       blob,
  //       recording: env.detail.recording,
  //     });

  //     const file = new File([blob], `chat-audio-${Date.now()}.mp3`, {
  //       type: mimeType,
  //       lastModified: Date.now(),
  //     });

  //     setNewVoiceMessage(oldState => {
  //       if (oldState.isRecording) {
  //         return {
  //           ...oldState,
  //           isRecording: false,
  //           file,
  //           blobURL: blobUrl,
  //         };
  //       }

  //       return oldState;
  //     });
  //   });
  // }, [messagesGroups.length]);

  const startVoiceMessageRecord = useCallback(async () => {
    try {
      // const stream = await new Promise<MediaStream>((resolve, reject) => {
      //   // navigator.mediaDevices.g
      //   navigator.getUserMedia(
      //     { audio: true },
      //     strm => {
      //       resolve(strm);
      //     },
      //     () => {
      //       reject();
      //     },
      //   );
      // });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      try {
        setNewVoiceMessage(oldState => ({
          ...oldState,
          loadingStartRecorder: true,
        }));
        stream.getTracks().map(track => track.stop());
        // await mp3Recorder.start();
        // const rec = new RecordRTCPromisesHandler(stream, {
        //   type: 'audio',
        // });
        // const rec = new Recorder({
        //   wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
        // });
        // await rec.init();
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        // setRecorder(rec);

        setCurrentFooter('voice');
        setNewVoiceMessage(oldState => ({
          ...oldState,
          isRecording: true,
          loadingStartRecorder: false,
        }));
      } catch (error) {
        // console.error(error);
      }
    } catch (error) {
      // navigator.mediaDevices.enumerateDevices().then(devices => {
      //   console.log(devices);
      // });
      // console.log(error);
      showToast({
        type: 'warn',
        title: intl.getTranslatedText(
          'components.chatContainer.messages.startVoiceMessageRecordError.title',
        ),
        description: intl.getTranslatedText(
          'components.chatContainer.messages.startVoiceMessageRecordError.description',
        ),
      });
    }
    // if () {
    //   showToast({
    //     type: 'warn',
    //     title: 'Permissão de acesso ao Microfone bloqueada',
    //     description:
    //       'Adicione a permissão de acesso ao Microfone e atualize a página para enviar mensagens de áudio',
    //   });
    // } else {
    //   try {
    //     await mp3Recorder.start();
    //     setCurrentFooter('voice');
    //     setNewVoiceMessage(oldState => ({ ...oldState, isRecording: true }));
    //   } catch (error) {
    //     // console.error(error);
    //   }
    // }
  }, [intl, recorder]);

  const stopVoiceMessageRecord = useCallback(
    async (cancelRecord = false): Promise<void> => {
      try {
        if (!cancelRecord) {
          if (recorder) {
            const blob = await recorder.stopRecording();

            const file = new File([blob], `chat-audio-${Date.now()}.mp3`, {
              type: blob.type,
              lastModified: Date.now(),
            });
            const blobURL = URL.createObjectURL(blob);

            setNewVoiceMessage(oldState => ({
              ...oldState,
              isRecording: false,
              file,
              blobURL,
            }));

            audioRef.current?.load();
          }
        } else {
          setNewVoiceMessage(oldState => {
            if (oldState.isRecording && recorder) {
              recorder.stopRecording();
              // recorder.stopRecording(() => {
              //   setRecorder(null);
              //   recorder.destroy();
              // });
              // mp3Recorder.stop();
            }

            return {
              isRecording: false,
              recordedTime: 0,
              blobURL: '',
              file: null,
              loadingStartRecorder: false,
            };
          });

          setCurrentFooter('text');
        }
        setNewMessageValue('');
      } catch (error) {
        // console.log(error);
      }
    },
    [recorder],
  );

  // const startVoiceMessageRecord = useCallback(async () => {
  //   let stream;
  //   try {
  //     stream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     });

  //     try {
  //       setNewVoiceMessage(oldState => ({
  //         ...oldState,
  //         loadingStartRecorder: true,
  //       }));
  //       stream.getTracks().map(track => track.stop());
  //       recorderSrvc.config.manualEncoderId = 'mp3';
  //       recorderSrvc.config.usingMediaRecorder = false;
  //       await recorderSrvc.startRecording();

  //       setCurrentFooter('voice');
  //       setNewVoiceMessage(oldState => ({
  //         ...oldState,
  //         isRecording: true,
  //         loadingStartRecorder: false,
  //       }));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } catch (error) {
  //     showToast({
  //       type: 'warn',
  //       title: 'Permissão de acesso ao Microfone bloqueada',
  //       description:
  //         'Adicione a permissão de acesso ao Microfone e atualize a página para enviar mensagens de áudio',
  //     });
  //   }
  // }, []);

  // const stopVoiceMessageRecord = useCallback(
  //   async (cancelRecord = false): Promise<void> => {
  //     try {
  //       if (!cancelRecord) {
  //         recorderSrvc.stopRecording();
  //       } else {
  //         setNewVoiceMessage(oldState => {
  //           if (oldState.isRecording) {
  //             recorderSrvc.stopRecording();
  //           }

  //           return {
  //             isRecording: false,
  //             recordedTime: 0,
  //             blobURL: '',
  //             file: null,
  //             loadingStartRecorder: false,
  //           };
  //         });

  //         setCurrentFooter('text');
  //       }
  //       setNewMessageValue('');
  //     } catch (error) {
  //       // console.log(error);
  //     }
  //   },
  //   [],
  // );

  useEffect(() => {
    let interval = 0;
    if (newVoiceMessage.isRecording) {
      interval = window.setInterval(() => {
        setNewVoiceMessage(oldState => {
          return {
            ...oldState,
            recordedTime: oldState.recordedTime + 1,
          };
        });
      }, 1000);
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [newVoiceMessage.isRecording, stopVoiceMessageRecord]);

  useEffect(() => {
    if (newVoiceMessage.recordedTime === 59) {
      stopVoiceMessageRecord();
    }
  }, [newVoiceMessage.recordedTime, stopVoiceMessageRecord]);

  const footerViewer = useMemo(() => {
    if (currentFooter === 'voice') {
      if (newVoiceMessage.isRecording) {
        return (
          <RecordAudioMessageFooter $mode="record">
            <CustomAntButton
              icon={<FiX size={20} />}
              useCustomIcon
              type="default"
              danger
              htmlType="button"
              onClick={() => stopVoiceMessageRecord(true)}
            />
            <span />
            <p>
              {format(
                addSeconds(
                  new Date(0),
                  parseInt(newVoiceMessage.recordedTime.toString()),
                ),
                'mm:ss',
              )}
            </p>
            <CustomAntButton
              icon={<FiCheck size={20} />}
              useCustomIcon
              type="primary"
              htmlType="button"
              onClick={() => stopVoiceMessageRecord()}
            />
          </RecordAudioMessageFooter>
        );
      }

      return (
        <RecordAudioMessageFooter
          $mode="preview"
          onSubmit={e => {
            e.preventDefault();

            if (newVoiceMessage.file) {
              handleSubmitNewMessage({
                audio: newVoiceMessage.file,
              });
              stopVoiceMessageRecord(true);
            }
          }}
        >
          <NewVoiceMessagePreviewPlayerContainer>
            <PlayOrStopButton
              type="button"
              onClick={() => {
                setPlaying(oldState => !oldState);
              }}
            >
              {!playing ? (
                <FiPlayCircle size={21} />
              ) : (
                <FiPauseCircle size={21} />
              )}
            </PlayOrStopButton>
            <NewVoiceMessagePreviewAudioBar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={time => {
                // console.log(time);
                setClickedTime(time);
              }}
            />
            <p>
              {duration &&
                format(
                  addSeconds(new Date(0), parseInt(duration.toString())),
                  'mm:ss',
                )}
            </p>
          </NewVoiceMessagePreviewPlayerContainer>
          <CustomAntButton
            icon={<FiTrash2 size={20} />}
            useCustomIcon
            type="default"
            danger
            htmlType="button"
            onClick={() => stopVoiceMessageRecord(true)}
          />
          <CustomAntButton
            icon={<FiSend size={20} />}
            useCustomIcon
            type="primary"
            htmlType="submit"
          />
        </RecordAudioMessageFooter>
      );
    }

    return (
      <Footer
        onSubmit={e => {
          e.preventDefault();
          handleSubmitNewMessage({
            text: newMessageValue,
          });
          setNewMessageValue('');
        }}
      >
        <TextArea
          placeholder={intl.getTranslatedText(
            'components.chatContainer.messageTextarea.placeholder',
          )}
          autoSize={{ maxRows: 5 }}
          value={newMessageValue}
          onChange={e => setNewMessageValue(e.target.value)}
        />
        {!newMessageValue ? (
          <CustomAntButton
            icon={<FiMic size={20} />}
            useCustomIcon
            type="primary"
            htmlType="button"
            onClick={() => startVoiceMessageRecord()}
            loading={newVoiceMessage.loadingStartRecorder}
          />
        ) : (
          <CustomAntButton
            icon={<FiSend size={20} />}
            useCustomIcon
            type="primary"
            htmlType="submit"
          />
        )}
      </Footer>
    );
  }, [
    curTime,
    currentFooter,
    duration,
    handleSubmitNewMessage,
    intl,
    newMessageValue,
    newVoiceMessage.file,
    newVoiceMessage.isRecording,
    newVoiceMessage.loadingStartRecorder,
    newVoiceMessage.recordedTime,
    playing,
    setClickedTime,
    setPlaying,
    startVoiceMessageRecord,
    stopVoiceMessageRecord,
  ]);

  if (!data) {
    return <></>;
  }

  if (!opened) {
    return (
      <OpenChatButton onClick={() => setOpened(true)}>
        <FiMessageCircle size={32} />
      </OpenChatButton>
    );
  }

  if (loadingMessages && messagesGroups.length === 0) {
    return (
      <Container>
        <Header>
          <div>
            <h6>{intl.getTranslatedText('components.chatContainer.title')}</h6>
            <small>
              {data.name} · {data.from}
            </small>
          </div>
          <CustomAntButton
            onClick={() => setOpened(false)}
            icon={<FiX size={20} />}
            useCustomIcon
            type="text"
          />
        </Header>
        <Content ref={contentRef}>
          <LoadingMessagesContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>
                {intl.getTranslatedText(
                  'components.chatContainer.loadingMessages',
                )}
              </p>
            </div>
          </LoadingMessagesContainer>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <div>
          <h6>{intl.getTranslatedText('components.chatContainer.title')}</h6>
          <small>
            {data.name} · {data.from}
          </small>
        </div>
        <CustomAntButton
          onClick={() => setOpened(false)}
          icon={<FiX size={20} />}
          useCustomIcon
          type="text"
        />
      </Header>
      <Content ref={contentRef}>
        {hasMoreMessages && (
          <LoadMoreMessagesButton
            onClick={() => {
              if (!loadingMessages) {
                handleGetMoreMessages();
              }
            }}
            disabled={loadingMessages}
          >
            {!loadingMessages ? (
              intl.getTranslatedText(
                'components.chatContainer.loadMoreMessagesButton',
              )
            ) : (
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            )}
          </LoadMoreMessagesButton>
        )}
        {messagesGroups.map(messagesGroup => {
          if (messagesGroup._user._id === me?._id) {
            return (
              <MyMessagesGroup
                key={messagesGroup._id}
                group={messagesGroup}
                messagesContentContainerRef={contentRef}
              />
            );
          }
          return (
            <UserMessagesGroup
              key={messagesGroup._id}
              group={messagesGroup}
              messagesContentContainerRef={contentRef}
            />
          );
        })}
        {/* <Recorder
          onRecordingComplete={blob => {
            console.log(URL.createObjectURL(blob));
          }}
        /> */}
        {/* <UserMessagesGroup group={} /> */}
      </Content>
      {footerViewer}
      <audio
        // preload="none"
        loop={false}
        ref={audioRef}
        style={{ display: 'none' }}
        src={newVoiceMessage.blobURL}
        // playsInline
      />
    </Container>
  );
};

export default forwardRef(ChatContainer);
