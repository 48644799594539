import styled, { css } from 'styled-components';

interface ISelectQuantitityOfStatistcContainer {
  $typeOfStatistic: 'redCards' | 'yellowCards' | 'corner' | 'offside';
}

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  margin-top: 16px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Content = styled.div``;

export const SelectQuantitityOfStatisticContainer = styled.div<ISelectQuantitityOfStatistcContainer>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  > div:nth-child(1) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    flex-shrink: 0;

    svg {
      ${props =>
        props.$typeOfStatistic === 'redCards' &&
        css`
          color: ${props.theme.colors.danger};
        `}
      ${props =>
        props.$typeOfStatistic === 'yellowCards' &&
        css`
          color: #e4ce00;
        `}
      ${props =>
        props.$typeOfStatistic === 'offside' &&
        css`
          color: #eb610e;
        `}
      ${props =>
        props.$typeOfStatistic === 'corner' &&
        css`
          color: ${props.theme.colors.danger};
        `}
    }
  }

  strong {
    flex-shrink: 0;
  }
`;

export const QuantityOfStatisticSelectorContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin-left: auto;

  h6 {
    color: ${props => props.theme.colors.light.light0};
    margin: 0 16px;
  }

  > div {
    margin-bottom: 0;

    div.ant-select-selector {
      border: 1px solid ${props => props.theme.colors.dark['dark+2']} !important;
    }
    div.ant-select-selection-placeholder {
      color: ${props => props.theme.colors.dark['dark+2']} !important;
    }
    /* background: ${props => props.theme.colors.dark['dark+2']}; */
  }
`;
