import React, { InputHTMLAttributes } from 'react';
import { Input as InputAnt, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './styles';

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  id: string;
  error?: string | false;
  showloading?: boolean;
  addonBefore?: React.ReactNode;
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  id,
  error,
  showloading,
  ...rest
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: '#009420' }} spin />
  );
  return (
    <Container error={!!error}>
      <label htmlFor={id}>{label}</label>
      <div>
        <InputAnt bordered id={id} {...rest} placeholder={placeholder} />
        {showloading && <Spin indicator={antIcon} />}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Input;
