import styled from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 72px);

  padding-top: 40px;

  @media only screen and (max-width: 1199px) {
    padding-top: 32px;

    min-height: calc(100vh - 61px);
  }

  @media only screen and (max-width: 600px) {
    padding-top: 16px;
  }
`;

export const TopContent = styled.div`
  padding: 0 56px;

  @media only screen and (max-width: 1199px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  div {
    display: flex;
    align-items: center;

    > svg {
      color: ${props => props.theme.colors.primary.primary0};
    }

    button {
      margin-left: 8px;
    }
  }
`;

export const MatchInfoContainer = styled.button`
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  border: 0;
  transition: 0.2s background;
  padding: 16px 0;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${props => props.theme.colors.light['light-2']};
      margin-right: 4px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const TeamsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    max-width: 752px;
    margin: 0 auto;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary.primary0};
    text-align: center;
    width: 100%;
  }
`;

export const CoachFriendRoomTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    div {
      width: 104px;
      height: 104px;

      img {
        height: 56px;
      }
    }

    strong {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
    }
  }
`;

export const AccumulatedValueContainer = styled.div`
  padding: 8px 16px;
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.primary.primary0};
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 16px;

  svg {
    color: #e4ce00;
  }

  small {
    margin-left: 8px;
  }
`;

export const RestTimerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;

  div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 24px;
    }

    h4 {
      color: ${props => props.theme.colors.primary.primary0};
    }
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const MatchInfoDetailsContainer = styled.div`
  width: 100%;
  padding: 16px 0;

  > div {
    width: 100%;
    max-width: 752px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    div {
      width: 100%;

      & + div {
        margin-top: 8px;
      }

      small {
        color: ${props => props.theme.colors.light['light-2']};
        text-align: start;
      }

      small:nth-child(1) {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const ViewMyGameContainer = styled.div`
  width: 100%;
  min-height: 104px;
  border-top: 1px solid ${props => props.theme.colors.dark['dark+3']};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: ${props => props.theme.colors.light['light-2']};
    width: 100%;
    text-align: center;
  }

  > button {
    margin-top: 16px;
  }
`;

export const ViewMyGameButton = styled(Link)`
  border: 0;

  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.dark.dark0};
  display: flex;
  flex-direction: column;
  padding: 8px 0 16px;
  transition: 0.2s background;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  > div {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      h6 {
        font-weight: ${props => props.theme.typograph.weights.medium};
        color: ${props => props.theme.colors.light.light0};
        margin: 0;

        &:nth-child(1) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2) {
          margin-left: 16px;
          flex-shrink: 0;
        }
      }
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
      width: 100%;
      text-align: center;
    }
  }
`;

export const TabsContainer = styled.div`
  background: ${props => props.theme.colors.dark['dark+1']};
  width: 100%;
  flex: 1;
`;

export const ContentTab = styled.div`
  padding: 16px;
`;

export const EnterOnRoomContainer = styled.div`
  width: 100%;
  padding: 56px 16px 16px;
  max-width: 752px;
  margin: 0 auto;

  p {
    width: 100%;
    text-align: center;
  }

  > h6 {
    text-align: center;
    margin-top: 16px;
    color: ${props => props.theme.colors.primary.primary0};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    button:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

export const PointsDescription = styled.small`
  font-size: ${props => props.theme.typograph.sizes.xs};
  font-weight: ${props => props.theme.typograph.weights.light};
  color: ${props => props.theme.colors.light.light0};
  padding: 2px 8px;
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 5px;

  span {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;
