const MyCoachFriendRoomsTexts = {
  'pages.coachFriend.myRooms.title': 'My rooms',

  // /* ------------ Buttons ------------ */
  'pages.coachFriend.myRooms.submitArchiveRoomConfirmButton': 'Archive',
  'pages.coachFriend.myRooms.submitUnarchiveRoomConfirmButton': 'Active',
  'pages.coachFriend.myRooms.goToHomeLink': 'Back to home',
  'pages.coachFriend.myRooms.goToActiveRoomsButton': 'Back to active room',
  'pages.coachFriend.myRooms.showArchivedRoomsButton': 'Show archived rooms',
  'pages.coachFriend.myRooms.showActiveRoomsButton': 'Show active rooms',
  /* ------------ Messages ------------ */
  'pages.coachFriend.myRooms.messages.getRoomsError.title':
    'Unexpected error to load rooms',
  'pages.coachFriend.myRooms.messages.getArchivedRoomsError.title':
    'Unexpected error to load hidden rooms', // hidden or private?
  'pages.coachFriend.myRooms.messages.submitArchiveRoomConfirm.description':
    'Do you really want to archive this room?',
  'pages.coachFriend.myRooms.messages.submitArchiveRoomSuccess.title':
    'Archived Room!',
  'pages.coachFriend.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Do you really want to active this room?',
  'pages.coachFriend.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Room is active!',
  'pages.coachFriend.myRooms.messages.noRooms':
    "You aren't in any <span>room</span> yet",
  'pages.coachFriend.myRooms.messages.viewingArchivedRooms':
    'You are viewing your archived rooms',
  'pages.coachFriend.myRooms.messages.noArchivedRooms':
    "You haven't any <span>room archived</span>",
};

const PublicCoachFriendRoomsTexts = {
  'pages.coachFriend.publicRooms.title': 'Public rooms',
  'pages.coachFriend.publicRooms.searchInput.placeholder':
    'Search by room name, team, or user',

  /* ------------ Messages ------------ */
  'pages.coachFriend.publicRooms.messages.viewingFindedOnSearchRooms':
    'Loading found rooms',
  'pages.coachFriend.publicRooms.messages.noPublicRooms':
    'No public room available at <span>Family vs Friends</span>',
  'pages.coachFriend.publicRooms.messages.noRooms': 'No room found',
};

const NewCoachFriendRoomTexts = {
  'pages.coachFriend.newRoom.title': 'New room',
  'pages.coachFriend.newRoom.step1': 'Room',
  'pages.coachFriend.newRoom.step2': 'Match',
  'pages.coachFriend.newRoom.step3': 'Accept',

  'pages.coachFriend.newRoom.submitNewRoomConfirmButton': 'New room',

  'pages.coachFriend.newRoom.form.roomType.validation.invalid':
    'Invalid room type',
  'pages.coachFriend.newRoom.form.roomType.validation.required':
    'Room type is required',
  'pages.coachFriend.newRoom.form.name.validation.required':
    'Room name is required',
  'pages.coachFriend.newRoom.form.price.validation.required':
    'Room price is required',

  'pages.coachFriend.newRoom.messages.blockPage.title':
    'Do you really want to quit this page?',
  'pages.coachFriend.newRoom.messages.getChampionshipsError.title':
    'Unexpected error to load championships',
  'pages.coachFriend.newRoom.messages.getMatchsOfChampionshipError.title':
    'Unexpected error to load championship games',
  'pages.coachFriend.newRoom.messages.submitNewRoomConfirm.title':
    'Do you wish to create this room?',
  'pages.coachFriend.newRoom.messages.submitNewRoomSuccess.title': 'Done!',
  'pages.coachFriend.newRoom.messages.submitNewRoomSuccess.description':
    'Invite your friend and do your game. Enjoy!',
  //   /* Room */
  ...{
    /* roomType - form */
    'pages.coachFriend.newRoom.form.roomType.label': 'Room type',
    'pages.coachFriend.newRoom.form.roomType.privateRoomTitle': 'Private room',
    'pages.coachFriend.newRoom.form.roomType.privateRoomDescription':
      'Only your friends or invited can join',
    'pages.coachFriend.newRoom.form.roomType.publicRoomTitle': 'Public room',
    'pages.coachFriend.newRoom.form.roomType.publicRoomDescription':
      'Open to wide world',
    /* name - form */
    'pages.coachFriend.newRoom.form.name.label': 'Room name',
    'pages.coachFriend.newRoom.form.name.placeholder': 'Type a room name',
    /* price - form */
    'pages.coachFriend.newRoom.form.price.label': 'Game price',
    'pages.coachFriend.newRoom.form.price.placeholder': 'for example: 99',
  },
  //   /* Match */
  ...{
    'pages.coachFriend.newRoom.form.selectedChampionship.label': 'Championship',
    'pages.coachFriend.newRoom.form.selectedMatch.label': 'Match',

    'pages.coachFriend.newRoom.form.matchInfo.date.label': 'Date and hour',
    'pages.coachFriend.newRoom.form.matchInfo.place.label': 'Place',

    'pages.coachFriend.newRoom.messages.selectChampionship':
      'Select a championship',
    'pages.coachFriend.newRoom.messages.loadingChampionships':
      'Loading championships...',
    'pages.coachFriend.newRoom.messages.selectMatch': 'Select a match',
    'pages.coachFriend.newRoom.messages.loadingMatchs':
      'Loading championship games...',
    'pages.coachFriend.newRoom.messages.noChampionshipMatchs':
      'This championship has no <span>matches</span> available yet',
  },
};

const CoachFriendRoomTexts = {
  'pages.coachFriend.room.tabs.games': 'Games',
  'pages.coachFriend.room.tabs.friends': 'Friends',
  'pages.coachFriend.room.submitGetInOnPublicRoomConfirmButton': 'Join room',
  'pages.coachFriend.room.goToPlayConfirmButton': 'Play',
  'pages.coachFriend.room.submitDeleteRoomConfirmButton': 'Delete room',
  'pages.coachFriend.room.rejectInviteButton': 'Refuse',
  'pages.coachFriend.room.playButton': 'Play now',
  'pages.coachFriend.room.showMyGame': 'Click to more details about your game',
  'pages.coachFriend.room.goToListRoomsLink': 'Back to room list',

  'pages.coachFriend.room.messages.submitRejectInviteForThisRoomConfirm.title':
    'Do you really want to refuse this entry request?',
  'pages.coachFriend.room.messages.submitGetInOnPublicRoomConfirm.title':
    'Would you like to join this room?',
  'pages.coachFriend.room.messages.goToPlayConfirm.title':
    'Do you want to play here?',
  'pages.coachFriend.room.messages.goToPlayConfirm.description':
    'Game cost T¢{price}',
  'pages.coachFriend.room.messages.submitDeleteRoomConfirm.title':
    'Do you really want to delete this room?',
  'pages.coachFriend.room.messages.submitDeleteRoomLoading': 'Working...',
  'pages.coachFriend.room.messages.submitDeleteRoomSuccess.title':
    'Deleted room!',
  'pages.coachFriend.room.messages.youNotHaveGame': "You haven't played yet",
  'pages.coachFriend.room.messages.itsNotPossibleToPlayOnRoom':
    "You can't play anymore here",
  'pages.coachFriend.room.messages.inviteDescription':
    'You received a invite to join us in this room. (Game cost T¢{price})',
  'pages.coachFriend.room.messages.enterOnPublicRoomDescription':
    "You didn't join in this room. would you like to join? (Game cost T¢{price})",
  'pages.coachFriend.room.messages.itsNotPossibleToEnterTheRoom':
    'Nobody can join in this room',
  'pages.coachFriend.room.messages.roomNotFound': '<span>Room</span> not found',

  /* Friend */
  ...{
    'pages.coachFriend.room.friends.addFriendsModal.title':
      'Invite your friend',
    'pages.coachFriend.room.friends.addFriendsModal.okButton':
      'Invite your friends',
    'pages.coachFriend.room.friends.addFriendsModal.searchUserInput.placeholder':
      'Search user',
    'pages.coachFriend.room.friends.addFriendsModal.selectedUsers':
      'Selected player',

    'pages.coachFriend.room.friends.userAddedAddFriendModalDescription':
      'Added',
    'pages.coachFriend.room.friends.searchFriendInput.placeholder':
      'Search friend',
    'pages.coachFriend.room.friends.userWasRequestedDescription': 'Requested',

    'pages.coachFriend.room.friends.submitAddUsersConfirmButton': 'Add',
    'pages.coachFriend.room.friends.submitDeleteUserConfirmButton':
      'Remove player',
    'pages.coachFriend.room.friends.submitLogOutForThisRoomConfirmButton':
      'Quit',

    'pages.coachFriend.room.friends.messages.submitSearchUserError.title':
      'Unexpected error to load users',
    'pages.coachFriend.room.friends.messages.userNotFound':
      '<span>User</span> not found',
    'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.title':
      'Do you really want to invite these users in this room?',
    'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.description':
      'They cannot be removed later',
    'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.title':
      'Invite sent successfully!',
    'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.description':
      'Your room invites has been sent to each user selected',
    'pages.coachFriend.room.friends.messages.submitDeleteUserConfirm.title':
      'Do really want to remove this player?',
    'pages.coachFriend.room.friends.messages.submitDeleteUserLoading':
      'Working...',
    'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.title':
      'Player removed!',
    'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.description':
      'This user has been kicked.',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomConfirm.title':
      'Do you really want to quit this room?',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomLoading':
      'Working...',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.title':
      'You left!',
    'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.description':
      'You are no longer player at this room',
    'pages.coachFriend.room.friends.messages.loadingFriends':
      'Loading friends...',
  },

  'pages.coachFriend.room.playCoachGoal.title': 'Match goals',

  'pages.coachFriend.room.minuteOfFirstGoal.title': '1st goal minute',
  'pages.coachFriend.room.minuteOfFirstGoal.interval.label': 'Minute range',

  'pages.coachFriend.room.athletes.title': 'Scorer',
  'pages.coachFriend.room.athletes.throw1': '1st shot',
  'pages.coachFriend.room.athletes.throw2': '2nd shot',
  'pages.coachFriend.room.athletes.throw3': '3rd shot',
  'pages.coachFriend.room.athletes.throw.notSelected': 'Not selected',

  'pages.coachFriend.room.statistics.title': 'Statistics',
  'pages.coachFriend.room.statistics.yellowCards.label': 'Yellow card',
  'pages.coachFriend.room.statistics.redCards.label': 'Red card',
  'pages.coachFriend.room.statistics.offsideNumber.label': 'Off-side',
  'pages.coachFriend.room.statistics.cornerNumber.label': 'Corner',

  /* Games */
  ...{
    'pages.coachFriend.room.games.searchGameInput.placeholder': 'Search game',

    'pages.coachFriend.room.games.messages.loadingGames':
      'Loading friends games...',
    'pages.coachFriend.room.games.messages.partialInfo':
      'The information below is partial',
    'pages.coachFriend.room.games.messages.noGames':
      'This room <span>has no</span> game',
  },

  /* My Game Details */
  ...{
    'pages.coachFriend.room.myGameDetails.title': 'My game details',

    'pages.coachFriend.room.myGameDetails.roomIdentifier.label': 'ROOM: ',
    'pages.coachFriend.room.myGameDetails.totalScore': '{totalScore} score',

    'pages.coachFriend.room.myGameDetails.goToRoomsListLink':
      'Back to rooms list',

    'pages.coachFriend.room.myGameDetails.messages.gameNotFount':
      '<span>Game</span> not found',
  },

  /* New Game */
  ...{
    'pages.coachFriend.room.newGame.title': 'Play',

    'pages.coachFriend.room.newGame.maxScoreDescription': 'up to',

    'pages.coachFriend.room.newGame.submitNewGameConfirmButton': 'End game',
    'pages.coachFriend.room.newGame.goToRoomsListLink': 'Back to rooms list',
    'pages.coachFriend.room.newGame.goldenGoalButton': 'Golden Goal',

    'pages.coachFriend.room.newGame.messages.unblockPageConfirm.title':
      'Do you really want to quit this game?',
    'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.title':
      'Ops...',
    'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.description':
      'Select all options available before to play',
    'pages.coachFriend.room.newGame.messages.submitNewGameConfirm.title':
      'Do you really want to play?',
    'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.title':
      'Game successfully done!',
    'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.description':
      'You cannot interact with your friends in this room',
    'pages.coachFriend.room.newGame.messages.noGame':
      '<span>Game</span> not available',

    /* Statistics */
    'pages.coachFriend.room.newGame.statistics.cornerRange.label':
      '{firstRangeValue} to {lastRangeValue}',

    /* MinuteOfFirstGoal */
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range1.label':
      '00min. - 09min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range2.label':
      '10min. - 19min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range3.label':
      '20min. - 29min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range4.label':
      '30min. - 39min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range5.label':
      '40min. - 49min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range6.label':
      '50min. - 59min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range7.label':
      '60min. - 69min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range8.label':
      '70min. - 79min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range9.label':
      '80min. - 89min.',
    'pages.coachFriend.room.newGame.minuteOfFirstGoal.range10.label':
      '90min. - 99min.',

    /* Athletes */
    'pages.coachFriend.room.newGame.athletes.selectPlayer1Modal.title':
      'Select a player to 1st shot | {teamNameAbbrev}',
    'pages.coachFriend.room.newGame.athletes.selectPlayer2Modal.title':
      'Select a player to 2nd shot | {teamNameAbbrev}',
    'pages.coachFriend.room.newGame.athletes.selectPlayer3Modal.title':
      'Select a player to 3rd shot | {teamNameAbbrev}',

    'pages.coachFriend.room.newGame.athletes.playerNumber':
      'Shit {playerNumber}',
    'pages.coachFriend.room.newGame.athletes.addPlayerButton': 'Click to add',
  },
};

export default {
  ...MyCoachFriendRoomsTexts,
  ...PublicCoachFriendRoomsTexts,
  ...NewCoachFriendRoomTexts,
  ...CoachFriendRoomTexts,
};
