import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import api from '@/services/api';
import { useRouteMatch } from 'react-router-dom';
import { isAfter, parseISO, sub } from 'date-fns';
import { ICoachFriendRoom } from '.';

interface ICoachFriendRoomContextData {
  isLoadingRoom: boolean;
  room: ICoachFriendRoom | null;
  currentChallengeRoute: 'list' | 'new' | 'myChallenges';
  setCurrentChallengeRoute: React.Dispatch<
    React.SetStateAction<'list' | 'new' | 'myChallenges'>
  >;
}

const CoachFriendRoomContext = createContext<ICoachFriendRoomContextData>(
  {} as ICoachFriendRoomContextData,
);

const CoachFriendRoomProvider: React.FC = ({ children }) => {
  const { params } = useRouteMatch<{ id: string }>();

  const [isLoadingRoom, setIsLoadingRoom] = useState(true);
  const [room, setRoom] = useState<ICoachFriendRoom | null>(null);

  const [currentChallengeRoute, setCurrentChallengeRoute] = useState<
    'list' | 'new' | 'myChallenges'
  >('list');

  const getRoom = useCallback(async () => {
    try {
      const { data } = await api.get<{
        doc: ICoachFriendRoom;
      }>(`/api/coach-friend/${params.id}`);

      const coachFriendRoom: ICoachFriendRoom = { ...data.doc };
      coachFriendRoom._game.local.abbrev = coachFriendRoom._game.local.name
        .substring(0, 3)
        .toUpperCase();
      coachFriendRoom._game.away.abbrev = coachFriendRoom._game.away.name
        .substring(0, 3)
        .toUpperCase();

      coachFriendRoom.started = isAfter(
        new Date(),
        parseISO(coachFriendRoom._game.info.date),
      );
      const limitForActionsOnRoomData = sub(
        parseISO(coachFriendRoom._game.info.date),
        {
          minutes: 15,
        },
      );
      coachFriendRoom.blocked = isAfter(new Date(), limitForActionsOnRoomData);

      coachFriendRoom.players = [];
      coachFriendRoom.games = [];

      setRoom(coachFriendRoom);
    } catch (error) {
      setRoom(null);
    }

    setIsLoadingRoom(false);
  }, [params.id]);

  useEffect(() => {
    getRoom();
  }, [getRoom]);

  return (
    <CoachFriendRoomContext.Provider
      value={{
        isLoadingRoom,
        room,
        currentChallengeRoute,
        setCurrentChallengeRoute,
      }}
    >
      {children}
    </CoachFriendRoomContext.Provider>
  );
};

function useCoachFriendRoom(): ICoachFriendRoomContextData {
  const context = useContext(CoachFriendRoomContext);

  if (!context) {
    throw new Error('useCoachFriendRoom must be used within a ChatProvider');
  }

  return context;
}

export { CoachFriendRoomProvider, useCoachFriendRoom };
