import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${props => props.theme.colors.dark['dark+2']};

    .ant-select-selection-placeholder {
      color: ${props => shade(0.25, props.theme.colors.light['light-2'])};
    }
  }
`;
