import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  margin-top: 16px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: 48%;

    @media only screen and (max-width: 768px) {
      width: 100%;

      &:nth-child(2) {
        margin-top: 32px;
      }
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};
      margin-bottom: 8px;
    }

    > ul {
      margin-bottom: 0;
    }
  }
`;
export const TeamImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.colors.dark['dark+2']};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  img {
    height: 26px;
  }
`;
export const ThrowContainer = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;

  & + li {
    margin-top: 8px;
  }

  > small {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }
`;
export const SelectedPlayerOfThrow = styled.div`
  border: none;
  background: ${props => props.theme.colors.dark['dark+2']};
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s opacity;
  box-shadow: 2px 2px 16px #00000030;
  border: 1px solid ${props => props.theme.colors.dark['dark+2']};

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    width: 100%;
    text-align: center;
  }
`;
