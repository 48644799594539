import styled from 'styled-components';

import Input from '@/components/Input';
import Radio from '@/components/Radio';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SelectPaymentContainer = styled.div`
  > p {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    line-height: 1.2;
    color: ${props => props.theme.colors.light.light0};
    margin: 0 0 0.5rem;
  }

  > small {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    line-height: 1.2;
    color: ${props => props.theme.colors.light['light-2']};
    margin: 0;
  }
`;

export const PaymentRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: ${props => props.theme.colors.dark['dark+3']};
  }
`;

export const PriceChallengeInputContainer = styled.div`
  strong {
    margin: 0;
    width: 100%;
    color: ${props => props.theme.colors.light.light0};
    line-height: 120%;
    display: block;

    span {
      font-weight: ${props => props.theme.typograph.weights.medium};
    }

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.small}px;
    }
  }
`;

export const PriceChallengeInput = styled(Input)`
  .ant-input-wrapper {
    input {
      background-color: ${props => props.theme.colors.dark['dark+2']};
    }

    .ant-input-group-addon {
      border: 1px solid ${props => props.theme.colors.dark['dark+2']};
    }
  }
`;
