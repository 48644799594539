import styled, { css } from 'styled-components';
import { darken, rgba } from 'polished';

interface IRankingSelectorProps {
  $selected: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  h4 {
    color: ${props => props.theme.colors.light.light0};
    margin: 0 56px;
  }

  > section {
    align-self: center;
    display: flex;
    margin-top: 64px;
    z-index: 5;
    padding: 0 56px;
  }

  ul {
    width: 100%;
    max-width: 648px;
    align-self: center;
    z-index: 10;
    padding: 0 56px;
  }

  /* padding: 64px 56px; */
  padding: 64px 0;
  min-height: calc(100vh - 72px);

  @media only screen and (max-width: 1199px) {
    /* padding: 40px 32px; */
    padding: 40px 0;
    min-height: calc(100vh - 61px);

    h4 {
      margin: 0 32px;
    }

    section,
    ul {
      padding: 0 32px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 32px 0;
    /* padding: 32px 16px; */
    h4 {
      margin: 0 16px;
    }

    section,
    ul {
      padding: 0 16px;
    }
  }
`;

export const RankingSelectorsContainer = styled.div`
  width: 100%;
  /* max-width: 688px; */
  display: flex;
  /* margin-top: 16px; */
  margin-top: 4px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 32px;

  &::after,
  &::before {
    content: '';
    min-width: 56px;
    @media only screen and (max-width: 1199px) {
      min-width: 32px;
    }

    @media only screen and (max-width: 600px) {
      min-width: 16px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const RankingSelector = styled.button<IRankingSelectorProps>`
  border: 0;
  padding: 4px 8px;
  background: ${props => props.theme.colors.dark['dark+2']};
  color: ${props => props.theme.colors.light['light-2']};
  border-radius: 4px;
  transition: 0.2s box-shadow;
  flex-shrink: 0;

  & + button {
    margin-left: 8px;
  }

  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
      color: ${props.theme.colors.dark.dark0};
    `}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  &:hover {
    box-shadow: 1px 1px 3px
      ${props => darken(0.1, props.theme.colors.dark.dark0)};
  }
`;

export const Content = styled.div`
  align-self: center;
  width: 100%;
  max-width: 648px;
  margin-bottom: 16px;

  padding: 0 56px;

  @media only screen and (max-width: 1199px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }

  > small {
    margin-bottom: 16px;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
  }
`;

export const Top3Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 104px;

  &:nth-child(2) {
    margin: 0 16px;
  }

  p:first-child {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  svg {
    color: #f8c700;
    margin-bottom: 8px;
  }

  span {
    /* width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 50%; */
    border: 3px solid ${props => props.theme.colors.primary.primary0};
    box-sizing: content-box;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
  }

  small {
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  small:last-child {
    color: ${props => props.theme.colors.primary.primary0};
    margin-top: 8px;
  }

  &:nth-child(2) {
    span {
      /* width: 100px;
      height: 100px; */
      box-shadow: 0px 0px 20px
        ${props => rgba(props.theme.colors.primary.primary0, 0.5)};
    }

    transform: translateY(-60px);
  }
`;

export const RankingPlayerItem = styled.div`
  border: 0;
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
  transition: 0.2s background;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    margin-right: 16px;
  }

  span {
    margin-right: 8px;
  }

  div {
    text-align: start;
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    margin-left: auto;

    small:nth-child(2) {
      margin-left: 16px;
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 8px;
  }
`;

export const SearchPlayerItem = styled.div`
  border: 0;
  /* background: ${props => props.theme.colors.dark.dark0}; */
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
  transition: 0.2s background;

  /* &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  } */

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    margin-right: 16px;
  }

  span {
    margin-right: 8px;
  }

  div {
    text-align: start;
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  @media only screen and (min-width: 1200px) {
    padding: 8px;
  }
`;

export const ViewMoreButton = styled.button`
  border: 0;
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  }
  border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 56px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const UserIsMeIndicator = styled.small`
  padding: 2px 8px;
  background: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 4px;
  margin-left: auto;
`;

export const SearchAutocompleteContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
  > p,
  > h6 {
    text-align: center;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const SelectedOnSearchUserContainer = styled.div`
  width: 100%;
  max-width: 648px;
  padding: 0 56px;
  align-self: center;

  @media only screen and (max-width: 1199px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const SelectedOnSearchUser = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  max-width: 100%;

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    /* padding-bottom: 8px; */
    /* border-bottom: 1px solid ${props =>
      props.theme.colors.dark['dark+4']}; */

    > small {
      align-self: flex-start;
    }

    span {
      margin-right: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      small {
        color: ${props => props.theme.colors.light['light-2']};

        &:nth-child(3) {
          color: ${props => props.theme.colors.primary['primary+1']};
        }
      }
    }
  }

  > p {
    margin-top: 16px;
  }

  > div:nth-child(2) {
    padding: 16px 0;
    width: 100%;

    p {
      width: 100%;
      text-align: center;
    }
  }

  ul {
    margin-bottom: 0;
    a {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      transition: 0.2s background;

      &:hover {
        background: ${props => props.theme.colors.dark['dark+2']};
      }

      p {
        margin-bottom: 8px;
      }

      small {
        color: ${props => props.theme.colors.light['light-2']};
      }

      & + a {
        border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
      }
    }

    > button {
      border: 0;
      background: transparent;
      width: 100%;
      border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
      padding: 8px 0;
      transition: 0.2s background;

      &:hover {
        background: ${props => props.theme.colors.dark['dark+2']};
      }

      p {
        width: 100%;
        text-align: center;
      }
    }
  }
`;
