import React from 'react';

import { useCoachFriendRoom } from '@/pages/CoachFriend/Room/CoachFriendRoomContext';

import { ITeam } from '@/pages/CoachFriend/Room';
import { ITeamSelectorProps } from './types';
import { Container, TeamSelectorContainer } from './styles';

const TeamSelector: React.FC<ITeamSelectorProps> = ({
  onSelect,
  selectedTeamId,
}) => {
  const coachFriendRoomContext = useCoachFriendRoom();
  const localTeam = coachFriendRoomContext?.room?._game.local as ITeam;
  const awayTeam = coachFriendRoomContext?.room?._game.away as ITeam;

  return (
    <Container>
      <small>Selecione o time</small>
      <div>
        <TeamSelectorContainer
          $selected={selectedTeamId === localTeam?._id}
          onClick={() => onSelect(localTeam)}
        >
          <div>
            <img src={localTeam?.image} alt={localTeam?.name} />
          </div>
          <strong>{localTeam?.name}</strong>
        </TeamSelectorContainer>
        <TeamSelectorContainer
          $selected={selectedTeamId === awayTeam?._id}
          onClick={() => onSelect(awayTeam)}
        >
          <div>
            <img src={awayTeam?.image} alt={awayTeam?.name} />
          </div>
          <strong>{awayTeam?.name}</strong>
        </TeamSelectorContainer>
      </div>
    </Container>
  );
};

export default TeamSelector;
