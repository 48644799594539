import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 16px; */
  text-align: start;

  & + div {
    margin-top: 8px;
  }

  > button:nth-child(1) {
    width: 104px;
    height: 104px;
    /* border-radius: 8px; */
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    flex-shrink: 0;

    border: 1px solid ${props => props.theme.colors.dark['dark+4']};
    background: ${props => props.theme.colors.dark['dark+2']};
    transition: 0.2s opacity;
    /* margin-right: 16px; */

    img {
      width: 100%;
      height: 100%;
      /* border-radius: 8px; */
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    flex: 1;
    overflow-x: hidden;

    div:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      strong {
        color: ${props => props.theme.colors.light.light0};
      }

      small {
        overflow: inherit;
        text-overflow: inherit;
        color: ${props => props.theme.colors.light['light-2']};
      }

      div {
        margin-top: 8px;
        display: flex;
        align-items: center;

        img {
          height: 18px;
          margin-right: 8px;
        }

        p {
          color: ${props => props.theme.colors.light['light-2']};
        }
      }
    }

    > h6 {
      flex-shrink: 0;
      margin-left: 8px;
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
