import { TCommonTexts } from '../../en/common';

const ErrorsTexts = {
  'common.errors.unexpectedError.title': 'Ocorreu um erro inesperado',
  'common.errors.unexpectedError.description':
    'Atualize a página e tente novamente!',
};

const MessagesTexts = {
  'common.messages.defaultLoading': 'Carregando...',
  'common.messages.actionCannotBeUndone': 'Essa ação não poderá ser desfeita',
  'common.messages.warning': 'Atenção!',
  'common.messages.minCharToSearch':
    'Digite acima de 3 caracteres para realizar a busca',
  'common.messages.changesWillBeLost': 'As alterações serão perdidas',
};

const ButtonsTexts = {
  'common.buttons.cancel': 'Cancelar',
  'common.buttons.no': 'Não',
  'common.buttons.yes': 'Sim',
  'common.buttons.goBack': 'Voltar',
  'common.buttons.next': 'Próximo',
  'common.buttons.viewMore': 'Ver mais',
  'common.buttons.exit': 'Sair',
  'common.buttons.moreDetails': 'Clique para ver mais detalhes',
  'common.buttons.lessDetails': 'Clique para ver menos detalhes',
  'common.buttons.archive': 'Arquivar',
  'common.buttons.unarchive': 'Tornar ativo',
  'common.buttons.editPhotoMaskLabel': 'editar',
};

const SelectLanguageTexts = {
  'common.selectLanguage.pt-BR': 'Português do Brasil',
  'common.selectLanguage.en': 'Inglês',
};

const FormatDatesTexts = {
  'common.formatDates.fullDate': "cccc, dd/MM/yyyy 'às' p",
  'common.formatDates.carriedOut': "'Realizado em' dd/MM/yyyy 'às' pp",
  'common.formatDates.yesterday': 'ontem',
};

const GamesTitlesTexts = {
  'common.gamesTitles.coachFriend': 'Família VS Amigos',
  'common.gamesTitles.versus': 'Versus',
  'common.gamesTitles.versusVIP': 'Versus VIP',
  'common.gamesTitles.cardGames': 'Cartelas de campeonatos',
  'common.gamesTitles.athletesOfTheWeek': 'Hat-trick',
  'common.gamesTitles.pushGames': 'Push Games',
};

const MatchInfoTexts = {
  'common.matchInfo.date': 'Data e hora',
  'common.matchInfo.place': 'Local',
};

const PlayersPositionsTexts = {
  'common.playersPositions.goalKeeper': 'Goleiro',
  'common.playersPositions.defense': 'Defesa',
  'common.playersPositions.midField': 'Meio campo',
  'common.playersPositions.attack': 'Ataque',
};

const OthersTexts = {
  'common.scoredPointsDescription': 'pontos',
  'common.noPointsAbbrev': 'SP', // Sem pontos
  'common.position': '{position}°',
  'common.vsDescription': 'VS',
  'common.meIdentifier': 'Você',
  'common.meAdminIdentifier': 'Admin',
  'common.requestedIdentifier': 'Solicitado',
  'common.countDown.days': 'dias',
  'common.countDown.hours': 'horas',
  'common.countDown.minutes': 'minutos',
  'common.countDown.seconds': 'segundos',
  'common.selectDefaultText': 'Selecione',
  'common.live': 'Ao vivo',
  'common.uninformed': 'Não informado',
  'common.games': 'jogos',
};

const commonTexts: TCommonTexts = {
  ...ErrorsTexts,
  ...MessagesTexts,
  ...ButtonsTexts,
  ...SelectLanguageTexts,
  ...FormatDatesTexts,
  ...OthersTexts,
  ...GamesTitlesTexts,
  ...MatchInfoTexts,
  ...PlayersPositionsTexts,
};

export default commonTexts;
