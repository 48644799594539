import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  ul {
    margin: 16px 0 32px;
    display: flex;
    flex-wrap: wrap;
  }

  > button {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: block;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;
