import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import { FiUserPlus, FiX, FiTrash2, FiLogOut } from 'react-icons/fi';
import * as _ from 'lodash';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Env from '../../../../config/Environment';
import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { useIntl } from '../../../../context/IntlContext';
import { useAuth } from '../../../../context/AuthContext';
import { useLoadingMasked } from '../../../../context/LoadingMaskedContext';

import CustomAntButton from '../../../../components/CustomAntButton';

import { IPlayer, IMe, IMeGame } from '..';

import {
  Container,
  SearchAndAddNewFriendContainer,
  FriendContainer,
  StatusIndicator,
  ContentAddFriendsModal,
  AddedFriend,
  FindedOnSearchUser,
  LoadingAndNotFoundContainer,
} from './styles';
import api from '../../../../services/api';
import { showToast } from '../../../../hooks/showToast';

interface IRouteParams {
  id: string;
}

interface IFriends {
  players: IPlayer[];
  roomBlocked: boolean;
  myDataInTheRoom: IMe | null;
  myGame: IMeGame | null;
  getPlayersOfRoom(): void;
  loadingPlayersOfRoom: boolean;
}

interface IUser {
  _id: string;
  username: string;
  name: string;
  photo?: {
    _id: string;
    filename: string;
  };

  addedOnAddFriendModal?: boolean;
}

const { Search } = Input;
const { confirm } = Modal;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Friends: React.FC<IFriends> = ({
  myDataInTheRoom,
  myGame,
  players,
  roomBlocked,
  getPlayersOfRoom,
  loadingPlayersOfRoom,
}) => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();
  const { showLoading, hideLoading } = useLoadingMasked();

  const [openedAddFriendsModal, setOpenedAddFriendsModal] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [loadingUsersSearch, setLoadingUsersSearch] = useState(false);
  const [findedOnUsersSearch, setFindedOnUsersSearch] = useState<IUser[]>([]);
  const [findedOnUsersSearchPagination, setFindedOnUsersSearchPagination] =
    useState({
      currentPage: 1,
      totalPages: 0,
      limit: 6,
    });

  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [loadingAddUsers, setLoadingAddUsers] = useState(false);

  const onlyFriends = useMemo(() => {
    return players.filter(player => player.players._user !== me?._id);
  }, [me?._id, players]);

  const handleSearchUser = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingUsersSearch(true);
      if (page === 1) {
        setFindedOnUsersSearch([]);
      }

      try {
        const { data } = await api.get<{
          docs: IUser[];
          page: number;
          pages: number;
        }>('/api/user', {
          params: {
            search,
            page,
            limit: findedOnUsersSearchPagination.limit,
          },
        });

        if (page === 1) {
          setFindedOnUsersSearch(data.docs);
        } else {
          setFindedOnUsersSearch(oldUsersSearchState => [
            ...oldUsersSearchState,
            ...data.docs,
          ]);
        }

        setFindedOnUsersSearchPagination(oldPaginationState => ({
          ...oldPaginationState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitSearchUserError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingUsersSearch(false);
    },
    [findedOnUsersSearchPagination.limit, intl],
  );

  const userSearchDebounced = useMemo(() => {
    return _.debounce(handleSearchUser, 500);
  }, [handleSearchUser]);

  const handleChangeUserSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      userSearchDebounced.cancel();
      setSearchValue(e.target.value);

      if (e.target.value.length > 3) {
        setLoadingUsersSearch(true);
        userSearchDebounced(e.target.value);
      } else {
        setLoadingUsersSearch(false);
        setFindedOnUsersSearch([]);
      }
    },
    [userSearchDebounced],
  );

  const searchUsersContent = useMemo(() => {
    let findedUsersWithMarkingOfSelectedUsers = findedOnUsersSearch.filter(
      findedUser => findedUser._id !== me?._id,
    );
    findedUsersWithMarkingOfSelectedUsers =
      findedUsersWithMarkingOfSelectedUsers.map(findedUser => {
        if (
          selectedUsers.find(
            selectedUser => selectedUser._id === findedUser._id,
          )
        ) {
          return {
            ...findedUser,
            addedOnAddFriendModal: true,
          };
        }

        return findedUser;
      });

    if (searchValue.length <= 3) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>{intl.getTranslatedText('common.messages.minCharToSearch')}</h6>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    if (
      loadingUsersSearch &&
      findedUsersWithMarkingOfSelectedUsers.length === 0
    ) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    if (findedUsersWithMarkingOfSelectedUsers.length > 0) {
      return (
        <ul>
          {findedUsersWithMarkingOfSelectedUsers.map(user => (
            <FindedOnSearchUser
              onClick={() =>
                setSelectedUsers(oldSelectedUsers => [
                  ...oldSelectedUsers,
                  user,
                ])
              }
              disabled={user.addedOnAddFriendModal}
              key={user._id}
            >
              <img
                src={
                  user.photo
                    ? Env.IMAGE_SERVER_URL + user.photo?.filename
                    : defaultAvatar
                }
                alt={user.name}
              />
              <div>
                <small>
                  <strong>{user.username}</strong>
                </small>
                <small>{user.name}</small>
              </div>
              {user.addedOnAddFriendModal && (
                <small>
                  {intl.getTranslatedText(
                    'pages.coachFriend.room.friends.userAddedAddFriendModalDescription',
                  )}
                </small>
              )}
            </FindedOnSearchUser>
          ))}
          {findedOnUsersSearchPagination.currentPage <
            findedOnUsersSearchPagination.totalPages && (
            <FindedOnSearchUser
              onClick={() =>
                handleSearchUser(
                  searchValue,
                  findedOnUsersSearchPagination.currentPage + 1,
                )
              }
              disabled={loadingUsersSearch}
            >
              <p>
                {!loadingUsersSearch
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </p>
            </FindedOnSearchUser>
          )}
        </ul>
      );
    }

    return (
      <LoadingAndNotFoundContainer>
        <div>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.coachFriend.room.friends.messages.userNotFound',
            )}
          </h6>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }, [
    findedOnUsersSearch,
    findedOnUsersSearchPagination.currentPage,
    findedOnUsersSearchPagination.totalPages,
    handleSearchUser,
    intl,
    loadingUsersSearch,
    me?._id,
    searchValue,
    selectedUsers,
  ]);

  const handleCloseAddFriendsModal = useCallback(() => {
    setOpenedAddFriendsModal(false);
    setSelectedUsers([]);
    setFindedOnUsersSearch([]);
    setSearchValue('');
  }, []);

  const handleAddUsersSubmit = useCallback(async () => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitAddUsersConfirm.description',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.coachFriend.room.friends.submitAddUsersConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingAddUsers(true);

      const body = {
        users: selectedUsers.map(selectedUser => ({
          _user: selectedUser._id,
        })),
      };

      await api.put(`/api/coach-friend/${params.id}/players`, body);

      setLoadingAddUsers(false);
      handleCloseAddFriendsModal();
      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.title',
        ),
        description: intl.getTranslatedText(
          'pages.coachFriend.room.friends.messages.submitAddUsersSuccess.description',
        ),
      });
      getPlayersOfRoom();
    } catch (error) {
      setLoadingAddUsers(false);
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [
    getPlayersOfRoom,
    handleCloseAddFriendsModal,
    intl,
    params.id,
    selectedUsers,
  ]);

  const handleDeleteUser = useCallback(
    async (userId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'pages.coachFriend.room.friends.messages.submitDeleteUserConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'pages.coachFriend.room.friends.submitDeleteUserConfirmButton',
            ),
            onOk() {
              resolve(true);
            },
            okButtonProps: {
              danger: true,
            },
          });
        });

        showLoading(
          intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitDeleteUserLoading',
          ),
        );

        const body = {
          _user: userId,
          action: 'remove',
        };

        await api.put(`/api/coach-friend/${params.id}/invite`, body);
        hideLoading();
        getPlayersOfRoom();
        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.title',
          ),
          description: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitDeleteUserSuccess.description',
          ),
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description:
            error.response?.data?.message ||
            intl.getTranslatedText('common.errors.unexpectedError.description'),
        });
        hideLoading();
      }
    },
    [getPlayersOfRoom, hideLoading, intl, params.id, showLoading],
  );

  const handleLogOutForThisRoom = useCallback(async (): Promise<void> => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.coachFriend.room.friends.submitLogOutForThisRoomConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          okButtonProps: {
            danger: true,
          },
        });
      });

      // setLoadingLogOutFromThisRoom(true);
      showLoading(
        intl.getTranslatedText(
          'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomLoading',
        ),
      );

      const body = {
        action: 'reject',
      };

      await api.put(`/api/coach-friend/${params.id}/invite`, body);
      hideLoading();
      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.title',
        ),
        description: intl.getTranslatedText(
          'pages.coachFriend.room.friends.messages.submitLogOutForThisRoomSuccess.description',
        ),
      });
      history.push('/coach_friend');
    } catch (error) {
      hideLoading();
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description:
          error.response?.data?.message ||
          intl.getTranslatedText('common.errors.unexpectedError.description'),
      });
    }
  }, [hideLoading, history, intl, params.id, showLoading]);

  if (loadingPlayersOfRoom) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.coachFriend.room.friends.messages.loadingFriends',
              )}
            </p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Modal
        title={intl.getTranslatedText(
          'pages.coachFriend.room.friends.addFriendsModal.title',
        )}
        okText={
          !loadingAddUsers
            ? intl.getTranslatedText(
                'pages.coachFriend.room.friends.addFriendsModal.okButton',
              )
            : intl.getTranslatedText('common.messages.defaultLoading')
        }
        okButtonProps={{
          disabled: selectedUsers.length === 0,
        }}
        onOk={handleAddUsersSubmit}
        visible={openedAddFriendsModal}
        onCancel={() => {
          if (!loadingAddUsers) {
            handleCloseAddFriendsModal();
          }
        }}
        confirmLoading={loadingAddUsers}
      >
        <ContentAddFriendsModal>
          <Search
            placeholder={intl.getTranslatedText(
              'pages.coachFriend.room.friends.addFriendsModal.searchUserInput.placeholder',
            )}
            value={searchValue}
            onChange={handleChangeUserSearch}
          />
          {selectedUsers.length > 0 && (
            <div>
              <strong>
                {intl.getTranslatedText(
                  'pages.coachFriend.room.friends.addFriendsModal.selectedUsers',
                )}
              </strong>
              <ul>
                {selectedUsers.map(selectedUser => (
                  <AddedFriend
                    onClick={() =>
                      setSelectedUsers(oldSelectedUsers => {
                        const selectedUserIndex = oldSelectedUsers.findIndex(
                          oldSelectedUser =>
                            oldSelectedUser._id === selectedUser._id,
                        );

                        const updatedSelectedUsers = [...oldSelectedUsers];
                        updatedSelectedUsers.splice(selectedUserIndex, 1);

                        return updatedSelectedUsers;
                      })
                    }
                    key={selectedUser._id}
                  >
                    <div>
                      <FiX size={10} />
                    </div>
                    <img
                      src={
                        selectedUser.photo
                          ? Env.IMAGE_SERVER_URL + selectedUser.photo.filename
                          : defaultAvatar
                      }
                      alt={selectedUser.name}
                    />
                    <small>{selectedUser.username}</small>
                  </AddedFriend>
                ))}
              </ul>
            </div>
          )}
          {searchUsersContent}
        </ContentAddFriendsModal>
      </Modal>
      <SearchAndAddNewFriendContainer>
        <Search
          placeholder={intl.getTranslatedText(
            'pages.coachFriend.room.friends.searchFriendInput.placeholder',
          )}
        />
        {myDataInTheRoom?.roles?.includes('owner') && !roomBlocked && (
          <CustomAntButton
            type="primary"
            icon={<FiUserPlus size={20} />}
            useCustomIcon
            onClick={() => setOpenedAddFriendsModal(true)}
          />
        )}
      </SearchAndAddNewFriendContainer>
      <ul>
        <FriendContainer>
          <img
            src={me?.photo ? Env.IMAGE_SERVER_URL + me.photo : defaultAvatar}
            alt="Você"
          />
          <p>Você</p>
          <div>
            {myDataInTheRoom?.roles?.includes('owner') && (
              <small>
                {intl.getTranslatedText('common.meAdminIdentifier')}
              </small>
            )}
            {!myDataInTheRoom?.roles?.includes('owner') &&
              !myGame &&
              !roomBlocked && (
                <CustomAntButton
                  type="text"
                  danger
                  useCustomIcon
                  icon={<FiLogOut size={21} />}
                  onClick={handleLogOutForThisRoom}
                />
              )}
          </div>
        </FriendContainer>
        {onlyFriends.map(friend => (
          <FriendContainer key={friend.players._user}>
            <img
              src={
                friend._player.photo
                  ? Env.IMAGE_SERVER_URL + friend._player.photo
                  : defaultAvatar
              }
              alt={friend._player.name}
            />
            <p>{friend._player.name}</p>
            <div>
              {!friend.players.status && (
                <StatusIndicator warn>
                  {intl.getTranslatedText('common.requestedIdentifier')}
                </StatusIndicator>
              )}
              {friend.players.roles?.includes('owner') && (
                <small>
                  {intl.getTranslatedText('common.meAdminIdentifier')}
                </small>
              )}
              {myDataInTheRoom?.roles?.includes('owner') &&
                !roomBlocked &&
                !friend.games && (
                  <CustomAntButton
                    type="text"
                    danger
                    useCustomIcon
                    icon={<FiTrash2 size={21} />}
                    onClick={() => handleDeleteUser(friend.players._user)}
                  />
                )}
            </div>
          </FriendContainer>
        ))}
      </ul>
    </Container>
  );
};

export default Friends;
