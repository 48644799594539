import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-viewer';

import Env from '../../../../../config/Environment';
import DefaultAvatar from '../../../../../assets/DefaultAvatar.svg';
import { useIntl } from '../../../../../context/IntlContext';

import { IPlayedAthlete } from '..';

import { Container, ScoreText } from './styles';

interface IAthleteCard {
  playedAthlete: IPlayedAthlete;
}

const PlayedCardGameAthlete: React.FC<IAthleteCard> = ({ playedAthlete }) => {
  const intl = useIntl();

  const [viewerIsVisible, setViewerIsVisible] = useState(false);

  const getPlayerPositionLabel = useCallback(
    (playerPosition: string): string => {
      switch (playerPosition) {
        case 'G':
          return intl.getTranslatedText('common.playersPositions.goalKeeper');
        case 'D':
          return intl.getTranslatedText('common.playersPositions.defense');
        case 'M':
          return intl.getTranslatedText('common.playersPositions.midField');
        case 'A':
          return intl.getTranslatedText('common.playersPositions.attack');
        default:
          return playerPosition;
      }
    },
    [intl],
  );

  return (
    <>
      <Container key={playedAthlete?._athletes?._id}>
        <button
          type="button"
          onClick={() => {
            if (playedAthlete?._athletes?.photo) {
              setViewerIsVisible(true);
            }
          }}
          disabled={!playedAthlete?._athletes?.photo}
        >
          <img
            src={
              playedAthlete?._athletes?.photo
                ? Env.IMAGE_SERVER_URL + playedAthlete._athletes.photo
                : DefaultAvatar
            }
            alt={playedAthlete?._athletes?.name || ''}
          />
        </button>
        <div>
          <div>
            <strong>{playedAthlete?._athletes?.name}</strong>
            {playedAthlete?._athletes?.number &&
              playedAthlete?._athletes?.position && (
                <small>
                  {intl.getTranslatedText(
                    'pages.athletesOfTheWeek.card.userCardGameDetails.playedCardAthlete.athleteNumber',
                    {
                      playerNumber: playedAthlete?._athletes?.number,
                      playerPosition: getPlayerPositionLabel(
                        playedAthlete?._athletes?.position,
                      ),
                    },
                  )}
                </small>
              )}
            <div>
              <img
                src={playedAthlete?._team?.image}
                alt={playedAthlete?._team?.name || ''}
              />
              <p>{playedAthlete?._team?.name}</p>
            </div>
          </div>
          {/* <h6>{playedAthlete.athleteScore}</h6> */}
          <ScoreText $notScore={!playedAthlete.athleteScore}>
            {playedAthlete.athleteScore}
          </ScoreText>
        </div>
      </Container>
      <ImageViewer
        visible={viewerIsVisible}
        onClose={() => setViewerIsVisible(false)}
        onMaskClick={() => setViewerIsVisible(false)}
        noFooter
        drag={false}
        images={[
          {
            src: playedAthlete?._athletes?.photo
              ? Env.IMAGE_SERVER_URL + playedAthlete._athletes.photo
              : DefaultAvatar,
            alt: playedAthlete?._athletes?.name,
          },
        ]}
      />
    </>
  );
};

export default PlayedCardGameAthlete;
