import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 56.5rem;
  margin: 0 auto 1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const NavigationButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  max-width: 56.5rem;
  margin: 0 auto 1rem;
`;
