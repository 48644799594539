import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useIntl } from '../../../../../context/IntlContext';

import { IMyGame } from '../index';

import { Container, CardGameItem, ViewMoreButton } from './styles';

interface IMyGamesProps {
  myGames: IMyGame[];
  loading: boolean;
  loadMore(): Promise<void>;
  isOnLastPage: boolean;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO, format } = dateFns;

const MyGames: React.FC<IMyGamesProps> = ({
  myGames,
  loading,
  loadMore,
  isOnLastPage,
}) => {
  const { url } = useRouteMatch();

  const intl = useIntl();

  if (loading && myGames.length === 0) {
    return (
      <Container>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.championship.cardGame.myGames.loadingMyGames',
            )}
          </p>
        </div>
      </Container>
    );
  }

  if (myGames.length === 0) {
    return (
      <Container>
        <div>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.championship.cardGame.myGames.noGames',
            )}
          </h6>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <ul>
        {myGames.map(myGame => (
          <CardGameItem key={myGame._id} to={`${url}/my_games/${myGame._id}`}>
            <strong>{myGame.name}</strong>
            <small>
              {format(
                parseISO(myGame.createdAt),
                intl.getTranslatedText('common.formatDates.carriedOut'),
              )}
            </small>
          </CardGameItem>
        ))}
        {!isOnLastPage && (
          <ViewMoreButton disabled={loading} onClick={loadMore}>
            {!loading ? (
              <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
            ) : (
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            )}
          </ViewMoreButton>
        )}
      </ul>
    </Container>
  );
};

export default MyGames;
