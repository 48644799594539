import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from 'antd';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';

import { CheckCircleOutlined } from '@ant-design/icons';
import { FiChevronLeft } from 'react-icons/fi';
import { useBullet } from '@/context/BulletContext';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import CustomAntButton from '../../../../components/CustomAntButton';

import { IBlockPageMessage } from '../../../../services/history';

import { ICoachFriendRoom, IMyDataInTheRoom, IMe } from '..';

import PlayCoachGoalMode from './PlayCoachGoalMode';
import PayGoalMode from './PayGoalMode';
import CoachMode from './Coach';
import MinuteOfFirstGoalMode from './MinuteOfFirstGoalMode';

import { Container, Header, Content, ButtonContainer } from './styles';
import api from '../../../../services/api';
import Loading from '../../../../components/Loading';
import NotFound from '../../../../components/NotFound';
import AppError from '../../../../errors/AppError';
import { showToast } from '../../../../hooks/showToast';
import { useIntl } from '../../../../context/IntlContext';

interface IRouteParams {
  id: string;
}

export type CornerRange =
  | '0-4'
  | '5-9'
  | '10-14'
  | '15-19'
  | '20-24'
  | '25-29'
  | '30-34'
  | '35-39'
  | '40-44'
  | '45-49'
  | '50-54'
  | '55-59'
  | '60-64'
  | '65-69'
  | '70-74'
  | undefined;

export interface ICoachGameState {
  redCards: number;
  yellowCards: number;
  offsideNumber: number;
  cornerNumber: CornerRange;
}

export interface IPlayCoachGoalGameState {
  local: number;
  away: number;
}

export type GoalTimeRange =
  | '0-9'
  | '10-19'
  | '20-29'
  | '30-39'
  | '40-49'
  | '50-59'
  | '60-69'
  | '70-79'
  | '80-89'
  | '90-99'
  | undefined;

export interface IMinuteOfFirstGoalGameState {
  localTime: GoalTimeRange;
  awayTime: GoalTimeRange;
}

export interface IPayGoalGameState {
  local: {
    oneThrow: IPayGoalThrow;
    twoThrow: IPayGoalThrow;
    threeThrow: IPayGoalThrow;
  };
  away: {
    oneThrow: IPayGoalThrow;
    twoThrow: IPayGoalThrow;
    threeThrow: IPayGoalThrow;
  };
}

interface IPayGoalThrow {
  player: string;
  playerName: string;
}

const { confirm } = Modal;
const { parseISO, sub, isAfter } = dateFns;

const NewGame: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const bullet = useBullet();

  const [coachFriendRoom, setCoachFriendRoom] =
    useState<ICoachFriendRoom | null>(null);
  const [myDataInTheRoom, setMyDataInTheRoom] =
    useState<IMyDataInTheRoom | null>(null);
  const [loadingCoachFriendRoom, setLoadingCoachFriendRoom] = useState(true);

  const [playCoachGoalGame, setPlayCoachGoalGame] =
    useState<IPlayCoachGoalGameState>({
      local: 0,
      away: 0,
    });

  const [minuteOfFirstGoalGame, setMinuteOfFirstGoalGame] =
    useState<IMinuteOfFirstGoalGameState>({
      localTime: undefined,
      awayTime: undefined,
    });

  const [coachGame, setCoachGame] = useState<ICoachGameState>({
    redCards: 0,
    yellowCards: 0,
    offsideNumber: 0,
    cornerNumber: undefined,
  });

  const [payGoalGame, setPayGoalGame] = useState<IPayGoalGameState>({
    local: {
      oneThrow: {
        player: '',
        playerName: '',
      },
      twoThrow: {
        player: '',
        playerName: '',
      },
      threeThrow: {
        player: '',
        playerName: '',
      },
    },
    away: {
      oneThrow: {
        player: '',
        playerName: '',
      },
      twoThrow: {
        player: '',
        playerName: '',
      },
      threeThrow: {
        player: '',
        playerName: '',
      },
    },
  });

  const [loadingSendGame, setLoadingSendGame] = useState(false);

  const unblockPage = useMemo(() => {
    const message: IBlockPageMessage = {
      title: intl.getTranslatedText(
        'pages.coachFriend.room.newGame.messages.unblockPageConfirm.title',
      ),
      description: intl.getTranslatedText('common.messages.changesWillBeLost'),
      okText: intl.getTranslatedText('common.buttons.exit'),
      cancelText: intl.getTranslatedText('common.buttons.cancel'),
    };

    return history.block(JSON.stringify(message));
  }, [history, intl]);

  function handleBeforeUnload(e: BeforeUnloadEvent): void {
    e.returnValue = '';
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  useEffect(() => {
    async function getCoachFriendRoom(): Promise<void> {
      try {
        const { data } = await api.get<{
          doc: ICoachFriendRoom;
          _me: IMe;
          _meGame: null;
        }>(`/api/coach-friend/${params.id}`);

        const room: ICoachFriendRoom = { ...data.doc };
        room._game.local.abbrev = room._game.local.name
          .substring(0, 3)
          .toUpperCase();
        room._game.away.abbrev = room._game.away.name
          .substring(0, 3)
          .toUpperCase();

        const limitForActionsOnRoomData = sub(parseISO(room._game.info.date), {
          minutes: 30,
        });
        const roomIsblocked = isAfter(new Date(), limitForActionsOnRoomData);

        if (!data._meGame || roomIsblocked) {
          setCoachFriendRoom(room);
        }

        setMyDataInTheRoom({
          _me: data._me,
          _meGame: data._meGame,
        });
      } catch (error) {
        /* */
      }
      setLoadingCoachFriendRoom(false);
    }

    getCoachFriendRoom();
  }, [params]);

  const handleSelectPlayerOfThrowOnPayGoal = useCallback(
    (
      team: 'local' | 'away',
      throwOfTeam: 'oneThrow' | 'twoThrow' | 'threeThrow',
      playerId: string,
      playerName: string,
    ): void => {
      setPayGoalGame(oldPayGoalGame => {
        const updatedPayGoalGame = { ...oldPayGoalGame };

        updatedPayGoalGame[team][throwOfTeam].player = playerId;
        updatedPayGoalGame[team][throwOfTeam].playerName = playerName;

        return updatedPayGoalGame;
      });
    },
    [],
  );

  const handleChangeScoreOfTeamInPlayCoachGoal = useCallback(
    (team: 'local' | 'away', score: number): void => {
      if (score < 0) {
        return;
      }

      setPlayCoachGoalGame(oldPlayCoachGoalGame => {
        const updatedPlayCoachGoalGame = { ...oldPlayCoachGoalGame };
        updatedPlayCoachGoalGame[team] = score;
        return updatedPlayCoachGoalGame;
      });

      if (score < 3)
        handleSelectPlayerOfThrowOnPayGoal(team, 'threeThrow', '', '');
      if (score < 2)
        handleSelectPlayerOfThrowOnPayGoal(team, 'twoThrow', '', '');
      if (score < 1) {
        handleSelectPlayerOfThrowOnPayGoal(team, 'oneThrow', '', '');
        setMinuteOfFirstGoalGame(oldState => {
          const minuteOfFirstGoalUpdated = { ...oldState };
          if (team === 'local') minuteOfFirstGoalUpdated.localTime = undefined;
          if (team === 'away') minuteOfFirstGoalUpdated.awayTime = undefined;

          return minuteOfFirstGoalUpdated;
        });
      }
    },
    [handleSelectPlayerOfThrowOnPayGoal],
  );

  const handleChangeQuantityOfStatistic = useCallback(
    (
      type: 'redCards' | 'yellowCards' | 'offsideNumber',
      quantity: number,
    ): void => {
      if (quantity < 0) {
        return;
      }

      setCoachGame(oldCoachGame => {
        const updatedCoachGame = { ...oldCoachGame };
        updatedCoachGame[type] = quantity;
        return updatedCoachGame;
      });
    },
    [],
  );

  const handleChangeCornerRange = useCallback((range: CornerRange): void => {
    setCoachGame(oldCoachGame => {
      const updatedCoachGame = { ...oldCoachGame };
      updatedCoachGame.cornerNumber = range;
      return updatedCoachGame;
    });
  }, []);

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      const body: {
        localScore: number;
        awayScore: number;
        yellowCards: number;
        redCards: number;
        cornerNumber: CornerRange;
        offsideNumber: number;
        localTime: GoalTimeRange;
        awayTime: GoalTimeRange;
        localPlayers: IPayGoalThrow[];
        awayPlayers: IPayGoalThrow[];
      } = {
        localScore: playCoachGoalGame.local,
        awayScore: playCoachGoalGame.away,
        yellowCards: coachGame.yellowCards,
        redCards: coachGame.redCards,
        cornerNumber: coachGame.cornerNumber,
        offsideNumber: coachGame.offsideNumber,
        localTime: minuteOfFirstGoalGame.localTime,
        awayTime: minuteOfFirstGoalGame.awayTime,
        localPlayers: [],
        awayPlayers: [],
      };

      if (body.localScore >= 1)
        body.localPlayers.push(payGoalGame?.local?.oneThrow);
      if (body.localScore >= 2)
        body.localPlayers.push(payGoalGame?.local?.twoThrow);
      if (body.localScore >= 3)
        body.localPlayers.push(payGoalGame?.local?.threeThrow);
      if (body.awayScore >= 1)
        body.awayPlayers.push(payGoalGame?.away?.oneThrow);
      if (body.awayScore >= 2)
        body.awayPlayers.push(payGoalGame?.away?.twoThrow);
      if (body.awayScore >= 3)
        body.awayPlayers.push(payGoalGame?.away?.threeThrow);

      const requiredFields: any[] = [...body.localPlayers, ...body.awayPlayers];

      const restRequiredFields = {
        localTime: minuteOfFirstGoalGame.localTime,
        awayTime: minuteOfFirstGoalGame.awayTime,
        cornerNumber: coachGame.cornerNumber,
      };

      if (body.localScore === 0) delete restRequiredFields.localTime;
      if (body.awayScore === 0) delete restRequiredFields.awayTime;

      requiredFields.push(restRequiredFields);

      requiredFields.forEach(throwOfTeam => {
        if (!_.every(throwOfTeam, propertyValue => !!propertyValue)) {
          throw new AppError(
            intl.getTranslatedText(
              'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.title',
            ),
            intl.getTranslatedText(
              'pages.coachFriend.room.newGame.messages.submitNewGame.emptyFieldsValidationError.description',
            ),
            {
              type: 'validation',
            },
          );
        }
      });

      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.messages.submitNewGameConfirm.title',
          ),
          icon: <CheckCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.coachFriend.room.newGame.submitNewGameConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
        });
      });

      if (coachFriendRoom?.price) {
        const hasAvailableBalance = bullet.checkIfBalanceIsAvailableToAction(
          coachFriendRoom?.price,
        );
        if (!hasAvailableBalance) return;
      }

      setLoadingSendGame(true);
      await api.put(`/api/coach-friend/${params.id}/games`, body);

      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.title',
        ),
        description: intl.getTranslatedText(
          'pages.coachFriend.room.newGame.messages.submitNewGameSuccess.description',
        ),
      });

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
      history.push(`/coach_friend/${params.id}`);
    } catch (error) {
      if (error instanceof AppError && error.options?.type === 'validation') {
        showToast({
          type: 'warn',
          title: error.title,
          description: error.description,
        });
        return;
      }
      setLoadingSendGame(false);
    }
  }, [
    playCoachGoalGame.local,
    playCoachGoalGame.away,
    coachGame.yellowCards,
    coachGame.redCards,
    coachGame.cornerNumber,
    coachGame.offsideNumber,
    minuteOfFirstGoalGame.localTime,
    minuteOfFirstGoalGame.awayTime,
    payGoalGame?.local?.oneThrow,
    payGoalGame?.local?.twoThrow,
    payGoalGame?.local?.threeThrow,
    payGoalGame?.away?.oneThrow,
    payGoalGame?.away?.twoThrow,
    payGoalGame?.away?.threeThrow,
    coachFriendRoom?.price,
    params.id,
    intl,
    unblockPage,
    history,
    bullet,
  ]);

  if (loadingCoachFriendRoom) {
    return (
      <Container>
        <Breadcrumbs />
        <Loading />
      </Container>
    );
  }

  if (!coachFriendRoom) {
    return (
      <NotFound showBreadcrumbs>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.coachFriend.room.newGame.messages.noGame',
          )}
        </h4>
        <Link to="/coach-friend">
          <FiChevronLeft size={16} />
          {intl.getTranslatedTextWithHTML(
            'pages.coachFriend.room.newGame.goToRoomsListLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {' '}
          {intl.getTranslatedTextWithHTML(
            'pages.coachFriend.room.newGame.title',
          )}
        </h5>
        <Link to={`/coach_friend/${params.id}`}>
          <CustomAntButton type="text" danger>
            {intl.getTranslatedText('common.buttons.cancel')}
          </CustomAntButton>
        </Link>
      </Header>
      <Content>
        <PlayCoachGoalMode
          playCoachGoalGame={playCoachGoalGame}
          handleChangeScoreOfTeamInPlayCoachGoal={
            handleChangeScoreOfTeamInPlayCoachGoal
          }
          localTeam={coachFriendRoom._game.local}
          awayTeam={coachFriendRoom._game.away}
        />
        <MinuteOfFirstGoalMode
          minuteOfFirstGoalGame={minuteOfFirstGoalGame}
          setMinuteOfFirstGoalGame={setMinuteOfFirstGoalGame}
          localTeam={coachFriendRoom._game.local}
          awayTeam={coachFriendRoom._game.away}
          selectedScore={playCoachGoalGame}
        />
        <PayGoalMode
          payGoalGame={payGoalGame}
          handleSelectPlayerOfThrowOnPayGoal={
            handleSelectPlayerOfThrowOnPayGoal
          }
          localTeam={coachFriendRoom._game.local}
          awayTeam={coachFriendRoom._game.away}
          selectedScore={playCoachGoalGame}
        />
        <CoachMode
          coachGame={coachGame}
          handleChangeQuantityOfStatistic={handleChangeQuantityOfStatistic}
          handleChangeCornerRange={handleChangeCornerRange}
        />
        <ButtonContainer>
          <CustomAntButton
            type="primary"
            disabled={loadingSendGame}
            onClick={handleSubmit}
          >
            {!loadingSendGame
              ? intl.getTranslatedText(
                  'pages.coachFriend.room.newGame.goldenGoalButton',
                )
              : intl.getTranslatedText('common.messages.defaultLoading')}
          </CustomAntButton>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default NewGame;
