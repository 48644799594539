import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 752px;
  margin: 0 auto;

  > p {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    color: ${props => props.theme.colors.light['light-2']};
  }

  input.ant-input {
    border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  }
  button.ant-input-search-button {
    border-color: ${props => props.theme.colors.dark['dark+3']};
  }

  > ul {
    margin-top: 16px;
  }
`;

export const FindedOnSearchUser = styled.button`
  border: 0;

  width: 100%;
  background: ${props => props.theme.colors.dark['dark+1']};
  padding: 8px 0;
  display: flex;
  align-items: center;
  transition: 0.2s background;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: no-drop;
    `}

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
  }

  div {
    display: flex;
    flex-direction: column;

    small {
      text-align: start;

      strong {
        font-size: inherit;
        color: inherit;
      }
    }
  }

  > small {
    margin-left: auto;
  }

  /* For ViewMoreButton */
  > p {
    width: 100%;
    text-align: center;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
