import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface ICardGameItem {
  $archived?: boolean;
}

export const Container = styled.div`
  padding: 40px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    margin: 40px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6 {
      text-align: center;
      margin-bottom: 16px;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }

    a {
      font-size: ${props => props.theme.typograph.sizes.p}px;
      color: ${props => props.theme.colors.light['light-2']};
      text-align: center;
      display: flex;
      align-items: center;

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.light['light-2'])};
      }

      svg {
        margin-right: 8px;
      }
    }

    button {
      width: 100%;
      max-width: 400px;
    }
  }

  /* > button {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: block;
  } */
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 32px; */
`;

export const CardGameList = styled.ul`
  /* margin: 16px 0 32px; */
  width: 100%;
  max-width: 688px;
  margin: 16px auto 32px;
  /* display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start; */
`;

export const CardGameItem = styled.div<ICardGameItem>`
  /* width: 33%;
  margin-right: 0.33%; */
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    !props.$archived
      ? props.theme.colors.dark['dark+1']
      : props.theme.colors.dark['dark+2']};
  border-radius: 8px;
  position: relative;
`;

export const CardGameHeader = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  > svg {
    color: #f8c700;
  }

  > button {
    margin-left: auto;
  }
`;

export const MatchInfoContainer = styled.div<ICardGameItem>`
  /* background: ${props => props.theme.colors.dark['dark+1']}; */
  width: 100%;
  border: 0;
  transition: 0.2s background;
  padding: 0 0 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid
    ${props =>
      !props.$archived
        ? props.theme.colors.dark['dark+2']
        : props.theme.colors.dark['dark+3']};

  /* &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  } */

  /* > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${props => props.theme.colors.light['light-2']};
      margin-right: 4px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  } */
`;

export const TeamsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 8px; */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 8px;
    /* max-width: 752px; */

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary.primary0};
    text-align: center;
    width: 100%;
  }
`;

export const Team = styled.div<ICardGameItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: ${props =>
      !props.$archived
        ? props.theme.colors.dark['dark+2']
        : props.theme.colors.dark['dark+3']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 35px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }
`;

export const AccumulatedValueContainer = styled.div`
  padding: 8px 16px;
  background: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.primary.primary0};
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 16px auto 0;

  svg {
    color: #e4ce00;
  }

  small {
    margin-left: 8px;
  }
`;

export const PlayedPushGameInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
  margin-bottom: 16px;

  div {
    width: 100%;

    & + div {
      margin-top: 8px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const LoadMoreCardsButton = styled.button`
  border: 0;
  width: 100%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;
  align-self: stretch;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
      &:hover {
        background-color: ${props.theme.colors.dark['dark+1']};
      }
    `}

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;
