import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';
import AuthLayout from '../pages/_layouts/auth';

import { useAuth } from '../context/AuthContext';

interface ReactProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<ReactProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { token } = useAuth();

  function renderLayout(
    isPrivateRoute: boolean,
    // props: RouteComponentProps,
  ): React.ReactElement {
    return !isPrivateRoute ? (
      <AuthLayout>
        <Component />
      </AuthLayout>
    ) : (
      <DefaultLayout>
        <Component />
      </DefaultLayout>
    );
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!token ? (
          renderLayout(isPrivate)
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/home',
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
