const SignUpTexts = {
  'pages.signUp.title': 'Faça seu cadastro',

  /* ------------ Links ------------ */
  'pages.signUp.links.signInLink': 'Voltar para o login',

  /* ------------ Form ------------ */
  /* Avatar */
  'pages.signUp.form.photo.selectImageButton': `Selecione uma imagem para adicionar como avatar do usuário
  <strong>(obrigatório)</strong>`,
  'pages.signUp.form.photo.changeImageButton':
    'Clique para selecionar outra imagem',
  'pages.signUp.form.photo.validation.required':
    'O avatar do usuário é obrigatório',
  /* Name */
  'pages.signUp.form.name.label': 'Nome',
  'pages.signUp.form.name.placeholder': 'Digite seu nome',
  'pages.signUp.form.name.validation.required': 'O nome é obrigatório',
  /* username */
  'pages.signUp.form.username.label': 'Nome de usuário',
  'pages.signUp.form.username.placeholder': 'Digite o nome de usuário',
  'pages.signUp.form.username.validation.required':
    'O nome do usuário é obrigatório',
  'pages.signUp.form.username.validation.unavailable':
    'Esse nome de usuário já está sendo utilizado',
  /* docnumber */
  'pages.signUp.form.docNumber.label': 'CPF',
  'pages.signUp.form.docNumber.placeholder': '999.999.999-99',
  'pages.signUp.form.docNumber.validation.required': 'O CPF é obrigatório',
  'pages.signUp.form.docNumber.validation.invalid':
    'O CPF informado é inválido',
  'pages.signUp.form.docNumber.validation.unavailable':
    'Esse CPF já está sendo utilizado',
  /* email */
  'pages.signUp.form.email.label': 'Email',
  'pages.signUp.form.email.placeholder': 'Digite o seu email',
  'pages.signUp.form.email.validation.required': 'O email é obrigatório',
  'pages.signUp.form.email.validation.invalid':
    'O email informado não é válido',
  'pages.signUp.form.email.validation.unavailable':
    'Esse email já está sendo utilizado',
  /* gender */
  'pages.signUp.form.gender.label': 'Sexo',
  'pages.signUp.form.gender.male': 'Masculino',
  'pages.signUp.form.gender.female': 'Feminino',
  'pages.signUp.form.gender.other': 'Outro',
  'pages.signUp.form.gender.validation.required': 'O sexo é obrigatório',
  'pages.signUp.form.gender.validation.invalid':
    'O sexo selecionado é inválido',
  /* password */
  'pages.signUp.form.password.label': 'Senha',
  'pages.signUp.form.password.placeholder': 'Digite uma senha secreta',
  'pages.signUp.form.password.validation.required': 'A senha é obrigatória',
  'pages.signUp.form.password.validation.min':
    'A senha precisa ter mais de 6 dígitos',
  /* confirmPassword */
  'pages.signUp.form.confirmPassword.label': 'Confirmar senha',
  'pages.signUp.form.confirmPassword.placeholder': 'Confirme sua senha secreta',
  'pages.signUp.form.confirmPassword.validation.required':
    'A confirmação da senha é obrigatória',
  'pages.signUp.form.confirmPassword.validation.incorrect':
    'A confirmação da senha está incorreta',

  /* phone */
  'pages.signUp.form.phone.label': 'Telefone Celular',
  'pages.signUp.form.phone.placeholder': '(99)99999-9999',
  'pages.signUp.form.phone.validation.required': 'O telefone é obrigatório',
  'pages.signUp.form.phone.validation.invalid':
    'O telefone informado é inválido',
  /* Submit */
  'pages.signUp.form.submitButton': 'Criar conta',
  'pages.signUp.form.submitButtonLoading': 'Criando conta...',

  /* ------------ Messages ------------ */
  'pages.signUp.messages.signUpSubmitAvatarError.title':
    'Erro ao enviar o avatar do usuário',
  'pages.signUp.messages.signUpSubmitAvatarError.description':
    'Aconteceu um erro inesperado ao enviar o avatar do usuário!',
  'pages.signUp.messages.signUpSubmitSuccess.title': 'Cadastro realizado!',
  'pages.signUp.messages.signUpSubmitSuccess.description':
    'Você já pode fazer seu login no Titan369',
  'pages.signUp.messages.signUpSubmitError.title': 'Erro no cadastro',
  'pages.signUp.messages.signUpSubmitError.description':
    'Ocorreu um erro ao fazer o cadastro, tente novamente.',
  'pages.signUp.messages.signUpValidationFailure.title': 'Dados inválidos',
  'pages.signUp.messages.signUpValidationFailure.description':
    'Tente novamente',
};

export default {
  ...SignUpTexts,
};
