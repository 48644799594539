import { rgba, shade } from 'polished';
import styled, { css } from 'styled-components';

interface IGameContainer {
  $champion?: boolean;
  $secondOrThirdPosition?: boolean;
}

interface ISelectedQuantitityOfCardContainer {
  $typeOfStatistic: 'redCards' | 'yellowCards' | 'corner' | 'offside';
}

export const Container = styled.div`
  width: 100%;
  max-width: 752px;
  margin: 0 auto;

  > small {
    margin-bottom: 16px;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input.ant-input {
    border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  }
  button.ant-input-search-button {
    border-color: ${props => props.theme.colors.dark['dark+3']};
  }
`;
export const GameContainer = styled.button<IGameContainer>`
  border: 0;
  width: 100%;
  background: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
  transition: 0.2s background;
  border: 1px solid ${props => props.theme.colors.dark['dark+2']};

  ${props =>
    props.$secondOrThirdPosition &&
    css`
      border-color: ${props.theme.colors.primary['primary-1']};
    `};

  ${props =>
    props.$champion &&
    css`
      border-color: ${props.theme.colors.primary.primary0};
      box-shadow: 0px 0px 20px
        ${rgba(props.theme.colors.primary['primary+1'], 0.5)};
    `}

  & + button {
    margin-top: 8px;
  }

  &:hover {
    background: ${props => shade(0.05, props.theme.colors.dark['dark+2'])};
  }

  > div:nth-child(1) {
    width: 100%;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 16px;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
    }

    div {
      display: flex;
      align-items: center;
      margin-right: 8px;
      flex-shrink: 0;

      svg {
        color: #f8c700;
      }

      p {
        margin-left: 4px;
        color: ${props => props.theme.colors.light['light-2']};

        span {
          font-weight: ${props => props.theme.typograph.weights.bold};
        }
      }

      margin-left: auto;
    }

    svg {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const PlayCoachGoalSession = styled.div`
  margin-top: 16px;
  width: 100%;

  h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  div {
    display: flex;

    & + div {
      margin-top: 8px;
    }

    strong {
      color: ${props => props.theme.colors.light.light0};

      &:nth-child(1) {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(1) {
        flex-shrink: 0;
      }
    }
  }
`;

export const CoachSession = styled.div`
  margin-top: 24px;
  width: 100%;

  h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }
`;

export const SelectedQuantitityOfCardContainer = styled.div<ISelectedQuantitityOfCardContainer>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;

  & + div {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+3']};
  }

  div:nth-child(1) {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+3']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    svg {
      ${props =>
        props.$typeOfStatistic === 'redCards' &&
        css`
          color: ${props.theme.colors.danger};
        `}
      ${props =>
        props.$typeOfStatistic === 'yellowCards' &&
        css`
          color: #e4ce00;
        `}
      ${props =>
        props.$typeOfStatistic === 'offside' &&
        css`
          color: #eb610e;
        `}
      ${props =>
        props.$typeOfStatistic === 'corner' &&
        css`
          color: ${props.theme.colors.danger};
        `}
    }
  }

  > p {
    color: ${props => props.theme.colors.light.light0};
    margin-left: auto;
  }
`;

export const PayGoalSession = styled.div`
  margin-top: 24px;
  width: 100%;

  h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      width: 48%;

      @media only screen and (max-width: 768px) {
        width: 100%;

        &:nth-child(2) {
          margin-top: 16px;
        }
      }

      > div {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']};
        margin-bottom: 8px;
      }

      > ul {
        margin-bottom: 0;
      }
    }
  }
`;

export const MinuteOfFirstGoalSession = styled.div`
  margin-top: 24px;
  width: 100%;

  h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      width: 48%;

      @media only screen and (max-width: 768px) {
        width: 100%;

        &:nth-child(2) {
          margin-top: 16px;
        }
      }

      > div:nth-child(1) {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']};
        margin-bottom: 8px;
      }

      > div:nth-child(2) {
        small {
          color: ${props => props.theme.colors.light['light-2']};
        }
        strong {
          color: ${props => props.theme.colors.light.light0};
        }
      }
    }
  }
`;

export const TeamImageContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${props => props.theme.colors.dark['dark+3']};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  img {
    height: 25px;
  }
`;
export const ThrowContainer = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;

  & + li {
    margin-top: 8px;
  }

  > small {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 4px;
  }
`;
export const SelectedPlayerOfThrow = styled.div`
  border: none;
  background: ${props => props.theme.colors.dark['dark+3']};
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s opacity;
  box-shadow: 2px 2px 16px #00000030;
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    width: 100%;
    text-align: center;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
