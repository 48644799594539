import React, { useState } from 'react';

import { useBullet } from '@/context/BulletContext';
import {
  Container,
  SelectPaymentContainer,
  PaymentRadio,
  PriceChallengeInputContainer,
  PriceChallengeInput,
} from './styles';
import { useNewChallenge } from '../../NewChallengeContext';

const ChallengePaymentContainer: React.FC = () => {
  const bulletContext = useBullet();
  const newChallengeContext = useNewChallenge();

  const [isPriceTouched, setIsPriceTouched] = useState(false);
  const [isOpponentPriceTouched, setIsOpponentPriceTouched] = useState(false);

  const paymentTypeDescription = newChallengeContext.isCustomPayment
    ? ' valores diferentes'
    : ' o mesmo valor';

  const priceInputLabel = newChallengeContext.isCustomPayment
    ? 'Seu valor'
    : 'Valor do desafio';

  const handleSetCustomPayment = (isCustomPayment: boolean): void => {
    newChallengeContext.setIsCustomPayment(isCustomPayment);

    if (!isCustomPayment) {
      newChallengeContext.setOpponentPrice(null);
      setIsOpponentPriceTouched(false);
    }
  };

  return (
    <Container>
      <SelectPaymentContainer>
        <p>Pagamento</p>
        <PaymentRadio
          items={[
            {
              value: false,
              label: 'Padrão',
            },
            {
              value: true,
              label: 'Customizado',
            },
          ]}
          value={newChallengeContext.isCustomPayment}
          onChange={e => handleSetCustomPayment(e.target.value)}
        />
        <small>Você e seu oponente pagam{paymentTypeDescription}</small>
      </SelectPaymentContainer>
      <PriceChallengeInputContainer>
        <PriceChallengeInput
          label={priceInputLabel}
          placeholder="ex.: 20"
          id="priceInput"
          addonBefore="T¢"
          type="text"
          autoComplete="off"
          value={newChallengeContext.price || ''}
          error={isPriceTouched && newChallengeContext.priceValidationMessage}
          onBlur={() => setIsPriceTouched(true)}
          onChange={e => {
            const newValue = e.target.value;
            newChallengeContext.setPrice(parseInt(newValue.replace(/\D/g, '')));
          }}
        />
        <strong>
          Saldo disponível: <span>T¢{bulletContext.balance.amount}</span>
        </strong>
      </PriceChallengeInputContainer>
      {newChallengeContext.isCustomPayment && (
        <PriceChallengeInput
          label="Valor do seu oponente"
          placeholder="ex.: 20"
          id="opponentPriceInput"
          addonBefore="T¢"
          type="text"
          autoComplete="off"
          value={newChallengeContext.opponentPrice || ''}
          error={
            isOpponentPriceTouched &&
            newChallengeContext.opponentPriceValidationMessage
          }
          onBlur={() => setIsOpponentPriceTouched(true)}
          onChange={e => {
            const newValue = e.target.value;
            newChallengeContext.setOpponentPrice(
              parseInt(newValue.replace(/\D/g, '')),
            );
          }}
        />
      )}
    </Container>
  );
};

export default ChallengePaymentContainer;
