import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import { useAuth } from '../../../../context/AuthContext';
import { useIntl } from '../../../../context/IntlContext';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import Loading from '../../../../components/Loading';
import NotFound from '../../../../components/NotFound';
import api from '../../../../services/api';

import PlayedCardGameAthlete from './PlayedCardGameAthlete';

import { Container, Header, CardInfoContainer, ScoreText } from './styles';

interface IRouteParams {
  played_card_game_id: string;
  id: string;
}

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export interface IPlayedAthlete {
  _team: ITeam;
  _athletes: {
    _id: string;
    status: boolean;
    name: string;
    url: string;
    number: string;
    position: string;
    age: string;
    height: string;
    weight: string;
    from: string;
    updatedAt: string;
    createdAt: string;
    photo: string;
  };
  athleteScore: number;
  playerScore: number;
}

interface IPlayedAthleteCardGameState {
  _id: string;
  name: string;
  totalScore: number;
  finished: boolean;
  athletes: IPlayedAthlete[];
  createdAt: string;
  _user: {
    _id: string;
    name: string;
    username: string;
  };
  _card: {
    _id: string;
    name: string;
  };
}

const CardGameDetails: React.FC = () => {
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();

  const [playedCardGame, setPlayedCardGame] =
    useState<IPlayedAthleteCardGameState | null>(null);
  const [loadingPlayedCardGame, setLoadingPlayedCardGame] = useState(true);

  useEffect(() => {
    async function getPlayedGameCard(): Promise<void> {
      try {
        const { data } = await api.get<{
          doc: IPlayedAthleteCardGameState;
        }>(`/api/played-athlete/${params.played_card_game_id}`);

        if (data.doc._user._id !== me?._id) {
          history.push(`/athletes_of_the_week/card_games_history`);
          return;
        }

        const athletesWithAbreevOnTeam = data.doc.athletes.map(athlete => ({
          ...athlete,
          _team: {
            ...athlete._team,
            abbrev: athlete._team.name.substring(0, 3).toUpperCase(),
          },
        }));

        setPlayedCardGame({
          ...data.doc,
          athletes: athletesWithAbreevOnTeam,
        });
      } catch (error) {
        // showToast({
        //   type: 'error',
        //   title: 'Ocorreu um erro ao carregar os campeonatos',
        //   description: 'Recarregue a página!',
        // });
      }
      setLoadingPlayedCardGame(false);
    }

    getPlayedGameCard();
  }, [history, me?._id, params]);

  if (loadingPlayedCardGame) {
    return (
      <Container>
        <Breadcrumbs />
        <Header>
          <h5>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.myCardGameDetails.title',
            )}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!playedCardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.myCardGameDetails.messages.noGame',
          )}
        </h4>
        <Link to="/athletes_of_the_week/card_games_history">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.myCardGameDetails.goToGamesHistoryLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.myCardGameDetails.title',
          )}
        </h5>
      </Header>
      <CardInfoContainer>
        <div>
          <small>{playedCardGame._card.name}</small>
          <small>
            {format(
              parseISO(playedCardGame.createdAt),
              intl.getTranslatedText('common.formatDates.carriedOut'),
            )}
          </small>
        </div>
        <div>
          <ScoreText $notScore={!playedCardGame.totalScore}>
            {playedCardGame.totalScore}{' '}
            {intl.getTranslatedText('common.scoredPointsDescription')}
          </ScoreText>
        </div>
      </CardInfoContainer>
      <ul>
        {playedCardGame?.athletes.map(athlete => (
          <PlayedCardGameAthlete
            key={athlete._athletes._id}
            playedAthlete={athlete}
          />
        ))}
      </ul>
    </Container>
  );
};

export default CardGameDetails;
