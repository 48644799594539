import React from 'react';
import { GiCardRandom } from 'react-icons/gi';
import { IoIosFlag } from 'react-icons/io';
import { FaFlagCheckered } from 'react-icons/fa';

import { useIntl } from '../../../../../context/IntlContext';

import { IMyDataInTheRoom } from '../..';

import {
  Container,
  Header,
  Content,
  SelectedQuantitityOfCardContainer,
} from './styles';
import { PointsDescription } from '../../styles';

interface ICoachMode {
  myDataInTheRoom: IMyDataInTheRoom;
}

const CoachMode: React.FC<ICoachMode> = ({ myDataInTheRoom }) => {
  const intl = useIntl();

  const cardsScore = myDataInTheRoom._meGame?.cardsScore || 0;
  const offsideScore = myDataInTheRoom._meGame?.offsideScore || 0;
  const cornerScore = myDataInTheRoom._meGame?.cornerScore || 0;
  const myScore = cardsScore + offsideScore + cornerScore;

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.statistics.title')}
        </h6>
        <PointsDescription>
          <span>{myScore}</span>
          {` / 46 `}
          {intl.getTranslatedText('common.scoredPointsDescription')}
        </PointsDescription>
      </Header>
      <Content>
        <SelectedQuantitityOfCardContainer $typeOfStatistic="yellowCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.yellowCards.label',
            )}
          </strong>
          <h6>{myDataInTheRoom._meGame?.yellowCards}</h6>
        </SelectedQuantitityOfCardContainer>
        <SelectedQuantitityOfCardContainer $typeOfStatistic="redCards">
          <div>
            <GiCardRandom size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.redCards.label',
            )}
          </strong>
          <h6>{myDataInTheRoom._meGame?.redCards}</h6>
        </SelectedQuantitityOfCardContainer>
        <SelectedQuantitityOfCardContainer $typeOfStatistic="offside">
          <div>
            <FaFlagCheckered size={16} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.offsideNumber.label',
            )}
          </strong>
          <h6>{myDataInTheRoom._meGame?.offsideNumber}</h6>
        </SelectedQuantitityOfCardContainer>
        <SelectedQuantitityOfCardContainer $typeOfStatistic="corner">
          <div>
            <IoIosFlag size={21} />
          </div>
          <strong>
            {intl.getTranslatedText(
              'pages.coachFriend.room.statistics.cornerNumber.label',
            )}
          </strong>
          <h6>{myDataInTheRoom._meGame?.cornerNumber}</h6>
        </SelectedQuantitityOfCardContainer>
      </Content>
    </Container>
  );
};

export default CoachMode;
