import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ICardRoomItem {
  $archived?: boolean;
}

export const Container = styled.div`
  padding: 40px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  > div:nth-child(3),
  > div:nth-child(4) {
    margin: 40px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6 {
      text-align: center;
      margin-bottom: 16px;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }

    a {
      font-size: ${props => props.theme.typograph.sizes.p}px;
      color: ${props => props.theme.colors.light['light-2']};
      text-align: center;
      display: flex;
      align-items: center;

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.light['light-2'])};
      }

      svg {
        margin-right: 8px;
      }
    }

    button {
      width: 100%;
      max-width: 400px;
    }
  }

  /* > button {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: block;
  } */
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 32px;

  h4 {
    line-height: 120%;
    margin-bottom: 16px;
  }

  h5 {
    line-height: 120%;
  }
`;

export const RoomsList = styled.ul`
  margin: 16px 0 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CardRoomItem = styled(Link)<ICardRoomItem>`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${props =>
    !props.$archived
      ? props.theme.colors.dark['dark+1']
      : props.theme.colors.dark['dark+3']};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;

  > div:nth-child(1) {
    margin-bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div {
      display: flex;
      align-items: center;
      /* Private Room Indicator */
      > svg {
        color: ${props => props.theme.colors.primary.primary0};
        margin: 0 8px;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 16px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary['primary+1']};
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
  }

  /* button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  } */

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const RoomTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }
`;

export const LoadMoreCardsButton = styled.button`
  border: 0;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;
  align-self: stretch;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;
