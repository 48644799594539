import { IntlContextData } from '../context/IntlContext';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Home from '../pages/Home';
import Championships from '../pages/Championships';
import Championship from '../pages/Championships/Championship';
import CardGame from '../pages/Championships/Championship/CardGame';
import PlayCardGame from '../pages/Championships/Championship/CardGame/Play';
import CardGamesHistory from '../pages/Championships/Championship/CardGamesHistory';
import CardGameDetails from '../pages/Championships/Championship/CardGamesHistory/CardGameDetails';
import UserCardGameDetails from '../pages/Championships/Championship/CardGame/UserCardGameDetails';

import CoachFriend from '../pages/CoachFriend';
import CoachFriendNewRoom from '../pages/CoachFriend/NewRoom';
import CoachFriendRoom from '../pages/CoachFriend/Room';
import CoachFriendRoomNewGame from '../pages/CoachFriend/Room/NewGame';
import CoachFriendRoomMyGameDetails from '../pages/CoachFriend/Room/MyGameDetails';
import CoachFriendPublicRooms from '../pages/CoachFriend/PublicRooms';
import CoachFriendMyRooms from '../pages/CoachFriend/MyRooms';

import Versus from '../pages/Versus';
import VersusNewRoom from '../pages/Versus/NewRoom';
import VersusRoom from '../pages/Versus/Room';
import VersusPublicRooms from '../pages/Versus/PublicRooms';
import VersusMyRooms from '../pages/Versus/MyRooms';

import VersusVIP from '../pages/VersusVIP';
import VersusVIPNewRoom from '../pages/VersusVIP/NewRoom';
// import VersusVIPRoom from '../pages/VersusVIP/Room';
import VersusVIPPublicRooms from '../pages/VersusVIP/PublicRooms';
import VersusVIPMyRooms from '../pages/VersusVIP/MyRooms';

import AthletesOfTheWeek from '../pages/AthletesOfTheWeek';
import AthleteCardGame from '../pages/AthletesOfTheWeek/CardGame';
import PlayAthleteCardGame from '../pages/AthletesOfTheWeek/CardGame/Play';
import AthleteCardGamesHistory from '../pages/AthletesOfTheWeek/CardGamesHistory';
import AthleteCardGameDetails from '../pages/AthletesOfTheWeek/CardGamesHistory/CardGameDetails';
import AthleteUserCardGameDetails from '../pages/AthletesOfTheWeek/CardGame/UserCardGameDetails';

import PushGamesHistory from '../pages/PushGamesHistory';

import Profile from '../pages/Profile';

import GlobalRanking from '../pages/Rankings/GlobalRanking';
import CoachFriendRanking from '../pages/Rankings/CoachFriendRanking';
import VersusRanking from '../pages/Rankings/VersusRanking';
import VersusVIPRanking from '../pages/Rankings/VersusVIPRanking';
import CardGamesRanking from '../pages/Rankings/CardGamesRanking';
import AthletesOfTheWeekRanking from '../pages/Rankings/AthletesOfTheWeekRanking';
import PushGamesRanking from '../pages/Rankings/PushGamesRanking';

import Chats from '../pages/Chats';

export function formatParam(paramName: string): string {
  return `:${paramName}([0-9a-fA-F]{24})`;
}

interface IRouteListItem {
  path: string;
  name: string;
  exact?: boolean;
  component: React.FC;
  isPrivate: boolean;
}

export function getRoutesList(intl: IntlContextData): IRouteListItem[] {
  return [
    {
      path: '/',
      name: intl.getTranslatedText('routes.routesList.signIn.name'),
      exact: true,
      component: SignIn,
      isPrivate: false,
    },
    {
      path: '/signup',
      name: intl.getTranslatedText('routes.routesList.signUp.name'),
      exact: false,
      component: SignUp,
      isPrivate: false,
    },
    {
      path: '/home',
      name: intl.getTranslatedText('routes.routesList.home.name'),
      exact: false,
      component: Home,
      isPrivate: true,
    },
    {
      path: '/championships',
      name: intl.getTranslatedText('routes.routesList.championships.name'),
      exact: true,
      component: Championships,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}`,
      name: intl.getTranslatedText('routes.routesList.championship.name'),
      exact: true,
      component: Championship,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}/card_games/${formatParam(
        'card_game_id',
      )}`,
      name: intl.getTranslatedText('routes.routesList.cardGame.name'),
      exact: true,
      component: CardGame,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}/card_games/${formatParam(
        'card_game_id',
      )}/play`,
      name: intl.getTranslatedText('routes.routesList.playCardGame.name'),
      exact: false,
      component: PlayCardGame,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}/card_games/${formatParam(
        'card_game_id',
      )}/my_games/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText('routes.routesList.cardGameDetails.name'),
      exact: false,
      component: CardGameDetails,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}/card_games/${formatParam(
        'card_game_id',
      )}/users_games/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText(
        'routes.routesList.userCardGameDetails.name',
      ),
      exact: false,
      component: UserCardGameDetails,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam('id')}/card_games_history`,
      name: intl.getTranslatedText('routes.routesList.cardGamesHistory.name'),
      exact: true,
      component: CardGamesHistory,
      isPrivate: true,
    },
    {
      path: `/championships/${formatParam(
        'id',
      )}/card_games_history/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText('routes.routesList.cardGameDetails.name'),
      exact: false,
      component: CardGameDetails,
      isPrivate: true,
    },
    /* Coach Friend */
    {
      path: '/coach_friend',
      name: intl.getTranslatedText('routes.routesList.coachFriend.name'),
      exact: true,
      component: CoachFriend,
      isPrivate: true,
    },
    {
      path: '/coach_friend/new_room',
      name: intl.getTranslatedText('routes.routesList.coachFriendNewRoom.name'),
      exact: true,
      component: CoachFriendNewRoom,
      isPrivate: true,
    },
    {
      path: `/coach_friend/${formatParam('id')}`,
      name: intl.getTranslatedText('routes.routesList.coachFriendRoom.name'),
      exact: true,
      component: CoachFriendRoom,
      isPrivate: true,
    },
    {
      path: `/coach_friend/${formatParam('id')}/new`,
      name: intl.getTranslatedText(
        'routes.routesList.coachFriendRoomNewGame.name',
      ),
      exact: false,
      component: CoachFriendRoomNewGame,
      isPrivate: true,
    },
    {
      path: `/coach_friend/${formatParam('id')}/my_game`,
      name: intl.getTranslatedText(
        'routes.routesList.coachFriendRoomMyGameDetails.name',
      ),
      exact: false,
      component: CoachFriendRoomMyGameDetails,
      isPrivate: true,
    },
    {
      path: '/coach_friend/public_rooms',
      name: intl.getTranslatedText('routes.routesList.coachFriend.name'),
      exact: true,
      component: CoachFriendPublicRooms,
      isPrivate: true,
    },
    {
      path: '/coach_friend/my_rooms',
      name: intl.getTranslatedText('routes.routesList.coachFriendMyRooms.name'),
      exact: true,
      component: CoachFriendMyRooms,
      isPrivate: true,
    },
    /* Versus */
    {
      path: '/versus',
      name: intl.getTranslatedText('routes.routesList.versus.name'),
      exact: true,
      component: Versus,
      isPrivate: true,
    },
    {
      path: '/versus/new_room',
      name: intl.getTranslatedText('routes.routesList.versusNewRoom.name'),
      exact: true,
      component: VersusNewRoom,
      isPrivate: true,
    },
    {
      path: `/versus/${formatParam('id')}`,
      name: intl.getTranslatedText('routes.routesList.versusRoom.name'),
      exact: true,
      component: VersusRoom,
      isPrivate: true,
    },
    // {
    //   path: `/versus/${formatParam('id')}/new`,
    //   name: intl.getTranslatedText('routes.routesList.versusGame.name'),
    //   exact: false,
    //   component: VersusGame,
    //   isPrivate: true,
    // },
    {
      path: '/versus/public_rooms',
      name: intl.getTranslatedText('routes.routesList.versusPublicRooms.name'),
      exact: true,
      component: VersusPublicRooms,
      isPrivate: true,
    },
    {
      path: '/versus/my_rooms',
      name: intl.getTranslatedText('routes.routesList.versusMyRooms.name'),
      exact: true,
      component: VersusMyRooms,
      isPrivate: true,
    },
    /* VersusVIP */
    {
      path: '/versus_vip',
      name: intl.getTranslatedText('routes.routesList.versusVIP.name'),
      exact: true,
      component: VersusVIP,
      isPrivate: true,
    },
    {
      path: '/versus_vip/new_room',
      name: intl.getTranslatedText('routes.routesList.versusVIPNewRoom.name'),
      exact: true,
      component: VersusVIPNewRoom,
      isPrivate: true,
    },
    // {
    //   path: `/versus_vip/${formatParam('id')}`,
    //   name: intl.getTranslatedText('routes.routesList.coachFriendPublicRooms.name'),
    //   exact: true,
    //   component: VersusVIPRoom,
    //   isPrivate: true,
    // },
    // {
    //   path: `/versus_vip/${formatParam('id')}/new`,
    //   name: intl.getTranslatedText('routes.routesList.versusVIPGame.name'),
    //   exact: false,
    //   component: VersusVIPGame,
    //   isPrivate: true,
    // },
    {
      path: '/versus_vip/public_rooms',
      name: intl.getTranslatedText(
        'routes.routesList.versusVIPPublicRooms.name',
      ),
      exact: true,
      component: VersusVIPPublicRooms,
      isPrivate: true,
    },
    {
      path: '/versus_vip/my_rooms',
      name: intl.getTranslatedText('routes.routesList.versusVIPMyRooms.name'),
      exact: true,
      component: VersusVIPMyRooms,
      isPrivate: true,
    },
    /* AthletesOfTheWeek */
    {
      path: `/athletes_of_the_week`,
      name: intl.getTranslatedText('routes.routesList.athletesOfTheWeek.name'),
      exact: true,
      component: AthletesOfTheWeek,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/${formatParam('card_game_id')}`,
      name: intl.getTranslatedText('routes.routesList.athleteCardGame.name'),
      exact: true,
      component: AthleteCardGame,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/${formatParam('card_game_id')}/play`,
      name: intl.getTranslatedText(
        'routes.routesList.playAthleteCardGame.name',
      ),
      exact: false,
      component: PlayAthleteCardGame,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/${formatParam(
        'card_game_id',
      )}/my_games/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGameDetails.name',
      ),
      exact: false,
      component: AthleteCardGameDetails,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/${formatParam(
        'card_game_id',
      )}/users_games/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteUserCardGameDetails.name',
      ),
      exact: false,
      component: AthleteUserCardGameDetails,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/card_games_history`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGamesHistory.name',
      ),
      exact: true,
      component: AthleteCardGamesHistory,
      isPrivate: true,
    },
    {
      path: `/athletes_of_the_week/card_games_history/${formatParam(
        'played_card_game_id',
      )}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGameDetails.name',
      ),
      exact: false,
      component: AthleteCardGameDetails,
      isPrivate: true,
    },
    /* PushGames */
    {
      path: `/push_games_history`,
      name: intl.getTranslatedText('routes.routesList.pushGamesHistory.name'),
      exact: true,
      component: PushGamesHistory,
      isPrivate: true,
    },
    /* Profile */
    {
      path: `/profile`,
      name: intl.getTranslatedText('routes.routesList.profile.name'),
      component: Profile,
      isPrivate: true,
    },
    /* Rankings */
    {
      path: `/rankings/global`,
      name: intl.getTranslatedText('routes.routesList.globalRanking.name'),
      component: GlobalRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/coach_friend`,
      name: intl.getTranslatedText('routes.routesList.coachFriendRanking.name'),
      component: CoachFriendRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/versus`,
      name: intl.getTranslatedText('routes.routesList.versusRanking.name'),
      component: VersusRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/versus_vip`,
      name: intl.getTranslatedText('routes.routesList.versusVIPRanking.name'),
      component: VersusVIPRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/card_games`,
      name: intl.getTranslatedText('routes.routesList.cardGamesRanking.name'),
      component: CardGamesRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/athletes_of_the_week`,
      name: intl.getTranslatedText(
        'routes.routesList.athletesOfTheWeekRanking.name',
      ),
      component: AthletesOfTheWeekRanking,
      isPrivate: true,
    },
    {
      path: `/rankings/push_games`,
      name: intl.getTranslatedText('routes.routesList.pushGamesRanking.name'),
      component: PushGamesRanking,
      isPrivate: true,
    },
    /* Chat */
    {
      path: `/chats`,
      name: intl.getTranslatedText('routes.routesList.chats.name'),
      component: Chats,
      isPrivate: true,
    },
  ];
}
