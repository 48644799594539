import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 432px;
  background: ${props => props.theme.colors.dark['dark+1']};
  height: calc(100vh - 72px - 32px);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 2px #2b2b2b;
  position: fixed;
  z-index: 1000;
  top: 72px;
  right: 32px;
  overflow-y: scroll;

  @media only screen and (max-width: 600px) {
    max-width: calc(100% - 32px);
    height: calc(100vh - 61px - 16px);
    top: 61px;
    right: 16px;
    padding: 16px;
  }

  &::-webkit-scrollbar {
    background: ${props => props.theme.colors.dark['dark+1']};
    width: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${props => props.theme.colors.dark['dark+2']};
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  h5 {
    line-height: 120%;
  }
`;

export const NotificationItem = styled(Link)`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  transition: 0.2s background;

  & + a {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  > div:nth-child(1) {
    display: flex;

    > div {
      flex: 1;

      strong {
        margin-bottom: 4px;
      }

      > small {
        color: ${props => props.theme.colors.light['light-2']};
        margin-bottom: 16px;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }

    div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${props => props.theme.colors.primary.primary0};
      margin-right: 11px;
    }
  }
`;

export const LoadMoreButton = styled.button`
  padding: 16px 0;
  background: ${props => props.theme.colors.dark['dark+1']};
  border: 0;
  width: 100%;
  transition: 0.2s background;

  border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}

  &:hover {
    ${props =>
      !props.disabled &&
      css`
        background: ${shade(0.1, props.theme.colors.dark['dark+1'])};
      `}
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 56px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
