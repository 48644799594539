import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';

import CustomAntButton from '@/components/CustomAntButton';

import SelectChallengeContainer from './SelectChallengeContainer';
import FillChallengeOptionsContainer from './FillChallengeOptionsContainer';

import { SubHeaderContainer } from '../styles';
import { Container } from './styles';
import { NewChallengeProvider, useNewChallenge } from './NewChallengeContext';
import { useCoachFriendRoom } from '../../CoachFriendRoomContext';

const NewChallenge: React.FC = () => {
  const coachFriendRoomChallenge = useCoachFriendRoom();
  const newChallengeContext = useNewChallenge();

  return (
    <Container>
      <SubHeaderContainer>
        <CustomAntButton
          icon={<FiArrowLeft size={20} />}
          useCustomIcon
          type="text"
          htmlType="button"
          onClick={() =>
            coachFriendRoomChallenge.setCurrentChallengeRoute('list')
          }
        />
        <h6>Novo desafio</h6>
      </SubHeaderContainer>
      {!newChallengeContext.selectedChallenge ? (
        <SelectChallengeContainer />
      ) : (
        <FillChallengeOptionsContainer />
      )}
    </Container>
  );
};

const NewChallengeWrapper: React.FC = () => {
  return (
    <NewChallengeProvider>
      <NewChallenge />
    </NewChallengeProvider>
  );
};

export default NewChallengeWrapper;
