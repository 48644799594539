import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 56px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 16px 56px;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 16px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 752px;
  margin: 0 auto;
`;
export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  button {
    width: 100%;
  }
`;
