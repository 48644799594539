import styled from 'styled-components';

export const SubHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 600px) {
    padding-bottom: 0.5rem;
  }

  h6 {
    margin-left: 1rem;
    font-weight: ${props => props.theme.typograph.weights.medium};
    line-height: 1.2;

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.p}px;
    }
  }
`;
