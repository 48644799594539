import React from 'react';
import { FiEdit2 } from 'react-icons/fi';

import CustomAntButton from '@/components/CustomAntButton';

import { getSanitizedText } from '@/utils/sanitize';
import { CardHeader, Container } from './styles';
import { convertTextWithMarkersToHTML } from '../../../utils';
import { useNewChallenge } from '../../NewChallengeContext';

const SelectedChallengeCard: React.FC = () => {
  const newChallengeContext = useNewChallenge();

  return (
    <Container>
      <CardHeader>
        <p
          dangerouslySetInnerHTML={{
            __html: getSanitizedText(
              convertTextWithMarkersToHTML(newChallengeContext.challengeText),
            ),
          }}
        />
        <CustomAntButton
          icon={<FiEdit2 size={20} />}
          useCustomIcon
          type="text"
          htmlType="button"
          onClick={() => {
            newChallengeContext.setSelectedChallenge('');
            newChallengeContext.setFields([]);
          }}
        />
      </CardHeader>
      {newChallengeContext.formFieldsContainer}
    </Container>
  );
};

export default SelectedChallengeCard;
