import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { IPlayerOfTeam, ITeam } from '@/pages/CoachFriend/Room';

import { useIntl } from '@/context/IntlContext';
import Select from '@/components/Select';
import TeamSelector from '../../TeamSelector';
import { useNewChallenge } from '../../../../NewChallengeContext';
import { Markers, FormFieldIdentifier } from '../../../../types';

import { FormFieldsContainer } from '../../styles';
import AthleteSelector from '../../AthleteSelector';

const TimeOfAthleteGoalFormFields: React.FC = () => {
  const isFirstRender = useRef(true);
  const newChallengeContext = useNewChallenge();
  const intl = useIntl();

  useEffect(() => {
    if (isFirstRender.current) {
      newChallengeContext.setFields([
        {
          id: FormFieldIdentifier.TEAM,
          value: null,
        },
        {
          id: FormFieldIdentifier.ATHLETE,
          marker: Markers.SQUARE_BRACKETS,
          value: null,
          viewValue: '',
        },
        {
          id: FormFieldIdentifier.TIME_A,
          marker: Markers.CURLY_BRACKETS,
          value: null,
          viewValue: '',
        },
        {
          id: FormFieldIdentifier.TIME_B,
          marker: Markers.PARENTHESIS,
          value: null,
          viewValue: '',
        },
      ]);
    }

    isFirstRender.current = false;
  }, [newChallengeContext]);

  const selectedTeam = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.TEAM,
    )?.value as ITeam;
  }, [newChallengeContext.fields]);

  const setSelectedTeam = useCallback(
    (team: ITeam) => {
      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.TEAM) {
            return { ...field, value: team };
          }
          if (field.id === FormFieldIdentifier.ATHLETE) {
            return { ...field, value: null, viewValue: '' };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  const selectedAthlete = useMemo(() => {
    return newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.ATHLETE,
    )?.value as IPlayerOfTeam;
  }, [newChallengeContext.fields]);

  const setSelectedAthlete = useCallback(
    (athlete: IPlayerOfTeam) => {
      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.ATHLETE) {
            return {
              ...field,
              value: athlete,
              viewValue: `${athlete.name}`,
            };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  const minuteRange = useMemo(() => {
    const timeA = newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.TIME_A,
    )?.value as number;
    const timeB = newChallengeContext.fields.find(
      field => field.id === FormFieldIdentifier.TIME_B,
    )?.value as number;

    if (!timeA || !timeB) return null;

    return `${timeA}-${timeB}`;
  }, [newChallengeContext.fields]);

  const setMinuteRange = useCallback(
    (range: string) => {
      const [timeA, timeB] = range.split('-');

      newChallengeContext.setFields(oldState => {
        return oldState.map(field => {
          if (field.id === FormFieldIdentifier.TIME_A) {
            return {
              ...field,
              value: parseInt(timeA),
              viewValue: timeA,
            };
          }
          if (field.id === FormFieldIdentifier.TIME_B) {
            return {
              ...field,
              value: parseInt(timeB),
              viewValue: timeB,
            };
          }
          return field;
        });
      });
    },
    [newChallengeContext],
  );

  return (
    <FormFieldsContainer>
      <TeamSelector
        onSelect={team => setSelectedTeam(team)}
        selectedTeamId={selectedTeam?._id}
      />
      {selectedTeam && (
        <AthleteSelector
          onSelect={athlete => setSelectedAthlete(athlete)}
          selectedAthleteId={selectedAthlete?._id}
        />
      )}
      <Select
        label="Minutos"
        id="minuteRangeOfFirstGoal"
        placeholder={intl.getTranslatedText('common.selectDefaultText')}
        options={[
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range1.label',
            ),
            value: '0-9',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range2.label',
            ),
            value: '10-19',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range3.label',
            ),
            value: '20-29',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range4.label',
            ),
            value: '30-39',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range5.label',
            ),
            value: '40-49',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range6.label',
            ),
            value: '50-59',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range7.label',
            ),
            value: '60-69',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range8.label',
            ),
            value: '70-79',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range9.label',
            ),
            value: '80-89',
          },
          {
            label: intl.getTranslatedText(
              'pages.coachFriend.room.newGame.minuteOfFirstGoal.range10.label',
            ),
            value: '90-99',
          },
        ]}
        value={minuteRange}
        onChange={value => {
          if (value) {
            setMinuteRange(value as string);
          }
        }}
      />
    </FormFieldsContainer>
  );
};

export default TimeOfAthleteGoalFormFields;
