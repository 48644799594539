import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import Env from '../../../config/Environment';
import defaultAvatar from '../../../assets/DefaultAvatar.svg';

import api from '../../../services/api';
import { formatMoney } from '../../../utils/formatters';

import { useAuth } from '../../../context/AuthContext';
import { useIntl } from '../../../context/IntlContext';

import {
  Container,
  SearchContainer,
  RoomsListContainer,
  RoomsList,
  RoomItem,
  RoomItemTeam,
  AdversaryInfoContainer,
  LoadMoreRoomsButton,
} from './styles';

interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

interface IVersusRoom {
  _id: string;
  isPrivate: boolean;
  isFinished: boolean;
  price: number;
  name: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    local: ITeam;
    away: ITeam;
  };
  _createdBy: {
    _id: string;
    name: string;
    username: string;
    email: string;
    // photo: string;
    photo: {
      _id: string;
      filename: string;
    };
  };
}

interface IVersusRoomSearch {
  _id: string;
  isPrivate: boolean;
  isFinished: boolean;
  price: number;
  name: string;
  _champ: {
    _id: string;
    name: string;
  };
  _game: {
    local: ITeam;
    away: ITeam;
  };
  _createdBy: {
    _id: string;
    name: string;
    username: string;
    email: string;
    photo: string;
    // photo: {
    //   _id: string;
    //   filename: string;
    // };
  };
}

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const PublicRooms: React.FC = () => {
  const intl = useIntl();
  const { user: me } = useAuth();

  const [searchInputValue, setSearchInputValue] = useState('');

  const [rooms, setRooms] = useState<IVersusRoom[]>([]);
  const [roomsPagination, setRoomsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });
  const [loadingRooms, setLoadingRooms] = useState(true);

  const [findedOnSearchRooms, setFindedOnSearchRooms] = useState<
    IVersusRoomSearch[]
  >([]);
  const [findedOnSearchRoomsPagination, setFindedOnSearchRoomsPagination] =
    useState({
      limit: 10,
      currentPage: 1,
      totalPages: 0,
    });
  const [loadingFindedOnSearchRooms, setLoadingFindedOnSearchRooms] =
    useState(false);

  const handleGetRooms = useCallback(
    async (page = 1, searchTerm = ''): Promise<void> => {
      if (!searchTerm) {
        setLoadingRooms(true);
      } else {
        setLoadingFindedOnSearchRooms(true);
        setFindedOnSearchRoomsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
      }

      try {
        if (searchTerm) {
          const { data } = await api.get<{
            docs: IVersusRoomSearch[];
            page: number;
            pages: number;
          }>('/api/game-vs', {
            params: {
              search: searchTerm,
              page,
              limit: findedOnSearchRoomsPagination.limit,
              isPrivate: false,
              me: false,
              isVip: true,
            },
          });

          if (page === 1) {
            setFindedOnSearchRooms(
              data.docs.map(room => ({
                ...room,
                _game: {
                  ...room._game,
                  local: {
                    ...room._game.local,
                    abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
                  },
                  away: {
                    ...room._game.away,
                    abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
                  },
                },
              })),
            );
          } else {
            setFindedOnSearchRooms(oldState => [
              ...oldState,
              ...data.docs.map(room => ({
                ...room,
                _game: {
                  ...room._game,
                  local: {
                    ...room._game.local,
                    abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
                  },
                  away: {
                    ...room._game.away,
                    abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
                  },
                },
              })),
            ]);
          }

          setFindedOnSearchRoomsPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
          setLoadingFindedOnSearchRooms(false);
        } else {
          const { data } = await api.get<{
            docs: IVersusRoom[];
            page: number;
            pages: number;
          }>('/api/game-vs', {
            params: {
              // search: searchTerm,
              page,
              limit: roomsPagination.limit,
              isPrivate: false,
              me: false,
              isVip: true,
            },
          });

          if (page === 1) {
            setRooms(
              data.docs.map(room => ({
                ...room,
                _game: {
                  ...room._game,
                  local: {
                    ...room._game.local,
                    abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
                  },
                  away: {
                    ...room._game.away,
                    abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
                  },
                },
              })),
            );
          } else {
            setRooms(oldState => [
              ...oldState,
              ...data.docs.map(room => ({
                ...room,
                _game: {
                  ...room._game,
                  local: {
                    ...room._game.local,
                    abbrev: room._game.local.name.substring(0, 3).toUpperCase(),
                  },
                  away: {
                    ...room._game.away,
                    abbrev: room._game.away.name.substring(0, 3).toUpperCase(),
                  },
                },
              })),
            ]);
          }
          setRoomsPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
          setLoadingRooms(false);
        }
      } catch (error) {
        if (!searchTerm) {
          setLoadingRooms(false);
        } else {
          setLoadingFindedOnSearchRooms(false);
        }
      }
    },
    [findedOnSearchRoomsPagination.limit, roomsPagination.limit],
  );

  const roomsSearchDebounced = useMemo(() => {
    return _.debounce(handleGetRooms, 500);
  }, [handleGetRooms]);

  useEffect(() => {
    roomsSearchDebounced.cancel();

    if (searchInputValue.length > 3) {
      setLoadingFindedOnSearchRooms(true);
      roomsSearchDebounced(1, searchInputValue);
    } else {
      setLoadingFindedOnSearchRooms(false);
    }

    if (!searchInputValue) {
      setFindedOnSearchRooms([]);
      setFindedOnSearchRoomsPagination(oldState => ({
        ...oldState,
        currentPage: 1,
        totalPages: 0,
      }));
    }
  }, [searchInputValue, roomsSearchDebounced]);

  useEffect(() => {
    handleGetRooms(1);
  }, [handleGetRooms]);

  const contentPage = useMemo(() => {
    if (
      (loadingRooms && roomsPagination.totalPages === 0) ||
      (loadingFindedOnSearchRooms &&
        findedOnSearchRoomsPagination.totalPages === 0)
    ) {
      return <Spin indicator={antIcon} />;
    }

    if (!searchInputValue && rooms.length > 0) {
      return (
        <>
          <RoomsList>
            {rooms.map(room => (
              <RoomItem key={room._id} to={`/versus/${room._id}`}>
                <div>
                  <strong>{room.name}</strong>
                  <strong>T¢{formatMoney(room.price)}</strong>
                </div>
                <div>
                  <RoomItemTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </RoomItemTeam>
                  <p>{intl.getTranslatedText('common.vsDescription')}</p>
                  <RoomItemTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </RoomItemTeam>
                </div>
                <small>{room._champ.name}</small>
                <AdversaryInfoContainer>
                  <img
                    src={
                      room._createdBy.photo._id
                        ? Env.IMAGE_SERVER_URL + room._createdBy.photo._id
                        : defaultAvatar
                    }
                    alt={room._createdBy.name}
                  />
                  {room._createdBy._id !== me?._id ? (
                    <div>
                      <p>{room._createdBy.name}</p>
                      <small>{room._createdBy.username}</small>
                    </div>
                  ) : (
                    <div>
                      <p>{intl.getTranslatedText('common.meIdentifier')}</p>
                    </div>
                  )}
                </AdversaryInfoContainer>
                {/* <div>
                <img
                      src={
                        me?.photo
                          ? Env.IMAGE_SERVER_URL + me?.photo
                          : defaultAvatar
                      }
                      alt="Você"
                    />
                    <p>Você</p>
                </div> */}
              </RoomItem>
            ))}
            {roomsPagination.currentPage < roomsPagination.totalPages && (
              <LoadMoreRoomsButton
                onClick={() => {
                  if (loadingRooms) return;

                  handleGetRooms(roomsPagination.currentPage + 1);
                }}
                disabled={loadingRooms}
              >
                <h6>
                  {!loadingRooms
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </h6>
              </LoadMoreRoomsButton>
            )}
          </RoomsList>
        </>
      );
    }
    if (searchInputValue.length && searchInputValue.length <= 3) {
      return (
        <h6>{intl.getTranslatedText('common.messages.minCharToSearch')}</h6>
      );
    }

    if (findedOnSearchRooms.length > 0) {
      return (
        <>
          <small>
            {intl.getTranslatedText(
              'pages.versusVIP.publicRooms.messages.viewingFindedOnSearchRooms',
            )}
          </small>
          <RoomsList>
            {findedOnSearchRooms.map(room => (
              <RoomItem key={room._id} to={`/versus/${room._id}`}>
                <div>
                  <strong>{room.name}</strong>
                  <strong>T¢{formatMoney(room.price)}</strong>
                </div>
                <div>
                  <RoomItemTeam>
                    <div>
                      <img
                        src={room._game.local.image}
                        alt={room._game.local.name}
                      />
                    </div>
                    <strong>{room._game.local.abbrev}</strong>
                  </RoomItemTeam>
                  <p>{intl.getTranslatedText('common.vsDescription')}</p>
                  <RoomItemTeam>
                    <div>
                      <img
                        src={room._game.away.image}
                        alt={room._game.away.name}
                      />
                    </div>
                    <strong>{room._game.away.abbrev}</strong>
                  </RoomItemTeam>
                </div>
                <small>{room._champ.name}</small>
                <AdversaryInfoContainer>
                  <img
                    src={
                      room._createdBy.photo
                        ? Env.IMAGE_SERVER_URL + room._createdBy.photo
                        : defaultAvatar
                    }
                    alt={room._createdBy.name}
                  />
                  {room._createdBy._id !== me?._id ? (
                    <div>
                      <p>{room._createdBy.name}</p>
                      <small>{room._createdBy.username}</small>
                    </div>
                  ) : (
                    <div>
                      <p>{intl.getTranslatedText('common.meIdentifier')}</p>
                    </div>
                  )}
                </AdversaryInfoContainer>
                {/* <div>
                <img
                      src={
                        me?.photo
                          ? Env.IMAGE_SERVER_URL + me?.photo
                          : defaultAvatar
                      }
                      alt="Você"
                    />
                    <p>Você</p>
                </div> */}
              </RoomItem>
            ))}
            {findedOnSearchRoomsPagination.currentPage <
              findedOnSearchRoomsPagination.totalPages && (
              <LoadMoreRoomsButton
                onClick={() => {
                  if (loadingFindedOnSearchRooms) return;

                  handleGetRooms(
                    findedOnSearchRoomsPagination.currentPage + 1,
                    searchInputValue,
                  );
                }}
                disabled={loadingFindedOnSearchRooms}
              >
                <h6>
                  {!loadingFindedOnSearchRooms
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </h6>
              </LoadMoreRoomsButton>
            )}
          </RoomsList>
          {/* <Pagination
            current={teams.currentPage}
            onChange={page => {
              setTeams({ ...teams, currentPage: page });
              handleGetTeams(searchInputValue, page);
            }}
            total={teams.total}
            pageSize={teams.pageSize}
          /> */}
        </>
      );
    }

    if (!searchInputValue) {
      return (
        <h6>
          {intl.getTranslatedTextWithHTML(
            'pages.versusVIP.publicRooms.messages.noPublicRooms',
          )}
        </h6>
      );
    }

    return (
      <h6>
        {intl.getTranslatedText('pages.versusVIP.publicRooms.messages.noRooms')}
      </h6>
    );
  }, [
    findedOnSearchRooms,
    findedOnSearchRoomsPagination.currentPage,
    findedOnSearchRoomsPagination.totalPages,
    handleGetRooms,
    intl,
    loadingFindedOnSearchRooms,
    loadingRooms,
    me?._id,
    rooms,
    roomsPagination.currentPage,
    roomsPagination.totalPages,
    searchInputValue,
  ]);

  return (
    <Container>
      <h4>{intl.getTranslatedText('common.gamesTitles.versus')}</h4>
      <h5>{intl.getTranslatedText('pages.versusVIP.publicRooms.title')}</h5>
      <SearchContainer>
        <Search
          onChange={e => setSearchInputValue(e.target.value)}
          placeholder={intl.getTranslatedText(
            'pages.versusVIP.publicRooms.searchInput.placeholder',
          )}
          value={searchInputValue}
        />
      </SearchContainer>
      <RoomsListContainer>{contentPage}</RoomsListContainer>
    </Container>
  );
};

export default PublicRooms;
