import { darken, shade } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface IHeaderProps {
  $bg?: string;
}

interface IFilterItemProps {
  $selected: boolean;
}

interface ICardGameItemProps {
  $bg?: string;
}

function getBackgroundOfCardGameItem(bg = ''): string {
  if (bg) {
    return bg;
  }

  return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBP2zNNRqebdTAs6101-g1ZP-AENruAakJhA&usqp=CAU';
}

export const Container = styled.div`
  padding: 40px 0 56px;

  > div:nth-child(1) {
    margin-left: 56px;
  }

  @media only screen and (max-width: 1199px) {
    padding: 32px 0 56px;

    > div:nth-child(1) {
      margin-left: 32px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 0 56px;

    > div:nth-child(1) {
      margin-left: 16px;
    }
  }
`;

export const Header = styled.div<IHeaderProps>`
  width: 100%;
  height: 280px;
  margin-top: 24px;
  background: url(${props =>
      props.$bg ||
      'https://i0.statig.com.br/bancodeimagens/16/6u/li/166uli5jnowompk9gicgwodq8.jpg'})
    no-repeat center;
  background-size: cover;
  position: relative;

  > div {
    /* width: 100%;
    height: 100%; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 16px 56px;
    cursor: default;

    @media only screen and (max-width: 1199px) {
      padding: 16px 32px;
    }

    @media only screen and (max-width: 768px) {
      padding: 16px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgba(43, 43, 43, 0.95) 0%,
      rgba(43, 43, 43, 0.9) 26.04%,
      rgba(43, 43, 43, 0.7) 51.04%,
      rgba(43, 43, 43, 0.9) 69.73%,
      rgba(43, 43, 43, 0.95) 100%
    );

    > img {
      width: 50px;
    }

    > div {
      p {
        margin-top: 8px;
        color: ${props => props.theme.colors.light['light-2']};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 16px;

  > div:nth-child(1) {
    padding: 0 56px;

    width: 100%;
    max-width: 688px;
    display: flex;

    @media only screen and (max-width: 1199px) {
      padding: 0 32px;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      padding: 0 16px;
    }

    > a button {
      margin-left: 8px;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    max-width: 688px;
    display: flex;
    margin-top: 16px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::after,
    &::before {
      content: '';
      min-width: 56px;

      @media only screen and (max-width: 1199px) {
        min-width: 32px;
      }
      @media only screen and (max-width: 768px) {
        min-width: 16px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  > div:nth-child(3) {
    padding: 0 56px;

    @media only screen and (max-width: 1199px) {
      padding: 0 32px;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 16px;
    }

    width: 100%;
    height: 178px;
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: center;

    /* LoadingCardGames Container */
    div {
      display: flex;

      p {
        margin-left: 16px;
        color: ${props => props.theme.colors.light['light-2']};
      }
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }

  section {
    padding: 0 56px;

    @media only screen and (max-width: 1199px) {
      padding: 0 32px;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 16px;
    }

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }
`;

export const CardGameItem = styled(Link)<ICardGameItemProps>`
  display: inline-block;
  width: 33%;
  height: 178px;
  border-radius: 8px;
  background: url(${props => getBackgroundOfCardGameItem(props.$bg)}) no-repeat
    center;
  background-size: cover;
  position: relative;
  margin-right: 0.33%;
  margin-bottom: 8px;
  transition: 0.2s transform;

  @media only screen and (max-width: 1199px) {
    width: 49.5%;
    margin-right: 0.25%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    transform: translateY(-8px);
  }

  svg {
    color: ${props => props.theme.colors.primary['primary+1']};
    position: absolute;
    z-index: 10;
    top: 16px;
    right: 16px;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(69, 69, 69, 0.9),
      rgba(69, 69, 69, 0.9)
    );

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    border-radius: 8px;

    p {
      margin-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
`;

export const LoadMoreCardsButton = styled.button`
  border: 0;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
      &:hover {
        background-color: ${props.theme.colors.dark['dark+1']};
      }
    `}

  @media only screen and (max-width: 1199px) {
    width: 49.5%;
    margin-right: 0.25%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;

export const RestTimerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
  /* justify-content: center; */
  /* padding-bottom: 16px; */

  div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 16px;
    }

    h6 {
      color: ${props => props.theme.colors.primary.primary0};
    }
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const FilterItem = styled.button<IFilterItemProps>`
  border: 0;
  padding: 4px 8px;
  background: ${props => props.theme.colors.dark['dark+2']};
  color: ${props => props.theme.colors.light['light-2']};
  border-radius: 4px;
  transition: 0.2s box-shadow;
  flex-shrink: 0;

  & + button {
    margin-left: 8px;
  }

  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
      color: ${props.theme.colors.dark.dark0};
    `}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  &:hover {
    box-shadow: 1px 1px 3px
      ${props => darken(0.1, props.theme.colors.dark.dark0)};
  }
`;
