import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

import { formatParam, getRoutesList } from '../../routes/RoutesList';
import { useIntl } from '../../context/IntlContext';

interface IRouteParams {
  [key: string]: string;
}

const Breadcrumbs: React.FC = () => {
  const intl = useIntl();
  const match = useRouteMatch<IRouteParams>();

  const RoutesList = getRoutesList(intl);

  const crumbs = RoutesList
    // Get all routes that contain the current one.
    .filter(
      routeDefinition =>
        match.path.includes(routeDefinition.path) &&
        !!routeDefinition.isPrivate,
    )
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ path, name }) => ({
      path: Object.keys(match.params).length
        ? Object.keys(match.params).reduce(
            (currentPath, param) =>
              currentPath.replace(formatParam(param), match.params[param]),
            path,
          )
        : path,
      name,
    }));

  const preRoutes = crumbs;
  const currentRoute = crumbs.pop();
  return (
    <Breadcrumb>
      {preRoutes.map(({ path, name }) => (
        <Breadcrumb.Item key={`crumb_${path}`}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item>{currentRoute?.name}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
