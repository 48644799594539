import React, { useCallback, useMemo } from 'react';
import { FormikProps } from 'formik';
import { FiEdit2 } from 'react-icons/fi';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';

import CustomAntButton from '../../../../components/CustomAntButton';
import { useIntl } from '../../../../context/IntlContext';
import logoAmateur from '../../../../assets/logo-only-icon.svg';

import { INewRoomFormData, IChampionship, IMatch } from '../index';
import { ButtonsContainer } from '../styles';

import {
  Container,
  ChampionshipSelector,
  MatchSelector,
  MatchSelectorTeam,
  MatchInfoContainer,
  SessionContainer,
  LoadingAndNotFoundContainer,
  LoadMoreButton,
} from './styles';

export interface IMatchProps {
  formik: FormikProps<INewRoomFormData>;
  championships: IChampionship[];
  loadingChampionships: boolean;
  isOnLastPageOfChampionships: boolean;
  loadMoreChampionships(): void;
  handleGetMatchsOfChampionship(
    championshipId: string | undefined,
    page?: number,
  ): Promise<void>;
  matchsOfChampionship: IMatch[];
  loadingMatchsOfChampionship: boolean;
  isOnLastPageOfMatchsOfChampionship: boolean;
  loadMoreMatchsOfChampionship(): void;
  prevStep(): void;
  nextStep(): void;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { format, parseISO } = dateFns;

const Match: React.FC<IMatchProps> = ({
  formik,
  championships,
  loadingChampionships,
  isOnLastPageOfChampionships,
  loadMoreChampionships,
  handleGetMatchsOfChampionship,
  matchsOfChampionship,
  loadingMatchsOfChampionship,
  isOnLastPageOfMatchsOfChampionship,
  loadMoreMatchsOfChampionship,
  prevStep,
  nextStep,
}) => {
  const intl = useIntl();

  function eneableNextStepButton({
    values,
  }: FormikProps<INewRoomFormData>): boolean {
    if (values.championship && values.match) {
      return true;
    }

    return false;
  }

  const handleSelectChampionship = useCallback(
    (championship: IChampionship | null) => {
      formik.setFieldValue('championship', championship);

      if (!championship) {
        formik.setFieldValue('match', null);
        handleGetMatchsOfChampionship(undefined);
        return;
      }
      handleGetMatchsOfChampionship(championship._id);
    },
    [formik, handleGetMatchsOfChampionship],
  );

  const championshipsViewer = useMemo(() => {
    if (loadingChampionships && championships.length === 0) {
      return (
        <SessionContainer>
          <p>
            {intl.getTranslatedText(
              'pages.versus.newRoom.messages.selectChampionship',
            )}
          </p>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>
                {intl.getTranslatedText(
                  'pages.versus.newRoom.messages.loadingChampionships',
                )}
              </p>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (!formik.values.championship) {
      return (
        <SessionContainer>
          <p>
            {intl.getTranslatedText(
              'pages.versus.newRoom.messages.selectChampionship',
            )}
          </p>
          {/* <Search placeholder="Pesquisar campeonato" /> */}
          <ul>
            {championships.map(championship => (
              <ChampionshipSelector
                key={championship._id}
                onClick={() => handleSelectChampionship(championship)}
              >
                <img
                  src={
                    championship?.id === 'AMATEUR'
                      ? logoAmateur
                      : championship?.imageUrl
                  }
                  alt={championship.name}
                />
                <h6>{championship.name}</h6>
              </ChampionshipSelector>
            ))}
            {!isOnLastPageOfChampionships && (
              <LoadMoreButton
                disabled={loadingChampionships}
                onClick={loadMoreChampionships}
              >
                <p>
                  {!loadingChampionships
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              </LoadMoreButton>
            )}
          </ul>
        </SessionContainer>
      );
    }

    return (
      <SessionContainer>
        <strong>
          {intl.getTranslatedText(
            'pages.versus.newRoom.form.selectedChampionship.label',
          )}
        </strong>
        <ChampionshipSelector onClick={() => handleSelectChampionship(null)}>
          <img
            src={
              formik.values.championship.id === 'AMATEUR'
                ? logoAmateur
                : formik.values.championship.imageUrl
            }
            alt={formik.values.championship.name}
          />
          <h6>{formik.values.championship.name}</h6>
          <FiEdit2 size={20} />
        </ChampionshipSelector>
      </SessionContainer>
    );
  }, [
    championships,
    formik.values.championship,
    handleSelectChampionship,
    intl,
    isOnLastPageOfChampionships,
    loadMoreChampionships,
    loadingChampionships,
  ]);

  const matchsViewer = useMemo(() => {
    if (!formik.values.championship) {
      return <></>;
    }

    if (loadingMatchsOfChampionship && matchsOfChampionship.length === 0) {
      return (
        <SessionContainer>
          <p>
            {intl.getTranslatedText(
              'pages.versus.newRoom.messages.selectMatch',
            )}
          </p>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>
                {intl.getTranslatedText(
                  'pages.versus.newRoom.messages.loadingMatchs',
                )}
              </p>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (matchsOfChampionship.length === 0) {
      return (
        <SessionContainer>
          <p>
            {intl.getTranslatedText(
              'pages.versus.newRoom.messages.selectMatch',
            )}
          </p>
          <LoadingAndNotFoundContainer>
            <div>
              <h6>
                {intl.getTranslatedTextWithHTML(
                  'pages.versus.newRoom.messages.noChampionshipMatchs',
                )}
              </h6>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (!formik.values.match) {
      return (
        <SessionContainer>
          <p>
            {intl.getTranslatedText(
              'pages.versus.newRoom.messages.selectMatch',
            )}
          </p>
          <ul>
            {matchsOfChampionship.map(match => (
              <MatchSelector
                key={match._id}
                onClick={() => formik.setFieldValue('match', match)}
              >
                <div>
                  <MatchSelectorTeam>
                    <div>
                      <img src={match.local.image} alt={match.local.name} />
                    </div>
                    <strong>{match.local.name}</strong>
                  </MatchSelectorTeam>
                  <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
                  <MatchSelectorTeam>
                    <div>
                      <img src={match.away.image} alt={match.away.name} />
                    </div>
                    <strong>{match.away.name}</strong>
                  </MatchSelectorTeam>
                </div>
                <MatchInfoContainer>
                  <div>
                    <small>
                      {intl.getTranslatedText(
                        'pages.versus.newRoom.form.matchInfo.date.label',
                      )}
                    </small>
                    <small>
                      {format(
                        parseISO(match.info.date),
                        intl.getTranslatedText('common.formatDates.fullDate'),
                        {
                          locale: intl.getDatefnsLocale(),
                        },
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {intl.getTranslatedText(
                        'pages.versus.newRoom.form.matchInfo.place.label',
                      )}
                    </small>
                    <small>{match.info.place}</small>
                  </div>
                </MatchInfoContainer>
              </MatchSelector>
            ))}
            {!isOnLastPageOfMatchsOfChampionship && (
              <LoadMoreButton
                disabled={loadingMatchsOfChampionship}
                onClick={loadMoreMatchsOfChampionship}
              >
                <p>
                  {!loadingMatchsOfChampionship
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              </LoadMoreButton>
            )}
          </ul>
        </SessionContainer>
      );
    }

    return (
      <SessionContainer>
        <strong>
          {intl.getTranslatedText(
            'pages.versus.newRoom.form.selectedMatch.label',
          )}
        </strong>
        <MatchSelector
          $selected
          onClick={() => formik.setFieldValue('match', null)}
        >
          <div>
            <MatchSelectorTeam>
              <div>
                <img
                  src={formik.values.match.local.image}
                  alt={formik.values.match.local.name}
                />
              </div>
              <strong>{formik.values.match.local.name}</strong>
            </MatchSelectorTeam>
            <h6>{intl.getTranslatedText('common.vsDescription')}</h6>
            <MatchSelectorTeam>
              <div>
                <img
                  src={formik.values.match.away.image}
                  alt={formik.values.match.away.name}
                />
              </div>
              <strong>{formik.values.match.away.name}</strong>
            </MatchSelectorTeam>
          </div>
          <MatchInfoContainer>
            <div>
              <small>
                {intl.getTranslatedText(
                  'pages.versus.newRoom.form.matchInfo.date.label',
                )}
              </small>
              <small>
                {format(
                  parseISO(formik.values.match.info.date),
                  intl.getTranslatedText('common.formatDates.fullDate'),
                  {
                    locale: intl.getDatefnsLocale(),
                  },
                )}
              </small>
            </div>
            <div>
              <small>
                {intl.getTranslatedText(
                  'pages.versus.newRoom.form.matchInfo.place.label',
                )}
              </small>
              <small>{formik.values.match.info.place}</small>
            </div>
          </MatchInfoContainer>
          <FiEdit2 size={20} />
        </MatchSelector>
      </SessionContainer>
    );
  }, [
    formik,
    intl,
    isOnLastPageOfMatchsOfChampionship,
    loadMoreMatchsOfChampionship,
    loadingMatchsOfChampionship,
    matchsOfChampionship,
  ]);

  return (
    <Container>
      {championshipsViewer}
      {matchsViewer}
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          {intl.getTranslatedText('common.buttons.goBack')}
        </CustomAntButton>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          {intl.getTranslatedText('common.buttons.next')}
        </CustomAntButton>
      </ButtonsContainer>
    </Container>
  );
};

export default Match;
