import React from 'react';

import { InternationalizationWrapperProvider } from './InternationalizationWrapperContext';
import { IntlProvider } from './IntlContext';
import { AuthProvider } from './AuthContext';
import { LoadingMaskedProvider } from './LoadingMaskedContext';
import { ChatProvider } from './ChatContext';
import { ChatP2PProvider } from './ChatP2PContext';
import { ShareProvider } from './ShareContext';
import { BulletProvider } from './BulletContext';

const AppProvider: React.FC = ({ children }) => (
  <InternationalizationWrapperProvider>
    <IntlProvider>
      <AuthProvider>
        <BulletProvider>
          <LoadingMaskedProvider>
            <ShareProvider>
              <ChatProvider>
                <ChatP2PProvider>{children}</ChatP2PProvider>
              </ChatProvider>
            </ShareProvider>
          </LoadingMaskedProvider>
        </BulletProvider>
      </AuthProvider>
    </IntlProvider>
  </InternationalizationWrapperProvider>
);

export default AppProvider;
