import React from 'react';
import { Input, Spin } from 'antd';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GiCardRandom } from 'react-icons/gi';
import { IoIosFlag } from 'react-icons/io';
import { FaCrown, FaFlagCheckered } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';

import Env from '../../../../config/Environment';
import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { useIntl } from '../../../../context/IntlContext';

import { ICoachFriendRoom, IPlayerGame, ITeam } from '..';

import {
  Container,
  SearchContainer,
  GameContainer,
  PlayCoachGoalSession,
  PayGoalSession,
  MinuteOfFirstGoalSession,
  TeamImageContainer,
  ThrowContainer,
  SelectedPlayerOfThrow,
  CoachSession,
  SelectedQuantitityOfCardContainer,
  LoadingAndNotFoundContainer,
} from './styles';

interface IGames {
  games: IPlayerGame[];
  room: ICoachFriendRoom;
  match: {
    info: {
      place: string;
      date: string;
    };
    local: ITeam;
    away: ITeam;
  };
  handleCollapseGame(gameId: string): void;
  loadingGamesOfRoom: boolean;
}

const { Search } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Friends: React.FC<IGames> = ({
  games,
  room,
  match,
  handleCollapseGame,
  loadingGamesOfRoom,
}) => {
  const intl = useIntl();

  if (loadingGamesOfRoom) {
    return (
      <Container>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.coachFriend.room.games.messages.loadingGames',
              )}
            </p>
          </div>
        </LoadingAndNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <SearchContainer>
        <Search
          placeholder={intl.getTranslatedText(
            'pages.coachFriend.room.games.searchGameInput.placeholder',
          )}
        />
      </SearchContainer>
      {!room.isFinished && (
        <small>
          {intl.getTranslatedText(
            'pages.coachFriend.room.games.messages.partialInfo',
          )}
        </small>
      )}
      {games.length > 0 ? (
        <ul>
          {games.map((game, index) => (
            <GameContainer
              key={game.games._id}
              onClick={() => handleCollapseGame(game.games._id)}
              $champion={index === 0 && game.games.totalScore > 0}
              $secondOrThirdPosition={
                (index === 1 || index === 2) && game.games.totalScore > 0
              }
            >
              <div>
                <img
                  src={
                    game._player.photo
                      ? Env.IMAGE_SERVER_URL + game._player.photo
                      : defaultAvatar
                  }
                  alt={game._player.name}
                />
                <p>{game._player.name}</p>
                <div>
                  {index === 0 && game.games.totalScore > 0 && (
                    <FaCrown size={18} />
                  )}
                  <p>
                    <span>
                      {game.games.totalScore > 0
                        ? intl.getTranslatedText('common.position', {
                            position: index + 1,
                          })
                        : intl.getTranslatedText('common.noPointsAbbrev')}
                    </span>{' '}
                    | {game.games.totalScore > 0 ? game.games.totalScore : '--'}{' '}
                    pontos
                  </p>
                </div>
                {!game.collapsedOnList ? (
                  <FiChevronDown size={24} />
                ) : (
                  <FiChevronUp size={24} />
                )}
              </div>
              <PlayCoachGoalSession>
                {game.collapsedOnList && (
                  <h6>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.playCoachGoal.title',
                    )}
                  </h6>
                )}
                <div>
                  <strong>{match.local.name}</strong>
                  <strong>{game.games.localScore}</strong>
                </div>
                <div>
                  <strong>{match.away.name}</strong>
                  <strong>{game.games.awayScore}</strong>
                </div>
              </PlayCoachGoalSession>
              {game.collapsedOnList && (
                <>
                  <MinuteOfFirstGoalSession>
                    <h6>
                      {intl.getTranslatedText(
                        'pages.coachFriend.room.minuteOfFirstGoal.title',
                      )}
                    </h6>
                    <div>
                      <div>
                        <div>
                          <TeamImageContainer>
                            <img
                              src={match.local.image}
                              alt={match.local.name}
                            />
                          </TeamImageContainer>
                          <p>{match.local.name}</p>
                        </div>
                        <div>
                          {game.games.localTime ? (
                            <>
                              <small>
                                {intl.getTranslatedText(
                                  'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                                )}
                              </small>
                              <strong>{game.games.localTime}</strong>
                            </>
                          ) : (
                            <p>Esse time não marcou gols</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <div>
                          <TeamImageContainer>
                            <img src={match.away.image} alt={match.away.name} />
                          </TeamImageContainer>
                          <p>{match.away.name}</p>
                        </div>
                        <div>
                          {game.games.awayTime ? (
                            <>
                              <small>
                                {intl.getTranslatedText(
                                  'pages.coachFriend.room.minuteOfFirstGoal.interval.label',
                                )}
                              </small>
                              <strong>{game.games.awayTime}</strong>
                            </>
                          ) : (
                            <p>Esse time não marcou gols</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </MinuteOfFirstGoalSession>
                  <PayGoalSession>
                    <h6>
                      {intl.getTranslatedText(
                        'pages.coachFriend.room.athletes.title',
                      )}
                    </h6>
                    <div>
                      <div>
                        <div>
                          <TeamImageContainer>
                            <img
                              src={match.local.image}
                              alt={match.local.name}
                            />
                          </TeamImageContainer>
                          <p>{match.local.name}</p>
                        </div>
                        <ul>
                          {game.games.localPlayers &&
                            game.games.localPlayers?.length >= 1 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw1',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.localPlayers[0]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {game.games.localPlayers &&
                            game.games.localPlayers?.length >= 2 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw2',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.localPlayers[1]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {game.games.localPlayers &&
                            game.games.localPlayers?.length >= 3 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw3',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.localPlayers[2]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {!game.games.localPlayers?.length && (
                            <p>Esse time não marcou gols</p>
                          )}
                        </ul>
                      </div>
                      <div>
                        <div>
                          <TeamImageContainer>
                            <img src={match.away.image} alt={match.away.name} />
                          </TeamImageContainer>
                          <p>{match.away.name}</p>
                        </div>
                        <ul>
                          {game.games.awayPlayers &&
                            game.games.awayPlayers?.length >= 1 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw1',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.awayPlayers[0]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {game.games.awayPlayers &&
                            game.games.awayPlayers?.length >= 2 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw2',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.awayPlayers[1]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {game.games.awayPlayers &&
                            game.games.awayPlayers?.length >= 3 && (
                              <ThrowContainer>
                                <small>
                                  {intl.getTranslatedText(
                                    'pages.coachFriend.room.athletes.throw3',
                                  )}
                                </small>
                                <SelectedPlayerOfThrow>
                                  <p>
                                    {game.games.awayPlayers[2]?.playerName ||
                                      intl.getTranslatedText(
                                        'pages.coachFriend.room.athletes.throw.notSelected',
                                      )}
                                  </p>
                                </SelectedPlayerOfThrow>
                              </ThrowContainer>
                            )}
                          {!game.games.awayPlayers?.length && (
                            <p>Esse time não marcou gols</p>
                          )}
                        </ul>
                      </div>
                    </div>
                  </PayGoalSession>
                  <CoachSession>
                    <h6>
                      {intl.getTranslatedText(
                        'pages.coachFriend.room.statistics.title',
                      )}
                    </h6>
                    <SelectedQuantitityOfCardContainer $typeOfStatistic="yellowCards">
                      <div>
                        <GiCardRandom size={21} />
                      </div>
                      <strong>
                        {intl.getTranslatedText(
                          'pages.coachFriend.room.statistics.yellowCards.label',
                        )}
                      </strong>
                      <p>{game.games.yellowCards}</p>
                    </SelectedQuantitityOfCardContainer>
                    <SelectedQuantitityOfCardContainer $typeOfStatistic="redCards">
                      <div>
                        <GiCardRandom size={21} />
                      </div>
                      <strong>
                        {intl.getTranslatedText(
                          'pages.coachFriend.room.statistics.redCards.label',
                        )}
                      </strong>
                      <p>{game.games.redCards}</p>
                    </SelectedQuantitityOfCardContainer>
                    <SelectedQuantitityOfCardContainer $typeOfStatistic="offside">
                      <div>
                        <FaFlagCheckered size={16} />
                      </div>
                      <strong>
                        {intl.getTranslatedText(
                          'pages.coachFriend.room.statistics.offsideNumber.label',
                        )}
                      </strong>
                      <p>{game.games.offsideNumber}</p>
                    </SelectedQuantitityOfCardContainer>
                    <SelectedQuantitityOfCardContainer $typeOfStatistic="corner">
                      <div>
                        <IoIosFlag size={21} />
                      </div>
                      <strong>
                        {intl.getTranslatedText(
                          'pages.coachFriend.room.statistics.cornerNumber.label',
                        )}
                      </strong>
                      <p>{game.games.cornerNumber}</p>
                    </SelectedQuantitityOfCardContainer>
                  </CoachSession>
                </>
              )}
            </GameContainer>
          ))}
        </ul>
      ) : (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.coachFriend.room.games.messages.noGames',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      )}
    </Container>
  );
};

export default Friends;
