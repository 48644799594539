import React, { useMemo } from 'react';
import * as dateFns from 'date-fns';

import { useIntl } from '../../../../../../context/IntlContext';

import { IMatch } from '../../index';

import {
  Container,
  MatchCardScoreboardContainer,
  MatchCardScoreboardTeam,
  MatchCardPlayersContainer,
  MatchCardInfoContainer,
  MatchIsInLive,
} from './styles';

const { format, parseISO, isAfter } = dateFns;

const MatchCard: React.FC<IMatch> = ({
  info,
  away,
  local,
  finished,
  localScore,
  awayScore,
  localPlayers,
  awayPlayers,
}) => {
  const intl = useIntl();

  const matchIsStarted = useMemo(() => {
    return isAfter(new Date(), parseISO(info.date));
  }, [info.date]);

  return (
    <Container>
      <div>
        <h6>
          {local.name.toUpperCase()}{' '}
          {intl.getTranslatedText('common.vsDescription')}{' '}
          {away.name.toUpperCase()}
        </h6>
        {matchIsStarted && !finished && (
          <MatchIsInLive>{intl.getTranslatedText('common.live')}</MatchIsInLive>
        )}
      </div>
      <MatchCardScoreboardContainer>
        <MatchCardScoreboardTeam>
          <div>
            <img src={local.image} alt={local.name} />
          </div>
          <strong>{local.abbrev.toUpperCase()}</strong>
        </MatchCardScoreboardTeam>
        <h6>
          {matchIsStarted ? localScore : '-'}
          <span>x</span>
          {matchIsStarted ? awayScore : '-'}
        </h6>
        <MatchCardScoreboardTeam>
          <strong>{away.abbrev.toUpperCase()}</strong>
          <div>
            <img src={away.image} alt={away.name} />
          </div>
        </MatchCardScoreboardTeam>
      </MatchCardScoreboardContainer>
      <MatchCardPlayersContainer>
        {matchIsStarted ? (
          <>
            <div>
              <small>
                {localPlayers.length > 0 ? (
                  <>
                    {intl.getTranslatedTextWithHTML(
                      'pages.championship.cardGame.results.matchCard.firstGoal',
                      {
                        playerName: localPlayers[0].playerName,
                      },
                    )}
                  </>
                ) : (
                  intl.getTranslatedText(
                    'pages.championship.cardGame.results.matchCard.noGoals',
                  )
                )}
              </small>
            </div>
            <div>
              <small>
                {awayPlayers.length > 0 ? (
                  <>
                    {intl.getTranslatedTextWithHTML(
                      'pages.championship.cardGame.results.matchCard.firstGoal',
                      {
                        playerName: awayPlayers[0].playerName,
                      },
                    )}
                  </>
                ) : (
                  intl.getTranslatedText(
                    'pages.championship.cardGame.results.matchCard.noGoals',
                  )
                )}
              </small>
            </div>
          </>
        ) : (
          <p>
            {intl.getTranslatedText(
              'pages.championship.cardGame.results.matchCard.matchDidNotHappen',
            )}
          </p>
        )}
      </MatchCardPlayersContainer>
      <MatchCardInfoContainer>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.place')}</small>
          <small>{info.place}</small>
        </div>
        <div>
          <small>{intl.getTranslatedText('common.matchInfo.date')}</small>
          <small>
            {format(
              parseISO(info.date),
              intl.getTranslatedText('common.formatDates.fullDate'),
              {
                locale: intl.getDatefnsLocale(),
              },
            )}
          </small>
        </div>
      </MatchCardInfoContainer>
    </Container>
  );
};

export default MatchCard;
