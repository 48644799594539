import styled from 'styled-components';

export const Container = styled.div``;

export const Session = styled.div`
  > h6 {
    margin-bottom: 8px;
  }

  & + div {
    margin-top: 32px;
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const DescriptionItem = styled.li`
  list-style: none;

  & + li {
    margin-top: 8px;
  }

  small {
    color: ${props => props.theme.colors.light['light-2']};
    color: ${props => props.theme.colors.primary.primary0};
    text-align: start;
  }
`;

export const ChampionshipSelected = styled.div`
  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;

  img {
    width: 33px;
  }

  h6 {
    color: ${props => props.theme.colors.light.light0};
    margin-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.light.light0};
  }
`;

export const MatchSelected = styled.div`
  border: 0;
  width: 100%;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-bottom: 8px;

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.light['light-2']};
      margin: 0 24px;
    }
  }

  > svg {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const MatchSelectedTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    img {
      height: 36px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light.light0};
    text-align: center;
  }
`;

export const MatchInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-top: 1px solid ${props => props.theme.colors.dark['dark+4']};

  div {
    width: 100%;

    small {
      color: ${props => props.theme.colors.light['light-2']};
      text-align: start;
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
