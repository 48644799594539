import React, { useMemo } from 'react';
import { Spin, AutoComplete } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { FaCrown } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';

import Env from '../../../../../config/Environment';
import { IRankingPosition, IRankingSearchUser } from '../index';

import defaultAvatar from '../../../../../assets/DefaultAvatar.svg';

import { useIntl } from '../../../../../context/IntlContext';

import {
  Container,
  Top3Item,
  RankingPlayerItem,
  SearchPlayerItem,
  ViewMoreButton,
  LoadingAndNotFoundContainer,
  UserIsMeIndicator,
  SearchAutocompleteContentContainer,
  SelectedOnSearchUser,
} from './styles';

interface IRouteParams {
  id: string;
  card_game_id: string;
}

interface IRankingProps {
  ranking: IRankingPosition[];
  cardType: 'new' | 'running' | 'finished';
  loading: boolean;
  loadMoreRanking(): Promise<void>;
  isOnLastPageOfRanking: boolean;
  handleSearchUser(value: string): void;
  searchUserValue: string;
  loadingSearchUsers: boolean;
  findedOnSearchUsers: IRankingSearchUser[];
  isOnLastPageOfSearchUsers: boolean;
  loadMoreSearchUsers(): Promise<void>;
  handleGetUserSelected(value: string, page?: string): Promise<void>;
  userSelectedOnSearch: IRankingSearchUser | null;
  isOnLastPageOfGamesUserSelectedOnSearch: boolean;
  loadMoreGamesUserSelectedOnSearch(): Promise<void>;
  loadingUserSelectedOnSearch: boolean;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO, format } = dateFns;

const Ranking: React.FC<IRankingProps> = ({
  ranking,
  loading,
  cardType,
  loadMoreRanking,
  isOnLastPageOfRanking,
  handleSearchUser,
  searchUserValue,
  loadingSearchUsers,
  findedOnSearchUsers,
  isOnLastPageOfSearchUsers,
  loadMoreSearchUsers,
  handleGetUserSelected,
  userSelectedOnSearch,
  isOnLastPageOfGamesUserSelectedOnSearch,
  loadMoreGamesUserSelectedOnSearch,
  loadingUserSelectedOnSearch,
}) => {
  const { url } = useRouteMatch<IRouteParams>();

  const intl = useIntl();

  const restRanking = useMemo(() => {
    return ranking.slice(3);
  }, [ranking]);

  const searchResultsViewer = useMemo(() => {
    if (searchUserValue.length <= 3) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <p>{intl.getTranslatedText('common.messages.minCharToSearch')}</p>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (loadingSearchUsers) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            </div>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (findedOnSearchUsers.length > 0) {
      return (
        <>
          {findedOnSearchUsers?.map(user => (
            <AutoComplete.Option value={user.username} key={user._id}>
              <SearchPlayerItem>
                <img
                  src={Env.IMAGE_SERVER_URL + user?.photo?.filename}
                  alt={user?.name}
                />
                <div>
                  <p>{user?.name || '---'}</p>
                  <small>{user?.username || '---'}</small>
                </div>
                {user.isMe && (
                  <UserIsMeIndicator>
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </SearchPlayerItem>
            </AutoComplete.Option>
          ))}
          {!isOnLastPageOfSearchUsers && (
            <AutoComplete.Option value="" disabled>
              <ViewMoreButton
                disabled={loadingSearchUsers}
                onClick={loadMoreSearchUsers}
              >
                {!loadingSearchUsers ? (
                  <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
                ) : (
                  <p>
                    {intl.getTranslatedText('common.messages.defaultLoading')}
                  </p>
                )}
              </ViewMoreButton>
            </AutoComplete.Option>
          )}
        </>
      );
    }
    return (
      <AutoComplete.Option value="" disabled>
        <SearchAutocompleteContentContainer>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.championship.cardGame.ranking.messages.userNotFound',
            )}
          </h6>
        </SearchAutocompleteContentContainer>
      </AutoComplete.Option>
    );
  }, [
    findedOnSearchUsers,
    intl,
    isOnLastPageOfSearchUsers,
    loadMoreSearchUsers,
    loadingSearchUsers,
    searchUserValue.length,
  ]);

  const contentViewer = useMemo(() => {
    if (userSelectedOnSearch) {
      return (
        <SelectedOnSearchUser>
          <div>
            <img
              src={
                userSelectedOnSearch.photo
                  ? Env.IMAGE_SERVER_URL + userSelectedOnSearch.photo?.filename
                  : defaultAvatar
              }
              alt={userSelectedOnSearch.name}
            />
            <div>
              <p>{userSelectedOnSearch.name}</p>
              <small>{userSelectedOnSearch.username}</small>
            </div>
            {userSelectedOnSearch.isMe && (
              <UserIsMeIndicator>
                {intl.getTranslatedText('common.meIdentifier')}
              </UserIsMeIndicator>
            )}
          </div>
          {userSelectedOnSearch.games.length > 0 ? (
            <ul>
              {userSelectedOnSearch.games.map(game => (
                <Link
                  to={`${url}/${
                    userSelectedOnSearch.isMe ? 'my_games' : 'users_games'
                  }/${game._id}`}
                  key={game._id}
                >
                  <p>
                    {format(
                      parseISO(game.createdAt),
                      intl.getTranslatedText('common.formatDates.carriedOut'),
                    )}
                  </p>
                  <small>
                    {game.totalScore}{' '}
                    {intl.getTranslatedText('common.scoredPointsDescription')}
                  </small>
                </Link>
              ))}
              {!isOnLastPageOfGamesUserSelectedOnSearch && (
                <button
                  onClick={loadMoreGamesUserSelectedOnSearch}
                  type="button"
                >
                  {!loadingUserSelectedOnSearch
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </button>
              )}
            </ul>
          ) : (
            <div>
              <p>
                {intl.getTranslatedText(
                  'pages.championship.cardGame.ranking.messages.userNotHasGameOnThisCard',
                )}
              </p>
            </div>
          )}
        </SelectedOnSearchUser>
      );
    }

    if (ranking.length === 0) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.championship.cardGame.ranking.messages.noRanking',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    return (
      <>
        <section>
          <Top3Item
            to={`${url}/${
              ranking[1]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[1]?._id}`}
            onClick={e => !ranking[1]?._id && e.preventDefault()}
            $disabled={!ranking[1]?._id}
          >
            <p>2</p>
            <img
              src={
                ranking[1]?._user?.photo
                  ? Env.IMAGE_SERVER_URL + ranking[1]?._user?.photo?.filename
                  : defaultAvatar
              }
              alt={ranking[1]?._user?.name || '-----'}
            />
            <p>{ranking[1]?._user?.name || '-----'}</p>
            <small>{ranking[1]?._user?.username || '---'}</small>
            <small>
              {`${ranking[1]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
          <Top3Item
            to={`${url}/${
              ranking[0]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[0]?._id}`}
            onClick={e => !ranking[0]?._id && e.preventDefault()}
            $disabled={!ranking[0]?._id}
          >
            <p>1</p>
            <FaCrown size={24} />
            <img
              src={
                ranking[0]?._user?.photo
                  ? Env.IMAGE_SERVER_URL + ranking[0]?._user?.photo?.filename
                  : defaultAvatar
              }
              alt={ranking[0]?._user?.name || '-----'}
            />
            <p>{ranking[0]?._user?.name || '-----'}</p>
            <small>{ranking[0]?._user?.username || '---'}</small>
            <small>
              {`${ranking[0]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
          <Top3Item
            to={`${url}/${
              ranking[2]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[2]?._id}`}
            onClick={e => !ranking[2]?._id && e.preventDefault()}
            $disabled={!ranking[2]?._id}
          >
            <p>3</p>
            <img
              src={
                ranking[2]?._user?.photo
                  ? Env.IMAGE_SERVER_URL + ranking[2]?._user?.photo?.filename
                  : defaultAvatar
              }
              alt={ranking[2]?._user?.name || '-----'}
            />
            <p>{ranking[2]?._user?.name || '-----'}</p>
            <small>{ranking[2]?._user?.username || '---'}</small>
            <small>
              {`${ranking[2]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
        </section>
        <ul>
          {restRanking.map((rankPosition, index) => (
            <RankingPlayerItem
              to={`${url}/${
                rankPosition?._user?.isMe === false ? 'users_games' : 'my_games'
              }/${rankPosition._id}`}
              key={rankPosition._id}
            >
              <p>{index + 4}</p>
              <img
                src={
                  Env.IMAGE_SERVER_URL + rankPosition?._user?.photo?.filename
                }
                alt={rankPosition?._user?.name}
              />
              <div>
                <p>{rankPosition?._user?.name || '---'}</p>
                <small>{rankPosition?._user?.username || '---'}</small>
              </div>
              <div>
                <small>
                  {rankPosition.totalScore}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </small>
                {rankPosition?._user?.isMe && (
                  <UserIsMeIndicator>
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </div>
            </RankingPlayerItem>
          ))}
          {!isOnLastPageOfRanking && (
            <ViewMoreButton disabled={loading} onClick={loadMoreRanking}>
              {!loading ? (
                <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
              ) : (
                <p>
                  {intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              )}
            </ViewMoreButton>
          )}
        </ul>
      </>
    );
  }, [
    intl,
    isOnLastPageOfGamesUserSelectedOnSearch,
    isOnLastPageOfRanking,
    loadMoreGamesUserSelectedOnSearch,
    loadMoreRanking,
    loading,
    loadingUserSelectedOnSearch,
    ranking,
    restRanking,
    url,
    userSelectedOnSearch,
  ]);

  if (loading && ranking.length === 0) {
    return (
      <LoadingAndNotFoundContainer>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.championship.cardGame.ranking.messages.loadingRanking',
            )}
          </p>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }

  if (loadingUserSelectedOnSearch && !userSelectedOnSearch) {
    return (
      <LoadingAndNotFoundContainer>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.championship.cardGame.ranking.messages.loadingUserGames',
            )}
          </p>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }

  return (
    <Container>
      <div>
        {cardType === 'running' && (
          <small>
            {intl.getTranslatedText(
              'pages.championship.cardGame.ranking.messages.partialInfo',
            )}
          </small>
        )}
        <AutoComplete
          placeholder={intl.getTranslatedText(
            'pages.championship.cardGame.ranking.searchUserInput.placeholder',
          )}
          onSearch={handleSearchUser}
          onSelect={(value: string) => handleGetUserSelected(value)}
          value={searchUserValue}
          notFoundContent="User not found"
        >
          {searchResultsViewer}
        </AutoComplete>
      </div>
      {contentViewer}
    </Container>
  );
};

export default Ranking;
