import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 56px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 16px 56px;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export const Content = styled.div`
  padding: 32px 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 587px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    padding: 16px 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;
