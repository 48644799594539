import React from 'react';

import { useIntl } from '../../../../../context/IntlContext';

import { ICoachFriendRoom, IMyDataInTheRoom } from '../..';

import {
  Container,
  Header,
  Content,
  TeamImageContainer,
  ThrowContainer,
  SelectedPlayerOfThrow,
} from './styles';
import { PointsDescription } from '../../styles';

interface IPayGoalMode {
  coachFriendRoom: ICoachFriendRoom;
  myDataInTheRoom: IMyDataInTheRoom;
}

const PayGoalMode: React.FC<IPayGoalMode> = ({
  coachFriendRoom,
  myDataInTheRoom,
}) => {
  const intl = useIntl();

  let sessionScore = 0;
  if (myDataInTheRoom._meGame?.localPlayers) {
    if (myDataInTheRoom._meGame?.localPlayers?.length >= 1) sessionScore += 10;
    if (myDataInTheRoom._meGame?.localPlayers?.length >= 2) sessionScore += 10;
    if (myDataInTheRoom._meGame?.localPlayers?.length >= 3) sessionScore += 10;
  }
  if (myDataInTheRoom._meGame?.awayPlayers) {
    if (myDataInTheRoom._meGame?.awayPlayers?.length >= 1) sessionScore += 10;
    if (myDataInTheRoom._meGame?.awayPlayers?.length >= 2) sessionScore += 10;
    if (myDataInTheRoom._meGame?.awayPlayers?.length >= 3) sessionScore += 10;
  }

  return (
    <Container>
      <Header>
        <h6>
          {intl.getTranslatedText('pages.coachFriend.room.athletes.title')}
        </h6>
        <PointsDescription>
          <span>{myDataInTheRoom?._meGame?.playerScore}</span>
          {` / ${sessionScore} `}
          {intl.getTranslatedText('common.scoredPointsDescription')}
        </PointsDescription>
      </Header>
      <Content>
        <div>
          <div>
            <TeamImageContainer>
              <img
                src={coachFriendRoom._game.local.image}
                alt={coachFriendRoom._game.local.name}
              />
            </TeamImageContainer>
            <strong>{coachFriendRoom._game.local.name}</strong>
          </div>
          <ul>
            {myDataInTheRoom._meGame?.localPlayers &&
              myDataInTheRoom._meGame?.localPlayers?.length >= 1 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw1',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.localPlayers[0].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {myDataInTheRoom._meGame?.localPlayers &&
              myDataInTheRoom._meGame?.localPlayers?.length >= 2 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw2',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.localPlayers[1].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {myDataInTheRoom._meGame?.localPlayers &&
              myDataInTheRoom._meGame?.localPlayers?.length >= 3 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw3',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.localPlayers[2].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {!myDataInTheRoom._meGame?.localPlayers?.length && (
              <p>Esse time não marcou gols</p>
            )}
          </ul>
        </div>
        <div>
          <div>
            <TeamImageContainer>
              <img
                src={coachFriendRoom._game.away.image}
                alt={coachFriendRoom._game.away.name}
              />
            </TeamImageContainer>
            <strong>{coachFriendRoom._game.away.name}</strong>
          </div>
          <ul>
            {myDataInTheRoom._meGame?.awayPlayers &&
              myDataInTheRoom._meGame?.awayPlayers?.length >= 1 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw1',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.awayPlayers[0].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {myDataInTheRoom._meGame?.awayPlayers &&
              myDataInTheRoom._meGame?.awayPlayers?.length >= 2 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw2',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.awayPlayers[1].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {myDataInTheRoom._meGame?.awayPlayers &&
              myDataInTheRoom._meGame?.awayPlayers?.length >= 3 && (
                <ThrowContainer>
                  <small>
                    {intl.getTranslatedText(
                      'pages.coachFriend.room.athletes.throw3',
                    )}
                  </small>
                  <SelectedPlayerOfThrow>
                    <p>
                      {myDataInTheRoom._meGame?.awayPlayers[2].playerName ||
                        intl.getTranslatedText(
                          'pages.coachFriend.room.athletes.throw.notSelected',
                        )}
                    </p>
                  </SelectedPlayerOfThrow>
                </ThrowContainer>
              )}
            {!myDataInTheRoom._meGame?.awayPlayers?.length && (
              <p>Esse time não marcou gols</p>
            )}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default PayGoalMode;
