import React, { useState } from 'react';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';

import { useBullet } from '@/context/BulletContext';
import { FiEdit2 } from 'react-icons/fi';
import { FastField, FastFieldProps, FormikProps } from 'formik';
import { formatCpf } from '@/utils/formatters';
import UserAvatar from '@/components/UserAvatar';
import { ISetTransferValueProps } from './setTransferValue.types';

import { InputContainer } from '../styles';
import { SelectedUserContainer, SelectedUserField } from './styles';
import ConfirmAction from '../../ConfirmAction';
import { ITransferFormData } from '../transfer.types';

function enableTransferButton(
  formikProps: FormikProps<ITransferFormData>,
): boolean {
  const { touched, errors } = formikProps;

  const noErrors = !errors?.amount;
  const allFieldsTouched = touched?.amount;

  if (noErrors && allFieldsTouched) return true;
  return false;
}

const SetTransferValue: React.FC<ISetTransferValueProps> = ({
  formikProps,
  handleEditUser,
  handleNext,
}) => {
  const formValues = formikProps.values;
  const { selectedUser } = formValues;
  let selectedUserPhoto;

  if (selectedUser?.photo) {
    selectedUserPhoto =
      typeof formValues.selectedUser?.photo === 'object'
        ? formValues.selectedUser.photo._id
        : formValues.selectedUser?.photo;
  }

  const bullet = useBullet();
  const [shouldConfirmTransfer, setShouldConfirmTransfer] = useState(false);

  return (
    <>
      <SelectedUserContainer>
        <UserAvatar photoId={selectedUserPhoto} size={60} />
        <div>
          <h6>{selectedUser?.name}</h6>
          <p>{selectedUser?.username}</p>
          <SelectedUserField>
            <small>Email</small>
            <p>{selectedUser?.email}</p>
          </SelectedUserField>
          <SelectedUserField>
            <small>CPF</small>
            <p>{formatCpf(selectedUser?.docNumber as string)}</p>
          </SelectedUserField>
        </div>
        <CustomAntButton
          icon={<FiEdit2 size={20} />}
          useCustomIcon
          type="text"
          htmlType="button"
          onClick={() => handleEditUser()}
        />
      </SelectedUserContainer>
      <InputContainer>
        <h6>
          Qual o <span>valor</span> que você deseja transferir?
        </h6>
        <FastField name="amount">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                placeholder="ex.: 20"
                id="amountInput"
                addonBefore="T¢"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                onChange={e => {
                  const newValue = e.target.value;
                  formikProps.setFieldValue(
                    'amount',
                    newValue.replace(/\D/g, ''),
                  );
                }}
              />
            );
          }}
        </FastField>
      </InputContainer>
      <strong>
        Saldo disponível: <span>T¢{bullet.balance.amount}</span>
      </strong>
      {!shouldConfirmTransfer ? (
        <CustomAntButton
          htmlType="button"
          disabled={
            formikProps.isSubmitting || !enableTransferButton(formikProps)
          }
          type="primary"
          onClick={() => setShouldConfirmTransfer(true)}
        >
          Transferir
        </CustomAntButton>
      ) : (
        <ConfirmAction
          onConfirm={() => {
            handleNext();
            setShouldConfirmTransfer(false);
          }}
          onCancel={() => setShouldConfirmTransfer(false)}
        />
      )}
    </>
  );
};

export default SetTransferValue;
